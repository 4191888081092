import { API, Storage, graphqlOperation } from "aws-amplify";
import { createFile, createFolder, deleteFile, deleteFolder } from "../../graphql/mutations.js";
import { getFileByOrg, getFolderByOrg } from "../../graphql/queries.js";

export const getOrganisationFiles = async (organisationID) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID: organisationID,
			limit: 40
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getFileByOrg, queryParams));
			const { getFileByOrg: legacyStocks } = data;
			resolve(legacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};
export const getMemberFiles = async (employeeFile) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID: employeeFile
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getFileByOrg, queryParams));
			const { getFileByOrg: legacyStocks } = data;
			resolve(legacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveFile = async (fileDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			let fileMapped = {
				id: fileDetail.employeeFile,
				name: fileDetail.name,
				organisationID: fileDetail.organisationID,
				type: fileDetail.type,
				size: fileDetail.size,
				fileURI: fileDetail.fileURI
			};
			const {
				data: { createFile: data }
			} = await API.graphql({
				query: createFile,
				variables: { input: fileMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveFileToStorage = async (file, filePath) => {
	if (!file) return;
	try {
		const { key } = await Storage.put(filePath, file, {
			contentType: file?.type ?? "image/png"
		});
		return key;
	} catch (e) {
		console.log(e);
	}
};
export const getSignedUrl = async (fileURI) => {
	if (!fileURI) return;
	try {
		const signedURI = await Storage.get(fileURI, { download: false });
		return signedURI;
	} catch (e) {
		console.log(e);
	}
};

export const saveFolder = async (folderDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			let folderMapped = {
				name: folderDetail.name,
				organisationID: folderDetail.organisationID
			};
			const {
				data: { createFolder: data }
			} = await API.graphql({
				query: createFolder,
				variables: { input: folderMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrganisationFolders = async (organisationID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getFolderByOrg, {
					organisationID: organisationID,
					limit: 200
				})
			);
			const { getFolderByOrg: legacyStocks } = data;
			resolve(legacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteFileById = async (fileId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const {
				data: { deleteFile: data }
			} = await API.graphql({
				query: deleteFile,
				variables: { input: { id: fileId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const deleteFolderById = async (fileId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const {
				data: { deleteFolder: data }
			} = await API.graphql({
				query: deleteFolder,
				variables: { input: { id: fileId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteFileByIdFromStorage = async (fileUri) => {
	try {
		const result = await Storage.remove(fileUri);
		return result;
	} catch (e) {
		console.log(e);
	}
};
