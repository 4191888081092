import { useEffect, useContext, useState } from "react";
import { LinkButton, Button, useSimpleMessage, showFailure, useNavigate } from "ww-framework";
import { personUtils, orgUtils, PersonContext } from "ww-stores";
import { Dialog } from "@headlessui/react";
import { EmojiHappyIcon } from "@heroicons/react/outline";

export default function Invitations() {
	const { person, setPerson } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const [orgName, setOrgName] = useState("");
	const [inviteId, setInviteId] = useState("");
	const [orgMemberId, setOrgMemberId] = useState("");
	const navigate = useNavigate();
	const invite = person.invitations[0];
	useEffect(() => {
		if (invite?.orgMember?.organisation) {
			const {
				id,
				orgMember: { organisation },
				orgMemberID
			} = invite;
			setInviteId(id);
			setOrgMemberId(orgMemberID);
			setOrgName(organisation.name);
		} else if (invite?.id) {
			// lets delete that invite
			deleteInvitation(invite.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [person]);
	const deleteInvitation = async (invitationId) => {
		await orgUtils.deleteInvitation(invitationId);
		navigate("/dashboard");
	};

	const actionInvititation = async (inviteId, orgMemberId, action) => {
		try {
			let orgMember = {};
			if (action === "ACCEPT") {
				if (!person.id) {
					orgMember = await personUtils.saveProfile(person);
				} else {
					orgMember = person;
				}
			}

			const newOrg = await orgUtils.updateMember(orgMemberId, orgMember);
			await personUtils.actionInvitation(inviteId, action);

			if (action === "ACCEPT") {
				const newMember = {
					...orgMember,
					...{
						currentOrganisation: {
							id: newOrg.id,
							owner: newOrg.owner,
							name: newOrg.name,
							phone: newOrg?.phone || "",
							hrEmail: newOrg?.hrEmail || "",
							paidBreak: newOrg?.paidBreak || false,
							allPaidBreak: newOrg?.allPaidBreak || false,
							autoBreak: newOrg?.autoBreak || false,
							address: newOrg?.address || "",
							StaffCost: newOrg?.StaffCost || ""
						}
					}
				};

				newMember.organisations.items.push(newOrg);
				newMember.organisations = { items: [newOrg] };

				setPerson(newMember);
			}
			navigate("/dashboard");
			window.location.reload();
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to add Staff Member.",
					subTitle: error.message
				})
			);
		}
	};

	return (
		<div className="p-5">
			<div className="sm:flex sm:items-start">
				<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lightww-100 sm:mx-0 sm:h-10 sm:w-10">
					<EmojiHappyIcon className="h-6 w-6 text-darkww-600" aria-hidden="true" />
				</div>
				<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
					<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
						You have an Invitation!
					</Dialog.Title>
					<div className="mt-2">
						<div className="text-sm text-gray-500">
							{`Great News, you've been asked to join `}
							<span className="font-semibold text-gray-800">{orgName}</span>
							{`.`}
							<p /> {`All you have to do is hit the Accept button below to get all of your shift details.`}
						</div>
					</div>
				</div>
			</div>
			{inviteId && (
				<div className="mt-5 flex justify-end gap-5">
					<LinkButton
						label="Decline"
						className="text-red-600 font-semibold hover:text-red-900"
						onClick={() => actionInvititation(inviteId, orgMemberId, "DECLINE")}
					/>
					<Button label="Accept" onClick={() => actionInvititation(inviteId, orgMemberId, "ACCEPT")} />
				</div>
			)}
		</div>
	);
}
