import { API, graphqlOperation } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { Avatar } from "ww-framework";
import { OrganisationContext } from "ww-stores";
import { getProfileByPerson } from "../../../../../graphql/queries";
import CompleteCheckIns from "./CompleteCheckIns";
import LeaveRecords from "./LeaveRecords";
import StaffDetailsTabs from "./StaffDetailsTabs";
import StaffDocuments from "./StaffDocuments";
import StaffNotes from "./StaffNotes";

export const ProfileInformation = ({ userDetails }) => {
	if (!userDetails) {
		return null; // or a loading spinner
	}
	// ...

	return (
		<>
			{userDetails && (
				<div className="min-w-0 flex-1 flex items-center space-x-3">
					<div className="block flex-shrink-0">
						<div className="rounded-full border-4 border-darkww-600 h-16 w-16 pt-1 pl-1" style={{ borderColor: `rgb(236, 135, 2)` }}>
							<Avatar user={userDetails} size="h-12 w-12" />
						</div>
						<div className="relative">
							<div className="absolute bottom-0 left-12">
								<Avatar user={userDetails.avatarURI} className="h-8 w-8" aria-hidden="true" fill="currentColor" />
							</div>
						</div>
					</div>
					<div className="block min-w-0 flex-1 pl-2">
						<span className="block text-sm font-medium text-gray-900 truncate">
							{userDetails.firstName} {userDetails.lastName} ({userDetails.username})
						</span>
						<div className="">
							<span className="inline-block text-sm font-medium text-gray-900">Phone: {userDetails.employeePhone}</span>
							<span className=" text-sm font-medium text-gray-900 ml-4">PIN: {userDetails.pin}</span>
						</div>
						<span className="block text-sm text-gray-500">Emergency: {userDetails.emergencyNo}</span>
					</div>
				</div>
			)}
		</>
	);
};

export const StaffDetails = ({ currentMemberOrgUserId, updateOrgAndMembers, upsertToEmployee }) => {
	const { organisation } = useContext(OrganisationContext);
	const [userProfile, setUserProfile] = useState(null);
	const [currentMemberDetail, setCurrentMemberDetail] = useState(
		organisation.members.find((member) => member.orgUserId === currentMemberOrgUserId)
	);

	useEffect(() => {
		const m = organisation.members.find((member) => member.orgUserId === currentMemberOrgUserId);
		setCurrentMemberDetail(m);
	}, [currentMemberOrgUserId, organisation]);

	useEffect(() => {
		async function fetchUserDetails() {
			const response = await API.graphql(graphqlOperation(getProfileByPerson, { person: currentMemberDetail.person }));
			const fetchedUserProfile = response.data.getProfileByPerson.items[0];
			setUserProfile(fetchedUserProfile);
		}
		if (currentMemberDetail) {
			fetchUserDetails();
		}
	}, [currentMemberDetail]);

	if (!currentMemberDetail) {
		return null;
	}
	if (!userProfile) {
		return (
			<div className="overflow-y-scroll h-full pl-3 bg-[#EFEFEF] shadow-lg rounded-lg mt-1 p-3 flex justify-center items-center">
				<div className="flex justify-center items-center ">
					<div
						className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
						role="status"
					></div>
				</div>
			</div>
		);
	}
	return (
		<div className="overflow-y-scroll h-full pl-3 bg-[#EFEFEF] shadow-lg rounded-lg mt-1 p-3">
			<div className="flex mt-4"></div>
			<div className="max-h-40 my-3">
				<div className="bg-white group p-2 w-full h-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightww-300 undefined undefined">
					<ProfileInformation userDetails={currentMemberDetail} />
				</div>
				<StaffDetailsTabs
					tabs={[
						{
							name: "Check-Ins",
							view: <CompleteCheckIns currentMemberOrgUserId={currentMemberDetail.orgUserId} updateOrgAndMembers={updateOrgAndMembers} />
						},
						{
							name: "Documents",
							view: (
								<div>
									<StaffDocuments
										currentMemberDetail={currentMemberDetail}
										userProfile={userProfile}
										upsertToEmployee={upsertToEmployee}
									/>
								</div>
							)
						},
						{
							name: "Leave",
							view: <LeaveRecords currentMemberOrgUserId={currentMemberDetail.orgUserId} updateOrgAndMembers={updateOrgAndMembers} />
						},
						{
							name: "Notes & Disciplinary",
							view: <StaffNotes currentMemberOrgUserId={currentMemberDetail.orgUserId} updateOrgAndMembers={updateOrgAndMembers} />
						}
					]}
				/>
			</div>
			<div>
				<div className="w-full mt-4"></div>
			</div>
		</div>
	);
};
