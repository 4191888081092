import { useState, useContext, useEffect } from "react";
import { nth, Form, Button, Yup, useSimpleMessage, showFailure, showSuccess, TextArea, ConfirmModal, notifyUser, formateEpoch } from "ww-framework";
import { orgUtils } from "ww-stores";
import { PersonContext } from "../../ww-stores/Person/person";
import { getProfileByPerson } from "../../graphql/queries";
import { XIcon } from "@heroicons/react/outline";
import { API, graphqlOperation } from "aws-amplify";

const noteSchema = Yup.object().shape({
	note: Yup.string().required(),
	fromEmployee: Yup.string(),
	// timestamp: Yup.number()
});
const NoteForm = ({ shift, activeDay, rebuildCalendar, setOverLayOpen }) => {
	const { person } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const initValues = {
		note: ""
	};
	const [noteSaving, setNoteSaving] = useState(false);
	return (
		<>
			<h4 className="text-xs font-semibold text-white uppercase mt-3 tracking-wide">Add Note</h4>
			<Form
				validationSchema={noteSchema}
				enableReinitialize={true}
				disabled={noteSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						setNoteSaving(true);

						const shiftNotes = {
							...data,
							...{
								shiftID: shift.id,
								fromEmployeee: person.username || (person.firstName + " " + person.lastName),
							}
						};
						await orgUtils.saveNote(shiftNotes);
						rebuildCalendar(activeDay.date);
						setNoteSaving(false);
						resetForm();
						shift?.member?.person?.person &&
							(await notifyUser(
								[shift.member.person?.person],
								`Additional information has been added to your shift on ${formateEpoch(shift.baseDay)}`
							));
						setMessage(
							showSuccess({
								title: "Note saved successfully."
							})
						);
						setOverLayOpen(false);
					} catch (error) {
						setMessage(
							showFailure({
								title: "Unable to save note.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-72 sm:w-full">
					<div className="mt-2 with-full ">
						<TextArea type="textarea" name="note" placeholder="Note" />
					</div>
					<div className="mt-5 flex flex-row justify-between">
						<div className="flex justify-end gap-5">
							<Button type="submit" label="Save" disabled={noteSaving} />
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

const NoteList = ({ notes, isAdmin, rebuildCalendar, activeDay, setOverLayOpen, userMap }) => {
	const [noteDelete, setnoteDelete] = useState();
	const [open, setOpen] = useState(false);
	

	const confirmPopup = (e, id) => {
		e.preventDefault();
		setOpen(true);
		setnoteDelete(id);
	};
	const handlerModal = (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			deleteNote(e);
		}
		setOpen(false);
	};
	const deleteNote = async (e) => {
		e.preventDefault();
		if (noteDelete) {
			await orgUtils.deleteNote(noteDelete);
			rebuildCalendar(activeDay?.date);
			setOverLayOpen(false);
		}
		setOverLayOpen(false);
	};
	
	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title="Delete this Note?"
				subTitle="Are you sure?"
			/>
			<div className="mt-1 sm:mt-10">
				<h3 className="text-xs font-semibold text-white uppercase tracking-wide">{`Total Notes (${notes.length})`}</h3>
				{notes?.length > 0 ? (
        <ul role="presentation" className="mt-4 grid grid-cols-1 gap-4">
		{notes.map((note, index) => {
  const ownerName = userMap[note.owner] || "Unknown";
  const date = new Date(note.createdAt);
  // extract the hours, minutes, day, and date
  const hours = date.getHours();
  const minutes = ("0" + date.getMinutes()).slice(-2); // padding zero for single digit minute
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = dayNames[date.getDay()];
  const dateStr = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear().toString().slice(-2)}`;

  return (
    <li key={index}>
      <button type="button" className="w-full h-fit">
        <div className="text-sm text-left my-1 text-white">
        {`${ownerName}: ${hours}:${minutes}, ${day}, ${dateStr}`}
        </div>
        <div className="bg-white group p-2 w-full h-full flex items-center justify-between border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightww-300">
          <span style={{ whiteSpace: "pre-wrap" }} className="flex-1 h-full block">
            {note.note}
          </span>
          {isAdmin && (
            <XIcon
              className="h-6 w-6 top-2"
              onClick={(e) => confirmPopup(e, note.id)}
              aria-hidden="true"
            />
          )}
        </div>
      </button>
    </li>
  );
})}

					</ul>
				) : (
					<div className="text-center p-5 text-white group p-2 w-full h-full">No Notes Available.</div>
				)}
			</div>
		</>
	);
};

const ShiftNote = ({ shift, activeDay, rebuildCalendar, setOverLayOpen }) => {
	const { person } = useContext(PersonContext);
	const [notes, setNotes] = useState(shift?.notes?.items);
	const [userMap, setUserMap] = useState({});
	useEffect(() => {
		async function fetchData() {
		  const note = await orgUtils.getNotesByShifts(shift.id);
		  setNotes(note.items);
	  
		  // Fetch user map after setting the notes state
		  async function fetchUserMap() {
			const map = {};
	  
			const noteOwners = notes.map((note) => note.owner);
			const uniqueNoteOwners = [...new Set(noteOwners)];
	  
			for (const ownerId of uniqueNoteOwners) {
			  try {
				const response = await API.graphql(
				  graphqlOperation(getProfileByPerson, { person: ownerId })
				);
	  
				if (response.data.getProfileByPerson.items.length > 0) {
				  const user = response.data.getProfileByPerson.items[0];
				  map[ownerId] = user.username || user.firstName + " " + user.lastName;
				} else {
				  map[ownerId] = "Unknown";
				}
			  } catch (error) {
				map[ownerId] = "Unknown";
			  }
			}
	  
			setUserMap(map);
		  }
	  
		  fetchUserMap();
		}
	  
		fetchData();
	  }, [activeDay, notes, shift.id]);
	  
	  

	return (
		<div className="overflow-y-scroll h-full pl-3 bg-darkww-900 sm:shadow-lg rounded-lg ml-4 p-3">
			{activeDay?.day && (
				<div className="font-bold text-gray-700 text-white text-xl py-3">{`${activeDay?.dayDesc},  ${activeDay?.monthShortDesc} ${
					activeDay?.day
				}${nth(activeDay?.day)}`}</div>
			)}

			<NoteForm activeDay={activeDay} rebuildCalendar={rebuildCalendar} shift={shift} setOverLayOpen={setOverLayOpen} />
			<br></br>
			<hr />
			{person.isAdmin || person.assignedAdmin || shift.member?.personID === person.id || person.isPayRoll ? (
				<NoteList
					notes={notes}
					userMap={userMap}
					isAdmin={person.isAdmin || person.assignedAdmin}
					rebuildCalendar={rebuildCalendar}
					activeDay={activeDay}
					setOverLayOpen={setOverLayOpen}
				/>
			) : (
				''
			)}
		</div>
	);
};

export default ShiftNote;

