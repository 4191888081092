import { Dialog, Transition } from "@headlessui/react";
import { BriefcaseIcon, ChatIcon, XIcon, ClipboardCheckIcon } from "@heroicons/react/outline";
import { Storage } from "aws-amplify";
import React, { Fragment, memo, useContext, useEffect, useRef, useState } from "react";
import { Avatar, Button, epochToTimeStr, fillWeek, formateEpochToShortMonthDate, useWindowDimensions } from "ww-framework";
import { OrganisationContext, orgUtils, PersonContext, personUtils } from "ww-stores";
import CameraModal from "../CameraModal";
import AttendanceView from "./AttendanceView"; 
import ConfirmIdentity from "./ConfirmIdentity";
import NoteParticularDay from "../NoteParticularDay";
import ShiftNote from "../ShiftNote";

const MemberAvatar = memo(({ member, style = "" }) => <Avatar user={member} size="h-8 w-8" twClasses={`${style} z-20`} />);

export default function ClockInOutView() {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [selectedMember, setSelectedMember] = useState({});
	const [openPasswordConfirm, setOpenPasswordConfirm] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [previousDayShifts, setPreviousDayShifts] = useState([]);
	const [punches, setPunches] = useState([]);
	const [previousDayPunches, setPreviousDayPunches] = useState([]);
	const [shift, setShift] = useState({});
	const [punch, setPunch] = useState({});
	const [currentDate, setCurrentDate] = useState(new Date());
	const [filteredMembers, setFilteredMembers] = useState([]);
	const { width } = useWindowDimensions();
	const [listType, setListType] = useState("members");
	const [overLayOpen, setOverLayOpen] = useState(false);
	const [moduleType, setModuleType] = useState();
	const [noteParticularDay, setNoteParticularDay] = useState([]);
	const [isDateChanged, setIsDateChanged] = useState(false);
	const [showCameraModal, setShowCameraModal] = useState(false);
	const [pendingPunchType, setPendingPunchType] = useState(null);
	const enablePhoto = organisation?.enablePhoto;
	const isCreatingShiftRef = useRef(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isClockingIn, setIsClockingIn] = useState(false); 
	const [showAttendance, setShowAttendance] = useState(false);

	useEffect(() => {
		async function setCurrentDateData() {
			if (organisation.id && currentDate) {
				// Clone the date to prevent mutation issues
				const currentDateClone = new Date(currentDate.getTime());

				// Filter out the current user based on their email
				const membersWithoutCurrentUser = organisation.members.filter((member) => member.email !== person.email);
				setFilteredMembers(membersWithoutCurrentUser);

				const filtered = await fetchShifts();
				const punche = await fetchPunches();
				await fetchNoteParticularDay();

				if (selectedMember?.orgUserId) {
					setActiveShiftAndPunch(previousDayShifts, previousDayPunches, filtered, punche);
				}
			}
		}
		setCurrentDateData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation.id, currentDate]);

	const setActiveShiftAndPunch = (previousDayShifts, prevDayPunches, shifts, punches) => {
		// first check if the previous day shift is not punched out
		let previousDayMemberShifts = previousDayShifts.filter((k) => k.memberID === selectedMember.orgUserId);
		let previousDayMemberPunches = prevDayPunches.filter((k) => previousDayMemberShifts.find((j) => j.id === k.shiftID));
		previousDayMemberShifts.sort((a, b) => a.shiftStart - b.shiftStart);
		let previousDayActiveShift = previousDayMemberShifts.find((k) => {
			let punch = previousDayMemberPunches.find((j) => j.shiftID === k.id);
			return punch && punch.in && !punch.out;
		});
		if (previousDayActiveShift) {
			changeShift();
			let previousDayActivePunch = previousDayMemberPunches.find((k) => k.shiftID === previousDayActiveShift?.id);
			setShift(previousDayActiveShift);
			setPunch(previousDayActivePunch);
			return { shift: previousDayActiveShift, punch: previousDayActivePunch };
		}

		let dayShifts = shifts.filter((k) => k.memberID === selectedMember.orgUserId);
		let dayPunches = punches.filter((k) => dayShifts.find((j) => j.id === k.shiftID));
		let currentTimestamp = new Date().getTime() / 1000;
		let eightHoursInSeconds = 8 * 60 * 60;

		dayShifts.sort((a, b) => a.shiftStart - b.shiftStart);

		let aShift = dayShifts.find((k) => {
			let punch = dayPunches.find((j) => j.shiftID === k.id);

			// Shift has not been punched in and not past its shift end time
			let condition1 = !punch && k.shiftEnd > currentTimestamp;

			// Shift is clocked in and not clocked out
			let condition2 = punch && punch.in && !punch.out;

			// Adjusted condition to allow shifts to show earlier
			// Allow shifts to be shown if they are due to start within the next 8 hours
			let condition3 = k.shiftStart <= currentTimestamp + eightHoursInSeconds;

			return condition3 && (condition1 || condition2);
		});

		// If no shift is found using the adjusted logic, display the first available shift of the day
		if (!aShift && dayShifts.length > 0) {
			// Display the first shift of the day if it meets the timing condition
			aShift = dayShifts.find((k) => k.shiftStart >= currentTimestamp);
		}

		let aPunch = dayPunches.find((k) => k.shiftID === aShift?.id);
		setShift(aShift);
		setPunch(aPunch);

		return { shift: aShift, punch: aPunch };
	};
	const changeShift = () => {
		setIsDateChanged(true);
		let d = new Date();
		// Instead of modifying the same date object, clone it
		d.setDate(d.getDate() - 1);
		setCurrentDate(new Date(d)); // Pass a new Date object here
	};


	useEffect(() => {
		const timeUntilNextFortyPastHour = () => {
			const now = new Date();
			let nextFortyPastHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 40, 0, 0);
			if (now.getMinutes() >= 40) {
				nextFortyPastHour.setHours(now.getHours() + 1);
			}

			return nextFortyPastHour - now;
		};
		const timeoutId = setTimeout(() => {
			// Refresh the page
			window.location.reload();

			setInterval(() => {
				window.location.reload();
			}, 3600000); // 3600000 milliseconds = 1 hour
		}, timeUntilNextFortyPastHour());

		// Cleanup function to clear the timeout and interval
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);
	const handlePhotoCapture = async (imageSrc) => {
		setShowCameraModal(false); // Close the camera modal
		setIsSaving(true);
		try {
			// Check if a shift already exists, if not, create one
			let currentShift = shift; // Use a local variable to avoid race conditions

			if (!currentShift || !currentShift.id) {
				// Ensure shift is created only once
				if (!isCreatingShiftRef.current) {
					isCreatingShiftRef.current = true;

					const shiftDetails = {
						id: "",
						breakend: "",
						breakstart: "",
						end: 0,
						hasBreak: false,
						person: selectedMember.orgUserId,
						role: null,
						start: 0,
						organisationID: organisation.id,
						day: { baseEpoch: new Date().setUTCHours(0, 0, 0, 0) / 1000 },
						breakDuration: 0,
						unPaidBreak: false,
						isShiftPublished: "PUBLISH"
					};

					currentShift = await orgUtils.saveShift(shiftDetails);
					setShift(currentShift); // Update the state with the new shift
					isCreatingShiftRef.current = false;
				}
			}

			// Ensure we have a valid shift before proceeding with photo upload
			if (!currentShift || !currentShift.id) {
				throw new Error("Shift creation failed, cannot proceed with clock-in.");
			}

			// Now proceed to handle the photo
			if (enablePhoto && imageSrc) {
				const blob = await (await fetch(imageSrc)).blob();
				const timestamp = Date.now();
				const filename = `shift-images/${currentShift.id}-${pendingPunchType}-${timestamp}.jpeg`;

				await Storage.put(filename, blob, {
					contentType: "image/jpeg"
				});

				// Proceed with the punch using the new or existing shift
				if (pendingPunchType) {
					await addPunch(currentShift, pendingPunchType, filename);
					setPendingPunchType(null);
				}
			} else {
				throw new Error("Photo capture failed or no image source available.");
			}

			setIsClockingIn(false); // Reset clocking in state after success
			setIsSaving(false); // Reset isSaving state after success
		} catch (error) {
			console.error("Error handling photo capture:", error);
			isCreatingShiftRef.current = false; // Reset in case of error
			setIsClockingIn(false); // Reset clocking in state
			setIsSaving(false); // Reset isSaving state
			alert("Photo capture failed, please try again."); // Provide user feedback
		}
	};

	const initiatePunchAction = (shift, type) => {
		if (isSaving) return; // Prevent multiple clicks
		setIsSaving(true); // Start the punch-in process

		if (enablePhoto) {
			setPendingPunchType(type);
			setShowCameraModal(true); // Open the camera modal
		} else {
			handlePunchWithoutPhoto(type); // Handle punch directly when photo is not required
		}
	};
	const closeCameraModal = () => {
		setShowCameraModal(false);
		setIsSaving(false); // Reset saving state
		setIsClockingIn(false); // Reset clocking in state
	};

	const handlePunchWithoutPhoto = async (type) => {
		try {
			// Check and create shift if it doesn't exist
			let shiftToUse = shift;
			if (!shiftToUse || !shiftToUse.id) {
				const shiftDetails = {
					id: "",
					breakend: "",
					breakstart: "",
					end: 0,
					hasBreak: false,
					person: selectedMember.orgUserId,
					role: null,
					start: 0,
					organisationID: organisation.id,
					day: { baseEpoch: new Date().setUTCHours(0, 0, 0, 0) / 1000 },
					breakDuration: 0,
					unPaidBreak: false,
					isShiftPublished: "PUBLISH"
				};
				shiftToUse = await orgUtils.saveShift(shiftDetails);
				setShift(shiftToUse); // Update state with the new shift
			}

			// Now that we have a shift, proceed to add punch
			await addPunch(shiftToUse, type);
			setIsSaving(false); // Reset isSaving after the punch is completed
		} catch (error) {
			console.error("Error handling punch without photo:", error);
			setIsSaving(false); // Reset isSaving if there's an error to allow retry
		}
	};
	const fetchShifts = async () => {
		try {
			const newDays = fillWeek(currentDate, organisation?.startDay); // Ensure 'startDay' is used instead of 'startDate'
			const {
				first: { baseEpoch: startDate },
				last: { baseEpoch: endDate }
			} = newDays;
			let weekShifts = await orgUtils.getShifts(organisation.id, startDate, endDate, false);
			//if (epochDate > 1679282458943) {
			//todaysEpoch = currentDate.setUTCHours(10, 0, 0, 0) / 1000;
			//} else {
			let todaysEpoch = new Date(currentDate.getTime()).setUTCHours(0, 0, 0, 0) / 1000;

			//}
			const filtered = [...weekShifts.filter((s) => s.baseDay === todaysEpoch)];
			setPreviousDayShifts([...weekShifts.filter((s) => s.baseDay === todaysEpoch - 86400)]);
			setShifts(filtered);
			return filtered;
		} catch (error) {
			console.log(error);
		}
	};

	const fetchPunches = async () => {
		try {
			let epochDate = new Date(currentDate);
			epochDate = epochDate.getTime();
			let todaysEpoch = new Date(currentDate.getTime()).setUTCHours(0, 0, 0, 0) / 1000;

			//if (epochDate > 1679282458943) {
			//todaysEpoch = currentDate.setUTCHours(10, 0, 0, 0) / 1000;
			//} else {
			// todaysEpoch = currentDate.setUTCHours(0, 0, 0, 0) / 1000;
			//}
			const { items } = await orgUtils.getPunchOfDay(organisation.id, todaysEpoch);
			setPunches(items);
			const { items: previousItems } = await orgUtils.getPunchOfDay(organisation.id, todaysEpoch - 24 * 60 * 60);
			setPreviousDayPunches(previousItems);
			return items;
		} catch (error) {
			console.log(error);
		}
	};
	const fetchNoteParticularDay = async () => {
		try {
			let epochDate = new Date(currentDate);
			epochDate = epochDate.getTime();
			let todaysEpoch = new Date(currentDate.getTime()).setUTCHours(0, 0, 0, 0) / 1000;

			//if (epochDate > 1679282458943) {
			//todaysEpoch = currentDate.setUTCHours(10, 0, 0, 0) / 1000;
			//} else {
			// todaysEpoch = currentDate.setUTCHours(0, 0, 0, 0) / 1000;
			//}
			const data = await orgUtils.getNoteParticularDay(organisation.id, todaysEpoch, todaysEpoch + 23 * 60 * 60 + 59 * 60);
			setNoteParticularDay(data?.items?.[0] ?? {});
		} catch (err) {
			console.error(err);
		}
	};
	const confirmIdentity = (_e, member) => {
		if (isDateChanged) {
			setCurrentDate(new Date());
			setIsDateChanged(false);
		}
		setShift({});
		setPunch({});
		setSelectedMember(member);
		setOpenPasswordConfirm(true);
	};
	const handlerModal = (e, type) => {
		if (type === "confirm") {
			setActiveShiftAndPunch(previousDayShifts, previousDayPunches, shifts, punches);
			setTimeout(() => handelCloseProfile(), 20000);
		} else {
			setSelectedMember({});
		}
		setOpenPasswordConfirm(false);
	};
	const addPunch = async (shift, type, filename = null) => {
		try {
			// Use the passed shift, or create a new one if not provided
			let shiftToUse = shift;
			if (!shiftToUse || !shiftToUse.id) {
				const shiftDetails = {
					id: "",
					breakend: "",
					breakstart: "",
					end: 0,
					hasBreak: false,
					person: selectedMember.orgUserId,
					role: null,
					start: 0,
					organisationID: organisation.id,
					day: { baseEpoch: new Date().setUTCHours(0, 0, 0, 0) / 1000 },
					breakDuration: 0,
					unPaidBreak: false,
					isShiftPublished: "PUBLISH"
				};
				shiftToUse = await orgUtils.saveShift(shiftDetails);
				if (!shiftToUse || !shiftToUse.id) {
					throw new Error("Failed to create a new shift.");
				}
				setShift(shiftToUse); // Update state with the new shift
			}

			// Now that we have a shift, proceed to add punch
			const currentDate = new Date();
			const data = { ...punch };
			const formatTimestamp = (date) => {
				let timestamp = Math.floor(date.getTime() / 1000);
				return timestamp - date.getTimezoneOffset() * 60;
			};
			if (!data.imageReferences) {
				data.imageReferences = []; // Initialize if not present
			}
			const addImageReference = (punchType) => {
				const timestamp = formatTimestamp(currentDate);
				const imageRef = `${punchType}:${timestamp}:${filename}`;
				data.imageReferences?.push(imageRef);
			};

			switch (type) {
				case "punch-in":
					data.baseDay = shiftToUse?.baseDay ?? Math.round(new Date().setUTCHours(0, 0, 0, 0) / 1000);
					data.shiftID = shiftToUse.id ?? "";
					data.organisationID = organisation.id;
					const shiftStartTimestamp = shiftToUse?.shiftStart; 
					const currentTimestamp = formatTimestamp(currentDate);
					const earlyClockInThreshold = organisation.earlyClockInNotificationHours * 3600; 
					if (currentTimestamp >= shiftStartTimestamp - earlyClockInThreshold && currentTimestamp < shiftStartTimestamp) {
						data.in = shiftStartTimestamp; // Use shift start time
					} else {
						data.in = currentTimestamp; // Use current clock-in time
					}

					if (filename) addImageReference(type);
					break;
				case "break-in":
					const breakStart = formatTimestamp(currentDate);
					data.breaks = data.breaks ?? [];
					data.breaks.push(JSON.stringify({ start: breakStart }));
					if (filename) addImageReference(type);
					break;

				case "break-out":
					const lastBreak = JSON.parse(data.breaks.slice(-1).pop() || null);
					const breakEnd = formatTimestamp(currentDate);
					const completedBreak = JSON.stringify({ ...lastBreak, end: breakEnd });
					data.breaks[data.breaks.length - 1] = completedBreak;
					if (filename) addImageReference(type);
					break;

				case "punch-out":
					data.out = formatTimestamp(currentDate);
					if (filename) addImageReference(type);
					break;
				default:
					console.log("Invalid punch type");
					setIsSaving(false);
					setIsClockingIn(false);
					return;
			}

			const res = await orgUtils.savePunchOfMember(data);
			setPunch(res); // Update the punch state to reflect the latest punch
			await fetchShifts();
			await fetchPunches();
			await fetchNoteParticularDay();

			setTimeout(() => handelCloseProfile(), 80000); // Close profile after 8 seconds
			setIsSaving(false);
		} catch (error) {
			console.log("Error in addPunch:", error);
			isCreatingShiftRef.current = false; // Reset in case of error
			setIsSaving(false);
			setIsClockingIn(false);
		}
	};
	const handelCloseProfile = () => {
		if (!isDateChanged) {
			setCurrentDate(new Date());
		}
		setShift({});
		setPunch({});
		setSelectedMember({});
		setShowCameraModal(false);
		setIsSaving(false); // Reset saving state
		setIsClockingIn(false); // Reset clocking in state
	};
	const setFilters = async (e) => {
		if (e.target.value)
			setFilteredMembers(filteredMembers.filter((m) => personUtils.displayName(m)?.toLowerCase().startsWith(e.target.value.toLowerCase())));
		else setFilteredMembers(organisation.members);
	};
	const handelMembers = (e = "", r, type) => {
		if (type === "role") setFilteredMembers(organisation.members?.filter((m) => m.roleIDs && m.roleIDs.includes(r.id)));
		if (type === "dept") setFilteredMembers(organisation.members?.filter((m) => m.departmentIDs && m.departmentIDs.includes(r.id)));
		setListType("members");
	};
	const pickNewDay = (currentDay, shift, e = "", type = "") => {
		if (e !== "") e.stopPropagation();
		setModuleType(type);
		setOverLayOpen(true);
	};
	const getShift = async () => {
		await fetchShifts();
		await fetchPunches();
	};
	return (
		<div>
			{/* Toggle between Clock-In/Out and Attendance View */}
			{showAttendance ? (
				<AttendanceView />
			) : (
				<>
					<Transition.Root show={overLayOpen} as={Fragment}>
						<Dialog as="div" className="fixed z-20 inset-0 overflow-hidden h-screen" onClose={setOverLayOpen}>
							<div className="absolute inset-0 overflow-hidden">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-500"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-500"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
								</Transition.Child>
								<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<div style={{ width: 500 }} className="relative px-5">
											<Transition.Child
												as={Fragment}
												enter="ease-in-out duration-500"
												enterFrom="opacity-0"
												enterTo="opacity-100"
												leave="ease-in-out duration-500"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<div className="absolute top-1 right-8 -ml-8 pt-2 pr- flex sm:-ml-10 sm:pr-4">
													<button
														type="button"
														className="rounded-md text-gray-300 hover:text-white focus:outline-none"
														onClick={() => setOverLayOpen(false)}
													>
														<span className="sr-only">Close panel</span>
														<XIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>
											</Transition.Child>
											{moduleType === "note" ? (
												<div className="col-span-12 sm:col-span-3 h-full">
													<ShiftNote
														shift={shift}
														activeDay={currentDate}
														isAdmin={true}
														rebuildCalendar={getShift}
														setOverLayOpen={setOverLayOpen}
													/>
												</div>
											) : (
												moduleType === "noteParticular" && (
													<div className="col-span-12 sm:col-span-3 h-full">
														<NoteParticularDay
															note={noteParticularDay}
															activeDay={currentDate}
															getNoteParticularDay={getShift}
															setOverLayOpen={setOverLayOpen}
															organisation={organisation}
															person={person}
														/>
													</div>
												)
											)}
										</div>
									</Transition.Child>
								</div>
							</div>
							vlack v{" "}
						</Dialog>
					</Transition.Root>
					{/* end */}
					<CameraModal show={showCameraModal} onClose={closeCameraModal} onCapture={handlePhotoCapture} />
					<ConfirmIdentity open={openPasswordConfirm} record={selectedMember} handlerModal={handlerModal} />
					<div className="bg-white rounded-xl shadow-lg mx-2 min-h- lg:mx-6 mb-8">
						<div className="min-w-full border rounded-lg md:grid md:grid-cols-3">
							<div
								className={`border-r-2 border-darkww-100 lg:col-span-1 overflow-auto h- ${
									width < 500 && (selectedMember?.orgUserId || selectedMember?.id) && "hidden"
								}`}
							>
								<div className="mx-3 my-3 sticky top-4 z-50">
									<div className="relative text-gray-600">
										<span className="absolute inset-y-0 left-0 flex items-center pl-2">
											<svg
												fill="none"
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												viewBox="0 0 24 24"
												className="w-6 h-6 text-gray-300"
											>
												<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
											</svg>
										</span>
										<input
											type="search"
											className="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
											name="search"
											placeholder="Search"
											required
											onChange={(e) => {
												setFilters(e);
											}}
										/>
									</div>
								</div>
								<div className="flex justify-around">
									<h2
										className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${
											listType === "members" && "border-pink-500"
										}`}
										onClick={(e) => {
											setListType("members");
											setFilters(e);
										}}
									>
										Members
									</h2>
									<h2
										className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${
											listType === "roles" && "border-pink-500"
										}`}
										onClick={() => setListType("roles")}
									>
										Roles
									</h2>
									{organisation?.departments && organisation?.departments?.length > 0 && (
										<h2
											className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${
												listType === "depts" && "border-pink-500"
											}`}
											onClick={() => setListType("depts")}
										>
											Departments
										</h2>
									)}
								</div>
								<div className={`mx-3 my-1 overflow-auto h-96 ${width < 500 && (selectedMember?.orgUserId || selectedMember?.id) && "hidden"}`}>
									<div className="relative text-gray-600">
										<span className="inset-y-0 left-0 flex items-center pl-2">
											{listType === "members" ? (
												<div className="flex flex-col w-full">
													{filteredMembers
														?.filter((member) => !member.isPayRoll)
														.sort((a, b) => {
															const nameA = a?.id ? personUtils.displayName(a) || a.orgUsername : a.orgUsername || "No Name Found";
															const nameB = b?.id ? personUtils.displayName(b) || b.orgUsername : b.orgUsername || "No Name Found";

															return nameA?.localeCompare(nameB);
														})
														.map((member, index) => (
															<div className={`mt-1`} key={index} onClick={(e) => confirmIdentity(e, member)}>
																<div className="h-12 flex items-center w-full">
																	<div className="flex flex-row flex-1 bg-white py-0 shadow-md rounded-md w-full">
																		<div className={`ml-2 flex h-10 flex-1 items-center`}>
																			<MemberAvatar member={member} />
																			<div className="pl-2 text-left">
																				<div className="text-sm font-medium truncate w-full">
																					{member?.username
																						? member.username
																						: member?.firstName || member?.lastName
																						? `${member?.firstName} ${member?.lastName}`
																						: member?.username || member?.orgUsername || "No Name"}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														))}
												</div>
											) : listType === "roles" ? (
												<div className="flex flex-col w-full">
													{organisation?.roles?.map((r) => (
														<span
															className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
															key={r.id}
															onClick={(e) => handelMembers(e, r, "role")}
														>
															<div style={{ backgroundColor: r?.labelColour }} className="h-6 md:h-12 w-6 rounded-l-md z-10"></div>
															<div className="w-full">
																<div className="flex justify-between">
																	<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">{r.name}</span>
																	<span className="relative">
																		{r?.unReadCount > 0 && (
																			<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-1 -left-2">
																				{r.unReadCount}
																			</div>
																		)}
																	</span>
																</div>
															</div>
														</span>
													))}
												</div>
											) : (
												listType === "depts" && (
													<div className="flex flex-col w-full">
														{organisation.departments?.map((d) => (
															<span
																className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
																key={d.id}
																onClick={(e) => handelMembers(e, d, "dept")}
															>
																{/* <div
																	style={{ backgroundColor: r?.labelColour }}
																	className="h-6 md:h-12 w-6 rounded-l-md z-10"
																></div> */}
																<div className="w-full">
																	<div className="flex justify-between">
																		<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">{d.name}</span>
																	</div>
																</div>
															</span>
														))}
													</div>
												)
											)}
										</span>
									</div>
								</div>
							</div>
							{selectedMember && Object.keys(selectedMember).length > 0 && (
								<div className="col-span-2">
									<div className="relative flex justify-between w-full items-center p-3 border-b border-gray-300">
										{/* Avatar */}
										<div className="flex items-center flex-shrink-0">
											<MemberAvatar member={selectedMember} />
											<span className="ml-2 font-bold text-gray-600">Hi, {personUtils.displayName(selectedMember)}</span>
										</div>

										{/* Attendance button (keep it centered but give it space) */}
										{selectedMember.orgUsername === null ||
										selectedMember.assignedAdmin ||
										selectedMember.assignedRequests ||
										selectedMember.assignedSuperAdmin ? (
											<div className="flex items-center justify-center mx-auto">
												<button
													onClick={() => setShowAttendance(true)}
													className="flex items-center text-sm text-darkww-700 border-2 border-lightww-500 px-2 py-1 rounded-md hover:bg-gray-100"
												>
													<ClipboardCheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
													Attendance View
												</button>
											</div>
										) : null}

										{/* Close button */}
										<span className="ml-2 font-bold text-gray-600 cursor-pointer" onClick={() => handelCloseProfile()}>
											Close
										</span>
									</div>

									<div className="sm:flex block mt-4 ml-4">
										{shift && Object.keys(shift)?.length > 0 && shift?.role ? (
											<div className={`relative sm:h-full h-40 p-6 ${!isDateChanged && "sm:pt-0"}`}>
												<div className="sm:flex-col flex items-center bg-white w-full p-2 h-full rounded-none border border-gray-300 shadow-sm hover:bg-sky-200">
													<div className="w-28 h-16 rounded-none hidden sm:block" style={{ backgroundColor: shift.role?.labelColour }} />
													<div className="w-6 h-full block sm:hidden cursor-pointer" style={{ backgroundColor: shift.role?.labelColour }} />
													<div className="text-3xl py-2 font-bold text-darkww-700 mt-2 hidden sm:block">{shift.role?.name}</div>
													<div className="flex flex-row">
														{noteParticularDay && Object.keys(noteParticularDay).length > 0 && (
															<>
																<div
																	className="hidden sm:block text-gray-500 font-bold p-2 border-1 mr-4 md:mr-2 rounded-full cursor-pointer relative"
																	onClick={(e) => pickNewDay({}, shift, e, "noteParticular")}
																>
																	<span className="m-0 p-0 text-sm font-medium block ">
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			className="h-8 w-8"
																			fill="none"
																			viewBox="0 0 24 24"
																			stroke="currentColor"
																			strokeWidth="2"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
																			/>
																		</svg>
																	</span>
																</div>
																<div
																	className="block sm:hidden text-gray-500 font-bold p-2 border-1 rounded-full cursor-pointer absolute right-8"
																	onClick={(e) => pickNewDay({}, shift, e, "noteParticular")}
																>
																	<span className="m-0 p-0 text-sm font-medium block ">
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			className="h-6 w-6"
																			fill="none"
																			viewBox="0 0 24 24"
																			stroke="currentColor"
																			strokeWidth="2"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
																			/>
																		</svg>
																	</span>
																</div>
															</>
														)}
														{shift?.notes?.items.length > 0 && (
															<>
																<div
																	className="hidden sm:block text-gray-500 font-bold p-2 border-1 mr-4 md:ml-2 md:mr-0 rounded-full cursor-pointer realtive"
																	onClick={(e) => pickNewDay({}, shift, e, "note")}
																>
																	<ChatIcon className="w-8 h-8" />
																</div>
																<div
																	className="block sm:hidden text-gray-500 font-bold p-2 border-1 rounded-full cursor-pointer absolute top-4 right-8"
																	onClick={(e) => pickNewDay({}, shift, e, "note")}
																>
																	<ChatIcon className="w-6 h-6" />
																</div>
															</>
														)}
													</div>
													<div className="sm:h-20 w-28 flex flex-col items-center justify-center h-full cursor-pointer">
														<div className="text-sm font-medium text-gray-500 truncate hidden sm:block">{"Start Time"}</div>
														<div className="text-3xl font-semibold text-gray-700">{epochToTimeStr(shift.shiftStart)}</div>
														<div className="text-sm font-medium text-gray-500 truncate sm:hidden">{"TO"}</div>
														<div className="text-3xl font-semibold text-gray-700 sm:hidden">
															{shift.setAsClose ? "Close" : epochToTimeStr(shift.shiftEnd)}
														</div>
													</div>
													{shift.hasBreak && (
														<div className="text-gray-500 text-md flex flex-row items-center">
															<BriefcaseIcon className="h-6 w-6 px-1" />
															{shift.breakDuration
																? `${shift.breakDuration} Mins`
																: `${epochToTimeStr(shift.breakStart)} - ${epochToTimeStr(shift.breakEnd)}`}
														</div>
													)}

													<div className="sm:h-28 w-28 sm:flex flex-col items-center justify-end h-full hidden cursor-pointer">
														<div className="text-sm font-medium text-gray-500 truncate">{"End Time"}</div>
														<div className="text-3xl font-semibold text-gray-700">{shift.setAsClose ? "Close" : epochToTimeStr(shift.shiftEnd)}</div>
														<div className="text-xl h-8 font-bold text-darkww-700 mt-2"></div>
													</div>
													<div className="text-lg font-bold pl-1 text-darkww-700 mt-2 sm:hidden">{shift.role?.name}</div>
												</div>
											</div>
										) : (
											<div className="col-span-2">
												<div className="text-center mt-8 text-2xl m-auto w-2/4 p-2">You are not rostered today!</div>
												<div className="text-center mt-4 text-2xl m-auto w-2/4 p-2">Are you sure you want clock-in?</div>
											</div>
										)}
										<div className="bg-white p-3 max-w-md mx-auto w-full">
											<h1 className="text-4xl mt-4 text-center font-bold">
												{currentDate && formateEpochToShortMonthDate(currentDate?.setUTCHours(0, 0, 0, 0) / 1000)}'s Shift
											</h1>
											{punch && Object.keys(punch).length > 0 && (
												<>
													<span className="text-xl flex flex-col justify-start mt-8 items-center">Clock-In at {epochToTimeStr(punch?.in)}</span>
													{punch.out === null && (
														<div className="mt-4  text-center">
															{punch?.breaks === null || punch?.breaks?.length === 0 ? (
																<Button
																	label={`Take a Break`}
																	className="rounded-md text-xl font-medium text-darkww-700 border-darkww-700 border-2 hover:bg-darkww-600 hover:text-white px-auto py-1 px-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
																	onClick={() => {
																		if (isSaving) return;
																		setIsSaving(true);
																		return initiatePunchAction(shift, "break-in");
																	}}
																/>
															) : (
																Object.keys(JSON.parse(punch?.breaks?.slice(-1).pop())).length === 2 &&
																!punch.out && (
																	<Button
																		label={`Start New Break`}
																		className="rounded-md text-md font-medium text-darkww-700 border-darkww-700 border-2 hover:bg-darkww-600 hover:text-white px-auto py-1 px-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
																		onClick={() => {
																			if (isSaving) return;
																			setIsSaving(true);
																			return initiatePunchAction(shift, "break-in");
																		}}
																	/>
																)
															)}
														</div>
													)}

													<div className="mt-8">
														<h2 className="text-center text-2xl border-b-2 mb-2 border-darkww-100">Breaks</h2>
														<ul>
															{punch?.breaks?.map((onBreak, index) => {
																let parsedBreak = JSON.parse(onBreak);
																return (
																	<li className="p-2 rounded-lg" key={index}>
																		<div className="flex align-middle flex-row justify-between">
																			<div className="p-2">
																				<p className="text-xl text-black">Break - {index + 1}</p>
																			</div>
																			{parsedBreak.start && (
																				<div className="p-2">
																					<p className="text-lg text-black">{epochToTimeStr(parsedBreak?.start)}</p>
																				</div>
																			)}
																			{parsedBreak.end ? (
																				<div className="p-2">
																					<p className="text-lg text-black">{epochToTimeStr(parsedBreak?.end)}</p>
																				</div>
																			) : (
																				<Button
																					label="End Break"
																					className="rounded-md flex text-xl font-medium text-white bg-darkww-700 hover:bg-darkww-600 px-auto py-2 px-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
																					onClick={() => {
																						if (isSaving) return;
																						setIsSaving(true);
																						return initiatePunchAction(shift, "break-out");
																					}}
																				/>
																			)}
																		</div>
																		<hr className="mt-2" />
																	</li>
																);
															})}
														</ul>
													</div>
												</>
											)}
											<div className="flex justify-center mt-8 gap-2">
												{!punch?.in ? (
													<Button
														label="Clock-In"
														className="rounded-md text-2xl font-bold text-white bg-darkww-700 hover:bg-darkww-600 px-auto py-2 px-8 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
														onClick={() => {
															if (isSaving) return;
															setIsSaving(true);
															return initiatePunchAction(shift, "punch-in");
														}}
													/>
												) : (
													<>
														{!punch.out ? (
															<Button
																label="Clock-Out"
																className="block rounded-md text-2xl font-bold text-white bg-darkww-700 hover:bg-darkww-600 px-auto py-2 px-8 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
																onClick={() => {
																	if (isSaving) return;
																	setIsSaving(true);
																	return initiatePunchAction(shift, "punch-out");
																}}
															/>
														) : (
															<div>
																<div className="text-xl flex flex-col justify-start items-center">Clock-Out at {epochToTimeStr(punch?.out)}</div>
																<div className="text-xl flex flex-col justify-start mt-3 items-center">Thank You!</div>
															</div>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}
