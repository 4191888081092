import { ChatIcon, CheckCircleIcon, ChevronDownIcon, XCircleIcon } from "@heroicons/react/solid";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React, { Fragment, useContext } from "react";
import {
	Menu,
	Transition as Transitions,
	epochToTimeStr,
	formateEpoch,
	formateEpochToShortMonthDate2,
	notifyUser,
	showFailure,
	useSimpleMessage
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

const SwapShiftRequests = ({
	specialShifts,
	pendingSwapShiftRequest,
	shiftsAvailableForSwap,
	addNote,
	approveSwapShift,
	members,
	memberID,
	loadRoles,
	setIsLoading,
	confirmPopup
}) => {
	const personObject = useContext(PersonContext);
	const person = personObject.person;
	const { organisation } = useContext(OrganisationContext);
	const { setMessage } = useSimpleMessage();

	const deny = async (e, id, type = null, m = null, status = "NOT_APPROVE") => {
		try {
			setIsLoading(true);
			if (type === "Swap Shift") {
				const shiftData = {
					...{
						id: id?.id,
						organisationID: id?.organisationID,
						fromMemberID: id?.fromMemberID,
						fromShiftId: id?.fromShiftId,
						toShiftId: id?.toShiftId,
						memberID: id?.memberID,
						status: person.isAdmin || person.assignedRequests ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
						note: id?.note || null
					}
				};
				await orgUtils.saveShiftSwapRequest(shiftData);
			} else if (type === "Give Away") {
				const shiftData = {
					...{
						id: id.id,
						organisationID: id.organisationID,
						fromMemberID: id.fromMemberID,
						fromShiftId: id.fromShiftId,
						toShiftId: id.toShiftId,
						memberID: id.memberID,
						status: person.isAdmin ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
						note: id?.note || null
					}
				};
				await orgUtils.saveGiveAwayRequest(shiftData);
			} else {
				await orgUtils.changeStatusTimeOff(id, status);
			}
			m && (await notifyUser([m], `Your ${type} request has been denied`));
			await loadRoles();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};
	return (
		<ul className="flex flex-col divide-y w-full">
			{pendingSwapShiftRequest.map((swapShift, index) => (
				<li key={`${index}-requests`} className="flex flex-row">
					<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 content-center items-center py-4">
						<div className="flex-1 pl-1 content-center">
							<span className="text-gray-600">Swap Shift: </span>
							<br className="sm:hidden" />
							<span className="font-medium">
								{swapShift?.fromMember?.id === memberID
									? "You"
									: personUtils.displayName(swapShift?.fromMember?.person) || swapShift?.fromMember?.orgUsername || "User Removed"}
								{" ("}
								{swapShift?.fromShift?.role?.name}
								{")"}
							</span>
							<span className="text-gray-600 text-xs">
								{" on "}
								{swapShift?.fromShift?.shiftStart && formateEpochToShortMonthDate2(swapShift?.fromShift?.shiftStart, true)}
								{" asked for "}
							</span>
							<span className="font-medium">
								{swapShift?.toMember?.id === memberID
									? "your"
									: personUtils.displayName(swapShift?.toMember?.person) ||
									  swapShift?.toMember?.orgUsername ||
									  swapShift?.toMember?.orgUsername ||
									  "User Removed"}
								{" ("}
								{swapShift?.toShift?.role?.name}
								{")"}
							</span>
							<span className="text-gray-600 text-xs">
								{" on "}
								{swapShift?.toShift?.shiftStart && formateEpochToShortMonthDate2(swapShift?.toShift?.shiftStart, true)}
							</span>
							{swapShift?.note && (
								<Tooltip trigger={["click", "hover"]} overlay={swapShift.note}>
									<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
								</Tooltip>
							)}
						</div>
						{(person.isAdmin || person.assignedRequests) && swapShift.status === "APPROVE_BY_MEMBER" ? (
							<div className="flex flex-row">
								<button
									className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-3 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
									onClick={async (e) => {
										await addNote(swapShift, "Swap Shift", organisation.owner, "APPROVE_BY_MANAGER");
										approveSwapShift(e, swapShift, "Swap Shift", organisation.owner, "APPROVE_BY_MANAGER");
									}}
								>
									<CheckCircleIcon className="w-6 h-6" />
									<span className="sm:block hidden pl-1">Confirm</span>
								</button>
								<button
									className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-3 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
									onClick={async (e) => await addNote(swapShift, "Swap Shift", organisation.owner, "DENIED_BY_MANAGER")}
								>
									<XCircleIcon className="w-6 h-6" />
									<span className="sm:block hidden pl-1">Deny</span>
								</button>
							</div>
						) : (
							<div className="flex flex-row">
								{swapShift.status === "PENDING" && swapShift.fromMemberID === memberID ? (
									<button
										className="mr-2 inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
										onClick={(e) => deny(e, swapShift, "Swap Shift", organisation.owner)}
									>
										<CheckCircleIcon className="w-6 h-6" />
										<span className="sm:block hidden ml-1">Cancel</span>
									</button>
								) : (
									<>
										<button
											className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
											onClick={(e) => approveSwapShift(e, swapShift, "Swap Shift", organisation.owner, "APPROVE_BY_MEMBER")}
										>
											<CheckCircleIcon className="w-6 h-6" />
											<span className="sm:block hidden ml-1">Accept</span>
										</button>
										<button
											className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
											onClick={(e) => deny(e, swapShift, "Swap Shift", organisation.owner)}
										>
											<XCircleIcon className="w-6 h-6" />
											<span className="sm:block hidden pl-1">Refuse</span>
										</button>
									</>
								)}
							</div>
						)}
					</div>
				</li>
			))}
			{specialShifts &&
				specialShifts.length > 0 &&
				specialShifts
					.slice()
					.reverse()
					.map((k, i) => {
						let weekShift = shiftsAvailableForSwap.find((shifts) => shifts?.date === k?.fromShift?.weekStartDay)?.shifts ?? [];
						// remove all shifts that are past the current date
						weekShift = weekShift.filter((shift) => shift.baseDay * 1000 > new Date().valueOf());
						let myShiftsDates = weekShift?.filter((my) => my.memberID === memberID);

						// let isDayConflicting = !myShiftsDates.some((my) => my?.baseDay === k?.fromShift?.baseDay);
						// let onThisDay = myShiftsDates.filter((h) => members?.find((m) => m.person === person?.person)?.roleIDs?.includes(h.roleID));
						const offerPersonWeekShifts = weekShift.filter((shift) => shift.memberID === k.fromShift?.memberID);

						const offerPersonShiftDays = offerPersonWeekShifts.map((shift) => new Date(shift.baseDay * 1000).getDay());

						const availableShiftsForSwap = myShiftsDates.filter((myShift) => {
							const myShiftDay = new Date(myShift.baseDay * 1000).getDay();
							return !offerPersonShiftDays.includes(myShiftDay);
						});

						return (
							k.fromShift && (
								<li key={`${i}-srequests-!`} className="flex flex-row">
									<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
										<div className="flex-1 pl-1">
											<span className="text-gray-600">Swap Shift: </span>
											<br className="sm:hidden" />
											<span className="font-medium">
												{k?.fromMember?.id
													? personUtils.displayName(k?.fromMember?.person) || k?.fromMember?.orgUsername
													: k?.fromMember?.orgUsername || "User Removed"}
												{" ("}
												{k?.fromShift?.role?.name}
												{")"}
											</span>
											<span className="text-gray-600 text-xs">
												<span className="text-green-600 ml-1">
													{k?.fromShift?.shiftStart &&
														formateEpochToShortMonthDate2(
															(k.fromShift.shiftStart % 86400) + k?.fromShift?.baseDay,
															true
														)}{" "}
												</span>
												<span className="text-gray-600 text-xs">
													would like to swap this shift{" "}
													<span className="text-blue-900 inline-flex ml-1">
														{k.note && (
															<Tooltip trigger={["click", "hover"]} overlay={k.note}>
																<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
															</Tooltip>
														)}
													</span>
												</span>
											</span>
										</div>
										<div className="flex flex-row">
											<Menu as="div" className="relative">
												{({ open }) => (
													<>
														{(k.fromShift.shiftStart % 86400) + k?.fromShift?.baseDay <
														new Date(new Date().toUTCString()).valueOf() / 1000 ? (
															"Date has passed"
														) : (
															<Menu.Button className="inline-flex justify-center content-center w-full mt-0.3 border border-gray-300 rounded-md text-sm font-medium bg-darkww-500">
																{
																	<span className="ml-1 text-white p-1 text-sm font-medium block">
																		Available Shifts
																	</span>
																}
																<ChevronDownIcon
																	className="flex-shrink-0 h-4 w-4 mt-2 text-white sm:block"
																	aria-hidden="true"
																/>
															</Menu.Button>
														)}
														<Transitions
															show={open}
															as={Fragment}
															enter="transition ease-out duration-100"
															enterFrom="transform opacity-0 scale-95"
															enterTo="transform opacity-100 scale-100"
															leave="transition ease-in duration-75"
															leaveFrom="transform opacity-100 scale-100"
															leaveTo="transform opacity-0 scale-95"
														>
															<Menu.Items
																static
																className="absolute right-0 mt-1 w-fit rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
															>
																{availableShiftsForSwap?.length === 0 ? (
																	<Menu.Item>
																		<div className="whitespace-nowrap flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
																			No shifts to swap with
																		</div>
																	</Menu.Item>
																) : (
																	availableShiftsForSwap?.map((shift) => (
																		<div
																			className="sm:block"
																			onClick={() => confirmPopup(k.fromShift, shift, k?.note ?? "", k.id)}
																		>
																			<Menu.Item>
																				<div className="whitespace-nowrap flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
																					{formateEpoch(shift.baseDay)} | {epochToTimeStr(shift.shiftStart)}
																					-{epochToTimeStr(shift.shiftEnd)}{" "}
																				</div>
																			</Menu.Item>
																		</div>
																	))
																)}
															</Menu.Items>
														</Transitions>
													</>
												)}
											</Menu>
											{/* {onThisDay && isDayConflicting ? (
												<Menu as="div" className="relative">
													{({ open }) => (
														<>
															{(k.fromShift.shiftStart % 86400) + k?.fromShift?.baseDay <
															new Date(new Date().toUTCString()).valueOf() / 1000 ? (
																"Date has passed"
															) : (
																<Menu.Button className="inline-flex justify-center content-center w-full mt-0.3 border border-gray-300 rounded-md text-sm font-medium bg-darkww-500">
																	{
																		<span className="ml-1 text-white p-1 text-sm font-medium block">
																			Available Shifts
																		</span>
																	}
																	<ChevronDownIcon
																		className="flex-shrink-0 h-4 w-4 mt-2 text-white sm:block"
																		aria-hidden="true"
																	/>
																</Menu.Button>
															)}
															<Transitions
																show={open}
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items
																	static
																	className="absolute right-0 mt-1 w-fit rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
																>
																	{availableShiftsForSwap?.length === 0 ? (
																		<Menu.Item>
																			<div className="whitespace-nowrap flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
																				No shifts to swap with
																			</div>
																		</Menu.Item>
																	) : (
																		availableShiftsForSwap?.map((shift) => (
																			<div
																				className="sm:block"
																				onClick={() => confirmPopup(k.fromShift, shift, k?.note ?? "", k.id)}
																			>
																				<Menu.Item>
																					<div className="whitespace-nowrap flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
																						{formateEpoch(shift.baseDay)} |{" "}
																						{epochToTimeStr(shift.shiftStart)}-
																						{epochToTimeStr(shift.shiftEnd)}{" "}
																					</div>
																				</Menu.Item>
																			</div>
																		))
																	)}
																</Menu.Items>
															</Transitions>
														</>
													)}
												</Menu>
											) : (
												<div className="whitespace-nowrap flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
													You're working that day
												</div>
											)} */}
										</div>
									</div>
								</li>
							)
						);
					})}
		</ul>
	);
};

export default SwapShiftRequests;
