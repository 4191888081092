import { API } from "aws-amplify";
import { createRole, updateRole, deleteRole as AWSDeleteRole } from "../../graphql/mutations.js";

export const saveRole = async (roleDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			let roleMapped = {
				id: roleDetails.id,
				name: roleDetails.name,
				labelColour: roleDetails.labelColour,
				organisationID: roleDetails.organisationID
			};

			const mutation = roleDetails.id ? updateRole : createRole;
			const { data } = await API.graphql({ query: mutation, variables: { input: roleMapped } });
			const savedRole = data[roleDetails.id ? "updateRole" : "createRole"];

			const updatedRole = { ...roleDetails, ...savedRole };
			resolve(updatedRole);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteRole = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({ query: AWSDeleteRole, variables: { input: { id: id } } });
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};
