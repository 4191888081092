import { Dialog, Transition } from "@headlessui/react";
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Menu, fillWeek, formateEpochToShortMonthDate } from "ww-framework";
import { PersonContext, orgUtils } from "ww-stores";
import { calculateTotalEarnings, calculateTotalTime } from "./Calendar/Calendar";

const CalculatedDayTotals = React.memo(({ rateOrHour, day, onToggleRateOrHour, org }) => {
	const bgClass = rateOrHour === "percentage" && day.percentage < org.StaffCost && day.percentage > 0 ? "bg-green-600" : "bg-pink-600";

	const displayPercentage =
		rateOrHour === "percentage" && day.report !== undefined
			? day.report.takings !== undefined && day.report.takings > org.StaffCost
				? ((day.totalEarnings / day.report.takings) * 100).toFixed(1) + "% (takings)"
				: day.report.target !== undefined && day.report.target > 0
				? ((day.totalEarnings / day.report.target) * 100).toFixed(1) + "% (target)"
				: "N/A"
			: "-%";

	return (
		<div
			className={`${bgClass} -mt-2 border-2 border-white shadow-sm rounded-md ml-0 pl-2 pr-1 w-fit mx-auto cursor-pointer`}
			onClick={onToggleRateOrHour}
		>
			{rateOrHour === "hour" && (
				<div className="text-white text-md flex items-center">
					<div>{day.totalHours.toFixed(2).replace(/[.,]00$/, "")} hrs</div>
					<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
				</div>
			)}
			{rateOrHour === "rate" && (
				<div className="text-white text-md flex">
					<span>€{parseFloat(day.totalEarnings).toFixed(1)}</span>
					<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
				</div>
			)}
			{rateOrHour === "percentage" && (
				<div className="text-white w-fit text-sm flex inline-flex whitespace-nowrap">
					<span>{displayPercentage}</span>
					<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
				</div>
			)}
		</div>
	);
});
const LocationsReport = () => {
	const { person } = useContext(PersonContext);
	const [selectedDay, setSelectedDay] = useState(new Date());
	const [associatedOrganisations, setAssociatedOrganisations] = useState([]);
	const [calendarDays, setCalendarDays] = useState([]);
	const [organisationWithReport, setOrganisationWithReport] = useState([]);
	const [chooseModuleOpen, setChooseModuleOpen] = useState(false);
	const [modelReport, setModelReport] = useState({});
	const [weekTotal, setWeekTotal] = useState({ takings: 0, target: 0, till: 0, food: 0, drinks: 0 });
	const [rateOrHour, setRateOrHour] = useState("hour");
	const [paidTimeOff, setPaidTimeOff] = useState(false);
	const [holidayPay, setHolidayPay] = useState(false);
	const [filterByDepartment, setFilterByDepartment] = useState({});
	const [selectedDepartment, setSelectedDepartment] = useState("ALL");
	const [cumulativeTotals, setCumulativeTotals] = useState({ totalHours: 0, totalEarnings: 0, percentage: 0 });
	const [averagePercentage, setAveragePercentage] = useState(0);
	const [cachedOrgData, setCachedOrgData] = useState({});

	useEffect(() => {
		const loadAndCacheOrgData = async () => {
			let tempCachedData = {};
			for (const org of associatedOrganisations) {
				try {
					const orgData = await orgUtils.get(org);
					tempCachedData[org.id] = orgData; // Store data by org ID
				} catch (err) {
					console.error(`Error fetching data for organization ${org.id}:`, err);
				}
			}
			setCachedOrgData(tempCachedData);
		};
		if (associatedOrganisations.length > 0) {
			loadAndCacheOrgData();
		}
	}, [associatedOrganisations]);

	const toggleHolidayPay = (e) => {
		if (typeof e.target.checked === "boolean") setHolidayPay(e.target.checked);
	};
	const togglePaidTimeOff = (e) => {
		if (typeof e.target.checked === "boolean") setPaidTimeOff(e.target.checked);
	};
	const switchRateAndHour = (e) => {
		if (e !== "") e.stopPropagation();
		if (rateOrHour === "hour") setRateOrHour("rate");
		else if (rateOrHour === "rate") setRateOrHour("percentage");
		else if (rateOrHour === "percentage") setRateOrHour("hour");
	};
	const filterShifts = async (e, departmentId, orgId) => {
		e.stopPropagation();
		setFilterByDepartment((prevFilters) => ({
			...prevFilters,
			[orgId]: departmentId
		}));
		setSelectedDepartment(departmentId);

		await loadCalendar(departmentId, orgId);
	};

	const loadCalendar = useCallback(
		async (departmentId = "ALL", filteredOrgId = null) => {
			try {
				if (!selectedDay) return;
const newCalendarDays = fillWeek(selectedDay, person?.currentOrganisation?.startDay);
				const {
					days,
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = newCalendarDays;

				let cumulativeTotals = { totalHours: 0, totalEarnings: 0, percentage: 0 };
				let weekTotal = { takings: 0, target: 0, targetToDate: 0, till: 0, food: 0, drinks: 0 };
				let orgsWithPercentageCount = 0;
				let updatedOrganisations = [];

				for (const org of associatedOrganisations) {
					if (filteredOrgId && org.id !== filteredOrgId) continue;

					if (!cachedOrgData[org.id]) {
						continue;
					}
					let orgDaysWithValidReports = 0;
					try {
						const orgData = cachedOrgData[org.id];
						const weekReport = await orgUtils.getWeekReport(org.id, startDate, endDate + 23 * 60 * 60 + 59 * 60);
						const shifts = await orgUtils.getShifts(
							org.id,
							startDate,
							endDate + 23 * 60 * 60 + 59 * 60,
							person?.isAdmin || person?.assignedAdmin
						);
						const timeOff = await orgUtils.getTimeOff(org.id, startDate, endDate);
						const extraPays = await orgUtils.getExtraPayDay(org.id, startDate, endDate);
						// Determine the filter for the current organization
						const orgSpecificDeptId = filterByDepartment[org.id];
						let filteredShifts;
						if (orgSpecificDeptId === "NONE") {
							filteredShifts = [];
						} else if (orgSpecificDeptId && orgSpecificDeptId !== "ALL") {
							filteredShifts = shifts.filter((shift) => shift.member?.departmentID?.includes(orgSpecificDeptId));
						} else {
							filteredShifts = shifts;
						}
						const dayReports = days.map((calDay) => {
							const shiftsForDay = filteredShifts.filter(
								(shift) => shift.member && shift.shiftStart >= calDay.baseEpoch && shift.shiftStart < calDay.baseEpoch + 24 * 60 * 60
							);
							const timeOffData = timeOff.items.filter((k) => k.fromDate <= calDay.baseEpoch && k.toDate >= calDay.baseEpoch);
							const extraPayOfDay = extraPays.items.find((e) => e.baseDay === calDay.baseEpoch);
							const totalHours = calculateTotalTime(shiftsForDay);
							const totalEarnings = holidayPay
								? 1.08 * calculateTotalEarnings(shiftsForDay, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, departmentId)
								: calculateTotalEarnings(shiftsForDay, extraPayOfDay?.newPay ?? 0, timeOffData, paidTimeOff, departmentId);
							const report = weekReport.items.find((k) => k?.reportDate === calDay?.baseEpoch);
							const percentage = report
								? report.takings > 0
									? (totalEarnings / report.takings) * 100
									: report.target > 0
									? (totalEarnings / report.target) * 100
									: 0
								: 0;
							if (report && (report.takings > 0 || report.target > 0)) {
								orgDaysWithValidReports++;
							}
							return { ...calDay, report, totalHours, totalEarnings, percentage };
						});

						const orgWeeklyTotals = dayReports.reduce(
							(acc, curr) => {
								acc.totalHours += curr.totalHours;
								acc.totalEarnings += curr.totalEarnings;
								acc.percentage += curr.percentage;
								return acc;
							},
							{ totalHours: 0, totalEarnings: 0, percentage: 0 }
						);
						if (orgDaysWithValidReports > 0) {
							orgWeeklyTotals.percentage /= orgDaysWithValidReports;
						} else {
							orgWeeklyTotals.percentage = 0; // Set to 0 if no days have reports
						}

						if (orgWeeklyTotals.percentage > 0) orgsWithPercentageCount++;

						cumulativeTotals.totalHours += orgWeeklyTotals.totalHours;
						cumulativeTotals.totalEarnings += orgWeeklyTotals.totalEarnings;
						cumulativeTotals.percentage += orgWeeklyTotals.percentage;

						const orgReport = dayReports.reduce(
							(p, c) => {
								p.takings += parseFloat(c?.report?.takings ?? 0);
								p.target += parseFloat(c?.report?.target ?? 0);
								p.food += parseFloat(c?.report?.food ?? 0);
								p.drinks += parseFloat(c?.report?.drinks ?? 0);
								p.till += parseFloat(c?.report?.till ?? 0);
								if (c?.report?.takings && parseFloat(c.report.takings) !== 0) {
									p.targetToDate += parseFloat(c?.report?.target ?? 0);
								}
								return p;
							},
							{ takings: 0, target: 0, targetToDate: 0, till: 0, food: 0, drinks: 0 }
						);

						weekTotal.takings += orgReport.takings;
						weekTotal.target += orgReport.target;
						weekTotal.targetToDate += orgReport.targetToDate;
						weekTotal.till += orgReport.till;
						weekTotal.food += orgReport.food;
						weekTotal.drinks += orgReport.drinks;

						updatedOrganisations.push({
							...org,
							day: dayReports,
							departments: orgData?.departments?.items,
							weeklyTotals: orgWeeklyTotals,
							report: orgReport
						});
					} catch (err) {
						console.error(`Error processing organization ${org.id}:`, err);
					}
				}

				const averagePercentage = orgsWithPercentageCount > 0 ? cumulativeTotals.percentage / orgsWithPercentageCount : 0;

				setAveragePercentage(averagePercentage);
				setOrganisationWithReport(updatedOrganisations);
				setCalendarDays(days);
				setWeekTotal(weekTotal);
				setCumulativeTotals(cumulativeTotals);
			} catch (err) {
				console.error("Error in loadCalendar:", err);
			}
		},
		[person, selectedDay, associatedOrganisations, holidayPay, paidTimeOff, filterByDepartment, cachedOrgData]
	);

	useEffect(() => {
		loadCalendar();
	}, [selectedDay, holidayPay, paidTimeOff, filterByDepartment, loadCalendar]); // Triggers when filterByDepartment changes

	useEffect(() => {
		if (person.isAdmin) setAssociatedOrganisations(person?.organisations?.items?.map((k) => k.organisation));
		else setAssociatedOrganisations(person?.organisations?.items?.filter((p) => p?.assignedAdmin === true).map((k) => k.organisation));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [person]);
	useEffect(() => {
		loadCalendar();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [associatedOrganisations, selectedDay]);
	const NoteModule = ({ open, handlerModal, setChooseModuleOpen }) => {
		return (
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
					<div className="flex items-center justify-center h-full w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="bg-white transform rounded-lg w-fit w-72">
								<span
									className="inline-block absolute top-0 right-0 mr-2 mt-4 cursor-pointer"
									onClick={(e) => handlerModal(e, "close")}
								>
									<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								<div className="w-full flex flex-col px-2 py-3">
									<div className="flex flex justify-center gap-2">
										<div className="mt-1 sm:mt-2 sm:mb-2">
											<div className="">
												<span className="min-w-0 flex-1 flex items-center space-x-3">
													<div className="block text-md font-bold text-lightww-700 min-w-0 pl-2">
														<h2 className=" underline underline-offset-1">
															{modelReport?.reportDate && formateEpochToShortMonthDate(modelReport.reportDate)}
														</h2>
														<div className="flex gap-4 justify-start mt-2 mb-1 text-sm font-medium text-gray-700">
															<span>
																{modelReport.target && (
																	<>
																		<span className="text-lightww-800 mr-1">Target:</span>
																		{modelReport.target}
																	</>
																)}
															</span>
														</div>
														<span className="flex gap-4 justify-start mt-2 mb-1 text-sm font-medium text-gray-700">
															<span>
																{modelReport.takings && (
																	<>
																		<span className="text-lightww-800 mr-1">Takings:</span>
																		{modelReport.takings}
																	</>
																)}
															</span>
															<span>
																{modelReport.till && (
																	<>
																		<span className="text-lightww-800 mr-1"> Till:</span>
																		{modelReport.till}
																	</>
																)}
															</span>
														</span>
														<span className="flex gap-4 justify-start mb-2 text-sm font-medium text-gray-700">
															<span>
																{modelReport.food && (
																	<>
																		<span className="text-lightww-800 mr-1">Food:</span>
																		{modelReport.food}
																	</>
																)}
															</span>
															<span>
																{modelReport.drinks && (
																	<>
																		<span className="text-lightww-800 mr-1"> Drinks:</span>
																		{modelReport.drinks}
																	</>
																)}
															</span>
														</span>
														<span className="block text-md font-medium text-gray-800 whitespace-pre-line">
															{modelReport.note}
														</span>
													</div>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};
	const handlerChooseModuleModal = (e, type) => {
		if (type === "close") {
			setChooseModuleOpen(false);
			setModelReport({});
		}
	};
	const openModel = (report) => {
		setChooseModuleOpen(true);
		setModelReport(report);
	};
	const changeCalendar = useCallback(
		(direction) => {
			let newDate;

			const daysToMove = direction === "DOWN" ? -7 : 7;
			newDate = new Date(calendarDays?.[0]?.date);
			newDate.setDate(calendarDays?.[0]?.date?.getDate() + daysToMove);
			setSelectedDay(newDate);
		},
		[calendarDays]
	);
	return (
		<>
			<NoteModule open={chooseModuleOpen} handlerModal={handlerChooseModuleModal} setChooseModuleOpen={setChooseModuleOpen} />
			<div className="divide-y divide-gray-200 lg:col-span-9 h-fit overflow-auto">
				<div className="col-span-7 row-span-1 shadow-lg border-2 border-gray-300 rounded-lg p-3">
					<div className="flex flex-row relative h-full pr-2">
						<div className="flex-1 min-w-fit h-full mx-auto">
							<div className="flex flex-col h-full items-center justify-center">
								<div className="flex flex-row mb-2">
									<button type="button" onClick={() => changeCalendar("DOWN")}>
										<ArrowCircleLeftIcon className="h-20 w-20 text-gray-500 hover:text-darkww-600" aria-hidden="true" />
									</button>
									<div className="mx-4">
										<div className={`text-center w-[5rem] sm:w-32 h-full items-center`}>
											<div
												className={`bg-white flex justify-center items-center "sm:h-18 rounded-t border-pink-400 border-t border-r border-l`}
											>
												<span className={`text-3xl sm:text-7xl font-bold text-gray-600`}>
													{calendarDays?.[0]?.day?.padStart(2, "0")}
												</span>
											</div>
											<div
												className={`bg-pink-600 text-2xl sm:text-5xl text-white py-1 px-1 sm:h-16 rounded-b
											`}
											>
												{new Intl.DateTimeFormat("en", { month: "short" }).format(selectedDay)}
											</div>
										</div>
									</div>
									<button type="button" onClick={() => changeCalendar("UP")}>
										<ArrowCircleRightIcon className="h-20 w-20 text-gray-500 hover:text-darkww-600" aria-hidden="true" />
									</button>
								</div>
								{weekTotal?.target > 0 && (
									<div className="text-md mt-2 pr-1 text-darkww-700 font-medium">
										Targets:
										<span className="text-sm pl-1 text-lightww-700 font-normal"> To Date-€{weekTotal?.targetToDate}</span>
										<span className="text-sm pl-1 text-lightww-700 font-normal"> Week-€{weekTotal?.target}</span>
									</div>
								)}
								<div className="bg-darkww-700 justify-center sm:justify-start shadow-sm rounded-md ml-1 sm:ml-0 px-2 py-1 mt-2 w-fit flex  cursor-pointer">
									<div className="text-white text-lg text-900 font-bold">
										<div>
											<div className="text-sm pl-1 px-1 text-whiteww-900 font-normal flex flex-row items-center">
												<div className="text-sm pr-1 text-whiteww-900 font-normal">
													Total :<span className="text-md pl-1 text-whiteww-900">€{weekTotal.takings}</span>
												</div>
												<svg
													viewBox="0 0 5333.333 5333.333"
													xmlns="http://www.w3.org/2000/svg"
													className="w-6 h-6 pl-1 pr-1"
													fill="currentColor"
												>
													<path
														d="M14000.719 16299.6c-560.269-1-1167.206-173.7-1561.048-333.2-2260.334-915.3-3484.307-4657.6-278.776-6896.21 496.562-346.9-102.473-6418.39-155.809-6883.75-17.778-154.36 285.69-281.6 667.927-281.6h1107.203c366.187 1.01 663.976 123.92 663.976 272.23V16119.6c0 99.2-198.773 180.4-443.473 180"
														transform="matrix(.293 0 0 -.293 0 5333.333)"
													></path>
													<g>
														<path
															d="M8655.49 16300h-53.05c-101.5 0-184.12-92.8-184.12-206.9v-2150.5c0-128.8-79.57-233.6-177.43-233.6-97.86 0-177.44 104.8-177.44 233.6v2183.7c0 95.8-70.38 173.7-156.87 173.7h-15.1c-86.49 0-156.83-77.9-156.83-173.7v-2183.7c0-128.8-79.61-233.6-177.47-233.6-97.87 0-177.44 104.8-177.44 233.6v2183.7c0 95.8-70.38 173.7-156.87 173.7h-15.1c-86.49 0-156.83-77.9-156.83-173.7v-2183.7c0-128.8-79.61-233.6-177.47-233.6-97.82 0-177.44 104.8-177.44 233.6v2150.5c0 114.1-82.57 206.9-184.12 206.9h-53c-101.55 0-184.13-92.8-184.13-206.9v-3126.9c0-592.3 286.95-1173.6 787.49-1425.6 66.38-33.4 108.6-104 107.6-179.9L7047.9 2148.49c-1.87-137.71 109.09-250.18 246.86-250.18h524.84c137.76 0 248.72 112.47 246.9 250.18l-122.02 9212.21c-1 75.9 41.27 146.5 107.65 179.9 502.09 252.8 787.44 835.4 787.44 1425.6v3126.9c0 114.1-82.58 206.9-184.08 206.9"
															transform="matrix(.61232 0 0 -.29204 -2884.93 5362.739)"
														></path>
													</g>
												</svg>{" "}
												: <span className="text-md pl-1 pr-1 text-whiteww-900">€{weekTotal.food}</span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 1600 1600"
													className="w-4 h-4"
													fill="currentColor"
													stroke="#fff"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<path d="M1278.586 237.337H321.41l406.618 473.307a104.795 104.795 0 0 1 25.303 68.286l17.334 571.737h56l20-571.076c0-25.48 9.13-50.12 25.735-69.449l406.186-472.805"></path>
													<path d="M549.757 1398.654c75.627-9.4 159.984-14.653 248.974-14.653 89.72 0 174.732 5.346 250.833 14.88 12.59-4.067 14.217-18.04-.75-22.48l-242.012-71.718c-4.35-1.289-9.259-1.289-13.61 0l-242.01 71.718c-14.695 4.36-13.393 17.92-1.425 22.253"></path>
												</svg>
												: <span className="text-md pl-1 text-whiteww-900">€{weekTotal.drinks}</span>{" "}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={`border-2 border-white bg-pink-600 shadow-sm rounded-md px-2 py-1 w-fit mx-auto cursor-pointer`}
						onClick={(e) => switchRateAndHour(e)}
					>
						<div className="text-white text-sm flex items-center">
							<span className="mr-1">All Staff:</span>
							{rateOrHour === "hour" && (
								<div className="text-white text-md flex items-center">
									<div>{cumulativeTotals.totalHours.toFixed(2).replace(/[.,]00$/, "")} hrs</div>
									<ChevronDownIcon className="flex-shrink-0 ml-2 h-5 w-3 text-white sm:block" aria-hidden="true" />
								</div>
							)}
							{rateOrHour === "rate" && (
								<div className="text-white text-md flex">
									<span>€{parseFloat(cumulativeTotals.totalEarnings).toFixed(1)}</span>
									<ChevronDownIcon className="flex-shrink-0 ml-2 h-5 w-3 text-white sm:block" aria-hidden="true" />
								</div>
							)}
							{rateOrHour === "percentage" && (
								<div className="text-white text-md flex">
									<span>{averagePercentage.toFixed(1)}% (avg.)</span>
									<ChevronDownIcon className="flex-shrink-0 ml-2 h-5 w-3 text-white sm:block" aria-hidden="true" />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="mt-4 pb-3">
					<div className="">
						<div className="flex justify-between items-center py-1 px-1 mt-4 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 w-full">
							<div className="form-check form-switch flex items-center ml-6">
								<span>
									<input
										id="link-checkbox"
										type="checkbox"
										checked={!!holidayPay}
										onChange={(e) => toggleHolidayPay(e)}
										className="w-4 h-4 text-darkww-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-300"
									/>
									<label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-800">
										Hol. Pay @ 8%
									</label>
								</span>
								<span className="ml-6">
									<input
										id="link-checkbox"
										type="checkbox"
										checked={!!paidTimeOff}
										onChange={(e) => togglePaidTimeOff(e)}
										className="w-4 h-4 text-darkww-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-300"
									/>
									<label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-800">
										incl. Paid Days
									</label>
								</span>
							</div>
							<div></div>
							<h3 className="text-xl font-bold text-darkww-700 uppercase tracking-wide absolute left-1/2 transform -translate-x-1/2">
								Locations
							</h3>
						</div>
					</div>
					<div className="flex flex-col mt-2">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
									<table className="min-w-full divide-y divide-lightww-100 table-auto">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="px-6 py-3 text-left text-md font-semibold text-darkww-600 uppercase tracking-wider"
												>
													Organisation
												</th>
												{calendarDays.map((day) => (
													<th
														scope="col"
														className="px-6 py-3 text-left text-md font-medium text-darkww-600 uppercase tracking-wider"
													>
														{day.formatted}
													</th>
												))}
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-lightww-100">
											{organisationWithReport.map((org) => (
												<tr key={org.id}>
													<td className="px-4 p-2 whitespace-nowrap">
														<div className="flex items-center">
															<div className="">
																<div className="flex items-between">
																	<span className="flex text-lg mr-2 font-medium text-gray-900">{org.name}</span>

																	{org.departments.length > 0 && (
																		<Menu as="div" className="relative inline-block pl-6 -mr-8 text-left">
																			<Menu.Button className="text-sm bg-darkww-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 mr-2 rounded-md">
																				<span className="ml-2 text-gray-700 text-xs font-medium block text-white">
																					Dept
																				</span>
																				<ChevronDownIcon
																					className="flex-shrink-0 mr-1 h-5 w-3 text-white sm:block"
																					aria-hidden="true"
																				/>
																			</Menu.Button>

																			<Menu.Items className="origin-top-left absolute left-0 mt-2 w-38 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
																				<div className="sm:block">
																					<Menu.Item>
																						<div
																							className={`block px-4 py-2 text-sm cursor-pointer ${
																								selectedDepartment === "ALL"
																									? "bg-lightww-600 text-white"
																									: "text-gray-700"
																							}`}
																							onClick={(e) => filterShifts(e, "ALL", org.id)}
																						>
																							All
																						</div>
																					</Menu.Item>
																					{org.departments?.map((d) => (
																						<Menu.Item key={d.id}>
																							<div
																								className={`block px-4 py-2 text-sm cursor-pointer ${
																									selectedDepartment === d.id
																										? "bg-lightww-600 text-white"
																										: "text-gray-700 "
																								}`}
																								onClick={(e) => filterShifts(e, d.id, org.id)}
																							>
																								{d.name}
																							</div>
																						</Menu.Item>
																					))}
																					<Menu.Item>
																						<div
																							className={`block px-4 py-2 text-sm cursor-pointer ${
																								selectedDepartment === "NONE"
																									? "bg-lightww-600 text-white"
																									: "text-gray-700"
																							}`}
																							onClick={(e) => filterShifts(e, "NONE", org.id)}
																						>
																							None
																						</div>
																					</Menu.Item>
																				</div>
																			</Menu.Items>
																		</Menu>
																	)}
																</div>

																{org?.report?.target > 0 && (
																	<span className="text-sm pr-1 font-medium text-darkww-700">
																		Targets:
																		{org?.report?.targetToDate && (
																			<span className="text-sm font-normal  pl-1 text-lightww-700">
																				To Date-€{org?.report?.targetToDate}
																			</span>
																		)}
																		<span className="text-sm pl-1 font-normal text-lightww-700">
																			Week-€{org?.report?.target}
																		</span>
																	</span>
																)}

																<div className="bg-darkww-700 justify-center sm:justify-start shadow-sm rounded-md  sm:ml-0 px-1 py-1 mt-2 w-fit flex items-center cursor-pointer">
																	<div className="text-white text-lg text-900 font-bold">
																		<div className="text-sm pl-1 px-1 text-whiteww-900 font-normal flex flex-row items-center">
																			<div className="text-sm pr-1 text-whiteww-900 font-normal">
																				Total :
																				<span className="text-md pl-1 text-whiteww-900">
																					€{org?.report?.takings}
																				</span>
																			</div>
																			{org?.report?.food && (
																				<span className="text-md pr-1 §	1text-whiteww-900 flex">
																					<svg
																						viewBox="0 0 5333.333 5333.333"
																						xmlns="http://www.w3.org/2000/svg"
																						className="w-4 h-4 mt-0.5"
																						fill="currentColor"
																					>
																						<path
																							d="M14000.719 16299.6c-560.269-1-1167.206-173.7-1561.048-333.2-2260.334-915.3-3484.307-4657.6-278.776-6896.21 496.562-346.9-102.473-6418.39-155.809-6883.75-17.778-154.36 285.69-281.6 667.927-281.6h1107.203c366.187 1.01 663.976 123.92 663.976 272.23V16119.6c0 99.2-198.773 180.4-443.473 180"
																							transform="matrix(.293 0 0 -.293 0 5333.333)"
																						></path>
																						<g>
																							<path
																								d="M8655.49 16300h-53.05c-101.5 0-184.12-92.8-184.12-206.9v-2150.5c0-128.8-79.57-233.6-177.43-233.6-97.86 0-177.44 104.8-177.44 233.6v2183.7c0 95.8-70.38 173.7-156.87 173.7h-15.1c-86.49 0-156.83-77.9-156.83-173.7v-2183.7c0-128.8-79.61-233.6-177.47-233.6-97.87 0-177.44 104.8-177.44 233.6v2183.7c0 95.8-70.38 173.7-156.87 173.7h-15.1c-86.49 0-156.83-77.9-156.83-173.7v-2183.7c0-128.8-79.61-233.6-177.47-233.6-97.82 0-177.44 104.8-177.44 233.6v2150.5c0 114.1-82.57 206.9-184.12 206.9h-53c-101.55 0-184.13-92.8-184.13-206.9v-3126.9c0-592.3 286.95-1173.6 787.49-1425.6 66.38-33.4 108.6-104 107.6-179.9L7047.9 2148.49c-1.87-137.71 109.09-250.18 246.86-250.18h524.84c137.76 0 248.72 112.47 246.9 250.18l-122.02 9212.21c-1 75.9 41.27 146.5 107.65 179.9 502.09 252.8 787.44 835.4 787.44 1425.6v3126.9c0 114.1-82.58 206.9-184.08 206.9"
																								transform="matrix(.61232 0 0 -.29204 -2884.93 5362.739)"
																							></path>
																						</g>
																					</svg>
																					: €{org?.report?.food}
																				</span>
																			)}
																			{org?.report?.drinks && (
																				<span className="text-md text-whiteww-900 flex">
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="20"
																						height="20"
																						viewBox="0 0 1600 1600"
																						className="w-4 h-4 mt-0.5"
																						fill="currentColor"
																						stroke="#fff"
																						strokeWidth="2"
																						strokeLinecap="round"
																						strokeLinejoin="round"
																					>
																						<path d="M1278.586 237.337H321.41l406.618 473.307a104.795 104.795 0 0 1 25.303 68.286l17.334 571.737h56l20-571.076c0-25.48 9.13-50.12 25.735-69.449l406.186-472.805"></path>
																						<path d="M549.757 1398.654c75.627-9.4 159.984-14.653 248.974-14.653 89.72 0 174.732 5.346 250.833 14.88 12.59-4.067 14.217-18.04-.75-22.48l-242.012-71.718c-4.35-1.289-9.259-1.289-13.61 0l-242.01 71.718c-14.695 4.36-13.393 17.92-1.425 22.253"></path>
																					</svg>
																					: €{org?.report?.drinks}
																				</span>
																			)}
																		</div>
																	</div>
																</div>
																<td className="table-cell">
																	<div
																		className={`${
																			rateOrHour === "percentage" &&
																			org.weeklyTotals.percentage < org.StaffCost &&
																			org.weeklyTotals.percentage > 0
																				? "bg-green-600"
																				: "bg-pink-600"
																		} border-t-2 border-white shadow-sm rounded-md px-2 py-1 w-fit mx-auto cursor-pointer`}
																		onClick={(e) => switchRateAndHour(e)}
																	>
																		<div className="text-white text-sm flex items-center">
																			<span className="mr-1">Staff:</span>
																			{rateOrHour === "hour" && (
																				<div className="text-white text-md flex items-center">
																					<div>
																						{org.weeklyTotals.totalHours
																							.toFixed(2)
																							.replace(/[.,]00$/, "")}{" "}
																						hrs
																					</div>
																					<ChevronDownIcon
																						className="flex-shrink-0 ml-2 h-5 w-3 text-white sm:block"
																						aria-hidden="true"
																					/>
																				</div>
																			)}
																			{rateOrHour === "rate" && (
																				<div className="text-white text-md flex">
																					<span>
																						€{parseFloat(org.weeklyTotals.totalEarnings).toFixed(1)}
																					</span>
																					<ChevronDownIcon
																						className="flex-shrink-0 ml-2 h-5 w-3 text-white sm:block"
																						aria-hidden="true"
																					/>
																				</div>
																			)}
																			{rateOrHour === "percentage" && (
																				<div className="text-white text-md flex">
																					<span>{org.weeklyTotals.percentage.toFixed(1)}% (avg.)</span>
																					<ChevronDownIcon
																						className="flex-shrink-0 ml-2 h-5 w-3 text-white sm:block"
																						aria-hidden="true"
																					/>
																				</div>
																			)}
																		</div>
																	</div>
																</td>
															</div>
														</div>
													</td>

													{org?.day?.map((rep) => (
														<td className="px-6 pr-2 table-cell" key={rep.day}>
															<div className="mt-1 sm:mt-5 sm:mb-2">
																<div className="">
																	<div className="min-w-0 flex-1 flex items-center space-x-3">
																		<span className="block min-w-0">
																			<span className="block text-sm font-medium text-gray-700">
																				{rep?.report?.target && (
																					<>
																						<span className="text-gray-500 mr-0.5">Target:</span>
																						{rep?.report?.target}
																					</>
																				)}
																			</span>
																			<span className="block text-sm font-medium text-gray-700">
																				{rep?.report?.takings && (
																					<>
																						<span className="text-gray-500 mr-0.5">Takings:</span>
																						{rep?.report?.takings}
																					</>
																				)}
																				{rep?.report?.till && (
																					<>
																						<span className="text-gray-500 mr-0.5"> Till:</span>
																						{rep?.report?.till}
																					</>
																				)}
																			</span>
																			<span className="block text-sm font-medium text-gray-700">
																				{rep?.report?.food > 0 && (
																					<>
																						<span className="text-gray-500 mr-0.5">Food:</span>
																						{rep?.report?.food}
																					</>
																				)}
																				{rep?.report?.drinks > 0 && (
																					<>
																						<span className="text-gray-500 mr-0.5"> Drinks:</span>
																						{rep?.report?.drinks}
																					</>
																				)}
																			</span>
																			<span
																				className="block text-xs font-medium text-gray-800"
																				onClick={() => openModel(rep?.report)}
																			>
																				{rep?.report?.note?.length > 50
																					? rep?.report?.note.slice(0, 50) + "..."
																					: rep?.report?.note}
																			</span>
																		</span>
																	</div>
																</div>
															</div>
															<CalculatedDayTotals
																rateOrHour={rateOrHour}
																day={rep}
																onToggleRateOrHour={switchRateAndHour}
																org={org}
															/>
														</td>
													))}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default React.memo(LocationsReport);
