import { showFailure } from "ww-framework";
import { orgUtils } from "ww-stores";

export const getOtherLocationShifts = async ({ orgMembers, loggedInPerson, startDate, endDate }) => {
	if (loggedInPerson.organisations.items.length > 0) {
		const allOtherOrganisations = loggedInPerson.organisations.items
			.filter((k) => k.organisationID !== loggedInPerson.currentOrganisation.id)
			.map((orgmember) => orgmember.organisation);
		const shiftsInOtherLocations = await Promise.all(
			allOtherOrganisations.map(async (org) => {
				try {
					const membersWithEmails = orgMembers.filter((member) => member.email);
					let data = await orgUtils.getShifts(org.id, startDate, endDate, true);
					// Filter out shifts where the user has no email
					data = data.filter((shift) => {
						const shiftMember = membersWithEmails.find((member) => member.email === shift?.member?.email);
						return shiftMember !== undefined;
					});
					return data;
				} catch (err) {
					console.error("Error in getOtherLocationShifts:", err);
					return [];
				}
			})
		);
		return shiftsInOtherLocations.flat();
	}
	return [];
};

export const isMemberAlreadyRostered = async ({ member, day, loggedInPerson, selectedWeekShifts, orgMembers, setMessage, currentShiftId }) => {
	const otherLocationShifts = await getOtherLocationShifts({
		orgMembers: orgMembers,
		loggedInPerson,
		startDate: day?.baseEpoch,
		endDate: day?.baseEpoch + 86340 // to the baseEpoch, add 23 hours, 59 minutes to capture the whole day
	});
	const foundMemberShiftsOnDay = selectedWeekShifts.find((k) => {
		const isSameDay = k.baseDay === day.baseEpoch;
		const isSameMember = k?.member?.email === member?.email && k?.member?.id === member?.orgUserId;
		const isDifferentShift = k.id !== currentShiftId;
		const matches = isSameDay && isSameMember && isDifferentShift;

		return matches;
	});
	const foundOtherLocationShiftOnDay = otherLocationShifts.find(
		(k) => k.baseDay === day.baseEpoch && k?.member?.email === member?.email && k?.member?.id === member?.orgUserId
	);
	if (foundMemberShiftsOnDay) {
		setMessage(
			showFailure({
				title: "Unable to save Shift.",
				subTitle: "This employee is already rostered that day."
			})
		);
		return true;
	}
	// Check if a shift is found in other locations' shifts on the same day
	if (foundOtherLocationShiftOnDay) {
		const orgID = foundOtherLocationShiftOnDay?.organisationID;
		const org = loggedInPerson.organisations.items.find((k) => k.organisationID === orgID)?.organisation;
		setMessage(
			showFailure({
				title: "Unable to save Shift.",
				subTitle: `This employee is already rostered in ${org?.name} on the same day.`
			})
		);
		return true;
	}

	return false;
};

export const notifyNotClockedOut = async ({ punches, shifts, lateClockOutNotificationHours }) => {
	let validPunches = punches.filter((p) => p.in !== null);
	let punchesNotClockedOut = validPunches.filter((p) => p.out === null);
	// for each of the punches not clocked out, check if there is another punch for the same shift that is clocked out.
	// if so, we remove the punch from the list
	punchesNotClockedOut = punchesNotClockedOut.filter((p) => {
		const shift = shifts.find((s) => s.id === p.shiftID);
		if (shift) {
			const punchesForShift = validPunches.filter((p) => p.shiftID === shift.id);
			const punchWithBoth = punchesForShift.find((p) => p.in !== null && p.out !== null);
			if (punchWithBoth) {
				return false;
			}
		}
		return true;
	});
	let shiftsNotClockedOut = [];
	punchesNotClockedOut.forEach((p) => {
		let shift = shifts.find((s) => s.id === p.shiftID);
		if (shift) {
			// if the shift is more than lateClockOutNotificationHours/12 hours past the shift end time
			const lateNotificationAfter = (lateClockOutNotificationHours ?? 12) * 3600;
			if (new Date().getTime() / 1000 - shift.shiftEnd > lateNotificationAfter) {
				shiftsNotClockedOut.push(shift);
			}
		}
	});
	if (shiftsNotClockedOut.length > 0) {
		return { shiftsNotClockedOut, punchesNotClockedOut };
	}
	return null;
};

export function getTimeOffData(_timeOff, _currentDay) {
	let _timeOffData = _timeOff.filter((k) => k.fromDate <= _currentDay.baseEpoch && k.toDate >= _currentDay.baseEpoch);
	let filteredTimeOffData = [..._timeOffData];

	_timeOffData.forEach((k) => {
		if (k.repeatDay) {
			if (_currentDay.dayDesc !== k.repeatDay) {
				filteredTimeOffData = filteredTimeOffData.filter((i) => i.id !== k.id);
			}
		}
	});
	_timeOffData = filteredTimeOffData;
	return _timeOffData;
}

export function notifyEarlyClockIn({ punches, shifts, earlyClockInNotificationHours }) {
	let validPunches = punches.filter((p) => p.in !== null);
	// a shift is clocked in early if the punch in time is more than earlyClockInNotificationHours or 30 minutes before the shift start time
	let shiftsClockedInEarly = [];
	let punchesClockedInEarly = [];
	validPunches.forEach((p) => {
		let shift = shifts.find((s) => s.id === p.shiftID);
		if (shift) {
			const earlyNotificationBefore = (earlyClockInNotificationHours ?? 0.5) * 60 * 60;
			if (p.in < shift.shiftStart - earlyNotificationBefore) {
				if (p.status !== "APPROVED") {
					shiftsClockedInEarly.push(shift);
					punchesClockedInEarly.push(p);
				}
			}
		}
	});
	if (shiftsClockedInEarly.length > 0) {
		return { shiftsClockedInEarly, punchesClockedInEarly };
	}
	return null;
}
