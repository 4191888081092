import React, { createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useWindowDimensions } from "ww-framework";

export const modes = [
	{
		id: "W",
		description: "Weekly"
	},
	{
		id: "M",
		description: "Monthly"
	}
];

export const CalendarDataContext = createContext();

export const CalendarDataProvider = ({ children }) => {
	const [searchParams] = useSearchParams();
	const [mode, setMode] = useState(searchParams.get("mode") ? modes[1] : modes[0]);
	const [chooseModuleOpen, setChooseModuleOpen] = useState(false);
	const [emailModuleOpen, setEmailModuleOpen] = useState(false);
	const [chooseModuleType, setChooseModuleType] = useState("ShiftAndNote");
	const [selectedDay, setSelectedDay] = useState(searchParams.get("month") ? new Date(searchParams.get("month") * 1000) : new Date());
	const [calendarDays, setCalendarDays] = useState([]);
	const [activeDay, setActiveDay] = useState();
	const [editShift, setEditShift] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const { width, height } = useWindowDimensions();
	const [overLayOpen, setOverLayOpen] = useState(false);
	const [shift, setShift] = useState([]);
	const [totalHours, setTotalHours] = useState([]);
	const [currentCalenderAverage, setCurrentCalenderAverage] = useState();
	const [totalRate, setTotalRate] = useState(0);
	const [clockedTotalRate, setClockedTotalRate] = useState(0);
	const [weeklyLiveCost, setWeeklyLiveCost] = useState(0);
	const [rateOrHour, setRateOrHour] = useState("hour");
	const [shiftTemplate, setShiftTemplate] = useState([]);
	const [preferenceData, setPreferenceData] = useState([]);
	const [timeOff, setTimeOff] = useState([]);
	const [moduleType, setModuleType] = useState();
	const [overLayNoteParticularDayOpen, setOverNoteParticularDayLayOpen] = useState(false);
	const [overLayRosterTemplateOpen, setOverLayRosterTemplateOpen] = useState(false);
	const [noteParticularDayData, setNoteParticularDayData] = useState();
	const [noteParticularDay, setNoteParticularDay] = useState();
	const [punchData, setPunchData] = useState([]);
	const [view, setView] = useState("C");
	const [confirmHours, setConfirmHours] = useState(false);
	const [averageTaking, setAverageTaking] = useState({ takings: "", target: "", actual: "", food: "", drinks: "" });
	const [chooseTimeOffModuleOpen, setTimeOffModuleOpen] = useState(false);
	const [filterByDepartment, setFilterByDepartment] = useState("ALL");
	const [filterByRole, setFilterByRole] = useState("ALL");
	const [holidayPay, setHolidayPay] = useState(false);
	const [paidTimeOff, setPaidTimeOff] = useState(false);
	const [sortByRole, setSortByRole] = useState(false);
	const [zoom, setZoom] = useState(false);
	const [templatesVisible, setTemplateVisible] = useState(false);
	const [moveOrCopyShift, setMoveOrCopyShift] = useState();
	const [isHoursConfirm, setIsHoursConfirm] = useState({ values: { 0: false } });
	const [toMailData, setToMailData] = useState(null);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [extraPays, setExtraPays] = useState([]);
	const [isDraggingOver, setIsDraggingOver] = useState(false);
	const [shiftsThisWeek, setShiftsThisWeek] = useState([]);
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [lateClockOutData, setLateClockOutData] = useState(null);
	const [earlyClockInData, setEarlyClockInData] = useState(null);

	useEffect(() => {
		let newShiftsThisWeek = [];
		calendarDays.forEach((day) => {
			let { allUnfilteredShift } = day;
			newShiftsThisWeek = [...newShiftsThisWeek, ...allUnfilteredShift];
		});
		setShiftsThisWeek(newShiftsThisWeek);
	}, [calendarDays]);

	return (
		<CalendarDataContext.Provider
			value={{
				mode,
				setMode,
				chooseModuleOpen,
				setChooseModuleOpen,
				emailModuleOpen,
				setEmailModuleOpen,
				chooseModuleType,
				setChooseModuleType,
				selectedDay,
				setSelectedDay,
				calendarDays,
				setCalendarDays,
				activeDay,
				setActiveDay,
				editShift,
				setEditShift,
				isLoading,
				setIsLoading,
				width,
				height,
				overLayOpen,
				setOverLayOpen,
				shift,
				setShift,
				totalHours,
				setTotalHours,
				currentCalenderAverage,
				setCurrentCalenderAverage,
				totalRate,
				setTotalRate,
				clockedTotalRate,
				setClockedTotalRate,
				weeklyLiveCost,
				setWeeklyLiveCost,
				rateOrHour,
				setRateOrHour,
				shiftTemplate,
				setShiftTemplate,
				preferenceData,
				setPreferenceData,
				timeOff,
				setTimeOff,
				moduleType,
				setModuleType,
				overLayNoteParticularDayOpen,
				setOverNoteParticularDayLayOpen,
				overLayRosterTemplateOpen,
				setOverLayRosterTemplateOpen,
				noteParticularDayData,
				setNoteParticularDayData,
				noteParticularDay,
				setNoteParticularDay,
				punchData,
				setPunchData,
				view,
				setView,
				confirmHours,
				setConfirmHours,
				averageTaking,
				setAverageTaking,
				chooseTimeOffModuleOpen,
				setTimeOffModuleOpen,
				filterByDepartment,
				setFilterByDepartment,
				filterByRole,
				setFilterByRole,
				holidayPay,
				setHolidayPay,
				paidTimeOff,
				setPaidTimeOff,
				sortByRole,
				setSortByRole,
				zoom,
				setZoom,
				templatesVisible,
				setTemplateVisible,
				moveOrCopyShift,
				setMoveOrCopyShift,
				isHoursConfirm,
				setIsHoursConfirm,
				toMailData,
				setToMailData,
				openConfirm,
				setOpenConfirm,
				extraPays,
				setExtraPays,
				isDraggingOver,
				setIsDraggingOver,
				shiftsThisWeek,
				setShiftsThisWeek,
				notificationModalOpen,
				setNotificationModalOpen,
				lateClockOutData,
				setLateClockOutData,
				earlyClockInData,
				setEarlyClockInData
			}}
		>
			{children}
		</CalendarDataContext.Provider>
	);
};
