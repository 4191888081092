import { useField, useFormikContext } from "formik";
import React, { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "ww-framework";
import "./date-picker.css";

export const DatePickerField = ({ ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props);

	return (
		<DatePicker
			{...field}
			{...props}
			dateFormat="dd/MM/yyyy"
			calendarStartDay={1}
			autocomplete="new-password"
			selected={(field.value && new Date(field.value)) || null}
			onChange={(val) => {
				setFieldValue(field.name, val);
			}}
		/>
	);
};

export const DateButtonInput = forwardRef(({ value, onClick }, ref) => (
	<button className="mb-1 p-0.5 px-2 rounded border-b border-gray-300" onClick={onClick} ref={ref} type="button">
		<p className="text-sm text-gray-700">{value ? value : "Select Date"}</p>
	</button>
));
