import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
	Avatar,
	notifyUser,
	useSearchParams,
	useWindowDimensions
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import Background from "../Background";
import MessagesView from "./MessagesView";
import NoticeBoard from "./NoticeBoard";

const Messages = () => {
	const { organisation } = useContext(OrganisationContext);
	const { members, departments, roles } = organisation;
	const { person } = useContext(PersonContext);
	const [activeMemberChat, setActiveMemberChat] = useState();
	const [currentMember, setCurrentMember] = useState({});
	const [messages, setMessages] = useState([]);
	const [allMessages, setAllMessages] = useState([]);
	const [allRoleMessages, setAllRoleMessages] = useState([]);
	const [allDeptMessages, setAllDeptMessages] = useState([]);
	const [allOrgMessages, setAllOrgMessages] = useState([]);
	const [allOrgUnRead, setAllOrgUnRead] = useState([]);
	const [filteredMembers, setFilteredMembers] = useState([]);
	const [filteredRoles, setFilteredRoles] = useState([]);
	const [filteredDept, setFilteredDept] = useState([]);
	const [chatType, setChatType] = useState("wholeOrganisation");
	const { width } = useWindowDimensions();
	const messagesEndRef = useRef(null);
	const [editNotice, setEditNotice] = useState({ id: "", title: "", description: "" });
	const [noticeModel, setNoticeModel] = useState(false);
	const [isNoticeBoard, setIsNoticeBoard] = useState(true);
	const [readCount, serReadCount] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const urlParams = new URLSearchParams(window.location.search);
	const usernameToFind = urlParams.get('to');
	const selectedMember = members.find(member => member.orgUsername === usernameToFind ||
		personUtils?.displayName(member) === usernameToFind);

	const setMessagesInState = useCallback(async (m, type) => {
		if (type === "members")
			setMessages(
				allMessages.filter((mess) => {
					let array1 = mess.filterChat.sort();
					let array2 = [currentMember.orgUserId, m.orgUserId].sort();
					return array1.every((value, index) => value === array2[index]);
				})
			);
		if (type === "roles") setMessages(allRoleMessages.filter((mess) => mess.toRoleID === m?.id));
		if (type === "departments") setMessages(allDeptMessages.filter((mess) => mess.toDeptID === m?.id));
		if (type === "wholeOrganisation") setMessages(allOrgMessages);
	}, [allDeptMessages, allMessages, allOrgMessages, allRoleMessages, currentMember.orgUserId, setMessages]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};
	const activeChat = useCallback(async (e, m, type) => {

		if (e) e.stopPropagation();
		setActiveMemberChat(m);
		await setMessagesInState(m, type);
	}, [setActiveMemberChat, setMessagesInState]);


	useEffect(() => {

		if (selectedMember) {
			const memberToActivate = filteredMembers.find(m => m?.username === selectedMember.orgUsername || personUtils?.displayName(m) === selectedMember.orgUsername);

			if (memberToActivate) {
				setActiveMemberChat(null);
				activeChat(undefined, memberToActivate, "members");
			}
		}
	}, [selectedMember, filteredMembers, activeMemberChat, activeChat]);


	useEffect(() => {
		person && setCurrentMember(members?.find((m) => m.person === person?.person));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members, person]);
	useEffect(() => {
		const mem = [];
		if (members?.length) {
			members
				.filter((m) => m?.id !== currentMember?.id)
				.forEach((m) => {
					m.unReadCount = allMessages?.filter((k) => k.readStatus === "UN_READ" && k.fromMemberID === m.orgUserId).length;
					mem.push(m);
				});
		}
		setFilteredMembers(mem);
		let rolM = [];
		if (roles?.length) {
			roles?.forEach((r) => {
				r.unReadCount = allRoleMessages?.filter((k) => k.toRoleID === r.id && !k.readBy.includes(currentMember?.orgUserId)).length;
				rolM.push(r);
			});
			person?.isAdmin ? setFilteredRoles(rolM) : setFilteredRoles(rolM?.filter((r) => currentMember?.roleIDs?.includes(r.id)));
		}
		let deptM = [];
		if (departments && departments?.length > 0) {
			let dept = person.isAdmin ? departments : departments?.filter((r) => currentMember?.departmentIDs?.includes(r.id));
			dept.forEach((d) => {
				d.unReadCount = allDeptMessages.filter((k) => k.toDeptID === d.id && !k.readBy.includes(currentMember?.orgUserId)).length;
				deptM.push(d);
			});
			setFilteredDept(deptM);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members, departments, roles, currentMember, allMessages, allRoleMessages, allDeptMessages]);
	useEffect(() => {
		fetchOnceAllMessage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMember]);

	useEffect(() => {
		scrollToBottom();
		updateMessageStatus(messages);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messages, isNoticeBoard]);

	async function fetchOnceAllMessage() {
		if (currentMember?.id) {
			getMessages();
			getRoleMessages();
			getDeptMessages();
			getOrgMessages();

			if (searchParams.get("to") && members) {
				let name = searchParams.get("to");
				setChatType("members");
				let acm = members.find((m) => personUtils?.displayName(m).toLowerCase().startsWith(name.toLowerCase()));
				setActiveMemberChat(acm);
				await setMessagesInState(acm, "message").then(setSearchParams({}));
			} else {
				activeChat("", organisation, "wholeOrganisation");
			}
		}
	}

	const updateMessageStatus = async (messages) => {
		if (chatType === "members") {
			const toChangeStatus = messages?.filter((k) => k.toMemberID === currentMember?.orgUserId && k.readStatus === "UN_READ");
			if (toChangeStatus.length > 0) {
				await Promise.all(
					toChangeStatus.map(async (m) => {
						await orgUtils.readIndividualMessage(m);
					})
				)
					.then(serReadCount(toChangeStatus.length))
					.then(getMessages());
			}
		}
		if (chatType === "roles" && activeMemberChat?.id) {
			const toChangeStatus = messages.filter((k) => k.toRoleID === activeMemberChat?.id && !k.readBy.includes(currentMember?.orgUserId));
			if (toChangeStatus.length > 0) {
				try {
					await Promise.all(
						toChangeStatus.map(async (m) => {
							let memberIDs = [];
							memberIDs.push(...m.readBy, currentMember?.orgUserId);
							await orgUtils.readGroupMessage("role", memberIDs, m.id);
						})
					)
						.then(serReadCount(toChangeStatus?.length))
						.then(getRoleMessages());
				} catch (error) {
					console.log(error);
				}
			}
		}
		if (chatType === "departments" && activeMemberChat?.id) {
			const toChangeStatus = messages.filter((k) => k.toDeptID === activeMemberChat?.id && !k.readBy.includes(currentMember?.orgUserId));
			if (toChangeStatus.length > 0) {
				try {
					await Promise.all(
						toChangeStatus.map(async (m) => {
							let memberIDs = [];
							memberIDs.push(...m.readBy, currentMember.orgUserId);
							await orgUtils.readGroupMessage("dept", memberIDs, m.id);
						})
					)
						.then(serReadCount(toChangeStatus.length))
						.then(getDeptMessages());
				} catch (error) {
					console.log(error);
				}
			}
		}
		if (chatType === "wholeOrganisation" && organisation?.id && isNoticeBoard === false) {
			const toChangeStatus = messages.filter((k) => k.organisationID === organisation.id && !k.readBy.includes(currentMember.orgUserId));
			if (toChangeStatus.length > 0) {
				try {
					await Promise.all(
						toChangeStatus.map(async (m) => {
							let memberIDs = [];
							memberIDs.push(...m.readBy, currentMember?.orgUserId);
							await orgUtils.readGroupMessage("whole", memberIDs, m.id);
						})
					).then(serReadCount(toChangeStatus.length));
					await getOrgMessages();
				} catch (error) {
					console.log(error);
				}
			}
		}
	};
	const setFilters = async (e) => {
		if (e.target.value) {
			if (chatType === "members")
				setFilteredMembers(filteredMembers?.filter((m) => personUtils?.displayName(m).toLowerCase().startsWith(e.target.value.toLowerCase())));
			if (chatType === "roles") setFilteredRoles(filteredRoles.filter((m) => m.name.toLowerCase().startsWith(e.target.value.toLowerCase())));
			if (chatType === "departments") setFilteredDept(departments.filter((m) => m.name.toLowerCase().startsWith(e.target.value.toLowerCase())));
		} else {
			setFilteredMembers(members.filter((m) => m.id !== currentMember.id));
			person.isAdmin ? setFilteredRoles(roles) : setFilteredRoles(roles.filter((r) => currentMember?.roleIDs?.includes(r.id)));
			if (departments && departments?.length > 0)
				person.isAdmin
					? setFilteredDept(departments)
					: setFilteredDept(departments.filter((r) => currentMember?.departmentIDs?.includes(r.id)));
		}
	};
	const getMessages = async () => {
		try {
			if (currentMember && organisation) {
				let messages = await orgUtils.getMessage(organisation.id, currentMember?.orgUserId);
				setAllMessages(messages.items);
				if (activeMemberChat)
					setMessages(
						messages?.items.filter((mess) => {
							let array1 = mess?.filterChat.sort();
							let array2 = [currentMember?.orgUserId, activeMemberChat?.orgUserId].sort();
							return array1.every((value, index) => value === array2[index]);
						})
					);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getRoleMessages = async () => {
		try {
			if (currentMember && organisation) {
				let roleMessages = await orgUtils.getRoleMessage(organisation.id);
				setAllRoleMessages(roleMessages.items);
				if (activeMemberChat) setMessages(roleMessages.items.filter((mess) => mess.toRoleID === activeMemberChat.id));
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getDeptMessages = async () => {
		try {
			if (currentMember && organisation) {
				let deptMessages = await orgUtils.getDeptMessage(organisation.id);
				setAllDeptMessages(deptMessages?.items);
				if (activeMemberChat) setMessages(deptMessages?.items.filter((mess) => mess?.toDeptID === activeMemberChat.id));
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getOrgMessages = async () => {
		try {
			if (currentMember && organisation) {
				let orgMessages = await orgUtils.getOrgMessage(organisation.id);
				let unReadCount = orgMessages?.items.filter((k) => !k.readBy.includes(currentMember?.orgUserId)).length;
				setAllOrgUnRead(unReadCount);
				setAllOrgMessages(orgMessages?.items);
				setMessages(orgMessages?.items);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const deleteMessage = async (messageId) => {
		try {
			await orgUtils.deleteMessage(messageId);
			setMessages(messages.filter((m) => m.id !== messageId));
			if (chatType === "members") {
				setAllMessages(allMessages.filter((m) => m.id !== messageId));
			} else if (chatType === "roles") {
				setAllRoleMessages(allRoleMessages.filter((m) => m.id !== messageId));
			} else if (chatType === "departments") {
				setAllDeptMessages(allDeptMessages.filter((m) => m.id !== messageId));
			} else if (chatType === "wholeOrganisation") {
				setAllOrgMessages(allOrgMessages.filter((m) => m.id !== messageId));
			}
		} catch (error) {
			console.log("Failed to delete message", error);
		}
	};

	const sendMessage = async (data) => {
		if (chatType === "members") {
			const message = {
				...data,
				toMemberID: activeMemberChat?.orgUserId,
				fromMemberID: currentMember?.orgUserId,
				organisationID: organisation.id,
				filterChat: [currentMember?.orgUserId, activeMemberChat?.orgUserId].sort(),
				readStatus: "UN_READ"
			};
			await orgUtils.saveMessage(message);
			await getMessages();
			await notifyUser(
				[activeMemberChat?.person],
				`${personUtils.displayName(currentMember)} just messaged you`
			);
		}
		if (chatType === "roles") {
			const message = {
				...data,
				toRoleID: activeMemberChat.id,
				fromMemberID: currentMember.orgUserId,
				organisationID: organisation.id
			};
			await orgUtils.saveRoleMessage(message);
			await getRoleMessages();
			const memerIds = organisation.members
				.map(
					(k) =>
						k.orgUserId !== currentMember?.orgUserId &&
						k?.roleIDs &&
						k?.roleIDs.includes(activeMemberChat?.id) &&
						k?.person &&
						k?.person
				)
				?.filter((i) => i);
			memerIds.length > 0 &&
				(await notifyUser(
					memerIds,
					`${personUtils.displayName(currentMember)} just messaged your ${activeMemberChat.name} group`
				));
		}
		if (chatType === "departments") {
			const message = {
				...data,
				toDeptID: activeMemberChat.id,
				fromMemberID: currentMember.orgUserId,
				organisationID: organisation.id
			};
			await orgUtils.saveDeptMessage(message);
			await getDeptMessages();
			const memerIds = organisation.members
				.map(
					(k) =>
						k.orgUserId !== currentMember.orgUserId &&
						k?.departmentIDs &&
						k?.departmentIDs.includes(activeMemberChat.id) &&
						k?.person &&
						k?.person
				)
				?.filter((i) => i);
			memerIds.length > 0 &&
				(await notifyUser(
					memerIds,
					`${personUtils.displayName(currentMember)} just messaged your ${activeMemberChat.name} group`
				));
		}
		if (chatType === "wholeOrganisation") {
			const message = {
				...data,
				fromMemberID: currentMember?.orgUserId,
				organisationID: organisation.id
			};
			await orgUtils.saveOrgMessage(message);
			await getOrgMessages();
			const memerIds = organisation.members
				.map((k) => k.orgUserId !== currentMember?.orgUserId && k?.person && k?.person)
				?.filter((i) => i);
			memerIds.length > 0 &&
				(await notifyUser(
					memerIds,
					`${personUtils.displayName(currentMember)} just messaged your Organisation group`
				));
		}
	};

	return (
		<Background currentRoute={"messages"} readCount={readCount}>
			<div className="min-w-full border rounded-lg lg:grid lg:grid-cols-3">
				<div className="border-r-2 border-darkww-100 lg:col-span-1">
					<div className="mx-3 my-3">
						<div className="relative text-gray-600">
							<span className="absolute inset-y-0 left-0 flex items-center pl-2">
								<svg
									fill="none"
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									viewBox="0 0 24 24"
									className="w-6 h-6 text-gray-300"
								>
									<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
								</svg>
							</span>
							<input
								type="search"
								className="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
								name="search"
								placeholder="Search"
								required
								onChange={(e) => {
									setFilters(e);
								}}
							/>
						</div>
					</div>

					<ul className={`overflow-auto h-96 ${width < 500 && (activeMemberChat?.orgUserId || activeMemberChat?.id) && "hidden"}`}>
						<div className="flex justify-around mb-4">
							<h2
								className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${chatType === "wholeOrganisation" && "border-pink-500"
									}`}
								onClick={(e) => {
									setChatType("wholeOrganisation");
								}}
							>
								All
								<span className="relative">
									{allOrgUnRead > 0 && (
										<div className="rounded-full bg-pink-600 w-4 h-4 text-white text-sm center absolute flex items-center justify-center -top-1 left-0">
											{allOrgUnRead}
										</div>
									)}
								</span>
							</h2>
							<h2
								className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${chatType === "members" && "border-pink-500"
									}`}
								onClick={() => {
									setChatType("members");
									setActiveMemberChat("");
								}}
							>
								Members
								<span className="relative">
									{filteredMembers.reduce((a, b) => a + b.unReadCount, 0) > 0 && (
										<div className="rounded-full bg-pink-600 w-4 h-4 text-white text-sm center absolute flex items-center justify-center -top-1 left-0">
											{filteredMembers.reduce((a, b) => a + b.unReadCount, 0)}
										</div>
									)}
								</span>
							</h2>
							{filteredRoles?.length > 0 && (
								<h2
									className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${chatType === "roles" && "border-pink-500"
										}`}
									onClick={() => {
										setChatType("roles");
										setActiveMemberChat("");
									}}
								>
									Role
									<span className="relative">
										{filteredRoles.reduce((a, b) => a + b.unReadCount, 0) > 0 && (
											<div className="rounded-full bg-pink-600 w-4 h-4 text-sm text-white center absolute flex items-center justify-center -top-1 left-0">
												{filteredRoles.reduce((a, b) => a + b.unReadCount, 0)}
											</div>
										)}
									</span>
								</h2>
							)}
							{filteredDept?.length > 0 && (
								<h2
									className={`my-2 mb-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${chatType === "departments" && "border-pink-500"
										}`}
									onClick={() => {
										setChatType("departments");
										setActiveMemberChat("");
									}}
								>
									Department
									<span className="relative">
										{filteredDept.reduce((a, b) => a + b.unReadCount, 0) > 0 && (
											<div className="rounded-full bg-pink-600 w-4 h-4 text-white text-sm center absolute flex items-center justify-center -top-1 left-0">
												{filteredDept.reduce((a, b) => a + b.unReadCount, 0)}
											</div>
										)}
									</span>
								</h2>
							)}
						</div>
						{chatType === "members" && (
							<li>
								{filteredMembers?.map((m) => (
									<span
										className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer ${activeMemberChat && activeMemberChat?.id === m.id ? "bg-gray-100 " : "hover:bg-gray-100 "
											}focus:outline-none`}
										key={m.id}
										onClick={(e) => {
											activeChat(e, m, "members");
										}}

									>
										<Avatar user={m} size="h-8 w-8" twClasses={`object-cover rounded-full`} />
										<div className="w-full">
											<div className="flex justify-between">
												<span className="block ml-4 font-semibold text-lg text-darkww-600">{personUtils?.displayName(m)}</span>
												<span className="relative">
													{m?.unReadCount > 0 && (
														<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-1 -left-2">
															{m.unReadCount}
														</div>
													)}
												</span>
											</div>
										</div>
									</span>
								))}
							</li>
						)}
						{chatType === "departments" && (
							<li>
								{filteredDept?.map((m) => (
									<span
										className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer ${activeMemberChat && activeMemberChat?.id === m.id ? "bg-gray-100 " : "hover:bg-gray-100 "
											}focus:outline-none`}
										key={m.id}
										onClick={(e) => activeChat(e, m, "departments")}
									>
										<div className="w-full">
											<div className="flex justify-between">
												<span className="block ml-2 font-semibold text-lg text-darkww-600">{m.name}</span>
												<span className="relative">
													{m?.unReadCount > 0 && (
														<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-1 -left-2">
															{m.unReadCount}
														</div>
													)}
												</span>
											</div>
										</div>
									</span>
								))}
							</li>
						)}
						{chatType === "roles" && (
							<li>
								{filteredRoles?.map((r) => (
									<span
										className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
										key={r.id}
										onClick={(e) => activeChat(e, r, "roles")}
									>
										<div style={{ backgroundColor: r?.labelColour }} className="h-6 md:h-12 w-6 rounded-l-md z-10"></div>
										<div className="w-full">
											<div className="flex justify-between">
												<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">{r.name}</span>
												<span className="relative">
													{r?.unReadCount > 0 && (
														<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-1 -left-2">
															{r.unReadCount}
														</div>
													)}
												</span>
											</div>
										</div>
									</span>
								))}
							</li>
						)}
						{chatType === "wholeOrganisation" && (
							<li>
								<span
									className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
									onClick={(e) => {
										setIsNoticeBoard(true);
										activeChat(e, organisation, "wholeOrganisation");
									}}
								>
									<div className="w-full">
										<div className="flex justify-between">
											<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">Notice Board</span>
										</div>
									</div>
								</span>
								<span
									className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
									onClick={(e) => {
										setIsNoticeBoard(false);
										activeChat(e, organisation, "wholeOrganisation");
									}}
								>
									<div className="w-full">
										<div className="flex justify-between">
											<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">Messages</span>
											<span className="relative">
												{allOrgUnRead > 0 && (
													<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-1 -left-2">
														{allOrgUnRead}
													</div>
												)}
											</span>
										</div>
									</div>
								</span>
							</li>
						)}
					</ul>
				</div>
				{activeMemberChat ? (
					<div className="col-span-2 block">
						{chatType === "wholeOrganisation" && isNoticeBoard === true ? (
							<NoticeBoard
								currentMember={currentMember}
								noticeModel={noticeModel}
								setNoticeModel={setNoticeModel}
								editNotice={editNotice}
								setEditNotice={setEditNotice}
								setChatType={setChatType}
								setActiveMemberChat={setActiveMemberChat}
							/>
						) : (
							<>
								<MessagesView
									currentMember={currentMember}
									chatType={chatType}
									activeMemberChat={activeMemberChat}
									setActiveMemberChat={setActiveMemberChat}
									messages={messages}
									sendMessage={sendMessage}
									deleteMessage={deleteMessage}
								/>
							</>
							// <div className="w-full h-full flex flex-col justify-between	">
							// 	<div className="relative flex items-center p-3 border-b border-darkww-100">
							// 		{chatType === "members" && (
							// 			<>
							// 				<Avatar user={activeMemberChat} size="h-8 w-8" twClasses={`object-cover rounded-full`} />
							// 				<span className="block ml-2 font-bold text-gray-600">{personUtils.displayName(activeMemberChat)}</span>
							// 			</>
							// 		)}
							// 		{chatType === "roles" && (
							// 			<>
							// 				<div
							// 					style={{ backgroundColor: activeMemberChat?.labelColour }}
							// 					className="h-7 w-10 rounded-l-md z-10"
							// 				></div>
							// 				<span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">{activeMemberChat?.name}</span>
							// 			</>
							// 		)}
							// 		{chatType === "departments" && (
							// 			<>
							// 				<span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">{activeMemberChat?.name}</span>
							// 			</>
							// 		)}
							// 		{chatType === "wholeOrganisation" && (
							// 			<>
							// 				<Logo org={organisation} size="h-8 w-8" />
							// 				<span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">{organisation.name}</span>
							// 			</>
							// 		)}
							// 		<button
							// 			className="sm:hidden ml-auto order-2 mt-1 mr-1 flex p-1 text-white rounded-md bg-darkww-500 focus:outline-none focus:ring-2 ring-white sm:-mr-2"
							// 			onClick={() => setActiveMemberChat("")}
							// 		>
							// 			back
							// 		</button>
							// 	</div>
							// 	<div className="relative w-full p-6 overflow-y-auto mb-auto max-h-80">
							// 		<ul className="space-y-2">
							// 			{messages && messages.length > 0
							// 				? messages.map((m) => (
							// 					<MessageBubble key={m.id} message={m} currentMember={currentMember} chatType={chatType} deleteMessage={deleteMessage} />
							// 				))
							// 				: "Start this conversation by sending the first message!"}
							// 			<div ref={messagesEndRef} />
							// 		</ul>
							// 	</div>
							// 	<Form
							// 		validationSchema={messageSchema}
							// 		enableReinitialize={true}
							// 		disabled={messageSending}
							// 		initialValues={initValues}
							// 		onSubmit={async (data, { resetForm }) => {
							// 			try {
							// 				setMessageSending(true);
							// 				if (chatType === "members") {
							// 					const message = {
							// 						...data,
							// 						toMemberID: activeMemberChat?.orgUserId,
							// 						fromMemberID: currentMember?.orgUserId,
							// 						organisationID: organisation.id,
							// 						filterChat: [currentMember?.orgUserId, activeMemberChat?.orgUserId].sort(),
							// 						readStatus: "UN_READ"
							// 					};
							// 					await orgUtils.saveMessage(message);
							// 					await getMessages();
							// 					await notifyUser(
							// 						[activeMemberChat?.person],
							// 						`${personUtils.displayName(currentMember)} just messaged you`
							// 					);
							// 				}
							// 				if (chatType === "roles") {
							// 					const message = {
							// 						...data,
							// 						toRoleID: activeMemberChat.id,
							// 						fromMemberID: currentMember.orgUserId,
							// 						organisationID: organisation.id
							// 					};
							// 					await orgUtils.saveRoleMessage(message);
							// 					await getRoleMessages();
							// 					const memerIds = organisation.members
							// 						.map(
							// 							(k) =>
							// 								k.orgUserId !== currentMember?.orgUserId &&
							// 								k?.roleIDs &&
							// 								k?.roleIDs.includes(activeMemberChat?.id) &&
							// 								k?.person &&
							// 								k?.person
							// 						)
							// 						?.filter((i) => i);
							// 					memerIds.length > 0 &&
							// 						(await notifyUser(
							// 							memerIds,
							// 							`${personUtils.displayName(currentMember)} just messaged your ${activeMemberChat.name} group`
							// 						));
							// 				}
							// 				if (chatType === "departments") {
							// 					const message = {
							// 						...data,
							// 						toDeptID: activeMemberChat.id,
							// 						fromMemberID: currentMember.orgUserId,
							// 						organisationID: organisation.id
							// 					};
							// 					await orgUtils.saveDeptMessage(message);
							// 					await getDeptMessages();
							// 					const memerIds = organisation.members
							// 						.map(
							// 							(k) =>
							// 								k.orgUserId !== currentMember.orgUserId &&
							// 								k?.departmentIDs &&
							// 								k?.departmentIDs.includes(activeMemberChat.id) &&
							// 								k?.person &&
							// 								k?.person
							// 						)
							// 						?.filter((i) => i);
							// 					memerIds.length > 0 &&
							// 						(await notifyUser(
							// 							memerIds,
							// 							`${personUtils.displayName(currentMember)} just messaged your ${activeMemberChat.name} group`
							// 						));
							// 				}
							// 				if (chatType === "wholeOrganisation") {
							// 					const message = {
							// 						...data,
							// 						fromMemberID: currentMember?.orgUserId,
							// 						organisationID: organisation.id
							// 					};
							// 					await orgUtils.saveOrgMessage(message);
							// 					await getOrgMessages();
							// 					const memerIds = organisation.members
							// 						.map((k) => k.orgUserId !== currentMember?.orgUserId && k?.person && k?.person)
							// 						?.filter((i) => i);
							// 					memerIds.length > 0 &&
							// 						(await notifyUser(
							// 							memerIds,
							// 							`${personUtils.displayName(currentMember)} just messaged your Organisation group`
							// 						));
							// 				}
							// 				resetForm();

							// 				setMessageSending(false);
							// 			} catch (error) {
							// 				console.log(error);
							// 				setMessageSending(false);
							// 			}
							// 		}}
							// 	>
							// 		<div className="flex items-center justify-between w-full p-3 border-t border-darkww-100 gap-2">
							// 			<span className="block w-full mx-3">
							// 				<InputField name="message" placeholder="Message..." />
							// 			</span>

							// 			<Button
							// 				type="submit"
							// 				label={
							// 					<svg
							// 						className="w-6 h-6 text-white bg-lightww-500 origin-center transform rotate-90"
							// 						xmlns="http://www.w3.org/2000/svg"
							// 						viewBox="0 0 20 20"
							// 						fill="currentColor"
							// 					>
							// 						<path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
							// 					</svg>
							// 				}
							// 				colors="bg-white ring-darkww-600"
							// 				className="w-full flex justify-center bg-lightww-500 py-2 px-3 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 mb-3"
							// 				disabled={messageSending}
							// 			/>
							// 		</div>
							// 	</Form>
							// </div>
						)}
					</div>
				) : (
					<div className="flex items-center">
						<h3 className="text-xl font-bold text-lightww-700 uppercase tracking-wide py-6 mx-auto">
							Select A{chatType === "members" ? " Staff Member" : chatType === "roles" ? " Role" : " Department"}
						</h3>
					</div>
				)}
			</div>
		</Background>
	);
};
export default Messages;
