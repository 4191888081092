import { useEffect, useState } from "react";

const fetchLocationData = async () => {
	const api1 = "http://ip-api.com/json";
	const api2 = "https://freeipapi.com/api/json";
	try {
		const response = await fetch(api2);
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching location data:", error);
		return null;
	}
};

const determineCurrency = (countryCode) => {
	const currencyMap = {
		US: "$", // United States - Dollar
		GB: "£", // United Kingdom - Pound
		FR: "€" // Ireland - Euro
	};

	const detectedCurrency = currencyMap[countryCode];
	return detectedCurrency || "€";
};

export { determineCurrency, fetchLocationData };

const CurrencyDisplay = () => {
	const [currencySymbol, setCurrencySymbol] = useState("€");

	useEffect(() => {
		const fetchCurrency = async () => {
			const locationData = await fetchLocationData();
			if (locationData?.countryCode) {
				const currency = determineCurrency(locationData.countryCode);
				setCurrencySymbol(currency);
			}
		};

		fetchCurrency();
	}, []);

	return <span className="text-gray-500 sm:text-sm mr-1">{currencySymbol}</span>;
};

export default CurrencyDisplay;