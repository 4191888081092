import { ColorSwatchIcon, TagIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputField, LinkButton, Yup, showFailure, showSuccess, useNavigate, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

const roleSchema = Yup.object().shape({
	name: Yup.string().required().min(2),
	labelColour: Yup.string().matches(/^#[0-9A-F]{6}$/i, "Not a valid HEX CSS value.")
});

export const LabelColourContext = React.createContext({
	colour: {},
	setColour: () => {}
});

export const LabelColourProvider = ({ children }) => {
	const [colour, setColour] = useState("#c62cf6");

	return <LabelColourContext.Provider value={{ colour, setColour }}>{children}</LabelColourContext.Provider>;
};

export const RoleForm = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const { colour, setColour } = useContext(LabelColourContext);
	const [roleName, setRoleName] = useState();
	const [roleSaving, setRoleSaving] = useState(false);
	const { setMessage } = useSimpleMessage();
	let navigate = useNavigate();

	const initValues = { name: roleName, labelColour: colour };

	return (
		<Form
			validationSchema={roleSchema}
			enableReinitialize={true}
			disabled={roleSaving}
			initialValues={initValues}
			onSubmit={async (data) => {
				try {
					setRoleSaving(true);

					const roleDetails = {
						...data,
						...{ organisationID: organisation.id }
					};

					const updateOrg = await orgUtils.saveRole(roleDetails);

					setOrganisation({
						...updateOrg,
						...(await orgUtils.mapOrgMembers(updateOrg)),
						...{ report: await orgUtils.mapReport(updateOrg?.report?.items || [], updateOrg.id) },
						...{ departments: orgUtils?.departments?.items || [] },
						...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? orgUtils?.subscription?.items?.[0]?.quantity }
					});
					setRoleSaving(false);
					setRoleName("");
					setColour("#c62cf6");
					setMessage(
						showSuccess({
							title: "Role saved successfully."
						})
					);
				} catch (error) {
					setRoleSaving(false);
					setMessage(
						showFailure({
							title: "Unable to save Role.",
							subTitle: error.message
						})
					);
				}
			}}
		>
			<div className="grid grid-cols-9 gap-6 mt-4 py-2">
				<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-3">
					<InputField label="Role Name" name="name" value={roleName} onChange={(value) => setRoleName(value)} />
				</div>

				<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-3">
					<div className="w-full">
						<div className="flex items-center">
							<InputField label="Label Colour" name="labelColour" value={colour} onChange={(value) => setColour(value)} />
							<button
								type="button"
								className=""
								onClick={() =>
									navigate("/administration/roles/label/colour", {
										state: {
											from: "/administration/roles",
											backgroundLocation: "/administration/roles",
											file: "/Organisation/Settings/Roles/LabelColourPicker"
										}
									})
								}
							>
								<span className="sr-only">Colour Swatch</span>
								<ColorSwatchIcon style={{ color: colour }} className="h-8 w-8 ml-3" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>

				<div className="h-full flex items-center col-span-9 sm:col-span-9 lg:col-span-3">
					<div className="w-full">
						<Button type="submit" label="Save" disabled={roleSaving} />
					</div>
				</div>
			</div>
		</Form>
	);
};

const Roles = () => {
	const { organisation } = useContext(OrganisationContext);
	const [roles, setRoles] = useState([]);
	const { setMessage } = useSimpleMessage();
	let navigate = useNavigate();

	const loadRoles = async () => {
		try {
			const { roles } = await orgUtils.get(organisation);
			setRoles(roles?.items || []);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (organisation.id) loadRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	const deleteRole = async (roleId) => {
		try {
			await orgUtils.deleteRole(roleId);
			loadRoles();
			setMessage(
				showSuccess({
					title: "Role Successfully Removed"
				})
			);
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to remove Role.",
					subTitle: error.message
				})
			);
		}
	};

	return (
		<div className="divide-y divide-gray-200 lg:col-span-9 h-fit">
			<div className="py-6 px-4 sm:p-6 lg:pb-8">
				<div className="text-center">
					<svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
						<path d="M12 14l9-5-9-5-9 5 9 5z" />
						<path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
						/>
					</svg>
					<h2 className="mt-2 text-lg font-medium text-gray-900">Manage Roles</h2>
					<p className="mt-1 text-sm text-gray-500">
						Roles are the specific areas of responsibility or skill within your organisation e.g. head chef, barista, bar tender, etc.
					</p>
					<p className="mt-1 text-sm text-gray-500">
						Roles are used to allow employees to easily see what they are doing and what groups they can change shifts with. They also
						allow managers to easily see who is qualified to do what shifts.
					</p>
					<p className="mt-1 text-sm text-gray-500">
						Roles can also be used to ensure you are people of the same experience can only change with similar employees e.g.
						supervisors, senior staff, assistant managers, etc.
					</p>
				</div>
				<RoleForm />
				<div className="text-center">
					<p className="mt-1 text-sm text-gray-500">
						Roles can be assigned next to employee names in the "Staff Members" section to the left.
					</p>
				</div>
			</div>

			<div className="mt-4 pb-6">
				<h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide pl-6 pt-6">Current roles ({roles.length})</h3>
				<div className="flex flex-col mt-6">
					<div className="overflow-x-auto lg:-mx-4">
						<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
							<div className="shadow overflow-hidden divide-y divide-gray-200 border-b border-gray-200 rounded-md sm:rounded-lg">
								{roles.map((role) => {
									return (
										<div key={role.id} className="grid grid-cols-12 gap-6">
											<div className="p-3 mt-2 col-span-1 hidden sm:block">
												<div className="pt-1 pl-3 w-14 h-14">
													<TagIcon style={{ color: role.labelColour }} className="h-10 w-10" aria-hidden="true" />
												</div>
											</div>
											<div className="ml-1 sm:ml-0 pt-3 mt-2 col-span-6 sm:col-span-3 flex flex-col">
												<span className="px-2 text-lg sm:text-md font-semibold">{role.name}</span>
												<div className="flex pl-2 mt-1 sm:pt-0">
													<div className="sm:hidden">
														<TagIcon style={{ color: role.labelColour }} className="h-5 w-5" aria-hidden="true" />
													</div>
													<span className="text-sm pl-1 sm:pl-0 text-gray-500 font-semibold">{role.labelColour}</span>
												</div>
											</div>
											<div className="col-span-4 hidden sm:block"></div>
											<div className="col-span-2 py-8 text-right text-sm font-medium">
												<LinkButton
													label="Edit"
													className="text-darkww-600 font-bold hover:text-darkww-900"
													onClick={() => {
														navigate("/administration/roles/edit", {
															state: {
																from: "/administration/roles",
																backgroundLocation: "/administration/roles",
																file: "/Organisation/Settings/Roles/EditRole",
																compProps: {
																	record: {
																		id: role.id,
																		name: role.name,
																		labelColour: role.labelColour
																	}
																}
															}
														});
													}}
												/>
											</div>
											<div className="col-span-2 px-2 lg:px-6 py-8 text-sm font-medium">
												<LinkButton
													label="Remove"
													className="text-red-600 font-bold hover:text-red-900"
													onClick={() => {
														deleteRole(role.id);
													}}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Roles;
