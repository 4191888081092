import { UserGroupIcon, OfficeBuildingIcon, UserAddIcon } from "@heroicons/react/outline";
import { Link } from "ww-framework";
import noOrgBgDefault from "ww-images/no-org-bg-default.jpg";

let supportLinks = [
	{
		id: 1,
		name: "Join an Organisation",
		href: "/invitations",
		description: `You're not currently associated with any organisations, you'll need one to get started properly. Please ask your organisation's manager to add you with your email, or set up your own organisation and invite employees.
    Once invited, we can prompt you to join that organisation on log in.`,
		icon: UserGroupIcon,
		linkText: "Check All Invitations"
	},
	{
		id: 2,
		name: "Your Profile",
		href: "/profile",
		description: `A good profile, that includes your full name and a profile picture, helps other users of the platform to engage with you better.
      Click on the link below to get started.`,
		icon: UserAddIcon,
		linkText: "Create a Profile"
	},
	{
		id: 3,
		name: "Create an Organisation",
		href: "/administration",
		description: `If you own or run a business then you can create an organisation to get started now. 
      You'll be able to add details such as your staff members, unique roles, locations, etc.
      Click on the link below to begin.`,
		icon: OfficeBuildingIcon,
		linkText: "Create Organisation Now"
	}
];

const Welcome = ({ profileCreated }) => {
	const linkOverwrite = {
		id: 2,
		name: "Update Profile",
		href: "/profile",
		description: `You'll need to fill out your profile with such details as your full name, profile pic, contact for you and in case of emergency.
      Click on the link below to get started.`,
		icon: UserAddIcon,
		linkText: "Update Profile"
	};

	if (profileCreated) supportLinks = supportLinks.map((link) => (link.id !== linkOverwrite.id ? link : linkOverwrite));

	return (
		<div className="h-full lg:bg-white ">
			{/* Header */}
			<div className="relative pb-32 bg-gray-800">
				<div className="absolute inset-0">
					<img className="w-full h-full object-cover" src={noOrgBgDefault} alt="" />
					<div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
				</div>
				<div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-8">
					<h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Welcome</h1>
					<p className="mt-6 max-w-3xl text-xl text-gray-300">
						Thank you for joining Workwall, we're looking forward to working for you. <br /> Below are some useful links to help you get
						started.
					</p>
				</div>
			</div>

			{/* Overlapping cards */}
			<section className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
				<h2 className="sr-only" id="contact-heading">
					Welcome
				</h2>
				<div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
					{supportLinks.map((link) => (
						<div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
							<div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
								<div className="absolute top-0 p-5 inline-block bg-darkww-600 rounded-xl shadow-lg transform -translate-y-1/2">
									<link.icon className="h-6 w-6 text-white" aria-hidden="true" />
								</div>
								<h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
								<p className="mt-4 text-base text-gray-500">{link.description}</p>
							</div>
							{link.linkText && (
								<div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
									<Link to={link.href} className="text-base font-medium text-darkww-700 hover:text-darkww-600">
										{link.linkText}
										<span aria-hidden="true"> &rarr;</span>
									</Link>
								</div>
							)}
						</div>
					))}
				</div>
			</section>
		</div>
	);
};

export default Welcome;
