import { IdentificationIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputField, LinkButton, Yup, showFailure, showSuccess, useNavigate, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

const departmentSchema = Yup.object().shape({
	name: Yup.string().required().min(2)
});

export const DepartmentForm = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const [departmentName, setDepartmentName] = useState();
	const [departmentSaving, setDepartmentSaving] = useState(false);
	const { setMessage } = useSimpleMessage();
	const initValues = { name: departmentName };

	return (
		<Form
			validationSchema={departmentSchema}
			enableReinitialize={true}
			disabled={departmentSaving}
			initialValues={initValues}
			onSubmit={async (data) => {
				try {
					setDepartmentSaving(true);

					const departmentDetails = {
						...data,
						...{ organisationID: organisation.id }
					};

					const updateOrg = await orgUtils.saveDepartment(departmentDetails);

					setOrganisation({
						...updateOrg,
						...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? updateOrg.subscription?.items?.[0]?.quantity },
						...(await orgUtils.mapOrgMembers(updateOrg)),
						...{ report: await orgUtils.mapReport(updateOrg?.report?.items || [], updateOrg.id) },
						...{ departments: updateOrg?.departments?.items || [] }
					});
					setDepartmentSaving(false);
					setDepartmentName("");
					setMessage(
						showSuccess({
							title: "Department saved successfully."
						})
					);
				} catch (error) {
					setDepartmentSaving(false);
					setMessage(
						showFailure({
							title: "Unable to save Department.",
							subTitle: error.message
						})
					);
				}
			}}
		>
			<div className="grid grid-cols-9 gap-8 mt-4 py-2">
				<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-5">
					<InputField label="Department Name" name="name" value={departmentName} onChange={(value) => setDepartmentName(value)} />
				</div>
				<div className="h-full flex mt-0.5 items-end pb-1.5 col-span-9 sm:col-span-9 lg:col-span-3">
					<div className="w-full">
						<Button type="submit" label="Save" disabled={departmentSaving} />
					</div>
				</div>
			</div>
		</Form>
	);
};

const Departments = () => {
	const { organisation } = useContext(OrganisationContext);
	const [departments, setDepartments] = useState([]);
	const { setMessage } = useSimpleMessage();
	let navigate = useNavigate();

	const loadDepartments = async () => {
		try {
			const { departments } = await orgUtils.get(organisation);
			setDepartments(departments?.items || []);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (organisation.id) loadDepartments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	const deleteDepartment = async (departmentId) => {
		try {
			await orgUtils.deleteDepartment(departmentId);
			loadDepartments();
			setMessage(
				showSuccess({
					title: "Department Successfully Removed"
				})
			);
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to remove Department.",
					subTitle: error.message
				})
			);
		}
	};

	return (
		<div className="divide-y divide-gray-200 lg:col-span-9 h-fit">
			<div className="py-6 px-4 sm:p-6 lg:pb-8">
				<div className="text-center">
					<IdentificationIcon className="mx-auto h-12 w-12 text-gray-400" />
					<h2 className="mt-2 text-lg font-medium text-gray-900">Manage Departments</h2>
					<p className="mt-1 text-sm text-gray-500">
						Departments are the areas of your organisation that operate mostly independently to others e.g. front or back of house, food &
						beverage or guest sevices, etc.
					</p>
					<p className="mt-1 text-sm text-gray-500">
						{" "}
						An employee that is assigned to one department will not be able to see employees in departments they have not been assigned
						to.{" "}
					</p>
					<p className="mt-1 text-sm text-gray-500">
						Departments can also be managed seperately, like when it comes to copying weeks, checking staff costs, etc.
					</p>
				</div>
				<DepartmentForm />
				<div className="text-center">
					<p className="mt-1 text-sm text-gray-500">
						Departments can be assigned next to employee names in the "Staff Members" section to the left
					</p>
					.
					<p className="mt-1 text-sm text-gray-500">
						If you don't want your staff to see other employees shifts, please leave departments empty. Each employee will be treated as
						it's own department and so can not see others. Otherwise enter at least one and assign each employee to that department.
					</p>
				</div>
			</div>

			<div className="mt-4 pb-6">
				<h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide pl-6 pt-6">Current departments ({departments.length})</h3>
				<div className="flex flex-col mt-6">
					<div className="overflow-x-auto lg:-mx-4">
						<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
							<div className="shadow overflow-hidden divide-y divide-gray-200 border-b border-gray-200 rounded-md sm:rounded-lg">
								{departments.map((department) => {
									return (
										<div key={department.id} className="grid grid-cols-12 gap-6">
											<div className="ml-1 sm:ml-0 pt-3 mt-2 col-span-6 sm:col-span-3 flex flex-col">
												<span className="px-2 text-lg sm:text-md font-semibold">{department.name}</span>
											</div>
											<div className="col-span-4 hidden sm:block"></div>
											<div className="col-span-2 py-8 text-right text-sm font-medium">
												<LinkButton
													label="Edit"
													className="text-darkww-600 font-bold hover:text-darkww-900"
													onClick={() => {
														navigate("/administration/departments/edit", {
															state: {
																from: "/administration/departments",
																backgroundLocation: "/administration/departments",
																file: "/Organisation/Settings/Departments/EditDepartment",
																compProps: {
																	record: {
																		id: department.id,
																		name: department.name
																	}
																}
															}
														});
													}}
												/>
											</div>
											<div className="col-span-2 px-2 lg:px-6 py-8 text-sm font-medium">
												<LinkButton
													label="Remove"
													className="text-red-600 font-bold hover:text-red-900"
													onClick={() => {
														deleteDepartment(department.id);
													}}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Departments;
