import React, { Fragment, useState } from "react";
import { Menu, Transition as Transitions, Form, InputField, Button, Yup } from "ww-framework";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { orgUtils } from "ww-stores";

const ExtraPay = ({ currentDay, organisationID, record, getExtraPay }) => {
	const [openCustom, setOpenCustom] = useState(false);
	const [paySaving, setPaySaving] = useState(false);
	const [payForDay, setPayForDay] = useState({});
	const initValues = {
		pay: record?.newPay || ""
	};
	const handlePayForDay = async (e, date, type, organisationID, record) => {
		if (e !== "") e.stopPropagation();
		try {
			if (type !== "CUSTOM") {
				const extraPayDayData = {
					id: record?.id,
					organisationID: organisationID,
					baseDay: date,
					newPay: type
				};
				await orgUtils.saveExtraPayDay(extraPayDayData);
				await getExtraPay();
			} else {
				setPayForDay({
					id: record?.id,
					organisationID: organisationID,
					baseDay: date
				});
				setOpenCustom(true);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const customPaySchema = Yup.object().shape({
		pay: Yup.number().required().min(2).max(99)
	});
	const handlerChooseModuleModal = (e, type) => {
		if (e !== "") e.stopPropagation();
		// setOpenCustom(false);
		setPayForDay({});
	};
	const submitForm = async (data, { resetForm }) => {
		try {
			setPaySaving(true);

			const extraPayDayData = { ...payForDay, newPay: parseInt(data?.pay) };
			await orgUtils.saveExtraPayDay(extraPayDayData);
			await getExtraPay();
			setPaySaving(false);
			resetForm();
			setOpenCustom(false);
		} catch (error) {
			console.log(error);
			setPaySaving(false);
		}
	};
	const CustomPay = ({ open, handlerModal, setOpenCustom }) => {
		return (
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
					<div className="flex items-center justify-center h-full w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="bg-white transform rounded-lg w-fit w-72">
								<span
									className="inline-block absolute top-0 right-0 mr-2 mt-4 cursor-pointer"
									onClick={(e) => handlerModal(e, "close")}
								>
									<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								<div className="w-full flex flex-col px-2 py-3">
									<div className="flex flex justify-center gap-2">
										<Form
											validationSchema={customPaySchema}
											enableReinitialize={true}
											disabled={paySaving}
											initialValues={initValues}
											onSubmit={submitForm}
										>
											<div className="w-full">
												<div className="mt-5">
													<InputField label="Extra Pay" name="pay" />
												</div>

												<div className="w-full mt-1">
													<Button onClick={(e) => e.stopPropagation()} type="submit" label="Save" disabled={paySaving} />
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};
	return (
		<>
			<CustomPay open={openCustom} handlerModal={handlerChooseModuleModal} setOpenCustom={setOpenCustom} />
			<Menu as="div" className="relative">
				{({ open }) => (
					<div
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<Menu.Button className="text-md border-2 ml-0.5 border-purple-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500">
							<ChevronDownIcon className="h-2 w-2 text-purple-700 sm:block" aria-hidden="true" />
						</Menu.Button>
						<Transitions
							show={open}
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="absolute mt-2 px-1.5 w-max rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
								<div className="sm:block" onClick={(e) => handlePayForDay(e, currentDay, 0, organisationID, record)}>
									<div className="ml-1.5 underline">Pay Rate</div>
									<Menu.Item>
										<div
											className={`block px-2 py-1.5 text-sm cursor-pointer ${
												record && record.newPay === 0 ? "bg-lightww-600 text-white" : "text-gray-700"
											}`}
										>
											Standard
										</div>
									</Menu.Item>
								</div>
								<div className="sm:block" onClick={(e) => handlePayForDay(e, currentDay, 50, organisationID, record)}>
									<Menu.Item>
										<div
											className={`block px-2 py-1.5 text-sm cursor-pointer ${
												record && record.newPay === 50 ? "bg-lightww-600 text-white" : "text-gray-700"
											}`}
										>
											+50 %
										</div>
									</Menu.Item>
								</div>
								<div className="sm:block" onClick={(e) => handlePayForDay(e, currentDay, 100, organisationID, record)}>
									<Menu.Item>
										<div
											className={`block px-2 py-1.5 text-sm cursor-pointer ${
												record && record.newPay === 100 ? "bg-lightww-600 text-white" : "text-gray-700"
											}`}
										>
											+100 %
										</div>
									</Menu.Item>
								</div>
								<div className="sm:block" onClick={(e) => handlePayForDay(e, currentDay, "CUSTOM", organisationID, record)}>
									<Menu.Item>
										<div
											className={`block px-2 py-1.5 text-sm cursor-pointer ${
												record && ![0, 50, 100].includes(record.newPay) ? "bg-lightww-600 text-white" : "text-gray-700"
											}`}
										>
											Custom
										</div>
									</Menu.Item>
								</div>
							</Menu.Items>
						</Transitions>
					</div>
				)}
			</Menu>
		</>
	);
};

export default ExtraPay;
