import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { Button, Form, InputField, Yup, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils, personUtils } from "ww-stores";

const noticeSchema = Yup.object().shape({
	email: Yup.string().email().required()
});
const AddEmailForm = ({ handlerModal, member, allMembers, getAllOrgNotices, organisation, setOrganisation }) => {
	const { setMessage } = useSimpleMessage();
	const [noticeSaving, setNoticeSaving] = useState(false);
	return (
		<>
			<Form
				validationSchema={noticeSchema}
				enableReinitialize={true}
				disabled={noticeSaving}
				initialValues={{ email: "" }}
				onSubmit={async (data, { resetForm }) => {
					try {
						const emailAddress = data.email?.toLowerCase();
						if (allMembers.find((member) => member.email?.toLowerCase() === emailAddress)) {
							return setMessage(
								showFailure({
									title: "This email address is already registered to an existing user."
								})
							);
						}
						setNoticeSaving(true);
						const results = await orgUtils.addMember({}, organisation.id, emailAddress, member.orgUsername, member.orgUserId);
						const {
							orgMember: { id: orgMemberID },
							organisation: updatedOrg
						} = results;

						await orgUtils.createInvitation(orgMemberID, emailAddress);

						const mergedOrg = {
							...updatedOrg,
							...(await orgUtils.mapOrgMembers(updatedOrg)),
							...{ report: await orgUtils.mapReport(updatedOrg?.report?.items || [], updatedOrg.id) },
							...{ departments: updatedOrg.departments?.items || [] },
							...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? updatedOrg?.subscription?.items[0].quantity }
						};
						setOrganisation(mergedOrg);

						setNoticeSaving(false);
						resetForm();
						handlerModal("", false);
						setMessage(
							showSuccess({
								title: "Email Attached Successfully."
							})
						);
					} catch (error) {
						console.log(error);
						setNoticeSaving(false);
						setMessage(
							showFailure({
								title: "Unable to add email To Member.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-full">
					<div className="my-5">
						<InputField
							label={`Invite ${
								member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
							} via email`}
							name="email"
						/>
					</div>
					<div className="w-full mt-1">
						<Button type="submit" label="Save" disabled={noticeSaving} />
					</div>
				</div>
			</Form>
		</>
	);
};
const AddEmailToMember = ({ record, open, handlerModal, setOrganisation }) => {
	const { organisation } = useContext(OrganisationContext);
	const { members } = organisation;
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-96">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3 px-2">
								<div className="justify-center gap-2">
									<AddEmailForm
										organisation={organisation}
										handlerModal={handlerModal}
										member={record}
										allMembers={members}
										setOrganisation={setOrganisation}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default AddEmailToMember;
