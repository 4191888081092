import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import CheckInsView from "./CheckInsView";

const StaffCheckInsOverlay = ({ overlayOpen, setOverlayOpen }) => {
	return (
		<Transition.Root show={overlayOpen} as={Fragment}>
			<Dialog as="div" className="prem fixed z-20 inset-0 overflow-hidden" onClose={setOverlayOpen}>
				<div className="absolute inset-0 overflow-hidden">
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full"
						>
							<div style={{ width: 500 }} className="relative px-5">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-500"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-500"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-1 right-8 -ml-8 pt-2 pr- flex sm:-ml-10 sm:pr-4">
										<button
											type="button"
											className="rounded-md text-gray-300 hover:text-white focus:outline-none"
											onClick={() => setOverlayOpen(false)}
										>
											<span className="sr-only">Close panel</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<CheckInsView />
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default StaffCheckInsOverlay;
