import { ChatIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Tooltip, Transition as Transitions } from "ww-framework";
import { personUtils } from "ww-stores";

export const CountBubble = ({ preferType, backgroundColor }) => {
	const [isShowing, setIsShowing] = useState(false);
	const [menuPosition, setMenuPosition] = useState("right");
	const menuRef = useRef(null);

	useEffect(() => {
		// Function checks if menu should be positioned on the left or right based on available space
		const checkMenuPosition = () => {
			if (menuRef.current) {
				const menuRect = menuRef.current.getBoundingClientRect();
				const bodyWidth = document.body.clientWidth;
				const spaceOnRight = bodyWidth - menuRect.right;
				const spaceOnLeft = menuRect.left;

				if (spaceOnRight < 200 && spaceOnLeft > 200) {
					setMenuPosition("left");
				} else {
					setMenuPosition("right");
				}
			}
		};

		checkMenuPosition();
		window.addEventListener("resize", checkMenuPosition);

		return () => {
			window.removeEventListener("resize", checkMenuPosition);
		};
	}, []);

	const menuStyle = {
		left: menuPosition === "left" ? "auto" : 0,
		right: menuPosition === "left" ? 0 : "auto"
	};

	return preferType.length || 0 ? (
		<div
			className={`mr-1 mt-0.5 p-0 text-center h-5 w-5 object-cover rounded-full text-white ${
				backgroundColor === "pink" ? "bg-pink-300" : backgroundColor === "green" ? "bg-green-300" : ""
			}`}
		>
			<Menu as="div" className="relative" ref={menuRef}>
				{({ open }) => (
					<div
						onMouseEnter={(e) => {
							e.stopPropagation();
							setIsShowing(true);
						}}
						onClick={(e) => {
							e.stopPropagation();
							setIsShowing(true);
						}}
						onMouseLeave={(e) => {
							e.stopPropagation();
							setIsShowing(false);
						}}
					>
						<Menu.Button className="flex justify-center w-full rounded-md text-sm font-medium text-gray-700">
							<span className="text-gray-700 p-0 m-0 text-sm font-medium block text-white">{preferType.length || 0}</span>
						</Menu.Button>
						<Transitions
							show={isShowing}
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items
								static
								className="absolute mt-1 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40  text-left"
								style={menuStyle}
							>
								{preferType.map((p) => {
									return (
										<Menu.Item key={p?.member?.id}>
											<div className="flex justify-between block px-2 py-2 text-sm text-gray-700 cursor-pointer">
												<div className="pl-2 text-left">
													<div className="text-sm font-medium">
														{p?.member?.person
															? personUtils.displayName(p?.member?.person) || p.member.orgUsername || "NO NAME"
															: p?.member?.orgUsername || p?.member?.email}
													</div>
												</div>
												{p.note && (
													<Tooltip trigger={["hover"]} overlay={p.note} placement="top" overlayStyle={{ opacity: 1 }}>
														<ChatIcon className="w-4 h-4" />
													</Tooltip>
												)}
											</div>
										</Menu.Item>
									);
								})}
							</Menu.Items>
						</Transitions>
					</div>
				)}
			</Menu>
		</div>
	) : (
		<span className="m-0 p-0 text-gray-700 text-sm font-medium block text-white"></span>
	);
};
