export const MartiniIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 1600 1600" {...props}>
			<path d="M1278.586 237.337H321.41l406.618 473.307a104.795 104.795 0 0 1 25.303 68.286l17.334 571.737h56l20-571.076c0-25.48 9.13-50.12 25.735-69.449l406.186-472.805" />
			<path d="M549.757 1398.654c75.627-9.4 159.984-14.653 248.974-14.653 89.72 0 174.732 5.346 250.833 14.88 12.59-4.067 14.217-18.04-.75-22.48l-242.012-71.718c-4.35-1.289-9.259-1.289-13.61 0l-242.01 71.718c-14.695 4.36-13.393 17.92-1.425 22.253" />
		</svg>
	);
};

export const FoodIcon = (props) => (
	<svg viewBox="0 0 5333.333 5333.333" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			// style={{
			// 	fill: "#000",
			// 	fillOpacity: 1,
			// 	fillRule: "nonzero",
			// 	stroke: "none"
			// }}
			d="M14000.719 16299.6c-560.269-1-1167.206-173.7-1561.048-333.2-2260.334-915.3-3484.307-4657.6-278.776-6896.21 496.562-346.9-102.473-6418.39-155.809-6883.75-17.778-154.36 285.69-281.6 667.927-281.6h1107.203c366.187 1.01 663.976 123.92 663.976 272.23V16119.6c0 99.2-198.773 180.4-443.473 180"
			transform="matrix(.293 0 0 -.293 0 5333.333)"
		/>
		<g>
			<path
				// style={{
				// 	fill: "#000",
				// 	fillOpacity: 1,
				// 	fillRule: "nonzero",
				// 	stroke: "none"
				// }}
				d="M8655.49 16300h-53.05c-101.5 0-184.12-92.8-184.12-206.9v-2150.5c0-128.8-79.57-233.6-177.43-233.6-97.86 0-177.44 104.8-177.44 233.6v2183.7c0 95.8-70.38 173.7-156.87 173.7h-15.1c-86.49 0-156.83-77.9-156.83-173.7v-2183.7c0-128.8-79.61-233.6-177.47-233.6-97.87 0-177.44 104.8-177.44 233.6v2183.7c0 95.8-70.38 173.7-156.87 173.7h-15.1c-86.49 0-156.83-77.9-156.83-173.7v-2183.7c0-128.8-79.61-233.6-177.47-233.6-97.82 0-177.44 104.8-177.44 233.6v2150.5c0 114.1-82.57 206.9-184.12 206.9h-53c-101.55 0-184.13-92.8-184.13-206.9v-3126.9c0-592.3 286.95-1173.6 787.49-1425.6 66.38-33.4 108.6-104 107.6-179.9L7047.9 2148.49c-1.87-137.71 109.09-250.18 246.86-250.18h524.84c137.76 0 248.72 112.47 246.9 250.18l-122.02 9212.21c-1 75.9 41.27 146.5 107.65 179.9 502.09 252.8 787.44 835.4 787.44 1425.6v3126.9c0 114.1-82.58 206.9-184.08 206.9"
				transform="matrix(.61232 0 0 -.29204 -2884.93 5362.739)"
			/>
		</g>
	</svg>
);
