import { useState } from "react";
import { InputField, Form, Button, Yup, useSimpleMessage, showFailure, showSuccess } from "ww-framework";
import { orgUtils } from "ww-stores";

const templateSchema = Yup.object().shape({
	name: Yup.string().required()
});

const RosterTemplateForm = ({ activeWeek, setOverLayOpen, organisation, person, setOverLayRosterTemplateOpen, shift }) => {
	const { setMessage } = useSimpleMessage();
	const initValues = {
		name: ""
	};
	const [templateSaving, setTemplateSaving] = useState(false);

	const shiftData = shift.map((s) => {
		const shiftDate = new Date(s.baseDay * 1000);
		const adjustedDay = (shiftDate.getUTCDay() - organisation.startDay + 7) % 7;
		const shiftDetails = {
			id: "",
			breakend: s?.breakEnd - s?.baseDay || "",
			breakstart: s?.breakStart - s?.baseDay || "",
			end: s?.shiftEnd - s?.baseDay || 0,
			setAsClose: s.setAsClose,
			hasBreak: s.hasBreak,
			person: s?.member?.id ?? null,
			role: s.role?.id,
			start: s?.shiftStart - s?.baseDay || 0,
			organisationID: organisation.id,
			day: adjustedDay + 1 // Adjusted day to account for the new start day of the week
		};
		return JSON.stringify(shiftDetails);
	});

	return (
		<>
			<h4 className="text-xs font-semibold text-white uppercase tracking-wide">Roster Template Name</h4>
			<Form
				validationSchema={templateSchema}
				enableReinitialize={true}
				disabled={templateSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						setTemplateSaving(true);
						let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;
						const template = {
							...data,
							...{
								organisationID: organisation.id,
								memberID: person?.organisations?.items[activeOrg].id,
								shifts: shiftData
							}
						};
						await orgUtils.saveTemplate(template);
						setTemplateSaving(false);
						setOverLayRosterTemplateOpen(false);
						resetForm();
						setMessage(
							showSuccess({
								title: "Template saved successfully."
							})
						);
						setOverLayOpen(false);
					} catch (error) {
						setMessage(
							showFailure({
								title: "Unable to save Template.",
								subTitle: error.message
							})
						);
					}
				}}>
				<div className="w-72 sm:w-full">
					<div className="mt-2 with-full ">
						<InputField type="text" name="name" placeholder="Template Name" />
					</div>
					<div className="mt-5 flex flex-row justify-between">
						<div className="flex justify-end gap-5">
							<Button type="submit" label="Save" disabled={templateSaving} />
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

const RosterTemplate = ({ activeWeek, setOverLayOpen, organisation, person, setOverLayRosterTemplateOpen, shift }) => {
	return (
		<div className="overflow-y-scroll h-full pl-3 bg-darkww-900 shadow-lg rounded-lg ml-4 p-3">
			{activeWeek[0] && (
				<div className="font-bold text-gray-700 text-white text-xl py-3">
					{activeWeek[0]?.day?.padStart(2, "0")} {activeWeek[0]?.monthShortDesc}
				</div>
			)}

			{(person.isAdmin || person?.assignedAdmin) && (
				<RosterTemplateForm
					activeWeek={activeWeek}
					organisation={organisation}
					person={person}
					setOverLayOpen={setOverLayOpen}
					setOverLayRosterTemplateOpen={setOverLayRosterTemplateOpen}
					shift={shift}
				/>
			)}
		</div>
	);
};

export default RosterTemplate;
