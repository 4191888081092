import { useContext } from "react";
import logoImage from "ww-images/workwall-logo.svg";
import { personUtils, PersonContext } from "ww-stores";
import { Form, InputField, Button, Yup, Link, useNavigate, useSimpleMessage, showFailure } from "ww-framework";

export default function SignIn() {
	const { setPerson } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const navigate = useNavigate();

	const userCredentials = {
		email: "",
		password: ""
	};
	const formSchema = Yup.object().shape({
		email: Yup.string().required().email(),
		password: Yup.string().required()
	});

	const authenticate = async (credentials) => {
		const { email, password } = credentials;
		try {
			const authenticatedPerson = await personUtils.signIn(email, password);
			const personsProfile = await personUtils.getProfile(authenticatedPerson);
			const personDetails = { ...authenticatedPerson, ...personsProfile };
			setPerson(personDetails);
			navigate("/dashboard");
		} catch (error) {
			setMessage(
				showFailure({
					title: "Sign In Failed",
					subTitle: error.message
				})
			);
		}
	};
	return (
		<div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-32 w-auto" src={logoImage} alt="Workwall" />
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to Workwall</h2>
				<p className="mt-2 text-center text-sm text-gray-600 max-w">
					Don't have an account?{" "}
					<Link className="text-lightww-600 font-bold hover:text-lightww-900" to="/employee-manager-signup">
						Sign Up
					</Link>
				</p>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<Form initialValues={userCredentials} validationSchema={formSchema} onSubmit={authenticate}>
						<InputField label="Email" name="email" placeholder="e.g. name@email.com" />
						<InputField label="Password" name="password" type="password" autoComplete="current-password" placeholder="Password" />
						<div className="flex items-center justify-end">
							<div className="text-sm">
								<Link className="text-lightww-600 font-bold  hover:text-lightww-900" to="/forgot">
									Forgot your password?
								</Link>
							</div>
						</div>
						<div className="pt-2">
							<Button type="submit" label="Sign In" />
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}
