import { Fragment, useState, useContext } from "react";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import Background from "./Background.jsx";
import { MyShifts } from "../Person/MyShifts";
import { AllShifts } from "../Person/AllShifts";
import { Button } from "ww-framework";
import StockOption from "./../Organisation/Stock";
import { XIcon } from "@heroicons/react/outline";
import { PersonContext } from "ww-stores";

const displayOptions = [
	{ title: "My Shifts Only", description: "Show a summary of the week relevant to you only.", current: true, value: "P" },
	{ title: "All Shifts", description: "Show a full listing of all of the weeks shifts", current: false, value: "C" }
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ModeSwitcher = ({ changeMode, currentMode }) => {
	return (
		<Listbox value={currentMode} onChange={changeMode}>
			{({ open }) => (
				<>
					<Listbox.Label className="sr-only">Change weekly display</Listbox.Label>
					<div className="relative">
						<div className="inline-flex shadow-sm rounded-md divide-x divide-darkww-600">
							<div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-darkww-600">
								<div className="relative inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
									<CheckIcon className="h-5 w-5" aria-hidden="true" />
									<p className="ml-2.5 text-sm font-medium">{currentMode.title}</p>
								</div>
								<Listbox.Button className="relative inline-flex items-center bg-darkww-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-darkww-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-darkww-500">
									<span className="sr-only">Change weekly display</span>
									<ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
								</Listbox.Button>
							</div>
						</div>

						<Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
								{displayOptions.map((option) => (
									<Listbox.Option
										key={option.title}
										className={({ active }) =>
											classNames(
												active ? "text-white bg-darkww-500" : "text-gray-900",
												"cursor-default select-none relative p-4 text-sm"
											)
										}
										value={option}
									>
										{({ currentMode, active }) => (
											<div className="flex flex-col">
												<div className="flex justify-between">
													<p className={currentMode ? "font-semibold" : "font-normal"}>{option.title}</p>
													{currentMode ? (
														<span className={active ? "text-white" : "text-lightww-100"}>
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
														</span>
													) : null}
												</div>
												<p className={classNames(active ? "text-lightww-100" : "text-gray-500", "mt-2")}>
													{option.description}
												</p>
											</div>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};

export default function Dashboard() {
	const [mode, setMode] = useState(displayOptions[0]);
	const [overLayOpen, setOverLayOpen] = useState(false);
	const { person } = useContext(PersonContext);

	const handelOpenOverlay = (e) => {
		if (e !== "") e.stopPropagation();
		setOverLayOpen(true);
	};
	return (
		<Background>
			<Transition.Root show={overLayOpen} as={Fragment}>
				<Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={setOverLayOpen}>
					<div className="absolute inset-0 overflow-hidden">
						<Transition.Child
							as={Fragment}
							enter="ease-in-out duration-500"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in-out duration-500"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<div style={{ width: 500 }} className="relative px-5">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-1 right-8 -ml-8 pt-2 pr- flex sm:-ml-10 sm:pr-4">
											<button
												type="button"
												className="rounded-md text-gray-300 hover:text-white focus:outline-none"
												onClick={() => setOverLayOpen(false)}
											>
												<span className="sr-only">Close panel</span>
												<XIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>

									<div className="col-span-12 sm:col-span-3 h-full">
										<StockOption
											stock={{}}
											setOverLayOpen={setOverLayOpen}
											organisation={person.currentOrganisation}
											person={person}
										/>
									</div>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<div className="h-fit pb-5 p-2">
				<div className="flex-1 h-full py-3 pr-2 flex justify-between items-end">
					<Button
						label="Low Stock"
						onClick={(e) => handelOpenOverlay(e)}
						className="rounded-md shadow-sm text-sm font-medium text-white bg-darkww-500 hover:bg-darkww-600 shadow-sm px-auto ml-2 bottom-0 left-0 py-2 px-6 rounded-md shadow-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 m-auto"
					/>
					<ModeSwitcher changeMode={setMode} currentMode={mode} />
				</div>
				{mode.value === "P" && <MyShifts />}
				{mode.value === "C" && <AllShifts />}
			</div>
		</Background>
	);
}
