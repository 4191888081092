import { useState, useEffect } from "react";
import { Form, Button, Yup, useSimpleMessage, showFailure, showSuccess, InputField, ConfirmModal, ComboBox } from "ww-framework";
import { orgUtils, personUtils } from "ww-stores";
import { XIcon } from "@heroicons/react/outline";
function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}
const stockSchema = Yup.object().shape({
	item: Yup.string().required()
});
const StockForm = ({ person, organisation, getStocks }) => {
	const { setMessage } = useSimpleMessage();
	const initValues = {
		item: "",
		stockLavel: "LOW"
	};
	const [stockSaving, setStockSaving] = useState(false);
	const [stockLavelSet] = useState([
		{ id: "LOW", name: "Low", description: "Low", colour: "#fbbf24" },
		{ id: "VERY_LOW", name: "Very Low", description: "Very Low", colour: "#f97316" },
		{ id: "OUT", name: "Out", description: "Out", colour: "#b91c1c" }
	]);
	const stockList = ({ selected, active, option: stockLavel }) => {
		return (
			<div>
				<div className="flex items-center ">
					<div className="h-8 w-6 rounded-md" style={{ backgroundColor: stockLavel.colour }}></div>
					<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{stockLavel.name}</span>
				</div>

				{selected ? (
					<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center px-4")} />
				) : null}
			</div>
		);
	};
	return (
		<>
			<div className="flex mt-4">
				<h4 className="text-xs font-semibold text-white uppercase tracking-wide">Add Stock Item</h4>
			</div>

			<Form
				validationSchema={stockSchema}
				enableReinitialize={true}
				disabled={stockSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;
						setStockSaving(true);
						const stock = {
							...data,
							...{
								organisationID: organisation.id,
								fromMemberID: person?.organisations?.items[activeOrg].id
							}
						};
						await orgUtils.saveStock(stock);
						// memberIds.length > 0 &&
						// 	(await notifyUser(
						// 		memberIds,
						// 		`Additional information has been added to your shift on ${formateEpoch(currentDay.baseEpoch)}`
						// 	));
						await getStocks();
						setStockSaving(false);
						resetForm();
						setMessage(
							showSuccess({
								title: "Stock saved successfully."
							})
						);
						// setOverLayOpen(false);
					} catch (error) {
						console.log(error);
						setMessage(
							showFailure({
								title: "Unable to save Stock!.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-72 sm:w-full">
					<div className="mt-2 with-full ">
						<InputField name="item" placeholder="Item Name & Size" />
					</div>
					<div className="with-full mb-4">
						<ComboBox
							label="Level"
							name="stockLavel"
							labelTextColour="text-white"
							custom={true}
							options={stockLavelSet}
							ComboBoxOption={stockList}
							initValue={initValues.stockLavel}
						/>
					</div>

					<div className="mt-1 flex flex-row justify-end">
						<div className="flex justify-end gap-5 mb-2">
							<Button type="submit" label="Save" disabled={stockSaving} />
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

const StockOption = ({ setOverLayOpen, organisation, person, setOverNoteParticularDayLayOpen }) => {
	const [open, setOpen] = useState(false);
	const [stock, setStock] = useState([]);
	const [stockDelete, setstockDelete] = useState();
	const { setMessage } = useSimpleMessage();

	useEffect(() => {
		getStocks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	const getStocks = async () => {
		const data = await orgUtils.getStock(organisation.id);
		setStock(data.items);
	};

	const confirmPopup = (e, toDeleteStock) => {
		e.preventDefault();
		setstockDelete(toDeleteStock);
		setOpen(true);
	};

	const handlerModal = async (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			deleteStock(e);
		}
		setOpen(false);
	};

	const deleteStock = async (e) => {
		if (stockDelete?.id) {
			await orgUtils.deleteStock(stockDelete?.id);
			await getStocks();
			setMessage(
				showSuccess({
					title: "Stock Deleted successfully."
				})
			);
		}
	};
	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title={`Delete ${stockDelete ? stockDelete.item + " Stock?" : "this Stock?"}`}
				subTitle="Are you sure?"
			/>
			<div className="overflow-y-scroll h-full pl-3 bg-darkww-900 shadow-lg rounded-lg ml-4 p-3">
				<StockForm
					organisation={organisation}
					person={person}
					setOverLayOpen={setOverLayOpen}
					setOverNoteParticularDayLayOpen={setOverNoteParticularDayLayOpen}
					getStocks={getStocks}
				/>

				{stock.length > 0 ? (
					stock.map((s) => (
						<div className="mt-1 sm:mt-5 p-3 bg-white" key={s.id}>
							<div
								className="flex justify-between"
								style={{
									borderBottom: `8px solid ${
										s.stockLavel === "LOW"
											? "#fbbf24"
											: s.stockLavel === "VERY_LOW"
											? "#f97316"
											: s.stockLavel === "OUT" && "#b91c1c"
									}`
								}}
							>
								<span>{s.item}</span>
								{personUtils.displayName(s.fromMember?.person)}
								{person.isAdmin && <XIcon className="h-6 w-6 top-2" onClick={(e) => confirmPopup(e, s)} aria-hidden="true" />}
							</div>
						</div>
					))
				) : (
					<div className="mt-1 sm:mt-5 p-3 bg-white">Add those Items which are running Low</div>
				)}
			</div>
		</>
	);
};

export default StockOption;
