import React, { useState } from "react";
import logoImage from "ww-images/workwall-logo.svg";
import { Form, InputField, Button, useNavigate, useSimpleMessage, showFailure, Yup } from "ww-framework";
import { personUtils } from "ww-stores";

export default function ForgotPassword() {
	const [disabled, setDisabled] = useState(false);
	const [codeSent, setCodeSent] = useState(false);
	const [isCodeResending, setIsCodeResending] = useState(false);
	const navigate = useNavigate();
	const { setMessage } = useSimpleMessage();

	const userCredentials = {
		email: "",
		code: "",
		password: "",
		new_password: ""
	};
	const formSchema = Yup.object().shape({
		email: Yup.string().required().email()
	});

	const verifyFormSchima = Yup.object().shape({
		code: Yup.string().required(),
		password: Yup.string().required(),
		new_password: Yup.string()
			.required("Confirm password should not be empty.")
			.oneOf([Yup.ref("password"), null], "Confirm Passwords must match with Password.")
	});

	const sendResetCodeAgain = () => {
		if (isCodeResending) return;
		const email = document.getElementById("email").value;

		if (!email) {
			setMessage(
				showFailure({
					title: "Email cannot be empty."
				})
			);
		}

		setIsCodeResending(true);
		getResetCode({ email });
	};

	const requestResetCode = (credentials) => {
		setDisabled(true);
		getResetCode(credentials);
	};

	const getResetCode = async (credentials) => {
		const { email } = credentials;

		try {
			await personUtils.forgotPassword(email);
			setDisabled(false);
			setCodeSent(true);
			setIsCodeResending(false);
		} catch (error) {
			setDisabled(false);
			setMessage(
				showFailure({
					title: "Unable to send code.",
					subTitle: error.message
				})
			);
		}
	};

	const resetPassword = async (credentials) => {
		const { email, new_password, code } = credentials;
		setDisabled(true);

		try {
			await personUtils.forgotPasswordSubmit(email, code, new_password);
			setDisabled(true);
			navigate("/employee-manager-signin");
		} catch (error) {
			setDisabled(false);
			setMessage(
				showFailure({
					title: "Unable to reset password.",
					subTitle: error.message
				})
			);
		}
	};

	return (
		<div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-32 w-auto" src={logoImage} alt="Workwall" />
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot your password?</h2>
				<p className="mt-2 text-center text-sm text-gray-600 max-w p-2">Enter your email address to receive a verification code.</p>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
					<Form
						initialValues={userCredentials}
						validationSchema={codeSent ? verifyFormSchima : formSchema}
						onSubmit={codeSent ? resetPassword : requestResetCode}
					>
						<div>
							<InputField label="Email" name="email" id="email" placeholder="e.g. name@email.com" />
							{codeSent && (
								<div className="flex justify-end">
									<Button
										type="button"
										onClick={sendResetCodeAgain}
										disabled={isCodeResending}
										label={isCodeResending ? "Sending...." : "Didn't receive a code? Send again."}
										className="flex mt-1 text-start text-sm max-w text-darkww hover:text-darkww-600 cursor-pointer"
										loaderClass="animate-spin mt-1 -ml-1 mr-3 h-3 w-3 text-blue"
									/>
								</div>
							)}
						</div>

						{codeSent && (
							<>
								<InputField label="Verification code" name="code" placeholder="Enter Reset Code" />
								<InputField type="password" label="Password" name="password" placeholder="Password" />
								<InputField type="password" label="Confirm password" name="new_password" placeholder="Confirm password" />
							</>
						)}
						{codeSent ? (
							<Button type="submit" label="Reset Password" disabled={disabled} />
						) : (
							<Button type="submit" label={disabled ? "Sending..." : "Send Reset Code"} disabled={disabled} />
						)}
					</Form>
				</div>
			</div>
		</div>
	);
}
