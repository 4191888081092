import { useEffect, useState } from "react";
import { Storage } from "ww-framework";

let initials = "WW";

export const Avatar = ({ user, size, twClasses }) => {
	const [userInitials, setUserInitials] = useState(initials);
	const [userAvatar, setUserAvatar] = useState("");
	const { avatar } = user || {};

	useEffect(() => {
		if (user) {
			const emailInitial = `${user.email?.charAt(0)}`;
			const usernameInitial = `${user.username?.charAt(0)}`;
			const nameInitials = `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`;

			if (user.firstName || user.lastName) {
				initials = nameInitials;
			} else if (user.username) {
				initials = usernameInitial;
			} else if (user.orgUsername) {
				const secondName = user.orgUsername.split(" ")[1];
				initials = `${user.orgUsername?.charAt(0)}${secondName ? secondName?.charAt(0) : ""}`;
			} else if (user.email) {
				initials = emailInitial;
			}
		}
		setUserInitials(initials);
	}, [user]);

	useEffect(() => {
		setUserAvatar(avatar?.signedImageURL);
	}, [avatar?.signedImageURL]);

	if (!avatar)
		return (
			<span className={`inline-block ${size} rounded-full overflow-hidden bg-gray-100`}>
				<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
					<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
				</svg>
			</span>
		);

	const PersonAvatar = userAvatar ? (
		<img className={`${size} object-cover rounded-full ${twClasses || ""}`} src={userAvatar} alt="" />
	) : (
		<span className={`inline-flex items-center justify-center ${size} rounded-full bg-gray-500 ${twClasses || ""}`}>
			<span className="font-medium leading-none text-white">{userInitials.toUpperCase()}</span>
		</span>
	);
	return PersonAvatar;
};

export const AvatarPicker = ({ person, onAvatarUpload }) => {
	const { avatarURI, avatar } = person;
	const [userAvatar, setUserAvatar] = useState();

	async function onAvatarSelect(e) {
		const file = e.target.files[0];

		if (!file) return;

		try {
			const { key } = await Storage.put(`${person.sub}-avatar-${file.name}`, file);

			// If the user had an existing avatar then delete it.
			if (avatarURI) {
				await Storage.remove(avatarURI);
			}

			onAvatarUpload(key);
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		setUserAvatar(avatar?.signedImageURL);
	}, [avatar]);

	const SmallAvatarPicker = () => {
		return (
			<>
				<p className="text-sm font-medium text-gray-700" aria-hidden="true">
					Photo
				</p>
				<div className="mt-1 lg:hidden">
					<div className="flex items-center">
						<div className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12" aria-hidden="true">
							{!userAvatar ? (
								<span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
									<span className="text-lg font-medium leading-none text-white">{initials.toUpperCase()}</span>
								</span>
							) : (
								<img className="object-cover rounded-full h-full w-full" src={userAvatar} alt="" />
							)}
						</div>
						<div className="ml-5 rounded-md shadow-sm">
							<div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-light-blue-500">
								<label htmlFor="avatar" className="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none">
									<span>Change</span>
									<span className="sr-only">user photo</span>
								</label>
								<input
									id="avatar"
									name="avatar"
									type="file"
									accept="image/png, image/gif, image/jpeg"
									className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
									onChange={onAvatarSelect}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};
	const LargeAvatarPicker = () => {
		return (
			<div className="hidden relative rounded-full w-40 h-40 overflow-hidden lg:block">
				{!userAvatar ? (
					<span className="inline-flex items-center justify-center h-40 w-40 rounded-full bg-gray-500">
						<span className="text-3xl font-medium leading-none text-white">{initials.toUpperCase()}</span>
					</span>
				) : (
					<img className="object-cover relative rounded-full w-40 h-40" src={userAvatar} alt="" />
				)}
				<label
					htmlFor="avatar"
					className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
				>
					<span>Change</span>
					<span className="sr-only">user photo</span>
					<input
						type="file"
						id="avatar"
						name="avatar"
						accept="image/png, image/gif, image/jpeg"
						className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
						onChange={onAvatarSelect}
					/>
				</label>
			</div>
		);
	};
	return (
		<div className="flex-grow lg:mt-4 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
			<SmallAvatarPicker />
			<LargeAvatarPicker />
		</div>
	);
};
