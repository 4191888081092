import { ChatIcon, ThumbUpIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Tooltip, Transition as Transitions } from "ww-framework";
import { personUtils } from "ww-stores";

export const TimeOffCountBubble = ({ memberTimeOff, approveTimeOff }) => {
	const [menuPosition, setMenuPosition] = useState("right");
	const menuRef = useRef(null);

	useEffect(() => {
		const checkMenuPosition = () => {
			if (menuRef.current) {
				const menuRect = menuRef.current.getBoundingClientRect();
				const bodyWidth = document.body.clientWidth;
				const spaceOnRight = bodyWidth - menuRect.right;
				const spaceOnLeft = menuRect.left;

				if (spaceOnRight < 200 && spaceOnLeft > 200) {
					setMenuPosition("left");
				} else {
					setMenuPosition("right");
				}
			}
		};

		checkMenuPosition();
		window.addEventListener("resize", checkMenuPosition);

		return () => {
			window.removeEventListener("resize", checkMenuPosition);
		};
	}, []);
	const menuStyle = {
		left: menuPosition === "left" ? "auto" : 0,
		right: menuPosition === "left" ? 0 : "auto"
	};

	return (
		<>
			{memberTimeOff?.length > 0 && (
				<>
					<Menu as="div" className="relative mr-1" ref={menuRef}>
						{({ open }) => (
							<div
								onClick={(e) => {
									e.stopPropagation();
									open = !!open;
								}}
							>
								<Menu.Button className="inline-flex justify-center w-full  border border-gray-300 rounded-md text-sm font-medium text-gray-700">
									<span className="ml-1 mt-0.5 text-gray-700 text-sm font-medium block text-white">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-4 w-4 flex items-center"
											style={{ color: "red" }}
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="3"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
											/>
										</svg>
									</span>
									<span className="px-1 text-red-600">{memberTimeOff?.length}</span>
									{/* <ChevronDownIcon className="flex-shrink-0 h-5 w-5 text-gray-400 sm:block" aria-hidden="true" /> */}
								</Menu.Button>
								<Transitions
									show={open}
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items
										static
										className="divide-y divide-gray-200 px-2 absolute mt-1 w-40 rounded-md shadow-lg py-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30  text-left"
										style={menuStyle}
									>
										{memberTimeOff.map((mt, i) => {
											return (
												<div key={i} className="sm:block">
													<Menu.Item>
														<div className="flex justify-between items-center block py-0.5 text-sm text-gray-700 cursor-pointer">
															{mt?.status === "PENDING" ? (
																<div
																	className="flex block px-2 text-sm text-gray-700 cursor-pointer"
																	onClick={(e) => approveTimeOff(e, mt.id, "APPROVE", mt?.member?.person?.person)}
																>
																	<ThumbUpIcon className="w-5 mr-1" />
																	{"· "}
																	{mt?.member?.person
																		? personUtils.displayName(mt?.member?.person) ||
																		  mt.member.orgUsername ||
																		  "NO NAME"
																		: mt?.member?.orgUsername || mt?.member?.email}
																</div>
															) : (
																<div className="flex block px-2 text-sm text-gray-700 cursor-pointer">
																	{mt?.member?.person
																		? personUtils.displayName(mt?.member?.person, mt?.member) ||
																		  mt?.member?.orgUsername ||
																		  "NO NAME"
																		: mt?.member?.orgUsername || mt?.member?.email || "NO NAME"}
																	{mt.isPaid && " (€)"}
																</div>
															)}
															{mt.note && (
																<Tooltip
																	trigger={["hover"]}
																	overlay={mt.note}
																	placement="top"
																	overlayStyle={{ opacity: 1 }}
																>
																	<ChatIcon className="w-4 h-4" />
																</Tooltip>
															)}
														</div>
													</Menu.Item>
												</div>
											);
										})}
									</Menu.Items>
								</Transitions>
							</div>
						)}
					</Menu>
				</>
			)}
		</>
	);
};
