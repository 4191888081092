import LogoImage from "../images/logo-light.svg";

export const TermsOfService = () => (
	<>
		<div className="min-h-full pt-2 pb-12 flex flex-col bg-white">
			<main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex-shrink-0 flex justify-center">
					<a href="/" className="inline-flex">
						<span className="sr-only">Workwall</span>
						<img className="h-12 w-auto" src={LogoImage} alt="" />
					</a>
				</div>
				<div className="py-8 px-8">
					<div>
						<h2 className="text-2xl mt-4 mb-2 font-bold">User terms of service</h2>
						<ol class="list-decimal list-outside ml-4">
							<li>
								These User Terms of Service (User Terms) govern your access and use of WorkWall's products and services (WorkWall
								Services) as an Authorized User of the WorkWall Services and comprise a legally binding contract between you and
								WorkWall. WorkWall refers to the applicable WorkWall entity in the Contract (defined below).
							</li>
							<li>
								You agree to comply with the most recent version of WorkWall's Acceptable Use Policy, which is incorporated by
								reference into these User Terms. If you access or use the WorkWall Services, or continue accessing or using the
								WorkWall Services after being notified of a change to the User Terms or the Acceptable Use Policy, you confirm that
								you have read, understand and agree to be bound by the User Terms and the Acceptable Use Policy.{" "}
							</li>
							<li>
								Customer has separately agreed to our Subscription Agreement or entered into a written agreement with WorkWall
								(Contract) that permitted Customer to create an account or instance within WorkWall (WorkWall Instance) that you and
								others could join (each invitee granted access to the WorkWall Instance is an Authorized User). The Contract contains
								our commitment to deliver the WorkWall Services to the Customer, who may then invite Authorized Users to join its
								WorkWall Instance. When an Authorized User submits content or information to the WorkWall Services, you acknowledge
								and agree that the Customer Data is owned by Customer and the Contract provides Customer with many choices and control
								over that Customer Data. For example, Customer may provision or deprovision access to the WorkWall Services, enable or
								disable third party integrations, manage permissions, retention and export settings, and these choices and
								instructions may result in the access, use, disclosure, modification or deletion of certain or all Customer Data.{" "}
							</li>
							<li>
								AS BETWEEN WORKWALL AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER’S RESPONSIBILITY TO (A) INFORM YOU OF ANY
								RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY SETTINGS THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN
								ANY RIGHTS, PERMISSIONS OR CONSENTS FROM YOU AND ANY AUTHORIZED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF
								CUSTOMER DATA AND THE OPERATION OF THE WORKWALL SERVICES; (C) ENSURE THAT THE TRANSFER AND PROCESSING OF CUSTOMER DATA
								UNDER THE CONTRACT IS LAWFUL; AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU AND ANY AUTHORIZED USER RELATING TO
								OR BASED ON CUSTOMER DATA, THE WORKWALL SERVICES OR CUSTOMER’S FAILURE TO FULFILL THESE OBLIGATIONS. WORKWALL MAKES NO
								REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE WORKWALL SERVICES, WHICH
								ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.{" "}
							</li>
							<li>
								All Authorized Users must comply with our Acceptable Use Policy and any applicable policies established by Customer.
								If you see inappropriate behaviour or content, please report it to your employer.
							</li>
							<li>
								These User Terms remain effective until Customer’s subscription for you expires or terminates, or your access to the
								WorkWall Services has been terminated by Customer, you or WorkWall.{" "}
							</li>
							<li>
								IN NO EVENT WILL YOU OR WORKWALL HAVE ANY LIABILITY TO THE OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT,
								SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY
								OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNLESS
								YOU ARE ALSO A CUSTOMER (AND WITHOUT LIMITATION TO OUR RIGHTS AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO
								FINANCIAL LIABILITY TO US FOR A BREACH OF THESE USER TERMS. OUR MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY BREACH OF
								THE USER TERMS IS ONE HUNDRED DOLLARS ($100) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL NOT APPLY TO THE EXTENT
								PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY’S RIGHT TO SEEK AND OBTAIN EQUITABLE RELIEF.{" "}
							</li>
							<li>
								WorkWall Services are intended for use by businesses and organizations and not for consumer purposes. To the maximum
								extent permitted by law, you hereby acknowledge and agree that consumer laws do not apply. If however any consumer
								laws do apply and cannot otherwise be lawfully excluded, nothing in these User Terms will restrict, exclude or modify
								any statutory warranties, guarantees, rights or remedies you have, and our liability is limited (at our option) to the
								replacement, repair or resupply of the WorkWall Services or the pro-rata refund to Customer of pre-paid fees for your
								subscription covering the remainder of the term.{" "}
							</li>
							<li>
								Except as otherwise set out in these User Terms, all notices under the User Terms will either be by email or through
								the WorkWall Services. Notices to WorkWall must be sent to support@theworkwall.com. A notice will be deemed to have
								been duly given (a) the day after it is sent, in the case of a notice sent through email; and (b) the same day, in the
								case of a notice sent through the WorkWall Services. Notices under the Contract will be delivered solely to Customer
								in accordance with the terms of that agreement. If you have any questions about WorkWall's User Terms, you may contact
								us at support@theworkwall.com
							</li>
							<li>
								Please review WorkWall's Privacy Policy for more information on how WorkWall collects and uses data relating to the
								use and performance of the WorkWall Services.{" "}
							</li>
							<li>
								If WorkWall makes a material change to the User Terms or the Acceptable Use Policy, we will provide you with
								reasonable notice prior to the change taking effect either by emailing the email address associated with your account
								or by messaging you through the WorkWall Services. You can review the most current version of the User Terms at any
								time by visiting this page, and by visiting the following for the most current versions of the other pages that are
								referenced in these User Terms, Acceptable Use Policy and Privacy Policy. Any material revisions to these User Terms
								will become effective on the date set forth in our notice, and all other changes will become effective on the date we
								publish the change. If you use the WorkWall Services after the effective date of any changes, that use will constitute
								your acceptance of the revised terms and conditions.{" "}
							</li>
							<li>
								No failure or delay by either party in exercising any right under the User Terms, including the Acceptable Use Policy,
								will constitute a waiver of that right. No waiver under the User Terms will be effective unless made in writing and
								signed by an authorized representative of the party being deemed to have granted the waiver.{" "}
							</li>
							The User Terms, including the Acceptable Use Policy, will be enforced to the fullest extent permitted under applicable
							law. If any provision of the User Terms is held by a court of competent jurisdiction to be contrary to law, the provision
							will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the
							fullest extent permitted by law, and the remaining provisions of the User Terms will remain in effect.
							<li>
								You may not assign any of your rights or delegate your obligations under these User Terms, including the Acceptable
								Use Policy, whether by operation of law or otherwise, without the prior written consent of us (not to be unreasonably
								withheld). We may assign these User Terms in their entirety (including all terms and conditions incorporated herein by
								reference), without your consent, to a corporate affiliate or in connection with a merger, acquisition, corporate
								reorganization, or sale of all or substantially all of our assets.{" "}
							</li>
							<li>
								The User Terms, including the Acceptable Use Policy, and any disputes arising out of or related hereto, will be
								governed exclusively by the same applicable governing law of the Contract. The courts located in the applicable venue
								of the Contract will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to the User
								Terms, including the Acceptable Use Policy, or its formation, interpretation or enforcement. Each party hereby
								consents and submits to the exclusive jurisdiction of such courts. In any action or proceeding to enforce rights under
								the User Terms, the prevailing party will be entitled to recover its reasonable costs and attorney’s fees.{" "}
							</li>
							<li>
								The User Terms, including any terms incorporated by reference into the User Terms, constitute the entire agreement
								between you and us and supersede all prior and contemporaneous agreements, proposals or representations, written or
								oral, concerning its subject matter. To the extent of any conflict or inconsistency between the provisions in these
								User Terms and any pages referenced in these User Terms, the terms of these User Terms will first prevail; provided,
								however, that if there is a conflict or inconsistency between the Contract and the User Terms, the terms of the
								Contract will first prevail, followed by the provisions in these User Terms, and then followed by the pages referenced
								in these User Terms (e.g., the Privacy Policy). Customer will be responsible for notifying Authorized Users of those
								conflicts or inconsistencies and until such time the terms set forth herein will be binding.
							</li>
						</ol>
						<h3 className="text-lg mt-4 mb-2 font-bold">
							{" "}
							Important Links:{" "}
							<a href="/privacy" className="underline decoration-4 text-sky-800">
								Privacy
							</a>
						</h3>
					</div>
				</div>
			</main>
		</div>
	</>
);
