import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
	Button,
	ComboBox,
	dateMeta,
	epochToTimeStr,
	Form,
	formateEpoch,
	InputField,
	notifyUser,
	showFailure,
	showSuccess,
	useSimpleMessage,
	Yup
} from "ww-framework";
import { orgUtils, personUtils } from "ww-stores";

const SwapShiftForm = ({ shift, suitableMembersOptions, handlerModal, suitableShiftOptions, person, buildCalendar }) => {
	const { setMessage } = useSimpleMessage();
	const initValues = {
	  name: "",
	  person: null,
	  toShift: null,
	  member: "ALL",
	  note: null
	};
	const [templateSaving, setTemplateSaving] = useState(false);
	const [suitableShifts, setSuitableShifts] = useState(suitableShiftOptions);
	const [member, setMember] = useState("");
	const [isNotForAll, setIsNotForAll] = useState(false);
	const [personID, setPersonID] = useState([]);
	
	const updateShiftList = async (c) => {
	  setMember(personUtils.displayName(person));
  
	  if (c.id !== "ALL") {
		setIsNotForAll(false);
		const currentMember = person?.currentOrganisation?.members?.find((m) => c?.id === m?.orgUserId);
		setSuitableShifts(suitableShiftOptions.filter((k) => k?.member?.id === c.id));
		setPersonID([currentMember?.person]);
	  } else {
		setIsNotForAll(true);
		setSuitableShifts(suitableShiftOptions);
		setPersonID(person.currentOrganisation.members.filter((l) => l.id !== person.id).map((k) => k.person));
	  }
	};
  
	const templateSchema = Yup.object().shape({
	  member: Yup.string().nullable(),
	  toShiftId: isNotForAll === false ? Yup.string().required() : Yup.string().nullable(true)
	});
  
	return (
	  <>
		<h4 className="text-xs pl-2 font-semibold text-dark tracking-wide">Select Staff to Swap with:</h4>
		<Form
		  validationSchema={templateSchema}
		  enableReinitialize={true}
		  disabled={templateSaving}
		  initialValues={initValues}
		  onSubmit={async (data, { resetForm }) => {
			try {
      setTemplateSaving(true);

      if (data.member !== "ALL" && data.member !== "") {
		const selectedShift = suitableShifts.find((s) => s.id === data.toShiftId);
        const shiftData = {
          ...{
            organisationID: person.currentOrganisation.id,
            fromMemberID: shift.memberID,
            fromShiftId: shift.id,
            toShiftId: selectedShift?.id || " ",
            memberID: selectedShift?.member.id,
            expirationTime: shift?.shiftStart + 172800,
            note: data?.note || null
          }
        };
        await orgUtils.saveShiftSwapRequest(shiftData);
        setTemplateSaving(false);
      } else {
        const selectedShift = suitableShifts.find((s) => s.id === data.toShiftId);
        const memberID = selectedShift?.member?.id || shift.memberID || null;
        const shiftData = {

          ...{
            organisationID: person.currentOrganisation.id,
            fromMemberID: shift.memberID,
            fromShiftId: shift.id,
            roleID: shift?.roleID,
            memberID,
            expirationTime: shift?.shiftStart + 172800,
            note: data?.note || null
          }
        };
        await orgUtils.saveAllRequest(shiftData);
        setTemplateSaving(false);
      }

      resetForm();
      setMessage(
        showSuccess({
          title: "Swap Shift Requested."
        })
      );
      handlerModal("", false);
      await buildCalendar();
      await notifyUser(personID, `${member || "Someone"} asked to swap one of your shifts`);
    } catch (error) {
      setMessage(
        showFailure({
          title: "Unable to Swap Shift.",
          subTitle: error.message
        })
      );
      console.error("Swap Shift Error:", error);
    }
        }}
		>
		  <div className="w-full">
			<div className="mt-2 px-2 with-full ">
			  <ComboBox
				label="Filter by Employee"
				name="member"
				labelTextColour="text-dark"
				options={suitableMembersOptions}
				initValue={initValues.person}
				getSelectedValueOnChange={(value) => updateShiftList(value)}
				placeholder="Select Staff Member or Leave Blank"
			  />
			</div>
			{isNotForAll === false && (
			  <div className="mt-2 px-2 with-full ">
				<ComboBox
				  label="All Suitable Shifts"
				  name="toShiftId"
				  labelTextColour="text-dark"
				  options={suitableShifts}
				  initValue={initValues.toShift}
				/>
			  </div>
			)}
			<div className="mt-2 px-2 with-full ">
			  <InputField label="Add a Note?" name="note" labelTextColour="text-dark" />
			</div>
			<div className="mt-5 pl-3 flex flex-row justify-between">
			  <div className="flex justify-end gap-5">
				<Button type="submit" label="Save" disabled={templateSaving} />
			  </div>
			</div>
		  </div>
		</Form>
	  </>
	);
  };
  
const SwapOption = ({ open, handlerModal, activeWeek, organisation, shift, person, currentWeek, currentDay, buildCalendar }) => {
	if (!open) return null;
	const currentDayShifts = currentWeek.allShifts.filter((k) => k.baseDay === currentDay.baseEpoch);
  
	// const suitableMembersByRole = person?.currentOrganisation?.members?.filter(
	//   (member) =>
	// 	member?.roleIDs?.includes(shift?.roleID) &&
	// 	member?.id !== person.id &&
	// 	!currentDayShifts.find((m) => m.memberID === member.orgUserId)
	// );
	// let availableShifts = currentWeek.allShifts.filter((k) => {
  
	// const otherShifts = currentWeek.allShifts.filter((k) => {
	//   return k.baseDay !== currentDay.baseEpoch && k.memberID !== person.id && !currentWeek?.shifts.map((m) => m.baseDay).includes(k.baseDay);
	  
	// });

	// const currentDayShifts = currentWeek.allShifts.filter((k) => k.baseDay === currentDay.baseEpoch);
  
	// const suitableMembersByRole = person?.currentOrganisation?.members?.filter(
	//   (member) =>
	// 	member?.roleIDs?.includes(shift?.roleID) &&
	// 	member?.id !== person.id &&
	// 	!currentDayShifts.find((m) => m.memberID === member.orgUserId)
	// );
	
	const todayEpoch = dateMeta(new Date()).baseEpoch;
	// shift available for swap are shifts that haven't started yet and are not the member's own shift
	let availableShifts = currentWeek.allShifts.filter((k) => {
	  return k.baseDay >= todayEpoch && k.member.personID !== person.id;
	});
	// keep only shifts with the same role as the current shift
	availableShifts = availableShifts.filter((k) => k.roleID === shift.roleID);
	// available members who have a shift among the available shifts
	let availableMembers = person?.currentOrganisation?.members?.filter((m) => {
	  return availableShifts.some((k) => k.memberID === m.orgUserId);
	});
	const otherShifts = availableShifts;
	const suitableMembersByRole = availableMembers;
	
  
  
	const suitableMembersOptions = suitableMembersByRole?.map((m) => ({
	  id: m.orgUserId,
	  description: personUtils.displayName(m),
	  name: personUtils.displayName(m)
	}));
	suitableMembersOptions?.unshift({ id: "ALL", description: "Send to All", name: "All Members" });
	if (!suitableMembersOptions) {
		// Render a loading state or return null when suitableMembersOptions is undefined
		return null;
	}
	const suitableShiftOptions = otherShifts
    .filter((m) => {
      // Check if the shift's member ID is present in suitableMembersOptions
      return suitableMembersOptions.some((s) => s.id === m.memberID);
    })
    .map((sh) => {
      const displayName = sh.memberID === "ALL" ? "All Members" : sh?.member?.person && personUtils.displayName(sh?.member?.person) ? personUtils.displayName(sh?.member?.person) : suitableMembersOptions.find((s) => s.id === sh.memberID)?.name;
      const member = sh.memberID === "ALL" ? null : sh?.member;
      return {
        id: sh.id,
        description: `${formateEpoch(sh.baseDay)} | ${epochToTimeStr(sh.shiftStart)} - ${epochToTimeStr(sh.shiftEnd)} (${displayName})`,
        name: `${formateEpoch(sh.baseDay)} | ${epochToTimeStr(sh.shiftStart)} - ${epochToTimeStr(sh.shiftEnd)} (${displayName})`,
        member: member
      };
    });
  
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg mx-4 w-full sm:w-1/2 lg:w-1/3">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3">
								<div className="justify-center gap-2">
									<SwapShiftForm
										activeWeek={activeWeek}
										organisation={organisation}
										shift={shift}
										person={person}
										currentWeek={currentWeek}
										suitableMembersOptions={suitableMembersOptions}
										suitableShiftOptions={suitableShiftOptions}
										handlerModal={handlerModal}
										buildCalendar={buildCalendar}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default SwapOption;
