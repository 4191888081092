import React, { Fragment, useContext, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { CalendarDataContext, OrganisationContext, PersonContext } from "ww-stores";
import { OnDragOutPlaceHolder } from "./DayCards";
import { SmallShiftCardWithoutMemberName } from "./SmallShiftCardWithoutMemberName";

const MultipleShiftCard = ({
	member,
	currentDay,
	dayShifts,
	memberIndex,
	showChooseModelPopup,
	pickNewDay,
	getDragStyle,
	preferences,
	timeOffData,
	punches
}) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const { zoom } = useContext(CalendarDataContext);
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div className="relative mt-1" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<div className={`${zoom ? "" : "0.5"}`} key={`${currentDay.date}-graycard`}>
				<div className={`${zoom ? "h-9" : "h-18"} flex items-center`}>
					<div className="flex flex-row items-center flex-1 bg-white py-0 shadow-md shadow-pink-300 rounded-md">
						<div className={`${zoom ? "h-6" : "h-12"} px-1 mx-2  rounded-md flex items-center justify-center bg-lightww-100`}></div>
						<div className={`${zoom ? "h-8" : "h-16"} flex-1 items-center`}>
							<div className="pl-1 text-left h-full flex flex-col justify-center">
								<div className={`${zoom ? "text-xs" : "text-sm"}`}>{`${dayShifts.length} Shifts`}</div>
								<div className="text-xs text-darkww-800"> (hover to expand)</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isHovered && (
				<div className="absolute top-0 left-0 w-full flex flex-col z-50 bg-pink-200 rounded-md shadow-lg shadow-pink-300">
					{dayShifts.map((dayShift, shiftIndex) => {
						let isDisabled = dayShift === undefined ? true : person.isAdmin || person.assignedAdmin ? false : member.orgUserId !== dayShift?.memberID;

						return (
							<Fragment key={`${member.orgUserId}--${dayShift?.id ?? shiftIndex}--${currentDay.baseEpoch}`}>
								<Draggable
									draggableId={`${member?.orgUserId}--|--${dayShift?.id ?? "grayed--|--" + currentDay.baseEpoch}`}
									index={memberIndex}
									isCombineEnabled={true}
									isScrollable={false}
									isDragDisabled={isDisabled}
								>
									{(provided, snapshot) => (
										<>
											{snapshot.isDragging ? <OnDragOutPlaceHolder zoom={zoom} /> : null}
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												onClick={(e) =>
													dayShift
														? showChooseModelPopup(currentDay, dayShift, e, person.isAdmin || person.assignedAdmin ? "shift" : "note")
														: pickNewDay(currentDay, "", e)
												}
												style={getDragStyle(snapshot.isDragging, provided.draggableProps.style, zoom)}
												key={`${memberIndex}--${shiftIndex}`}
											>
												<SmallShiftCardWithoutMemberName
													shift={dayShift}
													currentDay={currentDay}
													pickNewDay={pickNewDay}
													orgMembers={organisation.members || []}
													preferences={preferences}
													person={person}
													timeOffData={timeOffData}
													zoom={zoom}
													punches={punches}
												/>
												{provided.placeholder}
											</div>
										</>
									)}
								</Draggable>
							</Fragment>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default MultipleShiftCard;
