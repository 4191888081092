import { API } from "aws-amplify";
import { deleteLeaveOption as AWSDeleteLeaveOption, deleteLeaveRecord as AWSDeleteLeaveRecord, createLeaveOption, createLeaveRecord, updateLeaveOption, updateLeaveRecord } from "../../graphql/mutations.js";

export const saveLeaveOption = async (leaveOption) => {
	const leaveOptionMapped = {
		id: leaveOption.id,
		name: leaveOption.name,
		maximumLength: leaveOption.maximumLength,
		isAnnual: leaveOption.isAnnual,
		unit: leaveOption.unit,
		organisationID: leaveOption.organisationID
	};

	const mutation = leaveOption.id ? updateLeaveOption : createLeaveOption;
	const { data } = await API.graphql({ query: mutation, variables: { input: leaveOptionMapped } });
	const savedLeaveOption = data[leaveOption.id ? "updateLeaveOption" : "createLeaveOption"];

	const updatedLeaveOption = { ...leaveOption, ...savedLeaveOption };
	return updatedLeaveOption;
};

export const deleteLeaveOption = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({ query: AWSDeleteLeaveOption, variables: { input: { id: id } } });
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};

export const saveLeaveRecord = async (leaveRecord) => {
    const leaveRecordMapped = {
        id: leaveRecord.id,
        leaveOptionID: leaveRecord.leaveOptionID,
        memberID: leaveRecord.memberID,
        organisationID: leaveRecord.organisationID,
        leaveLength: leaveRecord.leaveLength,
        unit: leaveRecord.unit,
        dateTaken: leaveRecord.dateTaken,
        note: leaveRecord.note
    };

    const mutation = leaveRecord.id ? updateLeaveRecord : createLeaveRecord;
    const { data } = await API.graphql({ query: mutation, variables: { input: leaveRecordMapped } });
    const savedLeaveRecord = data[leaveRecord.id ? "updateLeaveRecord" : "createLeaveRecord"];

    const updatedLeaveRecord = { ...leaveRecord, ...savedLeaveRecord };
    return updatedLeaveRecord;
};

export const deleteLeaveRecord = async (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            await API.graphql({ query: AWSDeleteLeaveRecord, variables: { input: { id: id } } });
            resolve();
        } catch (error) {
            reject(error);
        }
    });
};