import { Auth } from "ww-framework";

export const checkAlreadyAuthenticated = async () => {
	let authDetails = {};
	try {
		const authenticatedUser = await Auth.currentAuthenticatedUser();
		const { attributes } = authenticatedUser;
		const { email, sub } = attributes;

		authDetails = {
			isAuthenticated: true,
			email,
			sub
		};
	} catch (error) {
		return {}; // If the user isn't authenticated Cognito returns an error, we don't want that so just return an empty object.
	}
	return authDetails;
};

export const signIn = (userEmail, password) => {
	return new Promise(async (resolve, reject) => {
		let authDetails = {};
		try {
			const cognitoUser = await Auth.signIn(userEmail, password);
			const { attributes } = cognitoUser;
			const { email, sub } = attributes;

			authDetails = {
				isAuthenticated: true,
				email,
				sub
			};

			/*
				cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED"
				Only happens if someone else creates an account on behalf of someone.
				Would only happen from the AWS cognito admin console at the moment as we've removed the org owner invitation functionality.
			*/
			resolve(authDetails);
		} catch (error) {
			reject(error);
		}
	});
};
export const signOut = async () => {
	try {
		await Auth.signOut();
	} catch (error) {
		console.log(error);
	}

	return {};
};
export const signUp = (email, password) => {
	return new Promise(async (resolve, reject) => {
		try {
			const data = await Auth.signUp({ username: email, password, attributes: { email } });
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const confirmSignUp = (email, authcode) => {
	return new Promise(async (resolve, reject) => {
		try {
			await Auth.confirmSignUp(email, authcode);
			resolve({});
		} catch (error) {
			reject(error);
		}
	});
};
export const forgotPassword = (email) => {
	return new Promise(async (resolve, reject) => {
		try {
			await Auth.forgotPassword(email);
			resolve({});
		} catch (error) {
			reject(error);
		}
	});
};
export const forgotPasswordSubmit = (email, code, new_password) => {
	return new Promise(async (resolve, reject) => {
		try {
			await Auth.forgotPasswordSubmit(email, code, new_password);
			resolve({});
		} catch (error) {
			reject(error);
		}
	});
};
export const getToken = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			const authenticatedUser = await Auth.currentSession();
			resolve(authenticatedUser);
		} catch (error) {
			reject(error);
		}
	});
};
