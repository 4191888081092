import React, { useState } from "react";
import { Tab } from "ww-framework";

const StaffDetailsTabs = ({ tabs }) => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	return (
		<div className=" mt-5 px-2">
			<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
				<Tab.List>
					{tabs.map((tab, index) => (
						<Tab key={tab.name}>
							{({ selected }) => (
								<div className={`flex items-center px-4  h-3 ${index !== tabs.length - 1 ? "border-r border-gray-400" : ""}`}>
									<div className={`font-medium ${selected ? "text-blue-500 border-b-2 border-blue-500" : "text-black"}`}>
										{tab.name}
									</div>
								</div>
							)}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="pt-2 mt-1">
					{tabs.map((tab, index) => (
						<Tab.Panel key={index}>{tab.view}</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
};

export default StaffDetailsTabs;
