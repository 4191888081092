import React, { useContext, useState } from "react";
import { Form, Button, TextArea, Yup, useSimpleMessage, showFailure, showSuccess } from "ww-framework";
import { PersonContext, saveEnqury } from "ww-stores";

export default function Profile() {
	const { person } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const [savingEnquiry, setSavingEnquiry] = useState(false);

	const enquirySchema = Yup.object().shape({
		note: Yup.string().required()
	});

	return (
		<>
			<div className="lg:max-w-6xl lg:mx-auto lg:px-8">
				<Form
					initialValues={{ note: "" }}
					validationSchema={enquirySchema}
					enableReinitialize={true}
					onSubmit={async (data) => {
						try {
							setSavingEnquiry(true);
							const ticket = {
								note: data.note,
								email: `${person.email} of Organisation ${person?.currentOrganisation?.name}`
							};
							await saveEnqury(ticket);
							setSavingEnquiry(false);
							setMessage(
								showSuccess({
									title: "Request sent to support."
								})
							);
						} catch (error) {
							setSavingEnquiry(false);
							setMessage(
								showFailure({
									title: "Could not send to support.",
									subTitle: error.message
								})
							);
						}
					}}
				>
					<div className="py-6 px-4 sm:p-6 lg:pb-8">
						<div>
							<h2 className="text-lg leading-6 font-medium text-gray-900">Raise support Ticket</h2>
						</div>

						<div className="mt-6 grid grid-cols-12 gap-6">
							<div className="col-span-12 sm:col-span-9">
								<div>
									<TextArea label="Comment" name="note" rows={3} />
								</div>
							</div>
						</div>
					</div>

					<div className="mt-1 py-2 px-4 flex justify-start">
						<Button type="submit" label="Submit" disabled={savingEnquiry} />
					</div>
				</Form>
			</div>
		</>
	);
}
