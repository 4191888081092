import React, { useState } from "react";
import { Tab } from "ww-framework";

const NotificationTabs = ({ tabs }) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	return (
		<div className=" mt-5 px-0 sm:px-2">
			<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
				<Tab.List>
					{tabs.map((tab, index) => (
						<Tab key={tab.name}>
							{({ selected }) => (
								<div className={`flex items-center px-4  h-3 ${index !== tabs.length - 1 ? "border-r border-gray-400" : ""}`}>
									<div className="relative inline-block">
										<div className={`font-medium ${selected ? "text-blue-500 border-b-2 border-blue-500" : "text-black"}`}>
											{tab.name}
										</div>
										<span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
											{tab.count}
										</span>
									</div>
								</div>
							)}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="pt-2 mt-1">
					{tabs.map((tab, index) => (
						<Tab.Panel key={index}>{tab.view}</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
};
export default NotificationTabs;
