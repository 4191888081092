import { AcademicCapIcon, IdentificationIcon, InformationCircleIcon, OfficeBuildingIcon, UserGroupIcon, UserIcon } from "@heroicons/react/outline";
import React, { useContext } from "react";
import { Link, Route, Routes, useLocation } from "ww-framework";
import { OrganisationContext } from "ww-stores";
import ClockInUser from "./Settings/ClockInUser";
import Departments from "./Settings/Departments";
import General from "./Settings/General";
import Roles from "./Settings/Roles";
import StaffMembers from "./Settings/Staff/StaffMembers";
import Subscription from "./Settings/Subscription";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Navigation = () => {
	const { organisation } = useContext(OrganisationContext);
	const location = useLocation();

	const navOptions = [
		{ name: "General", href: "/administration", icon: OfficeBuildingIcon },
		{ name: "Staff Members", href: "/administration/staff", icon: UserGroupIcon },
		{ name: "ClockIn Member", href: "/administration/clock-staff", icon: UserIcon },
		{ name: "Roles", href: "/administration/roles", icon: AcademicCapIcon },
		{ name: "Departments", href: "/administration/departments", icon: IdentificationIcon },
		{ name: "Subscription", href: "/administration/subscription", icon: InformationCircleIcon }
	];
	return (
		<aside className="py-6 lg:col-span-2">
			<nav className="space-y-1">
				{navOptions.map((item) => {
					const TabItem = () => {
						return (
							<>
								<item.icon
									className={classNames(
										location.pathname === item.href
											? "text-darkww-500 group-hover:text-darkww-500"
											: "text-gray-400 group-hover:text-gray-500",
										"flex-shrink-0 -ml-1 mr-3 h-6 w-6"
									)}
									aria-hidden="true"
								/>
								<span className="truncate">{item.name}</span>
							</>
						);
					};

					if (!organisation.id && item.name !== "General")
						return (
							<div
								key={item.name}
								className="border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
							>
								<TabItem />
							</div>
						);
					return (
						<Link
							key={item.name}
							className={classNames(
								location.pathname === item.href
									? "border-darkww-500 bg-sky-200 text-darkww-700 hover:bg-darkww-200 hover:text-white"
									: "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
								"group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
							)}
							to={`${item.href}`}
						>
							<TabItem />
						</Link>
					);
				})}
			</nav>
		</aside>
	);
};

export default function Settings() {
	return (
		<div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
			<Navigation />
			<Routes>
				<Route index element={<General />} />
				<Route path="staff" element={<StaffMembers />} />
				<Route path="clock-staff" element={<ClockInUser />} />
				<Route path="roles" element={<Roles />} />
				<Route path="departments" element={<Departments />} />
				<Route path="departments/*" element={<Departments />} />
				<Route path="/*" element={<General />} />
				<Route path="staff/*" element={<StaffMembers />} />
				<Route path="roles/*" element={<Roles />} />
				<Route path="subscription/" element={<Subscription />} />
			</Routes>
		</div>
	);
}
