import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import HeaderMenu, { PrimaryLink } from "../headers/light";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/phone_desk2.png";
import { Form, InputField, Button, Yup, useSimpleMessage, showFailure, showSuccess, TextArea } from "../../../../ww-framework";
import { saveEnqury } from "../../../../ww-stores/Enqury/index";
const Container = tw.div`relative`;
const TwoColumn = tw.div`sm:flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-10 sm:py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-4xl md:text-5xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:mb-8 lg:mt-5 text-base xl:text-lg`;

const Actions = styled.div`
	${tw`relative max-w-md text-center mx-auto mt-10`}
	input {
		${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-lightww hover:border-gray-500`}
	}
	button {
		${tw`w-full sm:absolute -right-2 -top-2 bottom-3 bg-lightww text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-darkww-700 transition duration-300`}
	}
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
	${tw`pointer-events-none  absolute -left-40 -bottom-40 h-64 w-64 text-darkww opacity-50 transform -translate-x-2/3`}
`;
const enquirySchema = Yup.object().shape({
	email: Yup.string().required().email(),
	note: Yup.string().nullable()
});
const Hero = ({ roundedHeaderButton, scrollTo }) => {
	const [savingEnquiry, setSavingEnquiry] = useState(false);
	const { setMessage } = useSimpleMessage();

	return (
		<>
			<HeaderMenu roundedHeaderButton={roundedHeaderButton} scrollTo={scrollTo} />
			<Container>
				<TwoColumn>
					<LeftColumn>
					<Heading className="flex flex-col justify-start items-start">
						<div className="flex sm:ml-12 md:ml-0">
							<span className="text-darkww-600">Make</span>
							<span className="text-lightww ml-3">Every</span>
						</div>
						<div className="flex pl-28 sm:pl-48"> {/* Adjust this value to line up "Shift" with "Every" */}
							<span className="text-lightww">Shift</span>
							<span className="text-darkww-600 ml-3">Better!</span>
						</div>
						</Heading>

						<Paragraph>
							WorkWall will improve your Rostering, Shift Management, Communication, HR, Document Storage & Time Clock systems to reduce
							your staff turnover, sustainability and save your manager's time and enhance their management of a healthier, happier,
							more productive, profitable & efficient team in any hospitality or retail venue.
						</Paragraph>
						<PrimaryLink
  css={
    roundedHeaderButton &&
    tw`w-full text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:h-14 mx-auto sm:ml-32 sm:leading-none text-lg focus:outline-none hover:bg-pink-700 transition duration-300 bg-pink-600 whitespace-nowrap`
  }
  style={{ width: '18rem' }} // Adjust the value as needed
  href="/employee-manager-signup"
>
  Click Here to Sign Up
</PrimaryLink>


						<Actions>
							<Form
								validationSchema={enquirySchema}
								enableReinitialize={true}
								disabled={savingEnquiry}
								initialValues={{ note: "", email: "" }}
								onSubmit={async (data, { resetForm }) => {
									try {
										setSavingEnquiry(true);
										await saveEnqury(data);
										resetForm();
										setMessage(
											showSuccess({
												title: "Thank you for your interest in WorkWall, we will be in touch shortly to help you further."
											})
										);
										setSavingEnquiry(false);
									} catch (error) {
										setSavingEnquiry(false);
										setMessage(
											showFailure({
												title: "We seem to be having difficulty with that at the moment.",
												subTitle: error.message
											})
										);
									}
								}}
							>
								<div className="flex justify-start font-bold text-pink-600 -mb-3">Or Contact Us For More Info</div>
								<TextArea name="note" placeholder="Message" className="w-full relative rounded-md shadow-sm " />
								<div className="relative">
									<InputField name="email" placeholder="Your E-mail Address" />

									<Button className="h-[64px]" type="submit" label="More Info" disabled={savingEnquiry} />
								</div>
							</Form>
						</Actions>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<DecoratorBlob1 />
			</Container>
		</>
	);
};

export default Hero;
