import { API, graphqlOperation } from "aws-amplify";
import { createFile, createFolder, deleteFile, deleteFolder } from "../../graphql/mutations.js";
import { getFileByOrg, getFolderByOrg } from "../../graphql/queries.js";
/**
 * types: all, public, protected
 * protected files and folders in an organisation start with underscores
 */

export const getOrganisationFiles = async (organisationID, type = "public") => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID: organisationID,
			limit: 20
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getFileByOrg, queryParams));
			const { getFileByOrg: legacyStocks } = data;
			const filteredLegacyStocks = {
				items: legacyStocks.items.filter((stock) => {
					if (type === "all") return true;
					if (type === "protected") return stock.name.startsWith("_");
					return !stock.name.startsWith("_");
				})
			};
			resolve(filteredLegacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveFile = async (fileDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			let fileMapped = {
				//employeeFile: fileDetail.employeeFile,
				folderId: fileDetail.folderId,
				name: fileDetail.name,
				organisationID: fileDetail.organisationID,
				type: fileDetail.type,
				size: fileDetail.size,
				fileURI: fileDetail.fileURI
			};
			const {
				data: { createFile: data }
			} = await API.graphql({
				query: createFile,
				variables: { input: fileMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveFolder = async (folderDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			let folderMapped = {
				name: folderDetail.name,
				organisationID: folderDetail.organisationID
			};
			const {
				data: { createFolder: data }
			} = await API.graphql({
				query: createFolder,
				variables: { input: folderMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrganisationFolders = async (organisationID, type = "public") => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getFolderByOrg, {
					organisationID: organisationID,
					limit: 20
				})
			);
			const { getFolderByOrg: legacyStocks } = data;
			const filteredLegacyStocks = {
				items: legacyStocks.items.filter((stock) => {
					if (type === "all") return true;
					if (type === "protected") return stock.name.startsWith("_");
					return !stock.name.startsWith("_");
				})
			};
			resolve(filteredLegacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};
export const deleteFileById = async (fileId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const {
				data: { deleteFile: data }
			} = await API.graphql({
				query: deleteFile,
				variables: { input: { id: fileId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const deleteFolderById = async (fileId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const {
				data: { deleteFolder: data }
			} = await API.graphql({
				query: deleteFolder,
				variables: { input: { id: fileId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getFolderByFilter = async (organisationID, filter, type = "public") => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getFolderByOrg, {
					organisationID: organisationID,
					filter: filter,
					limit: 20
				})
			);
			const { getFolderByOrg: legacyStocks } = data;
			const filteredLegacyStocks = {
				items: legacyStocks.items.filter((stock) => {
					if (type === "all") return true;
					if (type === "protected") return stock.name.startsWith("_");
					return !stock.name.startsWith("_");
				})
			};
			resolve(filteredLegacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};
