import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings";
// import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons";
import TeamIllustrationSrc from "../../images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "../../images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
	tw`md:w-6/12 mt-16 md:mt-0`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-8 md:order-first` : tw`md:ml-12 lg:ml-8 md:order-last`
]);

const Image = styled.img((props) => [props.imageRounded && tw`rounded`, props.imageBorder && tw`border`, props.imageShadow && tw`shadow`]);

const DecoratorBlob = tw(
	SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform opacity-50 translate-x-1/2 translate-y-1/2 fill-current text-lightww`; //minus z-10

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 lg:w-2/3 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
	${tw`mx-auto inline-block border border-lightww text-lightww text-center rounded p-2 flex-shrink-0`}
	${(props) => [props.iconRoundedFull && tw`rounded-full`, props.iconFilled && tw`border-0 bg-lightww text-gray-100`]}
  svg {
		${tw`w-5 h-5`}
	}
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

// const PrimaryButton = styled(PrimaryButtonBase)((props) => [tw`mt-12 text-sm inline-block mx-auto md:mx-0`, props.buttonRounded && tw`rounded-full`]);
const MainFeature2 = ({
	subheading = "Our Expertise",
	heading = (
		<>
			Designed & Developed by <span tw="text-lightww">Professionals.</span>
		</>
	),
	description = "Working in as industry where margins are so tight, it is important to save money when & where you can. We focus on improving those margins, while improving your employees lives.",
	primaryButtonText = "Learn More",
	primaryButtonUrl = "https://timerse.com",
	imageSrc = TeamIllustrationSrc,
	buttonRounded = true,
	imageRounded = true,
	imageBorder = false,
	imageShadow = false,
	showDecoratorBlob = false,
	textOnLeft = true,
	features = null,
	iconRoundedFull = true,
	iconFilled = true,
	iconContainerCss = null,
	id = "aboutUs"
}) => {
	// The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

	/*
	 * Change the features variable as you like, add or delete objects
	 * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
	 */
	const defaultFeatures = [
		{
			Icon: BriefcaseIcon,
			title: "Professionalism",
			description: "We have the best professional marketing people across the globe just to work with you.",
			iconContainerCss: tw`bg-teal-300 text-teal-800`
		},
		{
			Icon: MoneyIcon,
			title: "Affordable",
			description: "We promise to offer you the best rate we can - at par with the industry standard.",
			iconContainerCss: tw`bg-red-300 text-red-800`
		}
	];

	if (!features) features = defaultFeatures;

	return (
		<Container id={id}>
			<TwoColumn>
				<ImageColumn>
					<Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
					{showDecoratorBlob && <DecoratorBlob />}
				</ImageColumn>
				<TextColumn textOnLeft={textOnLeft}>
					<TextContent>
						<Subheading>{subheading}</Subheading>
						<Heading>{heading}</Heading>
						<Description>{description}</Description>
						<Features>
							{features.map((feature, index) => (
								<Feature key={index}>
									<FeatureHeadingContainer>
										<FeatureIconContainer
											iconFilled={iconFilled}
											iconRoundedFull={iconRoundedFull}
											css={feature.iconContainerCss || iconContainerCss}
										>
											{<feature.Icon />}
										</FeatureIconContainer>
										<FeatureHeading>{feature.title}</FeatureHeading>
									</FeatureHeadingContainer>
									<FeatureDescription>{feature.description}</FeatureDescription>
								</Feature>
							))}
						</Features>

						{/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
							{primaryButtonText}
						</PrimaryButton> */}
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};

export default MainFeature2;
