import { API } from "aws-amplify";
import { getInvitationsByEmail } from "../../graphql/queries.js";
import { updateInvitation as AWSUpdateInvitation } from "../../graphql/mutations.js";

export const getInvitations = async (email) => {
	return new Promise(async (resolve, reject) => {
		try {
			let invitations = [];
			const { data } = await API.graphql({
				query: getInvitationsByEmail,
				variables: {
					email: email,
					filter: {
						status: {
							eq: "Pending" // Not Working
						}
					}
				}
			});
			const { getInvitationsByEmail: dbInvitations } = data;

			if (dbInvitations && dbInvitations.items.length > 0) invitations = dbInvitations.items.filter((invite) => invite.status === "Pending"); // Hack: Because the filtering won't work!!!
			resolve(invitations);
		} catch (error) {
			reject(error);
		}
	});
};
export const getAllInvitations = async (email) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: getInvitationsByEmail,
				variables: {
					email: email
				}
			});
			const { getInvitationsByEmail: dbInvitations } = data;
			resolve(dbInvitations.items);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateInvitation = async (id, status) => {
	return new Promise(async (resolve, reject) => {
		try {
			let invitation = {
				id: id,
				status: status
			};

			await API.graphql({ query: AWSUpdateInvitation, variables: { input: invitation } });

			resolve();
		} catch (error) {
			reject(error);
		}
	});
};
