import React, { useRef, useCallback, useEffect, useState } from "react";
import Webcam from "react-webcam";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const CameraModal = ({ show, onClose, onCapture }) => {
	const webcamRef = useRef(null);
	const [isCameraReady, setIsCameraReady] = useState(false);
	const [errorMessage, setErrorMessage] = useState(""); // State to manage error message

	const capture = useCallback(() => {
		if (isCameraReady) {
			const imageSrc = webcamRef.current.getScreenshot();
			if (imageSrc) {
				onCapture(imageSrc);
				setErrorMessage(""); // Reset any error message
				onClose(); // Optionally close the modal after a successful capture
			} else {
				setErrorMessage("Failed to capture photo. Please try again."); // Set error message if capture fails
			}
		}
	}, [webcamRef, onCapture, isCameraReady, onClose]);

	useEffect(() => {
		if (show) {
			setIsCameraReady(false); // Reset readiness state
			setErrorMessage(""); // Clear error message on show
		}
	}, [show]);

	return (
		<Transition show={show} as={React.Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
				<div className="min-h-screen px-4 text-center">
					<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>

					<Transition.Child
						as={React.Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95">
						<div className="inline-block w-full max-w-3xl p-6 my-8 font-semibold overflow-hidden text-center text-xl align-middle transition-all transform bg-white shadow-xl rounded-2xl">
							<div className="relative">
								<Dialog.Title className="font-medium text-2xl leading-6 text-gray-700">
									Please include <span className="text-red-600 font-bold">FACE AND SHOULDERS</span> in photo
								</Dialog.Title>

								<button onClick={onClose} className="absolute top-0 right-0 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
									<XIcon className="h-8 w-8 -mt-2" aria-hidden="true" />
								</button>
							</div>

							<div className="mt-2">
								<Webcam
									audio={false}
									disablePictureInPicture={true}
									height={720}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									width={1280}
									videoConstraints={{ width: 1280, height: 720, facingMode: "user" }}
									onUserMedia={() => setIsCameraReady(true)} // Set camera readiness
								/>
							</div>

							{errorMessage && <div className="mt-2 text-red-600">{errorMessage}</div>}

							<div className="mt-4">
								<button
									type="button"
									className={`inline-flex justify-center w-full h-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-lightww-600 font-semibold text-white hover:bg-lightww-700 text-2xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-400 ${
										isCameraReady ? "" : "opacity-50 cursor-not-allowed"
									}`}
									onClick={capture}
									disabled={!isCameraReady} // Disable button if camera is not ready
								>
									{isCameraReady ? "Capture Photo" : "Initializing Camera..."}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default CameraModal;
