import { TrashIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	CheckBox,
	ConfirmModal,
	DatePickerField,
	Form,
	TextArea,
	Yup,
	epochToDateStr,
	showFailure,
	showSuccess,
	useSimpleMessage
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils } from "ww-stores";

// Validation schema for disciplinary record
const disciplinarySchema = Yup.object().shape({
	incidentDescription: Yup.string().nullable().required("Incident Description is required"),
	actionTaken: Yup.string().nullable(),
	policyViolated: Yup.string().nullable(),
	witnesses: Yup.array().of(Yup.string()).nullable(),
	employeeResponse: Yup.string().nullable(),
	consequences: Yup.string().nullable(),
	actionPlan: Yup.string().nullable(),
	appealDetails: Yup.string().nullable(),
	incidentDate: Yup.date().nullable().required("Incident Date is required"),
	actionTakenDate: Yup.date().nullable(),
	managerSigned: Yup.boolean().nullable(),
	employeeSigned: Yup.boolean().nullable()
});


const StaffNotes = ({ currentMemberOrgUserId, updateOrgAndMembers }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);
	const { members } = organisation;
	const [disciplinaryRecords, setDisciplinaryRecords] = useState([]);
	const [notes, setNotes] = useState("");
	const [isAddingDisciplinary, setIsAddingDisciplinary] = useState(false); // Toggle for disciplinary form
	const { setMessage } = useSimpleMessage();
	const [open, setOpen] = useState(false); // Controls modal visibility
	const [recordToDelete, setRecordToDelete] = useState(null);

	// Find the current member details
	const currentMemberDetail = members.find((orgMember) => orgMember.orgUserId === currentMemberOrgUserId);

	useEffect(() => {
		async function fetchUserDetails() {
			try {
				if (currentMemberDetail) {
					const disciplinaryRecords = currentMemberDetail.disciplinaryRecords || [];
					setDisciplinaryRecords(disciplinaryRecords);
					setNotes(currentMemberDetail.employeeNotes ?? "");
				} else {
					console.error("Current organisation details not found for the user.");
				}
			} catch (error) {
				console.error("Error fetching user details:", error);
			}
		}

		fetchUserDetails();
	}, [loggedInPerson, currentMemberDetail]);

	// Function to handle adding employee notes
	const addEmployeeNotes = async (data) => {
		try {
			await orgUtils.updateOrgMember({ employeeNotes: data.employeeNotes }, currentMemberDetail.orgUserId);

			setMessage(showSuccess({ title: "Notes Added Successfully." }));
			setNotes(data.employeeNotes);
		} catch (err) {
			console.error("Error adding employee notes:", err);
			setMessage(showFailure({ title: "Failed to add notes", subTitle: err.message }));
		}
	};

	// Function to handle adding disciplinary records
	const addDisciplinaryRecord = async (data) => {
		try {
			// Prepare the required disciplinary data object
			const disciplinaryData = {
				organisationID: organisation.id,
				memberID: currentMemberDetail.orgUserId,
				dateOfIncident: data.incidentDate ? new Date(data.incidentDate).getTime() / 1000 : null, // Convert to epoch
				incidentDescription: data.incidentDescription
			};

			// Conditionally add optional fields only if they are provided
			if (data.actionTaken) disciplinaryData.actionTaken = data.actionTaken;
			if (data.policyViolated) disciplinaryData.policyViolated = data.policyViolated;
			if (data.witnesses && data.witnesses.length > 0) disciplinaryData.witnesses = data.witnesses;
			if (data.employeeResponse) disciplinaryData.employeeResponse = data.employeeResponse;
			if (data.consequences) disciplinaryData.consequences = data.consequences;
			if (data.actionPlan) disciplinaryData.actionPlan = data.actionPlan;
			if (data.appealDetails) disciplinaryData.appealDetails = data.appealDetails;
			if (data.actionTakenDate) disciplinaryData.actionTakenDate = new Date(data.actionTakenDate).getTime() / 1000;
			if (data.managerSigned !== undefined) disciplinaryData.managerSigned = data.managerSigned;
			if (data.employeeSigned !== undefined) disciplinaryData.employeeSigned = data.employeeSigned;

			// Send data to the backend
			const response = await orgUtils.createDisciplinaryRecord(disciplinaryData);

			// Update the UI with new disciplinary record
			updateOrgAndMembers();
			setDisciplinaryRecords((prevRecords) => [...prevRecords, response]);
			setMessage(showSuccess({ title: "Disciplinary Record Added Successfully." }));
			setIsAddingDisciplinary(false); // Close the form after submission
		} catch (err) {
			console.error("Error adding disciplinary record:", err);
			setMessage(showFailure({ title: "Failed to add disciplinary record", subTitle: err.message }));
		}
	};
	const handlerModal = async (e, type) => {
		e.preventDefault(); // Prevent default form behavior
		if (type === "confirm" && recordToDelete) {
			try {
				await orgUtils.deleteDisciplinaryRecord(recordToDelete); // Delete the record by ID

				// Update disciplinaryRecords state to remove the deleted record
				setDisciplinaryRecords((prevRecords) => prevRecords.filter((record) => record.id !== recordToDelete));

				setMessage(showSuccess({ title: "Disciplinary Record Deleted Successfully." }));
			} catch (err) {
				console.error("Error deleting disciplinary record:", err);
				setMessage(showFailure({ title: "Failed to delete disciplinary record", subTitle: err.message }));
			}
		} else {
			console.log("Closing modal without action"); // Close log for non-confirm actions
		}
		// Close modal and reset state for both actions
		setOpen(false);
		setRecordToDelete(null);
	};

const handleDelete = (id) => {
	setRecordToDelete(id);
	setOpen(true); // Open the modal
};
	return (
		<div className="py-1">
			{/* Employee Notes Section */}
			<div className="mt-2 pt-1 pb-1">
				<Form
					validationSchema={Yup.object().shape({
						employeeNotes: Yup.string().nullable()
					})}
					initialValues={{ employeeNotes: notes }}
					enableReinitialize={true}
					onSubmit={async (data) => {
						addEmployeeNotes(data);
					}}
				>
					<div className="h-full flex pt-1 items-end col-span-9 sm:col-span-9 lg:col-span-3">
						<div className="w-full">
							<TextArea label="Employee Notes" labelTextColour="text-lg text-darkww-800 font-semibold mb-2" name="employeeNotes" />
						</div>
					</div>
					<div className="inline-block flex justify-end">
						<Button type="submit" label="Save Notes" />
					</div>
				</Form>
			</div>

			<hr className="my-2" />

			{/* Disciplinary Records Section */}
			<div>
				<h3 className="text-lg text-darkww-800 font-semibold mb-2">Disciplinary Records</h3>
				{disciplinaryRecords.length > 0 ? (
					disciplinaryRecords.map((record, index) => (
						<div key={index} className="p-2 mb-2 bg-gray-100 text-darkww-800 rounded-md flex justify-between items-start">
							<div className="flex-1">
								{record.incidentDescription && (
									<p>
										<strong>Incident:</strong> {record.incidentDescription}
									</p>
								)}
								{record.actionTaken && (
									<p>
										<strong>Action Taken:</strong> {record.actionTaken}
									</p>
								)}
								{record.policyViolated && (
									<p>
										<strong>Policy Violated:</strong> {record.policyViolated}
									</p>
								)}
								{record.dateOfIncident && (
									<p>
										<strong>Incident Date:</strong> {epochToDateStr(record.dateOfIncident)}
									</p>
								)}
								{record.actionTakenDate && (
									<p>
										<strong>Action Taken Date:</strong> {epochToDateStr(record.actionTakenDate)}
									</p>
								)}
							</div>
							<button className="bg-red-600 rounded text-white text-sm hover:bg-red-500 p-1 self-end" onClick={() => handleDelete(record.id)}>
								<TrashIcon className="w-5 h-5 text-white" />
							</button>
						</div>
					))
				) : (
					<p>No disciplinary records available.</p>
				)}
				<ConfirmModal
					handlerModal={handlerModal} // Updated to use handlerModal with event and type
					open={open}
					className="text-base font-medium text-gray-900"
					title="Delete this disciplinary record?"
					subTitle="Are you sure you want to delete this record? This action cannot be undone."
				/>
			</div>

			<hr className="my-2" />

			{/* Add New Disciplinary Record */}
			<div>
				{isAddingDisciplinary ? (
					<Form
						validationSchema={disciplinarySchema}
						initialValues={{
							incidentDescription: "",
							actionTaken: "",
							policyViolated: "",
							witnesses: [],
							employeeResponse: "",
							consequences: "",
							actionPlan: "",
							appealDetails: "",
							incidentDate: null, // Field for incident date
							actionTakenDate: null, // Field for action taken date
							managerSigned: false,
							employeeSigned: false
						}}
						onSubmit={async (data) => {
							addDisciplinaryRecord(data); // Submit with both dates included
						}}
					>
						<div className="flex flex-col space-y-4 text-darkww-900">
							{/* Incident Description with Date */}
							{/* Incident Description with Date Picker */}
							{/* Incident Description with Date Picker */}
							<div className="relative w-full">
								{/* Date picker and label at the top-right with proper alignment */}
								<div className="absolute right-1 -top-2 w-1/2 sm:w-1/4 flex items-end">
									<label className="text-sm text-darkww-900 mr-2">Date</label> {/* Explicit label */}
									<DatePickerField name="incidentDate" className="text-xs h-6 w-24 py-0 px-2" />
								</div>
								<TextArea label="Incident Description" labelTextColour="text-darkww-700" name="incidentDescription" />
							</div>

							{/* Action Taken with Date Picker */}
							<div className="relative w-full">
								{/* Date picker and label at the top-right with proper alignment */}
								{/* <div className="absolute right-1 -top-2 w-1/2 sm:w-1/8 flex items-end">
									<label className="text-sm text-darkww-700 mr-2">Date</label> {/* Explicit label */}
								{/* <DatePickerField name="actionTakenDate" className="text-xs h-6 w-20 py-0 px-2" />
								</div>  */}
								<TextArea label="Action Taken" labelTextColour="text-darkww-700" name="actionTaken" />
							</div>

							{/* Other fields */}
							<TextArea label="Policy Violated" labelTextColour="text-darkww-700" name="policyViolated" />
							<TextArea label="Witnesses" labelTextColour="text-darkww-700" name="witnesses" />
							<TextArea label="Employee Response" labelTextColour="text-darkww-700" name="employeeResponse" />
							<TextArea label="Consequences" labelTextColour="text-darkww-700" name="consequences" />
							<TextArea label="Action Plan" labelTextColour="text-darkww-700" name="actionPlan" />
							<TextArea label="Appeal Details" labelTextColour="text-darkww-700" name="appealDetails" />

							{/* Checkboxes */}
							<div className="flex gap-4">
								<CheckBox name="managerSigned" label="Manager Signed" labelTextColour="text-gray-600" />
								<CheckBox name="employeeSigned" label="Employee Signed" labelTextColour="text-gray-600" />
							</div>
						</div>

						{/* Submit and Cancel Buttons */}
						<div className="inline-block flex justify-end mt-4 gap-2">
							<Button
								type="button"
								label="Cancel"
								colors="bg-red-500 hover:bg-red-700 focus:ring-white mb-4"
								onClick={() => setIsAddingDisciplinary(false)}
							/>
							<Button type="submit" label="Save Disciplinary Record" />
						</div>
					</Form>
				) : (
					<Button type="button" label="Add Disciplinary Record" onClick={() => setIsAddingDisciplinary(true)} />
				)}
			</div>
		</div>
	);
};

export default StaffNotes;
