import { ChatIcon } from "@heroicons/react/outline";
import React from "react";
import { epochToTimeStr } from "ww-framework";
import { classNames } from "./Calendar";

export const SmallShiftCardWithoutMemberName = ({ shift, orgMembers, currentDay, preferences, pickNewDay, person, timeOffData, zoom, punches }) => {
	if (shift === null || shift === undefined)
		return (
			<div className={`${zoom ? "" : "mt-1"}`} key={`${currentDay.date}-graycard`}>
				<div className={`${zoom ? "h-9" : "h-18"} flex items-center`}>
					<div className="flex flex-row flex-1 bg-gray-100 py-0 shadow-md rounded-md">
						<div className={`${zoom ? "h-8" : "h-16"} flex-1 items-center`}>
							<div className="pl-2 text-left"></div>
						</div>
					</div>
				</div>
			</div>
		);
	const { role, shiftStart, shiftEnd, memberID, notes, shiftStatus, newPay, isShiftPublished, setAsClose } = shift;
	const memberPreference = preferences && preferences.find((value) => value.memberID === memberID);
	const memberTimeOff = timeOffData && timeOffData.find((value) => value.memberID === memberID);
	const punch = punches?.find((p) => p.shiftID === shift.id);
	let lastBreak = punch?.breaks?.length > 0 ? JSON.parse(punch?.breaks.slice(-1).pop()) : {};
	const getShiftStatusButtonValue = (shiftStatus) => {
		switch (shiftStatus) {
			case "CONFIRM":
				return (
					<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="limegreen" strokeWidth="2">
						<path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
					</svg>
				);
			case "SEEN":
				return (
					<svg className="w-4 h-4" fill="none" stroke="gray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
						></path>
					</svg>
				);
			default:
				return (
					<svg className="w-4 h-4" fill="none" stroke="gray" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
						></path>
					</svg>
				);
		}
	};
	return (
		<div className={`${zoom ? "" : "mt-1"}`}>
			<div className="relative">
				<div className={`bg-darkww-500 absolute ${zoom ? "left-6 top-1.5" : "left-0"} border-2 border-white shadow-sm rounded-md px-2 z-20`}>
					<div className="text-white text-sm">{epochToTimeStr(shiftStart)}</div>
				</div>
				{!zoom && (person?.isAdmin || person?.assignedAdmin) && punch && punch?.in && (
					<>
						<div className="bg-green-600 absolute left-14 top-0 border-2 border-white shadow-sm rounded-md px-2 z-20">
							<div className="text-white text-sm">{epochToTimeStr(punch?.in)}</div>
						</div>
						{lastBreak?.start && (
							<div className="bg-red-700 absolute right-0 top-0 border-2 border-white shadow-sm rounded-md px-1 z-20">
								<div className="text-white text-xs">{epochToTimeStr(lastBreak?.start)}</div>
							</div>
						)}
					</>
				)}
			</div>
			<div className={`${zoom ? "h-9" : "h-18"} flex items-center`}>
				<div
					style={zoom ? { backgroundColor: role?.labelColour } : { backgroundColor: "white" }}
					className={`flex flex-row flex-1 py-0 shadow-md rounded-md ${memberTimeOff && "ring-red-500 ring-4"}`}
				>
					<div style={{ backgroundColor: role?.labelColour }} className={`${zoom ? "h-8" : "h-16"} w-6 rounded-l-md z-10`}></div>
					<div
						className={`flex ${zoom ? "h-8 items-end" : "h-16 items-center"} flex-1  ${
							person?.isAdmin || person?.assignedAdmin
								? memberPreference?.status === "PREFER_NOT"
									? "bg-pink-200"
									: memberPreference?.status === "PREFER"
									? "bg-green-200"
									: ""
								: ""
						}`}
					>
						<div className="pl-2 mt-1 text-left">{!zoom && <div className="text-sm font-medium truncate">{role?.name}</div>}</div>
					</div>
					{notes?.items.length > 0 && !zoom && (
						<div className="relative">
							<div
								className={`w-5 absolute bottom-6 right-5 ${
									person?.isAdmin || person?.assignedAdmin
										? memberPreference?.status === "PREFER_NOT"
											? "bg-pink-200"
											: memberPreference?.status === "PREFER"
											? "bg-green-200"
											: ""
										: ""
								}`}
								onClick={(e) => pickNewDay(currentDay, shift, e, "note")}
							>
								<ChatIcon className="h-4 w-4" />
							</div>
						</div>
					)}
					{(person?.isAdmin || person?.assignedAdmin) && !zoom && (
						<div className="relative">
							<span
								className={classNames(
									shiftStatus === "UN_SEEN" ? "" : "",
									shiftStatus === "CONFIRM" ? "" : "",
									shiftStatus === "SEEN" ? "" : "",
									"absolute bottom-6 -right-1 border-2 border-none shadow-sm rounded-md px-2 w-fit z-99999"
								)}
							>
								<div className="text-white text-sm">
									{" "}
									{isShiftPublished === "NOT_PUBLISH" ? (
										<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="rgb(219 39 119)">
											<path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
											<path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
										</svg>
									) : (
										getShiftStatusButtonValue(shiftStatus)
									)}
								</div>
							</span>
							<div className={`absolute bottom-12 right-2`}>
								{newPay ? (
									<div className="text-gray-500 text-sm flex flex-row items-center">
										{newPay > 0 ? (
											<div className="text-gray-500 text-sm flex flex-row items-center">
												{" "}
												<div className="text-pink-600 -mb-2 text-xs">+{newPay}%</div>
											</div>
										) : null}
									</div>
								) : null}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="relative">
				<div
					className={`bg-darkww-700 absolute ${
						zoom ? "bottom-1.5 right-1" : "bottom-0"
					} right-0 border-2 border-white shadow-sm rounded-md px-2 w-fit`}
				>
					<div className="text-white text-sm">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
				</div>
				{!zoom && (person?.isAdmin || person?.assignedAdmin) && punch && (
					<>
						{punch?.out && (
							<div className="bg-red-700 absolute bottom-0 right-14 border-2 border-white shadow-sm rounded-md px-2 w-fit">
								<div className="text-white text-sm">{epochToTimeStr(punch.out)}</div>
							</div>
						)}
						{punch?.in && lastBreak?.end && (
							<div className="bg-green-600 absolute left-0 bottom-0 border-2 border-white shadow-sm rounded-md px-1 z-20">
								<div className="text-white text-xs">{epochToTimeStr(lastBreak?.end)}</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
