import { API } from "aws-amplify";
import { createDepartment, updateDepartment, deleteDepartment as AWSDeleteDepartment } from "../../graphql/mutations.js";

export const saveDepartment = async (departmentDetails) => {
    return new Promise(async (resolve, reject) => {
        try {
            let departmentMapped = {
                id: departmentDetails.id,
                name: departmentDetails.name,
                organisationID: departmentDetails.organisationID
            };

            const mutation = departmentDetails.id ? updateDepartment : createDepartment;
            const { data } = await API.graphql({ query: mutation, variables: { input: departmentMapped } });
            const savedDepartment = data[departmentDetails.id ? "updateDepartment" : "createDepartment"];

            const updatedDepartment = { ...departmentDetails, ...savedDepartment };
            resolve(updatedDepartment);
        } catch (error) {
            reject(error);
        }
    });
};

export const deleteDepartment = async (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            await API.graphql({ query: AWSDeleteDepartment, variables: { input: { id: id } } });
            resolve();
        } catch (error) {
            reject(error);
        }
    });
};
