import React, { useContext, useEffect, useState } from "react";

import { ChatIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Tooltip from "rc-tooltip";
import { ConfirmModal, formateEpochToShortMonthDate2, notifyUser, showFailure, useLocation, useNavigate, useSimpleMessage } from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

import "rc-tooltip/assets/bootstrap.css";

const TimeOffRequests = ({ loadRoles, pendingTimeOff, memberAllowedHours, setIsLoading }) => {
	const { organisation } = useContext(OrganisationContext);
	const personObject = useContext(PersonContext);
	const person = personObject.person;
	const { setMessage } = useSimpleMessage();
	let navigate = useNavigate();
	let location = useLocation();
	const [noteMoule, setNoteModuleOpen] = useState(false);
	const [typeAndDetail, setTypeAndDetail] = useState({});
	const [openConfirmCancelRequest, setOpenConfirmCancelRequest] = useState(false);
	const [selectedRequest, setSelectedRequest] = useState(null);
	const [nameFilter, setNameFilter] = useState('');
	const [filteredPendingTimeOff, setFilteredPendingTimeOff] = useState(pendingTimeOff);
	const [loadMoreSearchResults, setLoadMoreSearchResults] = useState(false);

	const approve = async (e, id, type = null, person = null, status = "APPROVE") => {
		try {
			setIsLoading(true);
			await orgUtils.changeStatusTimeOff(id, status);
			person && (await notifyUser([person], `Your ${type} request has been approved by your manager`));
			await loadRoles();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};
	const addNote = async (detail, type, admin, status) => {
		setTypeAndDetail({ detail: detail, type: type, admin: admin, status: status });
		setNoteModuleOpen(false);
	};
	const manageShifts = (month = "") => {
		navigate(`${location.pathname === "/" ? "" : location.pathname}/calendar?mode=M&month=${month}`, {
			state: {
				from: location.pathname || "/",
				backgroundLocation: location.pathname || "/",
				file: "/Organisation/Calendar",
				fullScreen: true
			}
		});
	};
	const changeTimeOffType = async (off) => {
		setIsLoading(true);
		const timeOff = {
			id: off.id,
			organisationID: off.organisationID,
			fromDate: off.fromDate,
			toDate: off.toDate,
			memberID: off.memberID,
			status: off.status,
			isPaid: !off?.isPaid
		};
		await orgUtils.saveTimeOff(timeOff);
		await loadRoles();
		setIsLoading(false);
	};
	const deny = async (e, id, type = null, m = null, status = "NOT_APPROVE") => {
		try {
			setIsLoading(true);
			if (type === "Swap Shift") {
				const shiftData = {
					...{
						id: id?.id,
						organisationID: id?.organisationID,
						fromMemberID: id?.fromMemberID,
						fromShiftId: id?.fromShiftId,
						toShiftId: id?.toShiftId,
						memberID: id?.memberID,
						status: person.isAdmin || person.assignedRequests ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
						note: id?.note || null
					}
				};
				await orgUtils.saveShiftSwapRequest(shiftData);
			} else if (type === "Give Away") {
				const shiftData = {
					...{
						id: id.id,
						organisationID: id.organisationID,
						fromMemberID: id.fromMemberID,
						fromShiftId: id.fromShiftId,
						toShiftId: id.toShiftId,
						memberID: id.memberID,
						status: person.isAdmin ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
						note: id?.note || null
					}
				};
				await orgUtils.saveGiveAwayRequest(shiftData);
			} else {
				await orgUtils.changeStatusTimeOff(id, status);
			}
			m && (await notifyUser([m], `Your ${type} request has been denied`));
			await loadRoles();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};
	const destroy = async (e, id) => {
		try {
			setIsLoading(true);
			await orgUtils.destroyTimeOff(id);
			await loadRoles();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to delete timeOff.",
					subTitle: error.message
				})
			);
		}
	};
	const handlerConfirmCancelRequestModal = async (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			await destroy(e, selectedRequest.id);
		}
		setSelectedRequest(null);
		setOpenConfirmCancelRequest(!openConfirmCancelRequest);
	};

	useEffect(() => {
		const getMemberIdForSearchTerm = (searchTerm) => {
			const membersWithoutCurrentUser = organisation.members.filter(member => member.email !== person.email);
			const member = membersWithoutCurrentUser.find(member => member ? personUtils.displayName(member)?.toLowerCase().includes(searchTerm.toLowerCase()) : null);
			return member?.orgUserId;
		};
		const fetchTimeOffRequestsForMember = async (memberId) => {
			try {
				setIsLoading(true);
				const data = await orgUtils.getTimeOffForMember(organisation.id, memberId);
				if (data) {
					setFilteredPendingTimeOff(data.items);
				}
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: "Unable to fetch time off requests.",
						subTitle: error.message
					})
				);
				setIsLoading(false);
			}
		};
		const handleFilter = () => {
			if (nameFilter === "") {
				setFilteredPendingTimeOff(pendingTimeOff);
			} else {
				if (loadMoreSearchResults) {
					const memberId = getMemberIdForSearchTerm(nameFilter);
					if (memberId) {
						fetchTimeOffRequestsForMember(memberId);
					}
					setLoadMoreSearchResults(false);
				} else {
					const filtered = pendingTimeOff.filter((off) => {
						const memberName = off?.member?.id
							? personUtils.displayName(off?.member?.person) || off?.member?.orgUsername
							: off?.member?.orgUsername
						return memberName.toLowerCase().includes(nameFilter.toLowerCase());
					});
					setFilteredPendingTimeOff(filtered);
				}
			}
		}
		handleFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nameFilter, pendingTimeOff, loadMoreSearchResults]);

	return (
		<>
			<ConfirmModal
				handlerModal={handlerConfirmCancelRequestModal}
				open={openConfirmCancelRequest}
				className="text-base font-medium text-gray-900"
				title="Cancel Time Off Request"
				subTitle="Are you sure you want to cancel this time off request?"
			/>
			<ul className="flex flex-col divide-y w-full">
				<div className="py-3 pt-3 pl-4 -mt-4 bg-blue-50 rounded-t-lg">
					<div className="flex flex-col">
						<input
							type="search"
							id="filterName"
							value={nameFilter}
							onChange={(e) => setNameFilter(e.target.value)}
							className="block w-48 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-darkww-500 focus:border-darkww-500 sm:text-sm"
							placeholder="Search by Staff Name"
							autoComplete="off"
						/>
					</div>
				</div>
				{filteredPendingTimeOff
					.sort((a, b) => b.createdAt - a.createdAt)
					.map((off, index) => {
						const allowedHours = memberAllowedHours?.find((k) => k.id === off.id);
						return (
							<li key={index} className="flex flex-row">
								<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
									<div className="flex flex-1 pl-1 items-center">
										<span className="text-gray-600 mr-1 inline-flex gap-1">
											{off.isPaid && (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={1.5}
													stroke="currentColor"
													className="w-6 h-6 text-green-700 ">
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
													/>
												</svg>
											)}
											Time Off:{" "}
										</span>
										<br className="sm:hidden" />
										<span className="font-medium">
											{off?.member?.id
												? personUtils.displayName(off?.member?.person) || off?.member?.orgUsername
												: off?.member?.orgUsername || "User Removed"}
										</span>
										<span className="text-gray-600 text-xs">
											<span className="text-blue-900 inline-flex ml-1" onClick={() => manageShifts(off.fromDate)}>
												{" ("}
												{off?.repeatDay && `Every ${off?.repeatDay} from `}
												{off?.fromDate && formateEpochToShortMonthDate2(off?.fromDate, false)} -{" "}
												{off?.toDate && formateEpochToShortMonthDate2(off?.toDate, false)}
												{") "}
											</span>
											{off?.note && (
												<Tooltip trigger={["click", "hover"]} overlay={off.note}>
													<ChatIcon className="w-5 h-5 ml-1 inline-flex text-lightww-400" />
												</Tooltip>
											)}
										</span>
										{off.isPaid && (person.isAdmin || person.assignedRequests) && off.status === "PENDING" && allowedHours && (
											<span className="text-gray-600 text-xs ml-2">
												{" "}
												{`(Paid leave due: ${(allowedHours?.allowedHours).toFixed(2)}; Paid requested: ${
													((off.toDate - off.fromDate) / 60 / 60 / 24 + 1) * 8 //considering 8 working hours in a day
												})`}
											</span>
										)}
									</div>
									{(person.isAdmin || person.assignedRequests) && (
										<div className="flex flex-row content-end">
											{!off?.repeatDay && (
												<button
													className="self-end inline-flex align-end items-center bg-darkww-400 pl-1 pr-1 border ring-white text-xs h-6 rounded-md shadow-sm text-white text-right flex justify-end"
													onClick={(e) => changeTimeOffType(off)}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth={1.5}
														stroke="currentColor"
														className="w-4 h-4">
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
														/>
													</svg>

													<span className="sm:block hidden"> {off.isPaid ? "Unpaid" : "Paid"}</span>
												</button>
											)}

											<button
												className="ml-2 inline-flex items-center bg-darkww-500 py-2 pl-3 pr-3 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
												onClick={async (e) => {
													approve(e, off.id, "Time Off", off?.member?.person, "APPROVE");
												}}>
												<CheckCircleIcon className="w-6 h-6" />
												<span className="sm:block hidden">Approve</span>
											</button>
											<button
												className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-3 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
												onClick={async (e) => deny(e, off.id, "Time Off", off?.member?.person?.person, "NOT_APPROVE")}>
												<XCircleIcon className="w-6 h-6" />
												<span className="sm:block hidden pl-1">Deny</span>
											</button>
										</div>
									)}

									{!person.isAdmin && !person.assignedRequests && (
										<>
											{/* Display status message for regular members */}
											<span className="text-blue-900 ml-auto capitalize mr-2">
												{off.status === "APPROVE" ? "Time Off Approved" : off.status === "NOT_APPROVE" ? "Time Off Denied" : "Time Off Pending"}
											</span>
											{off.status !== "NOT_APPROVE" && (
												<div className="flex flex-row">
													<button
														className="inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
														onClick={(e) => {
															setSelectedRequest(off);
															setOpenConfirmCancelRequest(!openConfirmCancelRequest);
														}}>
														<XCircleIcon className="w-6 h-6" />
														<span className="sm:block hidden">Cancel</span>
													</button>
												</div>
											)}
										</>
									)}
									{(person.isAdmin || person.assignedRequests) && off.status === "NOT_APPROVE" && (
										<div className="flex flex-row">
											<button
												className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
												onClick={(e) => approve(e, off.id, "Time Off", off?.member?.person?.person, "APPROVE")}>
												<CheckCircleIcon className="w-6 h-6" />
												<span className="sm:block hidden">Approve</span>
											</button>
										</div>
									)}
									{(person.isAdmin || person.assignedRequests) && off.status === "APPROVE" && (
										<div className="flex flex-row">
											<button
												className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
												onClick={(e) => deny(e, off.id, "Time Off", off?.member?.person?.person, "NOT_APPROVE")}>
												<XCircleIcon className="w-6 h-6" />
												<span className="sm:block hidden pl-1">Deny</span>
											</button>
										</div>
									)}
								</div>
							</li>
						);
					})}
			</ul>
			{nameFilter && !loadMoreSearchResults && (
				<div className="flex justify-center mt-5">
					<button
						className="inline-flex items-center py-1 px-4 font-medium border border-transparent rounded-md shadow-sm text-white bg-darkww-500 text-right flex justify-end"
						onClick={() => setLoadMoreSearchResults(true)}>
						<span className="sm:block hidden">Load More</span>
					</button>
				</div>
			)}
		</>
	);
};

export default TimeOffRequests;
