import { API, graphqlOperation } from "aws-amplify";
import {
	deleteAllMessage as AWSDeleteAllMessage,
	createAllMessage,
	createDeptMessage,
	createMessage,
	createRoleMessage,
	updateAllMessage,
	updateDeptMessage,
	updateMessage,
	updateRoleMessage
} from "../../graphql/mutations.js";
import { getOrgAllMessage, getOrgDeptMessage, getOrgMessage, getOrgRoleMessage } from "../../graphql/queries.js";
// import { onCreateMessage } from "../../graphql/subscriptions.js";

export const saveMessage = async (message) => {
	return new Promise(async (resolve, reject) => {
		try {
			let messageMapped = {
				id: message.id || null,
				organisationID: message.organisationID,
				fromMemberID: message.fromMemberID,
				toMemberID: message.toMemberID,
				readStatus: message?.readStatus || "UN_SEEN",
				message: message?.message,
				filterChat: message?.filterChat
			};
			const mutation = message.id ? updateMessage : createMessage;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: messageMapped }
			});
			const savedMessage = data[message.id ? "updateMessage" : "createMessage"];
			const updatedMessage = { ...message, ...savedMessage };
			resolve(updatedMessage);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteAllMessage = async (messageId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: AWSDeleteAllMessage,
				variables: { input: { id: messageId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const unReadMessages = async (orgID, toMemberID, roleIDs = [], deptIds = []) => {
	return new Promise(async (resolve, reject) => {
		try {
			let filterRole = roleIDs?.map((item) => JSON.parse(`{"toRoleID":{"eq":"${item}"}}`));
			let filterDept = deptIds?.map((item) => JSON.parse(`{"toDeptID":{"eq":"${item}"}}`));
			let unReadDeptMessages = 0;
			let unReadRoleMessages = 0;
			let unReadOrgMessages = 0;
			const { data } = await API.graphql(
				graphqlOperation(getOrgMessage, {
					organisationID: orgID,
					limit: 1000,
					filter: { and: [{ readStatus: { eq: "UN_READ" } }, { toMemberID: { eq: toMemberID } }] }
				})
			);
			if (roleIDs?.length > 0) {
				const {
					data: { getOrgRoleMessage: roledata }
				} = await API.graphql(
					graphqlOperation(getOrgRoleMessage, {
						organisationID: orgID,
						limit: 1000,
						sortDirection: "ASC",
						filter: { and: [{ or: filterRole }, { fromMemberID: { ne: toMemberID } }] }
					})
				);
				unReadRoleMessages = roledata.items.filter((k) => !k.readBy?.includes(toMemberID));
			}
			if (deptIds?.length > 0) {
				const {
					data: { getOrgDeptMessage: deptdata }
				} = await API.graphql(
					graphqlOperation(getOrgDeptMessage, {
						organisationID: orgID,
						limit: 1000,
						sortDirection: "ASC",
						filter: { and: [{ or: filterDept }, { fromMemberID: { ne: toMemberID } }] }
					})
				);
				unReadDeptMessages = deptdata.items.filter((k) => !k.readBy?.includes(toMemberID));
			}
			const {
				data: { getOrgAllMessage: orgMessages }
			} = await API.graphql(
				graphqlOperation(getOrgAllMessage, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "ASC",
					filter: { fromMemberID: { ne: toMemberID } }
				})
			);
			unReadOrgMessages = orgMessages.items.filter((k) => !k.readBy?.includes(toMemberID));
			const { getOrgMessage: legacyMessage } = data;
			resolve({ individual: legacyMessage, role: unReadRoleMessages, dept: unReadDeptMessages, all: unReadOrgMessages });
		} catch (error) {
			reject(error);
		}
	});
};
export const readMessage = async (message) => {
	return new Promise(async (resolve, reject) => {
		try {
			let messageMapped = {
				id: message.id,
				organisationID: message.organisationID,
				fromMemberID: message.fromMemberID,
				toMemberID: message.toMemberID,
				readStatus: "READ",
				message: message?.message,
				filterChat: message?.filterChat
			};
			const { data } = await API.graphql({
				query: updateMessage,
				variables: { input: messageMapped }
			});
			const savedMessage = data["updateMessage"];
			const updatedMessage = { ...message, ...savedMessage };
			resolve(updatedMessage);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveRoleMessage = async (roleMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			let roleMessageMapped = {
				id: roleMessage.id || null,
				organisationID: roleMessage.organisationID,
				fromMemberID: roleMessage.fromMemberID,
				toRoleID: roleMessage.toRoleID,
				message: roleMessage?.message,
				readBy: [roleMessage.fromMemberID]
			};
			const mutation = roleMessage.id ? updateRoleMessage : createRoleMessage;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: roleMessageMapped }
			});
			const savedMessage = data[roleMessage.id ? "updateRoleMessage" : "createRoleMessage"];
			const updatedMessage = { ...roleMessage, ...savedMessage };
			resolve(updatedMessage);
		} catch (error) {
			reject(error);
		}
	});
};
export const readGroupMessage = async (type, memberIDs, id) => {
	return new Promise(async (resolve, reject) => {
		try {
			let messageMapped = {
				id: id,
				readBy: memberIDs
			};
			const mutation = type === "role" ? updateRoleMessage : type === "dept" ? updateDeptMessage : type === "whole" && updateAllMessage;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: messageMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveDeptMessage = async (deptMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			let deptMessageMapped = {
				id: deptMessage.id || null,
				organisationID: deptMessage.organisationID,
				fromMemberID: deptMessage.fromMemberID,
				toDeptID: deptMessage.toDeptID,
				message: deptMessage?.message,
				readBy: [deptMessage.fromMemberID]
			};
			const mutation = deptMessage.id ? updateDeptMessage : createDeptMessage;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: deptMessageMapped }
			});
			const savedMessage = data[deptMessage.id ? "updateDeptMessage" : "createDeptMessage"];
			const updatedMessage = { ...deptMessage, ...savedMessage };
			resolve(updatedMessage);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveOrgGroupMessage = async (orgGroupMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			let orgGroupMessageMapped = {
				id: orgGroupMessage.id || null,
				organisationID: orgGroupMessage.organisationID,
				fromMemberID: orgGroupMessage.fromMemberID,
				message: orgGroupMessage?.message,
				readBy: [orgGroupMessage.fromMemberID]
			};
			const mutation = orgGroupMessage.id ? updateAllMessage : createAllMessage;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: orgGroupMessageMapped }
			});
			const savedMessage = data[orgGroupMessage.id ? "updateAllMessage" : "createAllMessage"];
			const updatedMessage = { ...orgGroupMessage, ...savedMessage };
			resolve(updatedMessage);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrganisationMessage = async (orgID, fromMemberID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgMessage, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "ASC",
					filter: { or: [{ fromMemberID: { eq: fromMemberID } }, { toMemberID: { eq: fromMemberID } }] }
				})
			);
			const { getOrgMessage: legacyMessage } = data;
			resolve(legacyMessage);
		} catch (error) {
			reject(error);
		}
	});
};

export const getRoleMessage = async (orgID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgRoleMessage, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "ASC"
				})
			);
			const { getOrgRoleMessage: legacyMessage } = data;
			resolve(legacyMessage);
		} catch (error) {
			reject(error);
		}
	});
};

export const getDeptMessage = async (orgID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgDeptMessage, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "ASC"
				})
			);
			const { getOrgDeptMessage: legacyMessage } = data;
			resolve(legacyMessage);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrgGroupMessage = async (orgID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgAllMessage, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "ASC"
				})
			);
			const { getOrgAllMessage: legacyMessage } = data;
			resolve(legacyMessage);
		} catch (error) {
			reject(error);
		}
	});
};
// export const subscribeToOrgMessage = async (orgID, toMemberID) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const { data } = await API.graphql(
// 				graphqlOperation(onCreateMessage, {
// 					organisationID: orgID,
// 					limit: 1000,
// 					sortDirection: "ASC",
// 					filter: { or: [{ toMemberID: { eq: toMemberID } }, { fromMemberID: { eq: toMemberID } }] }
// 				})
// 			).subscribe();
// 			console.log(data);
// 			const { onCreateMessage: legacyMessage } = data;
// 			resolve(legacyMessage);
// 		} catch (error) {
// 			reject(error);
// 		}
// 	});
// };
// export const unSubscribeToOrgMessage = async (orgID, toMemberID) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const { data } = await API.graphql(
// 				graphqlOperation(onCreateMessage, {
// 					organisationID: orgID,
// 					limit: 1000,
// 					sortDirection: "ASC",
// 					filter: { or: [{ toMemberID: { eq: toMemberID } }, { fromMemberID: { eq: toMemberID } }] }
// 				})
// 			).unSubscribe();
// 			console.log(data);
// 			const { onCreateMessage: legacyMessage } = data;
// 			resolve(legacyMessage);
// 		} catch (error) {
// 			reject(error);
// 		}
// 	});
// };
