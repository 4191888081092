import { Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { Button, formateEpochToShortMonthDate } from "ww-framework";
import { CalendarDataContext, orgUtils } from "ww-stores";

const width = window.innerWidth;

const NotClockedOutView = ({ rebuildCalendar, editShiftAction }) => {
	const { setNotificationModalOpen, lateClockOutData, activeDay, setEditShift } = useContext(CalendarDataContext);
	const { shiftsNotClockedOut, punchesNotClockedOut } = lateClockOutData;
	const [shifts, setShifts] = useState(shiftsNotClockedOut);
	const [loading, setLoading] = useState(false);

	const closeModal = () => {
		setNotificationModalOpen(false);
	};

	const timePastShiftEnd = (shift) => {
		const shiftEndTimestamp = shift.shiftEnd;
		const currentTimestamp = Math.floor(Date.now() / 1000);
		const differenceInSeconds = currentTimestamp - shiftEndTimestamp;
		const hours = differenceInSeconds / 3600;

		if (hours >= 1) {
			const wholeHours = Math.floor(hours);
			const minutes = Math.floor((hours - wholeHours) * 60);
			const hoursLabel = wholeHours === 1 ? "hour" : "hours";
			const minutesLabel = minutes === 1 ? "minute" : "minutes";

			if (minutes > 0) {
				return `${wholeHours} ${hoursLabel} and ${minutes} ${minutesLabel}`;
			} else {
				return `${wholeHours} ${hoursLabel}`;
			}
		} else {
			const minutes = Math.floor(hours * 60);
			const minutesLabel = minutes === 1 ? "minute" : "minutes";
			return `${minutes} ${minutesLabel}`;
		}
	};

	const clockOutShift = async (shift, now = true) => {
		setLoading(true);
		const punch = punchesNotClockedOut?.find((p) => p.shiftID === shift.id);
		try {
			const data = { ...punch };
			data.out = shift?.shiftEnd;
			await orgUtils.savePunchOfMember(data);
			setShifts((prev) => prev.filter((s) => s.id !== shift.id));
			rebuildCalendar(activeDay.date);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const clockOutAllShifts = async () => {
		for (let i = 0; i < shiftsNotClockedOut?.length; i++) {
			await clockOutShift(shiftsNotClockedOut[i], false);
		}
		closeModal();
	};

	const callEditShift = (e, shift) => {
		closeModal();
		setEditShift(shift);
		editShiftAction(e, "shift");
	};

	return (
		<div className="p-3">
			<div className="sm:flex sm:items-start">
				<div className="mx-auto flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-white border border-rose-200 sm:mx-0 sm:h-10 sm:w-10">
					<ExclamationCircleIcon className="h-6 w-6 text-rose-600" aria-hidden="true" />
				</div>
				<div className="mt-3 text-center sm:mt-0 sm:ml-4 text-left">
					<Dialog.Title as="h3" className="inline sm:block text-lg leading-6 font-medium text-gray-900">
						The following shifts are running over!
					</Dialog.Title>
					<div className="mt-2">
						<div className="text-sm text-gray-500 flex flex-col">
							{`The following shifts are running past the rostered finish time and have not been clocked out:`}
							<p className="mt-2" />
							{shifts.map((shift) => (
								<div key={shift?.id} className="flex flex-col sm:flex-row items-end gap-2 mt-1 mb-1">
									<div className="my-0 border border-pink-600 rounded flex items-center flex-grow">
										<span className="text-black px-3 flex-1">{shift?.member?.person?.username}</span>
										<div className="text-xs text-white bg-pink-600 py-1.5 px-2 flex-grow">
											<span className="text-sm font-bold block">{timePastShiftEnd(shift)}</span>
											<span className="block">{`past the rostered end time on ${formateEpochToShortMonthDate(shift.baseDay)}`}</span>
										</div>
									</div>

									{/* Conditional rendering for buttons based on screen width */}
									<div className="flex flex-row justify-end gap-2 w-full sm:w-auto">
										<Button
											label="Edit Shift"
											onClick={(e) => callEditShift(e, shift)}
											loading={loading}
											className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-600 text-xs py-1.5 px-2 rounded w-full sm:w-auto flex items-center justify-center"
										/>
										<Button
											label="Use shift end time"
											onClick={() => clockOutShift(shift, false)}
											loading={loading}
											className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-600 text-xs py-1.5 px-2 rounded w-full sm:w-auto flex items-center justify-center"
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="mt-2 sm:mt-10 flex flex-col sm:flex-row sm:justify-between gap-2 sm:gap-5">
				<span className="text-sm text-pink-600 sm:mt-5 -ml-1 sm:ml-14 order-2 sm:order-1">
					{"Clocking out all records clock out at shift end time."}
				</span>
				<div className="flex justify-end gap-2 order-1 sm:order-2">
					{shifts?.length > 0 && (
						<Button
							label="Clock all out"
							onClick={() => clockOutAllShifts()}
							loading={loading}
							colors="bg-red-600 hover:bg-red-700 focus:ring-red-700"
						/>
					)}
					<Button label="Okay" onClick={() => closeModal()} loading={loading} />
				</div>
			</div>
		</div>
	);
};

export default NotClockedOutView;
