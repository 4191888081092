import { useState, useContext, useEffect } from "react";
import { Outlet } from "ww-framework";
import { PersonContext } from "ww-stores";
import Settings from "./Settings.jsx";
import Background from "./Background.jsx";
import { OfficeBuildingIcon, XIcon } from "@heroicons/react/outline";

const OrgCreateHelpBanner = ({ organisation }) => {
	const [showBanner, setShowBanner] = useState(true);

	useEffect(() => {
		if (organisation.id) setShowBanner(false);
	}, [organisation?.id]);

	return (
		<div className={`bg-darkww-600 w-full absolute z-50 ${showBanner ? "block" : "hidden"}`}>
			<div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between flex-wrap">
					<div className="w-0 flex-1 flex items-center">
						<span className="flex p-2 rounded-lg bg-darkww-800">
							<OfficeBuildingIcon className="h-6 w-6 text-white" aria-hidden="true" />
						</span>
						<p className="ml-3 font-medium text-white truncate">
							<span className="md:hidden">Woohoo! Let's get you setup.</span>
							<span className="hidden md:inline">Simply enter a name for your Organisation and click "Save" to get started.</span>
						</p>
					</div>
					<div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
						<button
							type="button"
							className="-mr-1 flex p-2 rounded-md hover:bg-darkww-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
							onClick={() => setShowBanner(false)}
						>
							<span className="sr-only">Dismiss</span>
							<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default function Administration() {
	const { person } = useContext(PersonContext);

	const organisation = person.currentOrganisation; // The user can only get to this screen if they have an org but double check :)

	return (
		<>
			<OrgCreateHelpBanner organisation={organisation} />
			<Background>
				<Settings />
				<Outlet />
			</Background>
		</>
	);
}
