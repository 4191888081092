export const Button = ({ label, onClick, type, disabled, loading, colors, className, loaderClass }) => {
	const buttonColors = colors || "bg-darkww hover:bg-darkww-600 focus:ring-darkww-600";
	return (
		<div>
			<button
				type={type}
				onClick={onClick}
				disabled={disabled}
				className={
					className ||
					`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${buttonColors}`
				}
			>
				{(disabled || loading) && (
					<svg
						className={loaderClass || "animate-spin -ml-1 mr-3 h-5 w-5 text-white"}
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						></path>
					</svg>
				)}
				{label}
			</button>
		</div>
	);
};

export const LinkButton = ({ label, onClick, disabled = false, loading = false, className, loaderClass }) => {
	return (
		<button
			type="button"
			onClick={disabled ? null : onClick}
			disabled={disabled}
			className={
				className || `background-transparent font-bold text-sm outline-none text-lightww-600 hover:text-lightww-900 focus:outline-none`
			}
		>
			{loading && (
				<svg className={loaderClass || "animate-spin -ml-1 mr-3 h-5 w-5"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
					<path
						className="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					></path>
				</svg>
			)}
			{!loading && label}
		</button>
	);
};
