import { useEffect, useState } from "react";
import { Storage } from "ww-framework";

let initials = "WW";

export const Logo = ({ org, size, twClasses }) => {
	const [orgInitials, setOrgInitials] = useState(initials);
	const [orgLogo, setOrgLogo] = useState("");
	const { logo } = org || {};

	useEffect(() => {
		if (org) {
			const orgInitial = `${org.name?.charAt(0)}`;
			if (org.name) {
				initials = orgInitial;
			}
		}
		setOrgInitials(initials);
	}, [org]);

	useEffect(() => {
		setOrgLogo(logo?.signedImageURL);
	}, [logo?.signedImageURL]);

	if (!orgLogo)
		return (
			<span className={`inline-block ${size} rounded-full overflow-hidden bg-gray-100`}>
				<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
					<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
				</svg>
			</span>
		);

	const organisationLogo = orgLogo ? (
		<img className={`${size} object-cover rounded-full ${twClasses || ""}`} src={orgLogo} alt="" />
	) : (
		<span className={`inline-flex items-center justify-center ${size} rounded-full bg-gray-500 ${twClasses || ""}`}>
			<span className="font-medium leading-none text-white">{orgInitials.toUpperCase()}</span>
		</span>
	);
	return organisationLogo;
};
export const LogoPicker = ({ organisation, onLogoUpload }) => {
	const { logoURI, logo } = organisation;
	const [orgLogo, setOrgLogo] = useState();

	async function onLogoSelect(e) {
		const file = e.target.files[0];

		if (!file) return;

		try {
			const { key } = await Storage.put(`${organisation.id}-logo-${file.name}`, file);

			// If the user had an existing avatar then delete it.
			if (logoURI) {
				await Storage.remove(logoURI);
			}

			onLogoUpload(key);
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		setOrgLogo(logo?.signedImageURL);
	}, [logo]);

	const SmallAvatarPicker = () => {
		return (
			<>
				<div className="flex flex-col items-center mb-4 md:ml-0">
					<p className="text-center text-sm font-medium text-gray-700 mb-2" aria-hidden="true">
						Company Logo
					</p>
					<div className="lg:hidden flex flex-col items-center">
						<div className="flex-shrink-0 rounded-full overflow-hidden mb-2 h-20 w-20" aria-hidden="true">
							{!orgLogo ? (
								<span className="inline-flex items-center justify-center h-20 w-20 rounded-full bg-gray-500">
									<span className="text-lg font-medium leading-none text-white">{initials.toUpperCase()}</span>
								</span>
							) : (
								<img className="object-cover rounded-full h-full w-full" src={orgLogo} alt="" />
							)}
						</div>
						<div className="rounded-md shadow-sm">
							<div className="group relative bg-darkww-500 text-white border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-darkww-600 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-light-blue-500">
								<label htmlFor="avatar" className="relative text-sm leading-4 font-medium text-white pointer-events-none">
									<span>Change</span>
									<span className="sr-only">Organisation Logo</span>
								</label>
								<input
									id="avatar"
									name="avatar"
									type="file"
									accept="image/png, image/gif, image/jpeg"
									className="absolute w-full h-full opacity-0 cursor-pointer rounded-md"
									onChange={onLogoSelect}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};
	const LargeAvatarPicker = () => {
		return (
			<div className="hidden relative rounded-full w-40 h-40 overflow-hidden lg:block">
				{!orgLogo ? (
					<span className="inline-flex items-center justify-center h-40 w-40 rounded-full bg-gray-500">
						<span className="text-3xl font-medium leading-none text-white">{initials.toUpperCase()}</span>
					</span>
				) : (
					<img className="object-cover relative rounded-full w-40 h-40" src={orgLogo} alt="" />
				)}
				<label
					htmlFor="avatar"
					className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
				>
					<span>Change</span>
					<span className="sr-only">Organisation Logo</span>
					<input
						type="file"
						id="avatar"
						name="avatar"
						accept="image/png, image/gif, image/jpeg"
						className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
						onChange={onLogoSelect}
					/>
				</label>
			</div>
		);
	};
	return (
		<div className="flex-grow lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
			<SmallAvatarPicker />
			<LargeAvatarPicker />
		</div>
	);
};
