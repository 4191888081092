import React, { useContext, useEffect, useState } from "react";
import { AvatarPicker, Button, Form, InputField, showFailure, showSuccess, useFormikContext, useSimpleMessage, Yup } from "ww-framework";
import { PersonContext, personUtils } from "ww-stores";
import UserContract from "./UserContract";
import UserDocuments from "./UserDocuments";
import { FolderIcon } from "@heroicons/react/solid"; 
import { ChevronDownIcon } from "@heroicons/react/outline";

export default function Profile() {
	const { person, setPerson } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const [profileSaving, setProfileSaving] = useState(false);
	const [showRateInput, setShowRateInput] = useState("");
	const [showDocuments, setShowDocuments] = useState(false);
	
	const currentOrg = person?.organisations?.items
		.filter((k) => k.organisationID === person?.currentOrganisation?.id)
		.map((organization) => organization);
	const staffInputhourlyRate = currentOrg?.map((org) => org.organisation.staffInputhourlyRate);

	useEffect(() => {
		setShowRateInput(staffInputhourlyRate);
	}, []);
	const toggleDocuments = () => {
		setShowDocuments(!showDocuments); // Toggle visibility
	};

	const profileSchema = Yup.object().shape({
		username: Yup.string(),
		firstName: Yup.string(),
		lastName: Yup.string(),
		emergencyNo: Yup.string().nullable(),
		employeePhone: Yup.string().nullable(),
		pin: Yup.number().integer().min(999, "pin must be 4 digit").max(10000, "pin must be 4 digits").nullable()
	});

	const AvatarButton = () => {
		const { values } = useFormikContext();
		const avatarUpdated = async (newURI) => {
			const updatedAvatar = { ...person, ...{ avatarURI: newURI } };
			let updatedPerson = await personUtils.saveProfile(updatedAvatar);

			personUtils.loadAvatar(updatedPerson).then((avatarDetails) => {
				const newProfile = { ...updatedPerson, ...values, ...{ avatar: avatarDetails }, ...{ avatarURI: newURI } };
				setPerson(newProfile);
			});
		};
		return (
			<div className="col-span-12 sm:col-span-3">
				<AvatarPicker person={person} onAvatarUpload={avatarUpdated} />
			</div>
		);
	};

	return (
		<>
			<div className="lg:max-w-6xl lg:mx-auto lg:px-8">
				<Form
					initialValues={person}
					validationSchema={profileSchema}
					enableReinitialize={true}
					onSubmit={async (data) => {
						try {
							setProfileSaving(true);
							let updatedPerson = await personUtils.saveProfile({ ...data, pin: parseInt(data.pin) });
							setPerson(updatedPerson);
							setProfileSaving(false);
							setMessage(
								showSuccess({
									title: "Profile saved successfully."
								})
							);
						} catch (error) {
							setProfileSaving(false);
							setMessage(
								showFailure({
									title: "Unable to save profile.",
									subTitle: error.message
								})
							);
						}
					}}
				>
					<div className="pt-6 px-4 sm:p-6 lg:pb-8">
						<div>
							<h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
						</div>

						<div className="mt-3 grid grid-cols-12 gap-6">
							<div className="col-span-12 sm:col-span-9">
								<div className="mt-4">
									<InputField label="Username" name="username" />
								</div>
								<div className="mt-4">
									<InputField label="First Name" name="firstName" />
								</div>
								<div className="mt-4">
									<InputField label="Last Name" name="lastName" />
								</div>
								{staffInputhourlyRate === "YES" ? (
									<div className="mt-4">
										<InputField label="Wage" name="salary" />
									</div>
								) : (
									""
								)}
								<div className="mt-4">
									<InputField label="PIN" name="pin" />
								</div>
								<div className="mt-4">
									<InputField label="Phone Number" name="employeePhone" />
								</div>
								<div className="mt-4">
									<InputField label="Emergency Contact" name="emergencyNo" />
									<p className="mt-2 text-sm text-gray-500">
										Please include your emergency contact in this format: Name: Contact Number (Relationship)
									</p>
								</div>
							</div>
							<AvatarButton />
							<div className="col-span-12 sm:col-span-9 flex justify-between items-center">
								<div className="flex items-center cursor-pointer" onClick={toggleDocuments}>
									<ChevronDownIcon className="absolute ml-4 mt-2 h-6 w-6 text-lightww-200" />
									<FolderIcon className="h-14 w-14 text-darkww-500" />
									<span className="ml-2 text-darkww-700">View Contracts and Documents</span>
								</div>

								<Button type="submit" label="Save" disabled={profileSaving} />
							</div>
						</div>
					</div>
				</Form>
			</div>
			{showDocuments && (
				<>
					<div className="lg:max-w-6xl lg:mx-auto lg:px-8">
						<UserContract />
					</div>
					<div className="lg:max-w-6xl lg:mx-auto lg:px-0">
						<UserDocuments />
					</div>
				</>
			)}
		</>
	);
}
