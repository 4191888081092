import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputField, Yup, showFailure, showSuccess, useNavigate, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

const departmentSchema = Yup.object().shape({
	name: Yup.string().required().min(2)
});

const EditDepartment = ({ compProps }) => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const [departmentSaving, setDepartmentSaving] = useState(false);
	const [department, setDepartment] = useState({ id: "", name: "" });
	const { record } = compProps;
	const { setMessage } = useSimpleMessage();
	let navigate = useNavigate();

	useEffect(() => {
		if (record) setDepartment(record);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record.id]);

	return (
		<Form
			validationSchema={departmentSchema}
			enableReinitialize={true}
			disabled={departmentSaving}
			initialValues={department}
			onSubmit={async (data, { resetForm }) => {
				try {
					setDepartmentSaving(true);

					const departmentDetails = {
						...data,
						...{ organisationID: organisation.id }
					};

					const updateOrg = await orgUtils.saveDepartment(departmentDetails);

					setOrganisation({
						...updateOrg,
						...(await orgUtils.mapOrgMembers(updateOrg)),
						...{ departments: updateOrg.departments?.items || [] },
						...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? updateOrg?.subscription?.items?.[0]?.quantity }
					});
					setDepartmentSaving(false);
					resetForm();
					navigate(-1);
					setMessage(
						showSuccess({
							title: "Department saved successfully."
						})
					);
				} catch (error) {
					setDepartmentSaving(false);
					setMessage(
						showFailure({
							title: "Unable to save Department.",
							subTitle: error.message
						})
					);
				}
			}}
		>
			<div className="w-72 sm:w-full flex flex-col p-5">
				<div className="mt-5">
					<InputField label="DepartMent Name" name="name" />
				</div>
				<div className="w-full">
					<Button type="submit" label="Save" disabled={departmentSaving} />
				</div>
			</div>
		</Form>
	);
};

export default EditDepartment;
