import { API, graphqlOperation } from "aws-amplify";
import { createNotes, deleteNotes } from "../../graphql/mutations.js";
import { getNotesByShiftId } from "../../graphql/queries.js";

export const getNotesByShift = async (shiftId) => {
  return new Promise(async (resolve, reject) => {
    const queryParams = {
      shiftID: shiftId,
      limit: 1000
    };

    try {
      const { data } = await API.graphql(
        graphqlOperation(getNotesByShiftId, queryParams)
      );
      const { getNotesByShiftId: legacyNotes } = data;

      resolve(legacyNotes);
    } catch (error) {
      reject(error);
    }
  });
};
export const saveNote = async (noteDetails) => {
  return new Promise(async (resolve, reject) => {
    try {
      let roleMapped = {
        id: noteDetails.id,
        note: noteDetails.note,
        shiftID: noteDetails.shiftID,
        fromEmployee: noteDetails.fromEmployee,
      };

      const mutation = createNotes;
      const { data } = await API.graphql({
        query: mutation,
        variables: { input: roleMapped }
      });
      const savedNode = data["createNotes"];

      const updatedNote = { ...noteDetails, ...savedNode };
      resolve(updatedNote);
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteNote = async (noteId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.graphql({
        query: deleteNotes,
        variables: { input: { id: noteId } }
      });
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}
