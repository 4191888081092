import { useContext, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Avatar, epochToTimeStr } from "ww-framework";
import { personUtils, PersonContext } from "ww-stores";
import { ArrowNarrowRightIcon, BriefcaseIcon, XIcon } from "@heroicons/react/outline";
import { TagIcon } from "@heroicons/react/solid";
import { MiniCalendar } from "./MiniCalendar";
import ShiftNote from "../Organisation/ShiftNote";

export const ShiftCard = ({ shift, orgMembers, activeDay, rebuildCalendar }) => {
	const { person } = useContext(PersonContext);
	const { role, shiftStart, shiftEnd, memberID, hasBreak, breakStart, breakEnd, breakDuration, setAsClose } = shift;
	const memberDetails = orgMembers.find((orgMember) => orgMember.orgUserId === memberID);

	const [isOverlayOpen, setIsOverlayOpen] = useState(false); // State for overlay
	const [editShift, setEditShift] = useState(null); // State to hold the selected shift

	const openOverlay = () => {
		setEditShift(shift);
		setIsOverlayOpen(true);
	};

	const closeOverlay = () => {
		setIsOverlayOpen(false);
		setEditShift(null);
	};

	return (
		<>
			<div
				className="bg-white group p-2 w-full h-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightww-300"
				onClick={openOverlay} // Open overlay on click
			>
				<span className="min-w-0 flex-1 flex items-center space-x-3">
					<span className="block flex-shrink-0">
						<div style={{ borderColor: role?.labelColour }} className="rounded-full border-4 border-darkww-600 h-16 w-16 pt-1 pl-1">
							<Avatar user={memberDetails} size="h-12 w-12" />
						</div>
						<div className="relative">
							<div className="absolute bottom-0 left-12">
								<TagIcon style={{ color: role?.labelColour }} className="h-8 w-8" aria-hidden="true" />
							</div>
						</div>
					</span>
					<span className="block min-w-0 flex-1 pl-2">
						<span className="block text-sm font-medium text-gray-900 truncate">{personUtils.displayName(memberDetails)}</span>
						<span className="block text-sm font-medium text-gray-500 truncate">{role?.name}</span>
					</span>
				</span>
				<span className="flex-1 h-full hidden sm:block">
					<div className="relative">
						<div className={`bg-darkww-700 absolute -top-4 left-0 border-2 border-white shadow-lg rounded-lg px-2 w-fit`}>
							<div className="text-white text-lg flex flex-row">
								<div className="py-1 px-1">{epochToTimeStr(shiftStart)}</div>
								<div>
									<ArrowNarrowRightIcon className="text-white h-8 w-8 px-1" aria-hidden="true" />
								</div>
								<div className="py-1 px-1">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
							</div>
						</div>
						<div className={`absolute top-10`}>
							{hasBreak && (
								<div className="text-gray-500 text-sm flex flex-row items-center">
									<BriefcaseIcon className="h-6 w-6 px-1" />
									{breakDuration ? `${breakDuration} Mins` : `${epochToTimeStr(breakStart)} - ${epochToTimeStr(breakEnd)}`}
								</div>
							)}
						</div>
					</div>
				</span>

				<span className="flex-shrink-0 h-10 w-10 pr-10 items-center justify-center inline-flex sm:hidden">
					<div className="text-gray-500 divide-y divide-gray-200">
						<div className="py-1">{epochToTimeStr(shiftStart)}</div>
						<div className="py-1">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
					</div>
				</span>
			</div>

			{/* Overlay Modal */}
			<Transition.Root show={isOverlayOpen} as={Fragment}>
				<Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={closeOverlay}>
					<div className="absolute inset-0 overflow-hidden">
						<Transition.Child
							as={Fragment}
							enter="ease-in-out duration-500"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in-out duration-500"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full">
								<div className="relative w-screen max-w-md">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0">
										<div className="absolute top-0 right-8 -mr-8 pt-4 pr-4 flex">
											<button type="button" className="rounded-md text-gray-300 hover:text-white focus:outline-none" onClick={closeOverlay}>
												<span className="sr-only">Close panel</span>
												<XIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>

									{/* Shift Note Component */}
									{editShift && (
										<ShiftNote
											shift={editShift}
											activeDay={activeDay} // Passing activeDay
											rebuildCalendar={rebuildCalendar} // Passing rebuildCalendar
											setOverLayOpen={setIsOverlayOpen} // Passing overlay control
										/>
									)}
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export const AllShifts = () => {
	const { person } = useContext(PersonContext);
	const [currentDay, setCurrentDay] = useState();

	const rebuildCalendar = (day) => {
		// Logic to rebuild the calendar when necessary, e.g., refreshing the currentDay state
		setCurrentDay({ ...currentDay, day });
	};

	return (
		<div className="w-full">
			<MiniCalendar updateDay={setCurrentDay} showMyShiftsIndicator={true} />
			{currentDay?.shifts.length > 0 && (
				<div className="mt-2 sm:mt-10 w-full mx-auto sm:max-w-2xl">
					<h3 className="text-xs font-semibold text-white uppercase tracking-wide">{`Total Shifts (${currentDay?.shifts.length})`}</h3>
					<ul role="presentation" className="mt-4 grid grid-cols-1 gap-4">
						{currentDay?.shifts.map((shift, index) => (
							<li key={index} role="presentation">
								<ShiftCard
									shift={shift}
									orgMembers={person.currentOrganisation.members || []}
									activeDay={currentDay} // Pass activeDay to ShiftCard
									rebuildCalendar={rebuildCalendar} // Pass rebuildCalendar to ShiftCard
								/>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
