import { CheckCircleIcon, DocumentTextIcon, DownloadIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { orgUtils, PersonContext } from "ww-stores";
import { deleteFileById, deleteFileByIdFromStorage, getSignedUrl, saveFileToStorage } from "../Organisation/files";

export default function UserContract() {
	const { person } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const [contractFile, setContractFile] = useState(null);
	const [signedContractFile, setSignedContractFile] = useState(null);
	const [isLoadingContract, setIsLoadingContract] = useState(true);
	const [isUploadingSignedContract, setIsUploadingSignedContract] = useState(false);
    const [isDeletingSignedContract, setIsDeletingSignedContract] = useState(false);
	const [activeFolder, setActiveFolder] = useState(null);

	useEffect(() => {
		getContractFolderAndFile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (activeFolder) {
			fetchSignedContractFile();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFolder]);

	async function getContractFolderAndFile() {
		const folderExist = await orgUtils.getStoredFolderByFilter(person.currentOrganisation.id, { name: { eq: "_contracts" } }, "protected");
		const contractFileName = `_${person.person}`;

		if (folderExist.items.length === 0) {
			const folder = await orgUtils.storeFolder({
				name: "_contracts",
				organisationID: person.currentOrganisation.id
			});
			setActiveFolder(folder);

			if (folder.files.items.length > 0) {
				const file = folder.files.items.find((file) => file.name.startsWith(contractFileName));
				setContractFile(file || null);
			} else {
				setContractFile(null);
			}
		} else {
			setActiveFolder(folderExist.items[0]);
			if (folderExist.items[0].files.items.length > 0) {
				const file = folderExist.items[0].files.items.find((file) => file.name.startsWith(contractFileName));
				setContractFile(file || null);
			} else {
				setContractFile(null);
			}
		}
		setIsLoadingContract(false);
	}

	const fetchSignedContractFile = async () => {
		try {
			if (activeFolder) {
				const signedContractFileName = `_${person.person}_signed`;
				const signedFile = activeFolder.files.items.find((file) => file.name.startsWith(signedContractFileName));
				setSignedContractFile(signedFile || null);
			}
		} catch (error) {
			console.error("Error fetching signed contract file", error);
		}
	};

	async function uploadSignedContract({ selectedFile }) {
		if (!selectedFile) {
			console.error("No file selected");
			return;
		}

		const fileName = `_${person.person}_signed`;

		try {
			const fileId = uuidv4();
			const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."));
			const filePath = `${person.id}/${activeFolder.id}/${fileName}${ext}`;
			const fileURI = await saveFileToStorage(selectedFile, filePath);

			const fileDetail = {
				id: fileId,
				folderId: activeFolder.id,
				name: `${fileName}${ext}`,
				organisationID: person.currentOrganisation.id,
				type: selectedFile.type,
				size: selectedFile.size,
				fileURI: fileURI,
				createdAt: new Date()
			};

			await orgUtils.storeFile(fileDetail);
			await getContractFolderAndFile();
		} catch (error) {
			console.error("Error during signed contract upload:", error);
		}
	}

	const handleSignedContractUpload = async (e) => {
		const file = e.target.files[0];
		if (!file) {
			console.error("No file selected");
			return;
		}

		setIsUploadingSignedContract(true);

		try {
			await uploadSignedContract({ selectedFile: file });
			setMessage(showSuccess({ title: "Signed contract uploaded successfully." }));
		} catch (error) {
			setMessage(showFailure({ title: "Failed to upload signed contract", subTitle: error.message }));
		} finally {
			setIsUploadingSignedContract(false);
		}
	};

	const handleDownloadContract = async () => {
		try {
			const signedUrl = await getSignedUrl(contractFile.fileURI);
			window.open(signedUrl, "_blank", "noopener,noreferrer");
		} catch (error) {
			setMessage(showFailure({ title: "Failed to download contract", subTitle: error.message }));
		}
	};

	const handleDownloadSignedContract = async () => {
		if (signedContractFile) {
			try {
				const signedUrl = await getSignedUrl(signedContractFile.fileURI);
				window.open(signedUrl, "_blank", "noopener,noreferrer");
			} catch (error) {
				setMessage(showFailure({ title: "Failed to download signed contract", subTitle: error.message }));
			}
		} else {
			console.error("No signed contract available for download.");
		}
	};

	const handleDeleteSignedContract = async () => {
		try {
            setIsDeletingSignedContract(true);
			if (signedContractFile) {
				const { id, fileURI } = signedContractFile;

				await deleteFileById(id);
				await deleteFileByIdFromStorage(fileURI);

				await getContractFolderAndFile();
                setIsDeletingSignedContract(false);
				setMessage(showSuccess({ title: "Signed contract deleted successfully." }));
			}
		} catch (error) {
            setIsDeletingSignedContract(false);
			console.log("Error in deleteFile", error);
			setMessage(showFailure({ title: "Failed to delete signed contract", subTitle: error.message }));
		}
	};

	return (
		<div className="mx-4 mb-8 sm:p-6 lg:pb-4 bg-gray-100 rounded-lg shadow-sm">
			<h2 className="text-lg leading-6 font-medium text-gray-900">Contract</h2>
			{isLoadingContract ? (
				<p>Loading contract...</p>
			) : contractFile ? (
				<div className="mt-3">
					<div style={{ width: "50%" }} className="mt-4 flex items-center justify-between">
						<div style={{ width: "50%" }} className="py-2 border-b border-gray-200 rounded-md sm:rounded-lg">
							<span className="px-2 text-sm sm:text-md flex items-center text-blue-500">
								<DocumentTextIcon className="h-6 w-6 mr-1" />
								Contract
							</span>
						</div>
						<div className="px-2 lg:px-6 flex justify-center">
							<button
								className="focus:outline-none font-medium rounded-lg mr-2 text-sm py-1.5 px-4 bg-darkww-400 hover:bg-darkww-600"
								onClick={handleDownloadContract}
							>
								<div className="flex items-center">
									<DownloadIcon className="h-6 w-6 text-white" />
									<span className="ml-1 text-white">Download Contract</span>
								</div>
							</button>
						</div>
					</div>
					{!signedContractFile && (
						<p className="mt-4 text-sm text-gray-500">
							Your contract file has been shared with you. You can proceed to download, sign, and upload it below.
						</p>
					)}
					{!signedContractFile ? (
						<div className="mt-6">
							<label htmlFor="signedContractUpload" className="text-sm font-medium text-gray-700">
								Upload Signed Contract
							</label>
							<input type="file" id="signedContractUpload" style={{ display: "none" }} onChange={handleSignedContractUpload} />
							<div className="flex items-center mt-2">
								<Button
									type="button"
									label="Choose File"
									onClick={() => document.getElementById("signedContractUpload").click()}
									disabled={isUploadingSignedContract}
								/>
							</div>
						</div>
					) : (
						<div className="flex items-center mt-8 space-x-1">
                            <CheckCircleIcon className="h-6 w-6 text-green-500" />
							<p className="text-sm text-green-500">Your contract has been signed and uploaded.</p>
						</div>
					)}
					{signedContractFile ? (
						<div style={{ width: "70%" }} className="mt-2 flex items-center justify-between">
							<div style={{ width: "35%" }} className="py-2 border-b border-gray-200 rounded-md sm:rounded-lg">
								<span className="px-2 text-sm sm:text-md flex items-center text-blue-500">
									<DocumentTextIcon className="h-6 w-6 mr-1" />
									Signed Contract
								</span>
							</div>
							<div className="px-2 lg:px-6 flex justify-center">
								<button
									className="focus:outline-none font-medium rounded-lg mr-2 text-sm py-1.5 px-4 bg-darkww-400 hover:bg-darkww-600"
									onClick={handleDownloadSignedContract}
								>
									<div className="flex items-center">
										<DownloadIcon className="h-6 w-6 text-white" />
										<span className="ml-1 text-white">Download Signed Contract</span>
									</div>
								</button>
							</div>
							<div className="px-2 lg:px-6 flex justify-center">
                                <Button
									type="button"
									label="Delete"
                                    loading={isDeletingSignedContract}
                                    className="text-white bg-red-500 font-medium rounded-lg mr-2 text-sm py-1.5 px-4 focus:outline-none hover:bg-red-700"
									onClick={handleDeleteSignedContract}
									disabled={isUploadingSignedContract}
								/>
							</div>
						</div>
					) : (
						<p className="mt-2 text-sm text-gray-500">No signed contract uploaded yet.</p>
					)}
				</div>
			) : (
				<p>No contract available for download.</p>
			)}
		</div>
	);
}
