export const saveEnqury = async (enqury) => {
	return new Promise(async (resolve, reject) => {
		try {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify({
				email: enqury.email,
				note: enqury?.note || "Enquired From Home Page"
			});

			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
				redirect: "follow"
			};

			await fetch("https://hz0sqoqn30.execute-api.eu-west-1.amazonaws.com/enquiry/contact-us", requestOptions)
				.then((response) => response.text())
				.then((result) => resolve({ result }))
				.catch((error) => reject(error));
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};
