import { Combobox } from "@headlessui/react";
import { CheckIcon, ExclamationCircleIcon, SelectorIcon } from "@heroicons/react/solid";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const InputField = ({ ...props }) => {
	const {
		label,
		name,
		type,
		id,
		placeholder,
		autoComplete,
		disabled,
		onChange,
		labelTextColour = "text-gray-700",
		toolTip,
		toolTipId,
		isFlex = false,
		initValue,
		step,
		min,
		max
	} = props;
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(props);
	const colors =
		meta.error && meta.touched
			? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
			: "border-gray-300 shadow-sm placeholder-gray-400 focus:ring-darkww focus:border-darkww";

	const setValue = (e) => {
		setFieldValue(field.name, type !== "file" ? e.target.value : e.target.files);
		if (onChange && typeof onChange === "function") {
			onChange(type !== "file" ? e.target.value : e.target.files);
		}
	};
	useEffect(() => {
		if (initValue) {
			setFieldValue(field.name, initValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initValue]);
	return (
		<div className={`relative w-full ${isFlex && "flex"}`}>
			{type !== "hidden" && (
				<label
					htmlFor={name}
					data-tooltip-id={toolTipId}
					data-tooltip-content={toolTip}
					className={`block text-sm font-medium ${labelTextColour}`}
				>
					{label}
				</label>
			)}
			<div className="w-full relative rounded-md shadow-sm mt-2 mb-1">
				<input
					id={id}
					name={field.name}
					type={type || "text"}
					onChange={(e) => {
						setValue(e);
					}}
					onBlur={field.onBlur}
					value={type !== "file" ? field.value || "" : undefined}
					placeholder={placeholder}
					autoComplete={autoComplete}
					autoCapitalize="none"
					disabled={disabled}
					className={`appearance-none block rounded-md shadow-sm sm:text-sm focus:outline-none w-full pr-10 ${
						disabled ? "opacity-50" : ""
					} ${colors}`}
					aria-invalid={meta.error}
					aria-describedby={`${name}-error`}
					step={step}
					min={min}
					max={max}
				/>
				{meta.touched && meta.error && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
				)}
			</div>
			{meta.touched && meta.error && <p className="absolute left-0 -bottom-6 pb-1 text-sm text-red-600">{meta.error}</p>}
		</div>
	);
};

export const TextArea = ({ label, name, type, placeholder, autoComplete, rows, labelTextColour = "text-gray-700" }) => {
	const [field, meta] = useField(name);
	const colors =
		meta.error && meta.touched
			? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
			: "border-gray-300 shadow-sm placeholder-gray-400 focus:ring-darkww focus:border-darkww";
	return (
		<div>
			{type !== "hidden" && (
				<label htmlFor={name} className={`block text-sm font-medium ${labelTextColour}`}>
					{label}
				</label>
			)}
			<div className="mt-1 relative rounded-md shadow-sm">
				<textarea
					rows={rows}
					name={field.name}
					type={type || "text"}
					onChange={field.onChange}
					onBlur={field.onBlur}
					value={field.value}
					placeholder={placeholder}
					autoComplete={autoComplete}
					className={`appearance-none block rounded-md shadow-sm sm:text-sm focus:outline-none w-full pr-10 ${colors}`}
					aria-invalid={meta.error}
					aria-describedby={`${name}-error`}
				/>
				{meta.error && meta.touched && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
				)}
			</div>
			{meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
		</div>
	);
};

export const SearchableList = ({ list, onSelect, label }) => {
	const [query, setQuery] = useState("");
	const [selectedItem, setSelectedItem] = useState();

	const filteredList =
		query === ""
			? list
			: list.filter((item) => {
					return item?.description.toLowerCase().includes(query.toLowerCase());
			  });

	const onItemChange = (selection) => {
		setSelectedItem(selection);
		if (onSelect) onSelect(selection);
	};

	return (
		<Combobox as="div" value={selectedItem} onChange={onItemChange}>
			{label && <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>}
			<div className="relative mt-1">
				<Combobox.Input
					className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-lightww-500 focus:outline-none focus:ring-1 focus:ring-lightww-500 sm:text-sm"
					onChange={(event) => setQuery(event.target.value)}
					displayValue={(item) => item?.description}
				/>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
				</Combobox.Button>

				{filteredList.length > 0 && (
					<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredList.map((item) => (
							<Combobox.Option
								key={item.id}
								value={item}
								disabled={item.unavailable}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active ? "bg-lightww-600 text-white" : "text-gray-900"
									)
								}
							>
								{({ active, selected }) => (
									<>
										<span className={classNames("block truncate", selected && "font-semibold")}>{item?.description}</span>

										{selected && (
											<span
												className={classNames(
													"absolute inset-y-0 right-0 flex items-center pr-4",
													active ? "text-white" : "text-lightww-600"
												)}
											>
												<CheckIcon className="h-5 w-5" aria-hidden="true" />
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	);
};

const ComboBoxButton = () => {
	return (
		<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
			<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
		</Combobox.Button>
	);
};

const ComboBoxOption = ({ option, selected, active }) => {
	return (
		<>
			<div className="flex items-center">
				<span className={classNames("ml-3 truncate", selected && "font-semibold")}>{option?.description}</span>
			</div>

			{selected && (
				<span className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-lightww-600")}>
					<CheckIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			)}
		</>
	);
};

export const ComboBox = ({ ...props }) => {
	const { type, name, label, options = [], labelTextColour = "text-gray-700", initValue, autoComplete, getSelectedValueOnChange } = props;
	const { ComboBoxOption: CustomComboBoxOption, preferences } = props;
	const selection = options.find((option) => option.id === initValue);
	const [query, setQuery] = useState("");
	const [selectedOption, setSelectedOption] = useState();
	const { setFieldValue, setErrors, errors } = useFormikContext();
	const [field, meta] = useField(props);
	const colors =
		meta.error && meta.touched
			? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
			: "border-gray-300 shadow-sm placeholder-gray-400 focus:ring-darkww focus:border-darkww";

	const filteredOption =
		query === ""
			? options
			: options.filter((option) => {
					return option?.description.toLowerCase().includes(query.toLowerCase());
			  });

	const onSelection = (selection) => {
		getSelectedValueOnChange && getSelectedValueOnChange(selection);
		setSelectedOption(selection);
		setFieldValue(field.name, selection.id);
		props.onValueChange && props.onValueChange(selection);
	};

	useEffect(() => {
		setFieldValue(field.name, initValue);
		if (selection) onSelection(selection);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selection]);

	return (
		<Combobox as="div" value={selectedOption} onChange={onSelection}>
			{type !== "hidden" && (
				<label htmlFor={name} className={`block text-sm font-medium ${labelTextColour}`}>
					{label}
				</label>
			)}
			<div className="relative mt-1">
				<Combobox.Input
					className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-lightww-500 focus:outline-none focus:ring-1 focus:ring-lightww-500 sm:text-sm ${colors}`}
					onChange={(event) => setQuery(event.target.value)}
					name={field.name}
					autoComplete={autoComplete}
					onFocus={() => {
						setErrors({ ...errors, ...{ [field.name]: "" } });
						setQuery("");
					}}
					displayValue={(option) => option?.description}
				/>

				{meta.error && meta.touched ? (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
				) : (
					<ComboBoxButton meta={meta} />
				)}

				{filteredOption.length > 0 && (
					<Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredOption.map((option, index) => (
							<Combobox.Option
								key={option.id || index}
								value={option}
								disabled={option?.unavailable || false}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active ? "bg-lightww-600 text-white" : "text-gray-900"
									)
								}
							>
								{({ active, selected }) => {
									return CustomComboBoxOption ? (
										<CustomComboBoxOption option={option} selected={selected} active={active} preferences={preferences} />
									) : (
										<ComboBoxOption option={option} selected={selected} active={active} preferences={preferences} />
									);
								}}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
			{meta.error && meta.touched && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
		</Combobox>
	);
};

export const CheckBox = ({ ...props }) => {
	const { name, label, onChange, checked, labelTextColour = "text-gray-700", toolTip, toolTipId } = props;
	const [isChecked, setIsChecked] = useState(checked ?? false);
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(props);

	const toggle = () => {
		if (onChange && typeof onChange === "function") {
			onChange();
		}
		setIsChecked(!isChecked);
		setFieldValue(field.name, !isChecked);
	};
	// const colors =
	// 	meta.error && meta.touched
	// 		? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
	// 		: "border-gray-300 shadow-sm placeholder-gray-400 focus:ring-darkww focus:border-darkww";

	useEffect(() => {
		setIsChecked(checked);
		setFieldValue(field.name, checked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	return (
		<>
			<div className="relative flex items-start" data-tooltip-id={toolTipId} data-tooltip-content={toolTip}>
				<div className="flex items-center h-5">
					<input
						id={name}
						checked={isChecked}
						value={isChecked}
						aria-describedby="invite-description"
						name={name}
						type="checkbox"
						className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
						onChange={toggle}
					/>
				</div>
				<div className="ml-3 text-sm">
					<label htmlFor={name} className={`font-medium ${labelTextColour}`}>
						{label}
					</label>
				</div>
			</div>
			<p className={`${meta.touched && "mt-2 h-3"} text-sm text-red-600`}>{meta.error ? meta.error : ""}</p>
		</>
	);
};
export const RadioFeild = ({ ...props }) => {
	const { label, name, id = "radio", onChange, isChecked = false } = props;
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(props);

	const colors =
		meta.error && meta.touched
			? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
			: "border-gray-300 shadow-sm placeholder-gray-400 focus:ring-darkww focus:border-darkww";

	const setValue = (e) => {
		setFieldValue(field.name, e.target.checked);
		if (onChange && typeof onChange === "function") {
			onChange(e.target.checked);
		}
	};

	return (
		<>
			<div className="flex flex-end">
				<input
					id={id}
					name={name}
					type="radio"
					onClick={(e) => setValue(e)}
					defaultChecked={isChecked}
					className={`focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 ${colors}`}
					// aria-invalid={meta.error}
					// aria-describedby={`${name}-error`}
				/>
				{meta.error && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
				)}
				<label htmlFor={id} className="ml-3 block text-sm font-medium text-gray-700">
					{label}
				</label>
			</div>
			<p className={`${meta.touched && "mt-2 h-3"} text-sm text-red-600`}>{meta.error ? meta.error : ""}</p>
		</>
	);
};
