import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import { DialogFrame } from "ww-framework";
import { OrganisationContext, PersonContext } from "ww-stores";
import { CountBubble } from "./CountBubble";
import { PreferenceDialog } from "./PreferenceDialog";
import { TimeOffCountBubble } from "./TimeOffCountBubble";

export const Preferred = ({
	currentDay,
	preferenceSave,
	preferences,
	addNoteParticularDay,
	noteParticularDay,
	mode,
	timeOffData,
	approveTimeOff
}) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;
	const memberPreference = preferences && preferences.find((value) => value?.memberID === person?.organisations?.items[activeOrg]?.id);
	const preferNot = preferences && preferences.filter((value) => value.status === "PREFER_NOT");
	const memberTimeOff =
		timeOffData && (person?.isAdmin || person?.assignedAdmin)
			? timeOffData
			: timeOffData.find((value) => value.memberID === person?.organisations?.items[activeOrg]?.id);
	const prefer = preferences && preferences.filter((value) => value.status === "PREFER");
	const noteParticular = noteParticularDay && noteParticularDay.find((value) => value.noteDate === currentDay?.baseEpoch);

	const [preferenceDialogOpen, setPreferenceDialogOpen] = useState(false);
	const handlerPreferenceModal = async (e, type) => {
		// e.preventDefault();
		setPreferenceDialogOpen(false);
	};

	return (
		<>
			{!(person?.isAdmin || person?.assignedAdmin) ? (
				<div className="ml-auto flex">
					<div
						className={`mr-1 p-0 text-center h-5 w-5 object-cover rounded-full  ${
							noteParticular?.id !== undefined ? "text-pink-600" : "text-gray-600"
						}`}
						onClick={(e) => addNoteParticularDay(e, currentDay, noteParticular)}
					>
						<span className="m-0 p-0 text-sm font-medium block">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
								/>
							</svg>
						</span>
					</div>
					{memberTimeOff && memberTimeOff.status !== "NOT_APPROVE" && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5 mt-0.5 mr-1"
							style={{ color: memberTimeOff?.status === "PENDING" ? "GREY" : "RED" }}
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth="3"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
							/>
						</svg>
					)}
					{(organisation?.enableStaffPreferences ?? true) && (
						<div>
							<button
								onClick={() => setPreferenceDialogOpen(true)}
								className="inline-flex justify-center w-full py-0 mt-0.3 border border-gray-300 rounded-md text-sm font-medium text-gray-700 cursor-pointer"
							>
								<span className="mx-1 text-gray-700 text-sm font-medium block text-white">
									{memberPreference?.status === "PREFER_NOT" ? (
										<ThumbDownIcon className="w-5 h-5 text-pink-600" />
									) : memberPreference?.status === "PREFER" ? (
										<ThumbUpIcon className="w-5 h-5v text-green-600" />
									) : (
										<ThumbUpIcon className="w-5 h-5 text-gray-600" />
									)}
								</span>
							</button>
						</div>
					)}
					<DialogFrame open={preferenceDialogOpen} handlerModal={handlerPreferenceModal} title={"Set Preference"}>
						<PreferenceDialog
							person={person}
							currentDay={currentDay}
							preferenceSave={preferenceSave}
							preferences={preferences}
							addNoteParticularDay={addNoteParticularDay}
							noteParticularDay={noteParticularDay}
							mode={mode}
							timeOffData={timeOffData}
							approveTimeOff={approveTimeOff}
						/>
					</DialogFrame>
				</div>
			) : (
				<div className="ml-auto flex">
					<div
						className={`mr-1 p-0 text-center h-5 w-5 object-cover rounded-full  ${
							noteParticular?.id !== undefined ? "text-pink-600" : "text-gray-300"
						}`}
						onClick={(e) => addNoteParticularDay(e, currentDay, noteParticular)}
					>
						<span className="m-0 p-0 text-sm font-medium block ">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth="2"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
								/>
							</svg>
						</span>
					</div>
					<TimeOffCountBubble memberTimeOff={memberTimeOff} approveTimeOff={approveTimeOff} />
					<CountBubble preferType={preferNot} backgroundColor={"pink"} />
					<CountBubble preferType={prefer} backgroundColor={"green"} />
				</div>
			)}
		</>
	);
};
