import { API, graphqlOperation } from "aws-amplify";

import { createTimeOff, deleteTimeOff, updateTimeOff } from "../../graphql/mutations.js";
import { getOrgMemberTimeOff, getOrgTimeOff } from "../../graphql/queries.js";

export const saveTimeOff = async (timeOffDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			let timeOffMapped = {
				id: timeOffDetail.id || null,
				organisationID: timeOffDetail.organisationID,
				fromDate: timeOffDetail.fromDate,
				toDate: timeOffDetail.toDate,
				repeatDay: timeOffDetail.selectedRepeatDay,
				note: timeOffDetail.note,
				memberID: timeOffDetail.memberID,
				status: timeOffDetail.status,
				isPaid: timeOffDetail?.isPaid ?? false
			};
			const mutation = timeOffDetail.id ? updateTimeOff : createTimeOff;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: timeOffMapped }
			});
			const savedTimeOff = data[timeOffDetail.id ? "updateTimeOff" : "createTimeOff"];

			const updatedTimeOff = { ...timeOffDetail, ...savedTimeOff };
			resolve(updatedTimeOff);
		} catch (error) {
			reject(error);
		}
	});
};

export const getMemberTimeOff = async (organisationID, memberID, status = "PENDING") => {
	return new Promise(async (resolve, reject) => {
		let filter = null;
		if (status === "ACTIONED") {
			filter = {
				status: { ne: "PENDING" }
			};
		} else {
			filter = {
				status: { eq: status }
			};
		}
		try {
			const { data } = await API.graphql({
				query: getOrgMemberTimeOff,
				variables: {
					organisationID,
					memberID: { eq: memberID },
					sortDirection: "DESC",
					filter: filter,
					limit: 1000
				}
			});
			const { getOrgMemberTimeOff: memberTimeOff } = data;
			resolve(memberTimeOff);
		} catch (error) {
			reject(error);
		}
	});
};

export const getTimeOff = async (organisationID, startRange, endRange, isAdmin, memberID) => {
	return new Promise(async (resolve, reject) => {
		// endRange will be the very start of the day, need to set it to 23:59 to include shifts on that day.
		if (isAdmin === null) {
			isAdmin = false;
		}

		let queryParams;
		if (startRange && endRange) {
			const inclLastDay = endRange + 23 * 60 * 60 + 59 * 60;
			queryParams = {
				organisationID,
				limit: 1000,
				filter: {
					and: [{ status: { ne: "NOT_APPROVE" } }, { and: [{ fromDate: { le: inclLastDay } }, { toDate: { ge: startRange } }] }]
				},
				sortDirection: "DESC"
			};
		} else {
			if (isAdmin === true && memberID)
				queryParams = {
					organisationID,
					limit: 20,
					sortDirection: "DESC"
				};
			else if (isAdmin === false && memberID)
				queryParams = {
					organisationID,
					limit: 20,
					filter: {
						memberID: { eq: memberID }
					},
					sortDirection: "DESC"
				};
			else
				queryParams = {
					organisationID,
					limit: 1000,
					filter: {
						status: { ne: "NOT_APPROVE" }
					}
				};
		}
		try {
			// The legacy shifts refers to the shifts that were stored prior to the weekStartDay index being added to the shifts. This call can hopefully be removed in the future when ALL shifts have this index populated.
			const { data } = await API.graphql(graphqlOperation(getOrgTimeOff, queryParams));
			const { getOrgTimeOff: legacyTimeOff } = data;
			resolve(legacyTimeOff);
		} catch (error) {
			reject(error);
		}
	});
};

export const changeStatusTimeOff = async (ID, status, note) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: updateTimeOff,
				variables: { input: { id: ID, status: status, note: note } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyTimeOff = async (ID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteTimeOff,
				variables: { input: { id: ID } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const getPaidTimeOff = async (organisationID, endDate) => {
	return new Promise(async (resolve, reject) => {
		try {
			const queryParams = {
				organisationID,
				filter: { and: [{ status: { eq: "APPROVE" } }, { isPaid: { eq: true } }, { toDate: { le: endDate } }] },
				sortDirection: "DESC"
			};
			const { data } = await API.graphql(graphqlOperation(getOrgTimeOff, queryParams));
			const { getOrgTimeOff: legacyTimeOff } = data;
			resolve(legacyTimeOff);
		} catch (error) {
			reject(error);
		}
	});
};
