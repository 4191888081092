import { TrashIcon } from "@heroicons/react/outline";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import React, { Fragment, useContext, useState } from "react";
import { formateEpochToShortMonthDate, Menu, Transition as Transitions } from "ww-framework";
import { PersonContext, personUtils } from "ww-stores";

const MessageBubble = ({ message, currentMember, chatType, deleteMessage }) => {
    const { person } = useContext(PersonContext);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDeleteMessage = (messageId) => {
        deleteMessage(messageId);
    };

    const isCurrentUser = message.fromMemberID === currentMember?.orgUserId;

    return (
        <li
            className={`flex ${isCurrentUser ? "justify-end" : "justify-start"}`}
            key={message.id}
            onMouseEnter={chatType === "wholeOrganisation" ? handleMouseEnter : null}
            onMouseLeave={chatType === "wholeOrganisation" ? handleMouseLeave : null}
        >
            <div className="flex items-center relative">
                {(isCurrentUser && isHovered && (person.isAdmin || person.assignedAdmin)) && (
                    MessageContextMenu(message, handleDeleteMessage)
                )}
                <div className={`relative max-w-xl px-4 py-2 text-gray-700 ${message.fromMemberID === currentMember?.orgUserId && "bg-gray-100"} rounded shadow`}>
                    <span className="block">
                        {chatType !== "members" && (
                            <span className="block mr-0 text-sm text-gray-600">
                                {personUtils.displayName(message.fromMember?.person)}
                            </span>
                        )}
                        {message.message}
                    </span>
                    <span className="block text-xs">
                        {formateEpochToShortMonthDate(Date.parse(message.createdAt) / 1000, true)}
                    </span>
                </div>
                {(!isCurrentUser && isHovered && (person.isAdmin || person.assignedAdmin)) && (
                    MessageContextMenu(message, handleDeleteMessage)
                )}
            </div>
        </li>
    );
};

export default MessageBubble;

const MessageContextMenu = (message, handleDeleteMessage) => {
    return <Menu as="div" className="mt-1 w-fit font-medium copy-week">
        {({ open }) => (
            <>
                <Menu.Button className="px-1 py-1 text-md mr-2 ml-1 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
                    <DotsHorizontalIcon className="flex-shrink-0 ml-1 h-5 w-5 text-gray-600 hover:text-gray-900 sm:block" aria-hidden="true" />
                </Menu.Button>
                <Transitions
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        static
                        className="absolute mt-2 w-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                    >
                        <div className="sm:block">
                            <Menu.Item>
                                <div className="block px-4 py-2 text-sm text-gray-700 cursor-pointer" onClick={(e) => handleDeleteMessage(message.id)}>
                                    <TrashIcon className="h-4 w-4 inline-block mr-1" aria-hidden="true" />
                                    <span className="text-xs">Delete Message</span>
                                </div>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transitions>
            </>
        )}
    </Menu>;
}
