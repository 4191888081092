import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { CalendarDataContext } from "ww-stores";
import EarlyClockInView from "./EarlyClockInView";
import NotClockedOutView from "./NotClockedOutView";
import NotificationTabs from "./NotificationTabs";

const NotificationModal = ({ rebuildCalendar, editShiftAction }) => {
	const { earlyClockInData, lateClockOutData, notificationModalOpen, setNotificationModalOpen } = useContext(CalendarDataContext);
	const [notificationTabs, setNotificationTabs] = useState([]);

	const handlerClose = (e, action) => {
		e.preventDefault();
		if (action === "close") {
			setNotificationModalOpen(false);
		}
	};

	useEffect(() => {
		let tabs = [];
		if (lateClockOutData) {
			tabs.push({
				name: "Missed Clock Out",
				view: <NotClockedOutView rebuildCalendar={rebuildCalendar} editShiftAction={editShiftAction} />,
				count: lateClockOutData.shiftsNotClockedOut.length
			});
		}
		if (earlyClockInData) {
			tabs.push({
				name: "Early Clock In",
				view: <EarlyClockInView rebuildCalendar={rebuildCalendar} editShiftAction={editShiftAction} />,
				count: earlyClockInData.shiftsClockedInEarly.length
			});
		}
		setNotificationTabs(tabs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [earlyClockInData, lateClockOutData]);

	return (
		<Transition.Root show={notificationModalOpen} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={notificationModalOpen} onClose={(e) => {}}>
				<div className="flex items-center justify-center h-full w-full p-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-110">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerClose(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3 px-2">
								<div className="justify-center gap-2">
									<NotificationTabs tabs={notificationTabs} />
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NotificationModal;
