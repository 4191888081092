import React from "react";
import HeaderMenu, { PrimaryLink } from "../components/headers/light";
import logoImage from "ww-images/workwall-logo.svg";

const posts = [
	{
		id: 1,
		title: "How Technology is Revolutionising Employee Management in the Hospitality Industry",
		href: "#",
		description: `Hello and welcome to my blog, where we'll be talking about how technology is transforming the way hospitality businesses manage their employees. Yes, that's right, we're going to be tackling the serious business of staff scheduling and management in the most Graham Norton way possible!

So, let's dive right in! The hospitality industry is a fast-paced and exciting world, but it can be challenging to manage employees in such an environment. Fortunately, technology is here to save the day. With employee scheduling software like shift planning tools, staff rostering software, and work scheduling software, businesses can create schedules based on employee availability and business needs. Gone are the days of manually creating schedules and dealing with conflicts - technology has got your back!

But it's not just about scheduling. Employee management systems and cloud-based payroll systems can help businesses streamline their HR tasks, saving managers time and reducing stress. That's right, you can finally say goodbye to those tedious paperwork days and hello to a more efficient and modern way of managing your staff.

What's more, technology can help improve communication, enhance employee engagement and retention, and create a positive workplace atmosphere. Employees can access schedules and communicate with their colleagues and managers through their smartphones apps designed to make everyone's life easier, resulting in the whole team being kept in the loop. Plus, businesses can use technology to support employee wellbeing, which is crucial for a happy and productive workforce.

Real-world examples of businesses using technology to manage their employees are aplenty. From small cafes to large hotels, many hospitality businesses are now embracing technology to improve their staff management. And the benefits speak for themselves - businesses report more efficient operations, happier employees, and increased profits.

In summary, technology is revolutionising the way hospitality businesses manage their employees, and it's time for you to jump on the bandwagon. With employee scheduling software, employee management systems, and cloud-based payroll systems, you can streamline your HR tasks, improve communication, and create a positive workplace atmosphere. So, what are you waiting for? Embrace the tech and see the benefits for yourself!`,
		imageUrl:
			"https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
		date: "Mar 8, 2023",
		datetime: "2020-03-16",
		category: { title: "Tech", href: "#" },
		author: {
			name: "Richard Goggin",
			role: "Founder",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
		}
	}
];

export default function Blogs({ scrollTo }) {
	return (
		<>
			<HeaderMenu roundedHeaderButton={PrimaryLink} scrollTo={scrollTo} />
			{posts.map((post) => (
				<article key={post.id} className="flex flex-col mx-4 items-center justify-between">
					<div className="relative w-full">
						<img
							src={post.imageUrl}
							alt="img-bar-restaurant-hospitality-tech"
							className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
						/>
						<div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
					</div>
					<div className="max-w-2xl">
						<div className="mt-8 flex items-center text-md">
							<time dateTime={post.datetime} className="text-gray-500">
								{post.date}
							</time>
							<span className="mx-8 text-gray-500 text-md">3 mins</span>

							<a
								href={post.category.href}
								className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
							>
								{post.category.title}
							</a>
						</div>
						<div className="group relative">
							<h1 className="mt-3 flex justify-center text-lg text-3xl font-semibold leading-9 text-gray-900 group-hover:text-gray-600">
								<a href={post.href}>
									<span className="flex justify-center " />
									{post.title}
								</a>
							</h1>
						</div>
						<div>
							<h4 className="mt-3">Hello and welcome to our blog!</h4>
							<p className="mt-3">
								Today we'll be talking about how technology is transforming the way hospitality businesses manage their employees.
								Yup, we all know how much of a pain it can be juggling requests and last minute cancelations for staff scheduling and
								management but we're going to try to show how tech can be a great friend in as easy and accessible a way as possible!
							</p>
							<p>So, let's dive right in!</p>
							<h2 className="mt-3 flex justify-start text-lg font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
								Scheduling Made Easier with Technology
							</h2>

							<p>
								{" "}
								The hospitality industry is a fast-paced and exciting world, but it can be challenging to manage employees in such a
								hectic environment, especially when staff are shouting requests at you at the peak of service and giving you last
								minute notice (and they love doing both!). Fortunately, technology is here to give you some respite and make your life
								a lot easier. With employee scheduling software like shift planning tools, staff rostering software, time-off requests
								and shift swapping systems, businesses can quickly and easily have all the necessary info built in to create schedules
								based on employee availability and business needs and make the weeks roster in minutes rather than hours. No more
								messaging staff to check availability and if certain days suit. Managers are removed from being the sole focal point
								for all of these issues and employees are given more responsibility when it comes to booking time-off and finding
								their own cover (only bothering managers for easy, final approval).
							</p>
							<h2 className="mt-3 flex justify-start text-lg font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
								Streamlining HR Tasks with Employee Management Systems
							</h2>
							<p>
								But it's not just about scheduling. Employee management systems and cloud-based payroll systems can help businesses
								streamline their HR tasks, saving managers time and reducing stress. Back and forths from employees to Human Resources
								and payroll can consume a lot of a managers time, that is probably much needed elsewhere. More interactive tech
								solutions can help reduce unnecessary communication and tedious paperwork, and replace it with a more efficient and
								modern way of managing your staff.
							</p>
							<h2 className="mt-3 flex justify-start text-lg font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
								Improving Communication and Employee Engagement
							</h2>
							<p>
								What's more, technology can help improve communication, enhance employee engagement and retention, and create a
								positive workplace atmosphere. Employees can access schedules and communicate with their colleagues and managers
								through a common platform that utilises intelligent software to make input and collabooration easier and keeps
								everyone in the loop. Effectively building you a virtual staffroom! Plus, businesses can use technology to support
								employee wellbeing & engagement, which is crucial for a happy and productive workforce. Companies that want to stay
								professional should have a more official means of communication than large or multiple WhatsApps groups. These can
								cause important messages to be lost in a plethra of staff chat, emojis and GIFs. Tailored hospitality messaging
								systems can help contact the right staff at the right time, based on when and where they are working, and not infringe
								on staff's right to disconect, unnecessarily bothering them when they don't have to be. Staff can enjoy their time off
								and will be happier and better rested as a result.
							</p>
							<h2 className="mt-3 flex justify-start text-lg font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
								Real-World Examples and Benefits of Using Technology
							</h2>
							<p>
								Real-world examples of businesses using technology to manage their employees are aplenty. From small cafes to large
								hotels, many hospitality businesses are now embracing technology to improve their staff management. And the benefits
								speak for themselves - businesses report more efficient operations, happier employees, reduced staff turnover, and
								increased profits. However, finding the right software for your business is a bit more difficult and can involve
								locking yourself into long contracts for products you're not sure will fit your business. Engage with the company
								before buying and talk through your own specific needs, many used for hospitality have actually been built for other
								sectors and by people that have never lived <i>the</i> life!
							</p>
							<h2 className="mt-3 flex justify-start text-lg font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
								Embrace Technology for Improved Hospitality Business Management
							</h2>
							<p>
								In summary, technology is revolutionising the way hospitality businesses manage their employees. Many think "things
								are fine the way they are" and "sure, it's always worked like this", but it's time for us to realise how much easier
								our lives can be with tech and jump on that wave. We embrace it whenever we can in every other aspect of our lives,
								why not in the hospitality sector? With employee scheduling software, employee management systems, and cloud-based
								payroll, messaging & document storage systems, you can streamline your HR tasks, improve communication, and create a
								positive workplace atmosphere. So, what are you waiting for? Embrace the tech and see the benefits for yourself!
							</p>
							<div className="mt-3 text-lg text-darkww-700 font-semibold group-hover:text-gray-600">
								The only software to deliver all these benefits in an easy to use app, designed specifically for the hospitality
								sector is{" "}
								<a href="/" className="text-lightww">
									WorkWall.
								</a>
							</div>
							<div className="mt-3 text-lg text-darkww-700 font-semibold group-hover:text-gray-600">
								Read more about{" "}
								<a href="/#features" className="text-lightww">
									how we can help
								</a>
								<span>, our </span>
								<a href="/#pricing" className="text-lightww">
									pricing
								</a>
								<span> or just sign up </span>
								<a href="/employee-manager-signin" className="text-lightww">
									here
								</a>
								<span>.</span>
							</div>
						</div>
						<div className="relative mt-8 flex items-center gap-x-4">
							<img src={logoImage} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
							<div className="text-sm leading-6">
								<p className="font-semibold text-gray-900">
									<a href={post.author.href}>
										<span className="absolute inset-0" />
										{post.author.name}
									</a>
								</p>
								<p className="text-gray-600">{post.author.role}</p>
							</div>
						</div>
					</div>
				</article>
			))}
		</>
	);
}
