import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	CheckBox,
	ComboBox,
	DayHourSegments,
	DayMinuteSegments,
	DaySegments,
	Form,
	LinkButton,
	Yup,
	notifyUser,
	secondsToHm,
	showFailure,
	showSuccess,
	useSimpleMessage
} from "ww-framework";
import { OrganisationContext, orgUtils, personUtils } from "ww-stores";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const shiftSchema = Yup.object().shape({
	clockInHour: Yup.string().required(),
	clockOutHour: Yup.string().required(),
	clockInMinute: Yup.string().required(),
	clockOutMinute: Yup.string().required()
});

const ClockInClockOutForm = ({ record, cancelCB, rebuildCalendar, activeDay }) => {
	const { organisation } = useContext(OrganisationContext);
	const { setMessage } = useSimpleMessage();
	const [shiftSaving, setShiftSaving] = useState(false);
	const [showBreak, setShowBreak] = useState(false);
	const [breakSet] = useState([
		{ id: 15, name: 15, description: "15 Mins" },
		{ id: 30, name: 30, description: "30 Mins" },
		{ id: 60, name: 60, description: "60 Mins" },
		{ id: "Set", name: "Set", description: "Set" }
	]);
	// const [breakValue, setBreakValue] = useState();
	const [showBreakTime, setShowBreakTime] = useState(record?.breakDuration === 0 && record?.breakStart && record?.breakEnd ? true : false);
	const initValues = {
		id: record?.id || "",
		clockInHour: secondsToHm((record?.clockIn || record?.shiftStart) - record?.baseDay, "hour") || "12",
		clockInMinute: secondsToHm((record?.clockIn || record?.shiftStart) - record?.baseDay, "minute") || "00",
		clockOutHour: record?.setAsClose === true ? "00" : secondsToHm((record?.clockOut || record?.shiftEnd) - record?.baseDay, "hour") || "20",
		clockOutMinute: secondsToHm((record?.clockOut || record?.shiftEnd) - record?.baseDay, "minute") || "00",
		hasBreak: record?.hasBreak || false, 
		breakDuration: record?.breakDuration > 0 ? record?.breakDuration : record?.breakStart && record?.breakEnd ? "Set" : 0,
		breakstart: record?.hasBreak > 0 && record?.breakStart - record?.baseDay > 0 ? record?.breakStart - record?.baseDay : "",
		breakend: record?.hasBreak > 0 && record?.breakStart - record?.baseDay > 0 ? record?.breakEnd - record?.baseDay : ""
	};
	const breakList = ({ selected, active, option: person }) => {

		return (
			<div onClick={() => updateBreak(person.id)}>
				<div className="flex items-center">
					<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{person.name}</span>
				</div>

				{selected ? (
					<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center px-4")}>
						{/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
					</span>
				) : null}
			</div>
		);
	};
	useEffect(() => {
		setShowBreak(initValues.hasBreak);
	}, [initValues.hasBreak]);

	const updateBreak = (value) => {
		setShowBreakTime(false);
		if (value === "Set") {
			setShowBreakTime(true);
		}
		// setBreakValue(value);
	};

	return (
		<>
			<Form
				validationSchema={shiftSchema}
				enableReinitialize={true}
				disabled={shiftSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						setShiftSaving(true);
						const shiftDetails = {
							...data,
							...record
						};
						await orgUtils.addCheckinCheckouttoShift(shiftDetails);
						setShiftSaving(false);
						resetForm();
						cancelCB();
						await notifyUser(
							[organisation.owner],
							`${personUtils.displayName(record?.member)} Just added Clock In/Out check and approve !`
						);
						rebuildCalendar(activeDay.date);
						setMessage(
							showSuccess({
								title: "Clock In Clock Out Requested to Manager."
							})
						);
					} catch (error) {
						setShiftSaving(false);
						setMessage(
							showFailure({
								title: "Unable to request Clock In Clock Out.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-fit flex flex-col">
					<div className="mt-5">
						<div className="mt-2 pl-2 with-full flex justify-end">
							<CheckBox
								name="clockInHasBreak"
								label="Include break"
								labelTextColour="text-gray-600"
								onChange={() => setShowBreak(!showBreak)}
								checked={record?.hasBreak ?? false}
							/>
						</div>
						<label className="block text-sm font-medium text-darkww-700">Clock In</label>
						<div className="flex justify-center">
							<ComboBox
								name="clockInHour"
								labelTextColour="text-gray-700 xl:text-darkww-700"
								options={DayHourSegments}
								initValue={initValues.clockInHour}
							/>
							<div className="text-gray-700 xl:text-darkww-700 px-4 self-center">:</div>
							<ComboBox
								name="clockInMinute"
								labelTextColour="text-gray-700 xl:text-darkww-700"
								options={DayMinuteSegments}
								initValue={initValues.clockInMinute}
							/>
						</div>
					</div>
					<div className="mt-5">
						<label className="block text-sm font-medium text-darkww-700">Clock Out</label>
						<div className="flex justify-center">
							<ComboBox
								name="clockOutHour"
								labelTextColour="text-gray-700 xl:text-darkww-700"
								options={DayHourSegments}
								initValue={initValues.clockOutHour}
							/>

							<div className="text-gray-700 xl:text-darkww-700 px-4 self-center">:</div>
							<ComboBox
								name="clockOutMinute"
								labelTextColour="text-gray-700 xl:text-darkww-700"
								options={DayMinuteSegments}
								initValue={initValues.clockOutMinute}
							/>
						</div>
					</div>
					{showBreak && (
						<>
							<div className="flex justify-center mt-5">
								<ComboBox
									label="Break"
									name="clockInBreakDuration"
									labelTextColour="text-gray-700 xl:text-darkww-700"
									options={breakSet}
									ComboBoxOption={breakList}
									initValue={initValues.breakDuration}
								/>
							</div>
							{showBreakTime && (
								<div className="flex justify-center">
									{/* <div className="w-32"> */}
									<ComboBox
										label="Start"
										name="clockInBreakstart"
										labelTextColour="text-gray-700 xl:text-darkww-700"
										options={DaySegments}
										initValue={initValues.breakstart}
									/>
									{/* </div> */}
									<div className="text-gray-700 xl:text-darkww-700 px-4 self-center">to</div>
									{/* <div className="w-32"> */}
									<ComboBox
										label="End"
										name="clockInBreakend"
										labelTextColour="text-gray-700 xl:text-darkww-700"
										options={DaySegments}
										initValue={initValues.breakend}
									/>
									{/* </div> */}
								</div>
							)}
						</>
					)}
					<div className="flex justify-end gap-5 mt-2">
						<LinkButton label="Cancel" className="font-semibold hover:text-gray-400" onClick={cancelCB} />
						<Button type="submit" label="Save" disabled={shiftSaving} />
					</div>
				</div>
			</Form>
		</>
	);
};

export default ClockInClockOutForm;
