import { API, graphqlOperation } from "aws-amplify";
import { createStock, updateStock, deleteStock } from "../../graphql/mutations.js";
import { getStockByOrg } from "../../graphql/queries.js";

export const getOrganisationStock = async (organisationID) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID: organisationID,
			limit: 1000
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getStockByOrg, queryParams));
			const { getStockByOrg: legacyStocks } = data;
			resolve(legacyStocks);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveStock = async (stockDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			let stockMapped = {
				id: stockDetails.id || null,
				item: stockDetails.item,
				stockLavel: stockDetails?.stockLavel,
				organisationID: stockDetails.organisationID,
				fromMemberID: stockDetails.fromMemberID
			};

			const mutation = stockDetails.id ? updateStock : createStock;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: stockMapped }
			});
			const savedStock = data[stockDetails.id ? updateStock : createStock];

			const updatedStock = { ...stockDetails, ...savedStock };
			resolve(updatedStock);
		} catch (error) {
			reject(error);
		}
	});
};
export const deleteStockById = async (stockId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteStock,
				variables: { input: { id: stockId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
