import { API, graphqlOperation } from "aws-amplify";
import {
	createNotice,
	updateNotice,
	deleteNotice,
	createNoticeComments,
	updateNoticeComments,
	deleteNoticeComments
} from "../../graphql/mutations.js";
import { listNotices } from "../../graphql/queries.js";

export const getNoticesByOrganisation = async (organisationID) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			limit: 1000,
			filter: { organisationID: { eq: organisationID } }
		};
		try {
			const { data } = await API.graphql(graphqlOperation(listNotices, queryParams));
			const { listNotices: legacyNotice } = data;
			resolve(legacyNotice);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveNoitice = async (noticeData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let roleMapped = {
				id: noticeData.id || null,
				title: noticeData.title,
				organisationID: noticeData.organisationID,
				description: noticeData.description,
				memberID: noticeData.memberID
			};

			const mutation = noticeData.id ? updateNotice : createNotice;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: roleMapped }
			});
			const savedNode = data[noticeData.id ? updateNotice : createNotice];
			const updatedNotice = { ...noticeData, ...savedNode };
			resolve(updatedNotice);
		} catch (error) {
			reject(error);
		}
	});
};
export const deleteNoticeById = async (noticeId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteNotice,
				variables: { input: { id: noticeId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const deleteNoticeCommentById = async (commentId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteNoticeComments,
				variables: { input: { id: commentId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const addCommentToNotice = async (commentData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let commentMapped = {
				id: commentData.id || null,
				comment: commentData.comment,
				noticeId: commentData.noticeId,
				memberID: commentData.memberID,
				readBy: commentData?.readBy ?? []
			};

			const mutation = commentData.id ? updateNoticeComments : createNoticeComments;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: commentMapped }
			});
			const savedNode = data[commentData.id ? updateNoticeComments : createNoticeComments];
			const updatedNotice = { ...commentData, ...savedNode };
			resolve(updatedNotice);
		} catch (error) {
			reject(error);
		}
	});
};

export const readComment = async (id, memberIDs) => {
	return new Promise(async (resolve, reject) => {
		try {
			let commentMapped = {
				id: id || null,
				readBy: memberIDs ?? []
			};

			const { data } = await API.graphql({
				query: updateNoticeComments,
				variables: { input: commentMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
