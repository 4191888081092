export const cancelSubscription = async (subId) => {
	return new Promise(async (resolve, reject) => {
		try {
			var header = new Headers();
			header.append("Content-Type", "application/json; charset=utf-8");
			var raw = JSON.stringify({
				sub: subId
			});
			console.log(raw);
			var requestOptions = {
				method: "POST",
				headers: header,
				body: raw,
				redirect: "follow"
			};
			await fetch("https://h5i94xtm19.execute-api.eu-west-1.amazonaws.com/master/cancel-subscription", requestOptions)
				.then((response) => response.text())
				.then((result) => resolve({ result }))
				.catch((error) => reject(error));
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};
export const updateSubscription = async (subId, quantity) => {
	return new Promise(async (resolve, reject) => {
		try {
			var header = new Headers();
			header.append("Content-Type", "application/json; charset=utf-8");
			var raw = JSON.stringify({
				sub: subId,
				quantity: quantity
			});
			var requestOptions = {
				method: "POST",
				headers: header,
				body: raw,
				redirect: "follow"
			};
			await fetch("https://h5i94xtm19.execute-api.eu-west-1.amazonaws.com/master/update-subscription", requestOptions)
				.then((response) => response.text())
				.then((result) => resolve({ result }))
				.catch((error) => reject(error));
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};
export const createSubscriptionId = async (params) => {
	const { orgId, quantity, successUrl, cancelUrl } = params;
	return new Promise(async (resolve, reject) => {
		try {
			let header = new Headers();
			header.append("Content-Type", "application/json; charset=utf-8");
			let raw = JSON.stringify({
				orgId: orgId,
				quantity: quantity,
				cancelUrl: cancelUrl,
				successUrl: successUrl
			});
			let requestOptions = {
				method: "POST",
				headers: header,
				body: raw,
				redirect: "follow"
			};
			await fetch("https://h5i94xtm19.execute-api.eu-west-1.amazonaws.com/master/create-checkout-session", requestOptions)
				.then((response) => response.text())
				.then((result) => resolve({ result }))
				.catch((error) => reject(error));
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};
