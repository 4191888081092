import React, { useContext } from "react";
import { HexColorPicker } from "ww-framework";
import { LabelColourContext } from "../Roles";

const RoleColourPicker = () => {
	const { colour, setColour } = useContext(LabelColourContext);

	return (
		<div className="pt-4 p-10 flex flex-col">
			<div className="w-64 text-center">
				<p className="max-w-prose mx-auto text-xl text-gray-500">Choose your label colour</p>
			</div>
			<div className="mt-6 w-64 flex justify-center">
				<HexColorPicker color={colour} onChange={(value) => setColour(value)} />
			</div>
		</div>
	);
};

export default RoleColourPicker;
