import logoImage from "ww-images/workwall-logo.svg";
export const CheckingAuth = () => {
	return (
		<>
			<div className="min-h-full pt-24 pb-12 flex flex-col bg-white">
				<main className="animate-pulse flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
					<div className="flex-shrink-0 flex justify-center">
						<a href="/dashboard" className="inline-flex">
							<span className="sr-only">Workwall</span>
							<img className="h-24 lg:h-48 w-auto" src={logoImage} alt="" />
						</a>
					</div>
					<div className="py-8">
						<div className="text-center">
							<p className="text-sm font-semibold text-darkww-600 uppercase tracking-wide">Hold tight</p>
							<h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Loading...</h1>
							<p className="mt-2 text-base text-gray-500">Checking authentication. One moment please.</p>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};
