import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export const DialogFrame = ({ open, handlerModal, title, children }) => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform flex flex-col rounded-lg w-fit shadow-lg">
							<div className="bg-gray-200 w-full px-3 py-1 flex items-center justify-between rounded-t-lg border border-gray-300">
								<span className="text-sm font-semibold text-gray-800">{title}</span>
								<span className="cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
									<svg className="w-4 h-4 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
							</div>
							<div className="w-full flex flex-row px-1 py-2">
								<div className="flex justify-center gap-2 pt-1">{children}</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
