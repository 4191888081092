import React, { useContext, useState, useEffect, Fragment } from "react";
import { Form, InputField, TextArea, Button, Yup, useSimpleMessage, showFailure, showSuccess } from "ww-framework";
import { orgUtils, OrganisationContext, PersonContext } from "ww-stores";
import { Dialog, Transition } from "@headlessui/react";

const noticeSchema = Yup.object().shape({
	title: Yup.string().required().min(2),
	description: Yup.string().required().min(10)
});
const NoticeForm = ({ handlerModal, notice, currentMember, organisation, getAllOrgNotices }) => {
	const { setMessage } = useSimpleMessage();
	const [noticeSaving, setNoticeSaving] = useState(false);
	return (
		<>
			<Form
				validationSchema={noticeSchema}
				enableReinitialize={true}
				disabled={noticeSaving}
				initialValues={notice}
				onSubmit={async (data, { resetForm }) => {
					try {
						setNoticeSaving(true);

						const noticeData = {
							...data,
							organisationID: organisation.id,
							memberID: currentMember.orgUserId
						};

						await orgUtils.saveNotice(noticeData);
						setNoticeSaving(false);
						resetForm();
						handlerModal("", false);
						await getAllOrgNotices();
						setMessage(
							showSuccess({
								title: "Notice saved successfully."
							})
						);
					} catch (error) {
						setNoticeSaving(false);
						setMessage(
							showFailure({
								title: "Unable to save Notice.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-full">
					<div className="mt-5">
						<InputField label="Notice Title" name="title" />
					</div>
					<div className="mt-2">
						<TextArea label="Notice Description" rows={8} name="description" />
					</div>
					<div className="w-full mt-1">
						<Button type="submit" label="Save" disabled={noticeSaving} />
					</div>
				</div>
			</Form>
		</>
	);
};
const AddNotice = ({ record, open, handlerModal, getAllOrgNotices }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [currentMember, serCurrentMember] = useState({});
	const [notice, setNotice] = useState({ id: "", title: "", description: "" });
	useEffect(() => {
		serCurrentMember(organisation?.members?.find((k) => k.person === person?.person));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [person, organisation]);
	useEffect(() => {
		if (record) setNotice(record);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record?.id]);
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-96">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3 px-2">
								<div className="justify-center gap-2">
									<NoticeForm
										currentMember={currentMember}
										organisation={organisation}
										handlerModal={handlerModal}
										getAllOrgNotices={getAllOrgNotices}
										notice={notice}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default AddNotice;
