import { useEffect, useContext, useState } from "react";
import { fillWeek, arrayDiff } from "ww-framework";
import { orgUtils, PersonContext } from "ww-stores";
import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/outline";

export const MiniCalendar = ({ updateDay, showMyShiftsIndicator = false }) => {
	const { person } = useContext(PersonContext);
	const [activeDate, setActiveDate] = useState(new Date(/*`2022-01-31` Just for testing/replicating different days*/));
	const [currentDay, setCurrentDay] = useState();
	const [weekDays, setWeekDays] = useState([]);

	const firstDayOfWeek = weekDays[0];
	const changeCalendar = (direction) => {
		const daysToMove = direction === "DOWN" ? -7 : 7;
		const newDate = new Date(firstDayOfWeek.date);
		newDate.setDate(firstDayOfWeek?.date?.getDate() + daysToMove);

		setActiveDate(newDate); // This will trigger a new calendar in the useEffect hook.
	};
	const buildCalendar = (isAdmin) => {
		async function loadCalendar(isAdmin) {
			try {
				if (activeDate) {
					const newCalendarDays = fillWeek(activeDate, person?.currentOrganisation?.startDay);

					const {
						first: { baseEpoch: startDate },
						last: { baseEpoch: endDate },
						days
					} = newCalendarDays;

					let shifts = await orgUtils.getShifts(person?.currentOrganisation?.id, startDate, endDate, isAdmin);

					if (!person?.isAdmin) {
						const member = person.currentOrganisation?.members?.find((k) => k?.person === person?.person);
						shifts = shifts.filter((s) =>
							s.member?.departmentID !== null && member?.departmentIDs.length > 0
								? arrayDiff(s.member?.departmentID || [], member?.departmentIDs)
								: s?.memberID === member?.orgUserId
						);
					}

					// Merge the shifts found for the given date range to the mapped days for the same range.
					const daysWithShifts = days.map((calDay) => {
						const { baseEpoch } = calDay;
						const shiftsFound = shifts.filter((shift) => shift.baseDay === baseEpoch);
						return { ...calDay, ...{ shifts: shiftsFound } };
					});

					// Set the activeDate, if not set OR if current activeDate is not in the new list of days.
					const findActiveDate = daysWithShifts.find((day) => {
						return day.formatted === new Intl.DateTimeFormat().format(activeDate);
					});

					if (findActiveDate) {
						setCurrentDay(findActiveDate);
						updateDay(findActiveDate);
					}

					setWeekDays(daysWithShifts);
				}
			} catch (err) {
				console.error(err);
			}
		}
		setTimeout(() => loadCalendar(isAdmin), 100); // Delay to avoid jumpyness.
	};

	useEffect(() => {
		if (person?.currentOrganisation.id) buildCalendar(person?.isAdmin);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDate, person?.currentOrganisation.id]);

	return (
		<div className="grid grid-cols-9 gap-1 sm:gap-3 mx-auto w-full mt-2 sm:max-w-2xl">
			<div className="flex flex-col">
				<div className="h-6"></div>
				<div className="w-10 sm:w-16 h-10 sm:h-16 rounded-md sm:rounded-lg col-span-1 bg-pink-600 hover:bg-pink-800 hover:pink-800 p-1 sm:p-2">
					<button type="button" onClick={() => changeCalendar("DOWN")}>
						<ArrowSmLeftIcon className="h-8 sm:h-12 w-8 sm:w-12 text-white" aria-hidden="true" />
					</button>
				</div>
			</div>
			{weekDays.map((day, index) => {
				const myShifts = day.shifts.filter((shift) => shift.member?.personID === person.id);
				const isCurrentDay = currentDay.baseEpoch === day.baseEpoch;
				return (
					<button type="button" key={index} onClick={() => setActiveDate(day.date)}>
						<div className="flex flex-col">
							<div className="h-6 flex justify-center items-center">
								<div
									className={`rounded-full h-2 sm:h-3 w-2 sm:w-3 ${myShifts.length > 0 ? "bg-green-500" : "bg-gray-400"} ${
										showMyShiftsIndicator ? "block" : "hidden"
									}`}
								></div>
							</div>
							<div
								className={`w-10 sm:w-16 h-10 sm:h-16 border-2 sm:border-4 rounded-md sm:rounded-lg col-span-1 ${
									isCurrentDay ? "border-darkww-700 bg-darkww-700" : "border-gray-700 bg-white hover:bg-sky-200"
								}`}
							>
								<div
									className={`${
										isCurrentDay ? "text-white" : "text-gray-600"
									} font-semibold text-md sm:text-3xl flex justify-center -mt-1`}
								>
									{day.dayDesc?.slice(0, 2).toUpperCase() || ""}
								</div>
								<div
									className={`${
										isCurrentDay ? "text-lightww-300" : "text-darkww-600"
									} font-bold text-md sm:text-3xl flex justify-center -mt-2`}
								>
									{day.day?.padStart(2, "0")}
								</div>
							</div>
						</div>
					</button>
				);
			})}
			<div className="flex flex-col">
				<div className="h-6"></div>
				<div className="w-10 sm:w-16 h-10 sm:h-16 rounded-md sm:rounded-lg col-span-1 bg-pink-600 hover:bg-pink-800 p-1 sm:p-2">
					<button type="button" onClick={() => changeCalendar("UP")}>
						<ArrowSmRightIcon className="h-8 sm:h-12 w-8 sm:w-12 text-white" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	);
};
