import React, { useContext, useState, useEffect, Fragment } from "react";
import { Form, InputField, Button, Yup, useSimpleMessage, showFailure, showSuccess, formateEpochToShortMonthDate } from "ww-framework";
import { orgUtils, OrganisationContext, PersonContext, personUtils } from "ww-stores";
import { Dialog, Transition } from "@headlessui/react";

const noticeCommentSchema = Yup.object().shape({
	comment: Yup.string().required().min(2)
});
const NoticeForm = ({ handlerModal, notice, currentMember, organisation, getAllOrgNotices }) => {
	const { setMessage } = useSimpleMessage();
	const [noticeSaving, setNoticeSaving] = useState(false);
	return (
		<>
			<Form
				validationSchema={noticeCommentSchema}
				enableReinitialize={true}
				disabled={noticeSaving}
				initialValues={{ comment: "" }}
				onSubmit={async (data, { resetForm }) => {
					try {
						setNoticeSaving(true);

						const noticeData = {
							...data,
							memberID: currentMember.orgUserId,
							noticeId: notice.id,
							readBy: [currentMember?.orgUserId]
						};

						await orgUtils.addCommentOnNotice(noticeData);
						await getAllOrgNotices();
						setNoticeSaving(false);
						resetForm();
						handlerModal("", false);
						setMessage(
							showSuccess({
								title: "Notice saved successfully."
							})
						);
					} catch (error) {
						setNoticeSaving(false);
						console.log(error);
						setMessage(
							showFailure({
								title: "Unable to save Comments.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-full">
					<div className="mt-5">
						<InputField label="Your Comment" name="comment" />
					</div>
					{/* <div className="mt-2">
                        <TextArea label="Notice Description" rows={8} name="description" />
                    </div> */}
					<div className="w-full mt-1">
						<Button type="submit" label="Save" disabled={noticeSaving} />
					</div>
				</div>
			</Form>
		</>
	);
};
const AddNoticeComments = ({ record, open, handlerModal, getAllOrgNotices, MemberAvatar }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [currentMember, serCurrentMember] = useState({});
	const [comment, setComment] = useState([]);
	const [unreadComment, setUnreadComment] = useState([]);
	const [notice, setNotice] = useState({ id: "", title: "", description: "" });
	useEffect(() => {
		serCurrentMember(organisation?.members?.find((k) => k.person === person?.person));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [person, organisation]);
	useEffect(() => {
		if (record) {
			setNotice(record);
			let comments = record?.comment?.items ?? [];
			setComment(comments);
			setUnreadComment(comments.filter((c) => (Array.isArray(c.readBy) ? !c.readBy.includes(currentMember?.orgUserId) : comments)));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record.id]);
	useEffect(() => {
		if (unreadComment.length > 0) {
			Promise.all(
				unreadComment.map(async (m) => {
					let memberIDs = [];
					if (m.readBy) memberIDs?.push(...m.readBy);
					memberIDs.push(currentMember?.orgUserId);
					let noticeData = {
						id: m.id,
						memberID: m.memberID,
						noticeId: m.noticeId,
						readBy: memberIDs,
						comment: m.comment
					};
					await orgUtils.addCommentOnNotice(noticeData);
				})
			).then(getAllOrgNotices());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unreadComment]);
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit sm:w-6/12 w-full">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full py-3 px-2">
								<div className="justify-center gap-2">
									<div className="pr-4 flex flex-col">
										<div className="container px-0 mx-auto sm:px-5">
											<div>
												<h4 className="text-gray-800 dark:text-gray-100 font-bold mb-3">{notice?.title}</h4>
												<p className="text-gray-800 dark:text-gray-100 text-sm mb-4"> {notice?.description}</p>
											</div>
											<div className="overflow-y-auto	max-h-40 h-auto">
												{comment.length > 0 &&
													comment.map((c) => {
														let memberDrived = organisation.members?.find((k) => k.id === c.member?.personID);
														return (
															<div
																className="flex-col w-full py-4 mx-auto bg-white border-b-2 border-r-2 border-gray-200 sm:py-1 sm:rounded-lg sm:shadow-sm"
																key={c.id}
															>
																<div className="flex flex-row">
																	<MemberAvatar member={memberDrived} />
																	<div className="flex-col mt-1">
																		<div className="flex items-center flex-1 px-4 font-bold leading-tight">
																			{personUtils.displayName(memberDrived)}
																			<span className="ml-2 text-xs font-normal text-gray-500">
																				{formateEpochToShortMonthDate(Date.parse(c.createdAt) / 1000, true)}
																			</span>
																		</div>
																		<div className="flex-1 px-2 ml-2 text-sm font-medium leading-loose text-gray-600">
																			{c.comment}
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
											</div>
										</div>
									</div>
									<NoticeForm
										currentMember={currentMember}
										organisation={organisation}
										handlerModal={handlerModal}
										getAllOrgNotices={getAllOrgNotices}
										notice={notice}
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default AddNoticeComments;
