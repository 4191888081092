import React, { useState } from "react";
import HeaderMenu, { PrimaryLink } from "../components/headers/light";
import logoImage from "ww-images/workwall-logo.svg";
import barClosedImage from "ww-images/bar-closed-2024.png";
import sustainableHospitalityImage from "ww-images/sustainible-hospitality.png";

const posts = [
	{
		id: 1,
		title: "How Technology is Revolutionising Employee Management in the Hospitality Industry",
		href: "/How-Technology-is-Revolutionising-Employee-Management-in-the-Hospitality-Industry",
		description: `Hello and welcome to my blog, where we'll be talking about how technology is transforming the way hospitality businesses manage their employees. Yes, that's right, we're going to be tackling the serious business of staff scheduling and management in the most Graham Norton way possible!

So, let's dive right in! The hospitality industry is a fast-paced and exciting world, but it can be challenging to manage employees in such an environment. Fortunately, technology is here to save the day. With employee scheduling software like shift planning tools, staff rostering software, and work scheduling software, businesses can create schedules based on employee availability and business needs. Gone are the days of manually creating schedules and dealing with conflicts - technology has got your back!`,

		imageUrl:
			"https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
		date: "Mar 8, 2023",
		datetime: "2020-03-16",
		category: { title: "Tech", href: "#" },
		author: {
			name: "Richard Goggin",
			role: "Founder",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
		}
	},
	{
		id: 2,
		title: "The importance of staff retention in hospitality and retail.",
		href: "/the-hidden-costs-of-staff-turnover-and-top-3-ways-to-prevent-it",
		description: `Today, I think we are all very aware of how the hospitality & retail industries grapple with an escalating challenge of employee turnover. We see it with some of our favourite venues only able to open 4 out of 7 days a week due to staff shortages or new intersting businesses having to postpone opening for weeks to months as they can't find enough staff to open. Where we though the aftermath of Covid we would see things improve we have actually seen this issue has only intensified, becoming increasingly costly and complex. We can see a true insight into exactly how much this can impact a business's resources in one of the only extensive studies done in the area, from Cornell University's Hospitality Research Center over 20 years ago. In this, they uncover a startling average cost of US$5,864 per replaced front of house employee. In the current uncertain and competitive climate, a financial toll of this magnitude is clearly unsustainable, necessitating a critical re-evaluation and strengthening of employee retention strategies. This is especially true when you factor in an average turnover rate of 30% in both hospitality and retail (which more than doubles when you look at the States). Something I've seen to be very apparent the more owners and managers I talk to.`,
		imageUrl:
			"https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHdvcmslMjBwZW9wbGUlMjBjYWZlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
		date: "May 17, 2023",
		datetime: "2022-05-17",
		category: { title: "Employee Well-being", href: "#" },
		author: {
			name: "Richard Goggin",
			role: "Founder",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
		}
	},
	{
		id: 3,
		title: "Innovations for a very challenging 2024 in hospitality & retail.",
		href: "/getting-ready-for-a-challenging-2024-in-hospitality-retail", // Update this with the correct path
		description: `The hospitality and retail sectors are at a pivotal point in 2024, facing unique challenges and opportunities. The integration of technology in these sectors has become more than a trend; it's a necessity for sustainable growth. From streamlining operations to enhancing customer experiences, technology is playing a crucial role in reshaping these industries. In this article, we explore how businesses can leverage technology to overcome current challenges, reduce costs, and improve overall efficiency, ensuring a thriving future in the competitive market.`,
		imageUrl: barClosedImage,
		date: "Jan 7, 2024",
		datetime: "2024-01-07", // Use the correct date format
		category: { title: "Management", href: "#" },
		author: {
			name: "Richard Goggin",
			role: "Founder",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
		}
	},
	{
		id: 4,
		title: "Financial Aid and Grants Guide for Irish Hospitality Venues",
		href: "/Financial-Aid-and-Grants-Guide-for-Irish-Hospitality-Venues",
		description: `The hospitality industry in Ireland faces unprecedented challenges in the wake of the COVID-19 pandemic, increased VAT rates, and rising costs across wages, energy, and food supplies. This guide aims to assist bars and restaurants in navigating and securing financial aid and grants to alleviate these pressures, focusing on sustainability, energy efficiency, and online trading enhancements.`,
		imageUrl: sustainableHospitalityImage,
		date: "Apr 2, 2024",
		datetime: "2024-4-2",
		category: { title: "Finance", href: "#" },
		author: {
			name: "Richard Goggin",
			role: "Founder",
			href: "#",
			imageUrl: logoImage
		}
	}
];

export default function Blogs({ scrollTo }) {
	return (
		<>
			<HeaderMenu roundedHeaderButton={PrimaryLink} scrollTo={scrollTo} />

			<div className="bg-white py-14 sm:py-32">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<div className="mx-auto max-w-2xl text-center">
						<div className="flex flex-row mb-6 justify-center gap-16">
							<img className="h-40 w-auto sm:h-10" src={logoImage} alt="Workwall logo" />

							<h2
								style={{ fontFamily: `Futura, "Trebuchet MS", Arial, sans-serif`, color: `#626666` }}
								className="text-2xl underline font-extrabold lg:text-4xl"
							>
								The WorkWall Blog
							</h2>
							<img className="h-40 w-auto sm:h-10" src={logoImage} alt="Workwall logo" />
						</div>
						<p className="mt-2 text-lg leading-8 text-gray-600">
							Any help or insights we can give to help better manage your <b>employees</b> & <b>business</b> in all <b>retail</b> or{" "}
							<b>hospitality (restaurants, bars, hotels, cafes, & more)</b> organisations.
						</p>
					</div>
					<div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
						{posts.map((post) => (
							<article key={post.id} className="flex flex-col items-start justify-between">
								<a href={post.href}>
									<div className="relative w-full">
										<img
											src={post.imageUrl}
											alt=""
											className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
										/>
										<div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
									</div>
									<div className="max-w-xl">
										<div className="mt-8 flex items-center gap-x-4 text-xs">
											<time dateTime={post.datetime} className="text-gray-500">
												{post.date}
											</time>
											<a
												href={post.category.href}
												className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
											>
												{post.category.title}
											</a>
										</div>
										<div className="group relative">
											<h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
												<a href={post.href}>
													<span className="absolute inset-0" />
													{post.title}
												</a>
											</h3>
											<p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">{post.description}</p>
										</div>
										<div className="relative mt-8 flex items-center gap-x-4">
											<img src={logoImage} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
											<div className="text-sm leading-6">
												<p className="font-semibold text-gray-900">
													<a href={post.author.href}>
														<span className="absolute inset-0" />
														{post.author.name}
													</a>
												</p>
												<p className="text-gray-600">{post.author.role}</p>
											</div>
										</div>
									</div>
								</a>
							</article>
						))}
					</div>
				</div>
			</div>
		</>
	);
}
