import { DownloadIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, ConfirmModal, Form, InputField, Yup } from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils } from "ww-stores";
import { deleteFileById, deleteFileByIdFromStorage, getSignedUrl, saveFileToStorage } from "../../../files";

const fileUploadSchema = Yup.object().shape({
	fileName: Yup.string().required().min(3),
	startDate: Yup.date().required("Date is required.")
});

const StaffDocuments = ({ currentMemberDetail, userProfile, upsertToEmployee }) => {
	const { organisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);
	const { members } = organisation;
	const [files, setFiles] = useState([]);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [employeeFile, setEmployeeFile] = useState(null);
	const [activeFolder, setActiveFolder] = useState(null);
	const [openDownloadConfirm, setOpenDownloadConfirm] = useState({ flag: false, id: null });
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState({ flag: false, id: null });

	const memberDetails = members.find((orgMember) => orgMember.orgUserId === currentMemberDetail.orgUserId);
	console.log("Org user ID", memberDetails, currentMemberDetail);
	useEffect(() => {
		async function getEmployeeFiles() {
			// Filter the correct organisation's details using currentOrganisation
			const currentOrgDetails = userProfile.organisations.items.find((org) => org.organisationID === loggedInPerson.currentOrganisation.id);

			if (currentOrgDetails) {
				setEmployeeFile(currentOrgDetails.employeeFile);
			} else {
				console.error("Current organisation details not found for the user.");
			}
		}

		getEmployeeFiles();
		getMemberFolder();
	}, []);

	async function getMemberFolder() {
		const folderExist = await orgUtils.getStoredFolderByFilter(organisation.id, { name: { eq: memberDetails.person } });
		if (folderExist.items.length === 0) {
			const folder = await orgUtils.storeFolder({
				name: memberDetails.person,
				organisationID: organisation.id
			});
			setActiveFolder(folder);
			setFiles(folder.files.items);
		} else {
			setActiveFolder(folderExist?.items[0]);
			setFiles(folderExist?.items[0]?.files?.items);
		}
	}

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		openModal(file);
	};

	const submitFileUpload = async (data) => {
		if (!selectedFile) {
			console.error("No file selected");
			return;
		}
		try {
			const fileId = uuidv4();
			const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."));
			const filePath = `${memberDetails.id}/${activeFolder.id}/${data.fileName}${ext}`;
			const fileURI = await saveFileToStorage(selectedFile, filePath);
			const fileDetail = {
				id: fileId,
				folderId: activeFolder.id,
				name: `${data.fileName}${ext}`,
				organisationID: organisation.id,
				type: selectedFile.type,
				size: selectedFile.size,
				fileURI: fileURI,
				createdAt: new Date()
				//signedURL: signedURL,
				//employeeFile: memberDetails.id
			};
			const result = await orgUtils.storeFile(fileDetail);
			const resultUpsert = await upsertToEmployee({ result: result, startDate: data.startDate }, { ...memberDetails, employeeFile }, "File");
			setEmployeeFile(resultUpsert);
			getMemberFolder();
			setIsOpen(false);
		} catch (error) {
			console.error("Error during file upload:", error);
		}
	};
	const deleteEmployeeFile = async (id) => {
		try {
			const getFileData = files.find((o) => o.id === id);
			const memberFiles = employeeFile.filter((f, index) => {
				let file = JSON.parse(f);
				return file.id !== id;
			});
			await orgUtils.addFileToMember(memberFiles, memberDetails.orgUserId, organisation.id);
			await deleteFileById(id);
			await deleteFileByIdFromStorage(getFileData.fileURI);
			setEmployeeFile(memberFiles);
			getMemberFolder();
		} catch (err) {
			console.log("error in deleteFile", err);
		}
	};
	const handlerDownloadConfirmModal = async (e, type) => {
		e.preventDefault();

		if (type === "confirm") {
			const getFileData = files.find((o) => o.id === openDownloadConfirm.id);
			let toRedirect = await getSignedUrl(getFileData.fileURI);
			window.open(toRedirect, "_blank", "noopener,noreferrer");
		}
		setOpenDownloadConfirm({ flag: false, id: null });
	};

	const handlerDeleteConfirmModal = async (e, type) => {
		e.preventDefault();

		if (type === "confirm") {
			await deleteEmployeeFile(openDeleteConfirm.id);
		}
		setOpenDeleteConfirm({ flag: false, id: null });
	};

	const openModal = (file) => {
		setSelectedFile(file);
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	// const updateFileName = (index, newName) => {
	// 	setFiles((prevFiles) => prevFiles.map((file, i) => (i === index ? { ...file, name: newName } : file)));
	// };
	if (!userProfile) {
		return (
			<div className="flex justify-center items-center ">
				<div
					className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
					role="status"
				></div>
				{/* <div className="flex justify-center relative inline-block mr-4 mt-2">
					<div className="absolute top-full z-10">Overlay Text</div>
				</div> */}
			</div>
		);
	}
	return (
		<>
			<div className="flex justify-center relative inline-block mt-0">
				<div className="absolute top-full w-full px-2 text-left mt-1 z-10">
					<div className="py-1 w-full" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
						<input type="file" id="fileUpload" style={{ display: "none" }} onChange={handleFileUpload} />
						<ConfirmModal
							handlerModal={handlerDownloadConfirmModal}
							open={openDownloadConfirm?.flag}
							className="text-base font-medium text-gray-900"
							title={`Do you want to download this file ?`}
						/>
						<ConfirmModal
							handlerModal={handlerDeleteConfirmModal}
							open={openDeleteConfirm?.flag}
							className="text-base font-medium text-gray-900"
							title={`Do you want to delete this file ?`}
						/>
					</div>
				</div>
			</div>
			<div className="inline-block px-2 text-left mt-3.5 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ">
				<button
					className="flex items-center justify-center w-full text-left px-4 py-2 text-sm text-darkww-700 hover:bg-gray-100 hover:text-lightww-900"
					onClick={() => document.getElementById("fileUpload").click()}
					role="menuitem"
				>
					<DocumentAddIcon className="mr-2 h-6 w-6" aria-hidden="true" />
					Add Document
				</button>
			</div>

			<div className="mt-4 pb-6">
				<h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide pl-2">Files ({employeeFile?.length || 0})</h3>
				<div className="flex flex-col mt-2 sm:mt-3">
					<div className="overflow-x-auto sm:mx-0 lg:-mx-6">
						<div className="py-2 sm:py-4 align-middle inline-block min-w-full sm:px-8 md:px-6 lg:px-8">
							{employeeFile?.length > 0 && (
								<FilesList files={employeeFile} setOpenDownloadConfirm={setOpenDownloadConfirm} setOpenDeleteConfirm={setOpenDeleteConfirm} />
							)}
						</div>
					</div>
				</div>
			</div>

			{modalIsOpen && <FileUploadDialog selectedFile={selectedFile} closeModal={closeModal} submitFileUpload={submitFileUpload} />}
		</>
	);
};

const FilesList = ({ files, setOpenDownloadConfirm, setOpenDeleteConfirm }) => {
	return (
		<div className="shadow overflow-hidden divide-y divide-gray-200 border-b border-gray-200 w-full rounded-md sm:rounded-lg">
			{files.map((f, index) => {
				const file = JSON.parse(f);
				return (
					<FileComponent
						key={index}
						file={file}
						setOpenDownloadConfirm={setOpenDownloadConfirm}
						setOpenDeleteConfirm={setOpenDeleteConfirm}
					/>
				);
			})}
		</div>
	);
};

export const FileComponent = ({ file, setOpenDownloadConfirm, setOpenDeleteConfirm }) => {
	const getDaysLeft = (date) => {
		if (!date) return null;
		const expiryDate = new Date(date);
		const today = new Date();
		const diffTime = expiryDate - today;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); //make it whole number
		return diffDays;
	};
	const daysToExpiry = getDaysLeft(file.date);
	return (
		<div className="grid grid-cols-12 gap-6 pl-2 py-1">
			<div className="ml-1 sm:ml-0 py-1 mt-1 col-span-7 sm:col-span-7 flex flex-col">
				<span className="px-2 text-sm sm:text-md">
					<button
						style={{ textDecoration: "underline", color: "blue" }}
						onClick={() => setOpenDownloadConfirm({ flag: true, id: file.id })}
					>
						{file.name}
					</button>
				</span>
				<span className="px-2 text-xs sm:text-xs text-gray-500">Expiry Date: {new Date(file.date).toLocaleDateString()}</span>
			</div>
			<div className="col-span-2 flex justify-left items-center">
				{daysToExpiry && daysToExpiry <= 60 && (
					<span
						className={`ml-1 px-2 py-0.5 text-xs rounded ${
							daysToExpiry < 0 ? "bg-red-600" : daysToExpiry < 30 ? "bg-yellow-600" : "bg-green-600"
						} text-white`}
					>
						{daysToExpiry > 0 ? `${daysToExpiry} days left` : "expired"}
					</span>
				)}
			</div>
			<div className="col-span-1 px-2 lg:px-6 flex justify-center">
				<button
					className="focus:outline-none font-medium rounded-lg mr-2 text-sm py-0.5 px-1"
					onClick={() => setOpenDownloadConfirm({ flag: true, id: file.id })}
				>
					<DownloadIcon className="h-6 w-6 text-gray" />
				</button>
			</div>
			<div className="col-span-2 px-2 lg:px-6 text-sm font-medium flex justify-center items-center">
				<button
					className="focus:outline-none text-white bg-red-700 font-medium rounded-lg mr-2 text-sm py-0.5 px-1"
					onClick={() => setOpenDeleteConfirm({ flag: true, id: file.id })}
				>
					Delete
				</button>
			</div>
		</div>
	);
};

const FileUploadDialog = ({ selectedFile, closeModal, submitFileUpload }) => {
	const [formUploadSubmitDisabled, setFormUploadSubmitDisabled] = useState(false);
	return (
		<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-20">
			<div className="bg-white p-8 rounded">
				<Form
					validationSchema={fileUploadSchema}
					initialValues={{ startDate: "", fileName: selectedFile.name.substr(0, selectedFile.name.lastIndexOf(".")) }}
					enableReinitialize={true}
					disabled={formUploadSubmitDisabled}
					onSubmit={async (data, { resetForm }) => {
						try {
							setFormUploadSubmitDisabled(true);
							submitFileUpload(data);
						} catch (err) {
							setFormUploadSubmitDisabled(false);
						}
					}}
				>
					<div className="h-full flex pb-5 items-end col-span-9 sm:col-span-9 lg:col-span-3">
						<div className="w-full">
							<InputField label="File Name" name="fileName" />
						</div>
					</div>
					<div className="h-full flex pb-5 items-end col-span-9 sm:col-span-9 lg:col-span-3">
						<div className="w-full">
							<InputField type="date" label="Expiry Date" name="startDate" />
						</div>
					</div>
					<div className="h-full pb-6 flex items-end col-span-9 sm:col-span-9 lg:col-span-2">
						<div className="w-full">
							<Button type="submit" label="Save File" disabled={formUploadSubmitDisabled} />
						</div>
					</div>
					<div className="h-full pb-6 flex items-end col-span-9 sm:col-span-9 lg:col-span-2">
						<div className="w-full">
							<Button label="Close" onClick={closeModal} />
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default StaffDocuments;
