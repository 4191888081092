import { API, graphqlOperation } from "aws-amplify";
import { deletePunch as AWSDeletePunch, createPunch, updatePunch } from "../../graphql/mutations.js";
import { getPunchByDate } from "../../graphql/queries.js";

const filterRepeatedPunches = (punches) => {
	// check for punches with the same shiftID and only return the one with both in and out.
	// or the latest updated punch if there is no out
	return punches.reduce((acc, punch) => {
		let foundIndex = acc.findIndex((p) => p.shiftID === punch.shiftID);

		if (foundIndex !== -1) {
			let found = acc[foundIndex];

			if (punch.in && punch.out) {
				acc[foundIndex] = punch;
			} else if (!punch.in || !punch.out) {
				acc[foundIndex] = punch.updatedAt > found.updatedAt ? punch : found;
			}
		} else {
			acc.push(punch);
		}

		return acc;
	}, []);
};

export const getDayPunches = async (organisationID, date) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			limit: 1000,
			organisationID: organisationID,
			baseDay: { eq: date }
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getPunchByDate, queryParams));
			const { getPunchByDate: punches } = data;
			punches.items = punches.items.filter((punch) => punch.in);
			let filteredPunches = filterRepeatedPunches(punches.items);
			punches.items = filteredPunches;

			resolve(punches);
		} catch (error) {
			reject(error);
		}
	});
};

export const getDayPunchesBetweenDates = async (organisationID, start, end) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			limit: 1000,
			organisationID: organisationID,
			baseDay: { between: [start, end] }
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getPunchByDate, queryParams));
			const { getPunchByDate: punches } = data;
			punches.items = punches.items.filter((punch) => punch.in);
			let filteredPunches = filterRepeatedPunches(punches.items);
			punches.items = filteredPunches;

			resolve(punches);
		} catch (error) {
			reject(error);
		}
	});
};

export const savePunch = async (punchData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let punchMapped = {
				id: punchData.id || null,
				organisationID: punchData.organisationID,
				shiftID: punchData.shiftID,
				in: punchData.in,
				breaks: punchData?.breaks,
				out: punchData.out,
				baseDay: punchData.baseDay,
				imageReferences: punchData.imageReferences,
				status: punchData.status
			};

			const mutation = punchData.id ? updatePunch : createPunch;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: punchMapped }
			});
			const saved = punchData.id ? data?.updatePunch : data?.createPunch;
			resolve(saved);
		} catch (error) {
			reject(error);
		}
	});
};

export const deletePunch = async (punchId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: AWSDeletePunch,
				variables: { input: { id: punchId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
// export const deleteNoticeById = async (noticeId) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const { data } = await API.graphql({
// 				query: deleteNotice,
// 				variables: { input: { id: noticeId } }
// 			});
// 			resolve(data);
// 		} catch (error) {
// 			reject(error);
// 		}
// 	});
// };
