import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useState } from "react";
import { ConfirmModal, Menu, Transition as Transitions, arrayDiff, fillWeek, showSuccess, useSimpleMessage } from "ww-framework";
import { orgUtils } from "ww-stores";
import { getOtherLocationShifts } from "./Calendar/calendar_utils";
import { saveShift } from "./DaySummary";

const copyWeek = [
	{
		value: 1,
		label: "Last Week"
	},
	{
		value: 2,
		label: "2 Weeks Ago"
	},
	{
		value: 3,
		label: "3 Weeks Ago"
	},
	{
		value: 4,
		label: "4 Weeks Ago"
	}
];

const ShiftCopyButton = ({ organisation, buildCalendar, selectedDay, setIsLoading, person: loggedInPerson, filterByDepartment }) => {
	const [open, setOpen] = useState(false);
	const [openDeleteModel, setOpenDeleteModel] = useState(false);
	const [deleteRoster, setDeleteRoster] = useState();
	const [week, setWeek] = useState();
	const [template, setTemplate] = useState([]);
	const { setMessage } = useSimpleMessage();

	useEffect(() => {
		if (organisation.id) {
			templates(copyWeek);
			return () => {
				setTemplate(...copyWeek);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation.id]);
	const saveShifts = async (shifts, selectedWeek, otherLocationShifts = []) => {
		const shiftsToSave = shifts.map((shift) => {
			const shiftDate = new Date(shift.baseDay * 1000);
			const day = (shiftDate.getUTCDay() - organisation.startDay + 7) % 7;
			return saveShift(shift, selectedWeek?.days[day], organisation.id, otherLocationShifts);
		});
		return Promise.allSettled(shiftsToSave);
	};

	const saveShiftsfromTemplate = async (shifts, selectedWeek) => {
		const save = async (shift, activeDay) => {
			const shiftDetails = {
				breakend: shift?.breakEnd || "",
				breakstart: shift?.breakStart || "",
				end: shift?.end || 0,
				setAsClose: shift.setAsClose,
				hasBreak: shift.hasBreak,
				person: shift?.person ?? null,
				role: shift.role,
				start: shift?.start || 0,
				organisationID: organisation.id,
				day: activeDay,
				breakDuration: shift?.breakDuration || 0,
				unPaidBreak: shift?.unPaidBreak || false
			};
			await orgUtils.saveShift(shiftDetails);
		};
		const shiftsToSaveFromTemplate = shifts.map((shift) => {
			const day = shift.day;
			return save(shift, selectedWeek?.days[day - 1]);
		});
		return Promise.allSettled(shiftsToSaveFromTemplate);
	};
	// const getOtherLocationShifts = async (loggedInPerson, startDate, endDate) => {
	// 	if (loggedInPerson.organisations.items.length > 0) {
	// 		const allOtherOrganisations = loggedInPerson.organisations.items
	// 			.filter((k) => k.organisationID !== loggedInPerson.currentOrganisation.id)
	// 			.map((orgmember) => orgmember.organisation);
	// 		const shiftsInOtherLocations = await Promise.all(
	// 			allOtherOrganisations.map(async (org) => {
	// 				let data = await orgUtils.getShifts(org.id, startDate, endDate, true);
	// 				data = data.filter((shift) => shift?.member?.email);
	// 				return data;
	// 			})
	// 		);
	// 		return shiftsInOtherLocations.flat();
	// 	}
	// };
	const copyWeekData = async () => {
		try {
			setIsLoading(true);
			if (!isNaN(week)) {
				let today = selectedDay;
				let startDateWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 * week, today.getHours(), today.getMinutes());
				let selectedWeekCalendarDays = fillWeek(startDateWeek, organisation.startDay);
				const {
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = selectedWeekCalendarDays;

				const currentWeek = fillWeek(today, organisation?.startDay);
				const member = loggedInPerson.currentOrganisation?.members?.find((k) => k?.person === loggedInPerson?.person);
				let selectedWeekShifts = await orgUtils.getShifts(organisation.id, startDate, endDate, true);
				// Filter shifts based on the selected department
				if (filterByDepartment !== "ALL") {
					selectedWeekShifts = selectedWeekShifts.filter((s) => {
						return s.member?.departmentID?.includes(filterByDepartment);
					});
				}

				if (loggedInPerson?.assignedAdmin) {
					selectedWeekShifts = selectedWeekShifts.filter((s) =>
						s.member?.departmentID !== null && member?.departmentIDs.length > 0
							? arrayDiff(s.member?.departmentID || [], member?.departmentIDs)
							: s?.memberID === member?.orgUserId
					);
				}
				const otherLocationShifts = await getOtherLocationShifts({
					orgMembers: organisation.members || [],
					loggedInPerson,
					startDate: currentWeek.first.baseEpoch,
					endDate: currentWeek.last.baseEpoch + 86340
				});
				const isSaved = await saveShifts(selectedWeekShifts, fillWeek(today, organisation.startDay), otherLocationShifts);
				const alreadyRosteredCount = isSaved.filter((s) => s.status === "fulfilled" && s.value === 2).length;
				const emptyShiftsCount = isSaved.filter((s) => s.status === "fulfilled" && s.value === 1).length;
				if (alreadyRosteredCount > 0 && emptyShiftsCount > 0) {
					setMessage(
						showSuccess({
							title: `${alreadyRosteredCount ?? "Few"} shifts not copied as the employee is already rostered in another location`,
							subTitle: `${emptyShiftsCount + alreadyRosteredCount ?? "A few"} shifts not yet assigned to an employee`,
							duration: 8
						})
					);
				} else if (emptyShiftsCount > 0) {
					setMessage(
						showSuccess({
							title: `${emptyShiftsCount ?? "Few"} shifts not yet assigned to an employee`
						})
					);
				} else if (alreadyRosteredCount > 0) {
					setMessage(
						showSuccess({
							title: `${alreadyRosteredCount ?? "Few"} shifts not copied as the employee is already rostered in another location`
						})
					);
				}
				await buildCalendar();
			} else {
				let today = selectedDay;
				fillWeek(today, organisation?.startDay);
				const temp = template.find((t) => t.value === week);
				const templateShifts = temp?.template?.shifts.map((k) => {
					return JSON.parse(k);
				});
				await saveShiftsfromTemplate(templateShifts, fillWeek(today, organisation.startDay));
				await buildCalendar();
			}
			setIsLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	const confirmPopup = (week) => {
		setOpen(true);
		setWeek(week);
	};
	const handlerModal = (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			copyWeekData();
		}
		setOpen(false);
	};
	const destroyRoster = async () => {
		await orgUtils.destroyTemplate(deleteRoster.value);
		templates(copyWeek);
	};
	const handlerDeleteModal = (e, type) => {
		e.preventDefault();
		destroyRoster();
		setOpenDeleteModel(false);
	};
	const templates = async (copyWeek) => {
		if (organisation?.id) {
			const {
				getOrgTemplate: { items }
			} = await orgUtils.listTemplate(organisation.id, 4);
			setTemplate([...copyWeek, ...items.map((p) => ({ value: p.id, label: p.name, type: "template", template: p }))]);
		}
	};
	const confirmDelete = (e, value) => {
		if (e !== "") e.stopPropagation();
		setDeleteRoster(value);
		setOpenDeleteModel(true);
	};
	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title="Copy a full week?"
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerDeleteModal}
				open={openDeleteModel}
				className="text-base font-medium text-gray-900"
				title={`Delete ${deleteRoster?.label ?? "this"} Roster Template?`}
				subTitle="Are you sure?"
			/>
			<Menu as="div" className="ml-4 mt-5 mx-0 sm:mx-0 w-fit font-medium copy-week">
				{({ open }) => (
					<>
						<Menu.Button className="px-2 py-1 text-md bg-darkww-700 rounded-full ml-6 sm:ml-0 flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
							<span className="ml-3 text-gray-700 text-sm font-medium block text-white">Copy Week</span>
							<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
						</Menu.Button>
						<Transitions
							show={open}
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95">
							<Menu.Items
								static
								className="absolute mt-2 w-38 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
								{template.length > 0 &&
									template.map((value, index) => (
										<div className="sm:block" key={index}>
											<Menu.Item>
												<div className="flex flow-root block px-4 py-2 text-sm text-gray-700 cursor-pointer">
													<span onClick={() => confirmPopup(value.value)}>{value.label}</span>
													{value?.type === "template" && (
														<XIcon className="h-4 w-4 -mr-2 float-right" onClick={(e) => confirmDelete(e, value)} aria-hidden="true" />
													)}
												</div>
											</Menu.Item>
										</div>
									))}
							</Menu.Items>
						</Transitions>
					</>
				)}
			</Menu>
		</>
	);
};

export default ShiftCopyButton;
