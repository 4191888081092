import { API, graphqlOperation } from "aws-amplify";
import { createReport, deleteReport, updateReport } from "../../graphql/mutations.js";
import { getMonthOrgReports, getOrgReport } from "../../graphql/queries.js";

export const getReport = async (organisationID, baseDay) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID,
			filter: { reportDate: { eq: baseDay } },
			limit: 1000
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getOrgReport, queryParams));
			const { getOrgReport: legacyReport } = data;
			resolve(legacyReport);
		} catch (error) {
			reject(error);
		}
	});
};
export const getWeekReport = async (organisationID, startDate, endDate) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID,
			filter: { reportDate: { between: [startDate, endDate] } },
			limit: 1000
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getOrgReport, queryParams));
			const { getOrgReport: legacyReport } = data;
			resolve(legacyReport);
		} catch (error) {
			reject(error);
		}
	});
};
export const getReportsByMonth = async (organisationID, month) => {
	return new Promise(async (resolve, reject) => {
		const filters = {
			and: [{ yearMonth: { eq: month } }]
		};
		const queryParams = {
			yearMonth: month,
			organisationID,
			sortDirection: "ASC",
			limit: 1000,
			filter: filters
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getMonthOrgReports, queryParams));
			const { getMonthOrgReports: monthlyReports } = data;
			resolve(monthlyReports);
		} catch (error) {
			reject(error);
		}
	});
};
const getCustomOrgReport = `
  query GetOrgReport(
    $organisationID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrgReport(
      organisationID: $organisationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        takings
		target
        reportDay
      }
      nextToken
    }
  }
`;
export const getAverageOfDay = async (organisationID, date, day) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID,
			limit: 1000,
			filter: { and: [{ reportDay: { eq: day } }, { reportDate: { lt: date } }] }
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getCustomOrgReport, queryParams));
			const { getOrgReport: legacyReport } = data;
			resolve(legacyReport.items);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveReport = async (reportData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const reportMonth = new Date(reportData.reportDate * 1000);
			const year = reportMonth.getFullYear();
			const month = (reportMonth.getMonth() + 1).toString().padStart(2, "0");

			let reportMapped = {
				id: reportData.id || null,
				takings: isNaN(parseInt(reportData.takings)) ? reportData.takings : parseInt(reportData.takings),
				target: parseInt(reportData.target),
				till: isNaN(parseInt(reportData.till)) ? reportData.till : parseInt(reportData.till),
				food: parseInt(reportData.food),
				drinks: parseInt(reportData.drinks),
				organisationID: reportData.organisationID,
				reportDate: reportData.reportDate,
				note: reportData.note ?? "",
				reportDay: reportData.reportDay.toUpperCase(),
				yearMonth: parseInt(`${year}${month}`)
			};

			const mutation = reportData.id ? updateReport : createReport;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: reportMapped }
			});

			// Correctly extract the saved report data from the response
			const mutationName = reportData.id ? "updateReport" : "createReport";
			const savedNode = data[mutationName];

			if (!savedNode) {
				throw new Error("Failed to save report data");
			}

			const updatedNote = { ...reportData, ...savedNode };
			resolve(updatedNote);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyReport = async (reportId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteReport,
				variables: { input: { id: reportId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
