import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputField, ReactTooltip, Yup, formateEpochToShortMonthDate, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

const General = () => {
	const { organisation } = useContext(OrganisationContext);
	const [subscription, setSubscription] = useState({});
	const [cancelling, setCancelling] = useState(false);
	const [updatingMembers, setUpdatingMembers] = useState(false);
	const [minimumMembers, setMinimumMembers] = useState(20);
	const { setMessage } = useSimpleMessage();
	const loadSubscription = async () => {
		try {
			const { subscription } = await orgUtils.get(organisation);
			setSubscription(subscription?.items?.[0] || organisation.subscription);
		} catch (err) {
			console.log(err);
		}
	};
	const cancelSubscription = async () => {
		setCancelling(true);
		if (subscription?.id) {
			await orgUtils.cancelSubscription(subscription?.id);
			await loadSubscription();
		}
		setCancelling(false);
	};
	useEffect(() => {
		if (organisation.id) loadSubscription();
		if (organisation?.members?.length > 20) {
			setMinimumMembers(organisation.members.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);
	const updateQuantitySchema = Yup.object().shape({
		numberOfMembers: Yup.number().required("Number of Staff Members ?").min(minimumMembers, `You must have minimum of${minimumMembers} members`)
	});
	return (
		<>
			<ReactTooltip />
			<div className="divide-y divide-gray-200 lg:col-span-9 h-96">
				<div className="py-6 px-4 sm:p-6 lg:pb-8 flex justify-between">
					<div>
						<h2 className="text-lg leading-6 font-medium text-gray-900">Subscription</h2>
						<p className="mt-1 text-sm text-gray-500">your subscription information about your organsiation.</p>
					</div>

					{subscription?.cancelAtPeriodEnd === true ? (
						`Cancled At ${
							subscription?.updatedAt && formateEpochToShortMonthDate(new Date(subscription?.updatedAt).valueOf() / 1000, true)
						}`
					) : (
						<div className="">
							<Button label="Cancel Subscription" onClick={() => cancelSubscription()} disabled={cancelling} />
						</div>
					)}
				</div>
				<div className="mt-4 pb-6">
					<div className="flex flex-col mt-6">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
									<table className="min-w-full divide-y divide-gray-200 table-auto">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
												>
													Plan
												</th>

												<th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
												>
													Allowed Members
												</th>
												<th
													scope="col"
													className="pl-7 px-4 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
												>
													Purchased At
												</th>

												<th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
												>
													{subscription?.cancelAtPeriodEnd === true ? "Subscription Ends At" : "Next Billing Date"}
												</th>
												<th scope="col" className="relative px-6 py-3">
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											<tr>
												<td className="px-6 py-2 whitespace-nowrap">
													<div className="flex items-center">
														<div className="ml-4">
															<div className="text-sm font-medium text-gray-900">Membership</div>
															<div className="text-sm hidden sm:block text-gray-500">TheWorkWall</div>

															<div className="block sm:hidden mr-2 mt-2"></div>
														</div>
													</div>
												</td>
												<td className="px-6 py-2 whitespace-nowrap">
													<div className="flex items-center">
														<div className="ml-4">
															<div className="text-sm text-gray-700">{subscription.quantity}</div>
														</div>
													</div>
												</td>
												<td className="px-6 py-2 whitespace-nowrap">
													<div className="flex items-center">
														<div className="ml-4">
															<div className="text-sm text-gray-700">
																{subscription.createdAt &&
																	formateEpochToShortMonthDate(
																		new Date(subscription.createdAt).valueOf() / 1000,
																		true
																	)}
															</div>
														</div>
													</div>
												</td>
												<td className="px-6 py-2 whitespace-nowrap">
													<div className="flex items-center">
														<div className="ml-4">
															<div className="text-sm text-gray-700">
																{subscription?.expiryAt && formateEpochToShortMonthDate(subscription.expiryAt, true)}
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									<Form
										validationSchema={updateQuantitySchema}
										enableReinitialize={true}
										initialValues={{ numberOfMembers: subscription.quantity }}
										disabled={false}
										onSubmit={async (data) => {
											try {
												setUpdatingMembers(true);
												if (subscription?.id) {
													await orgUtils.updateSubscription(subscription?.id, parseInt(data.numberOfMembers));
													await loadSubscription();
												}
												setUpdatingMembers(false);
												setMessage(
													showSuccess({
														title: "Subscription Membership updated"
													})
												);
											} catch (error) {
												setUpdatingMembers(false);
												setMessage(
													showFailure({
														title: "Unable to update try later.",
														subTitle: error.message
													})
												);
											}
										}}
									>
										<div className="bg-gray-50">
											<div className="max-w-2xl mx-auto lg:max-w-none">
												<div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16 mx-8">
													<div className="flex-1 pb-5 flex items-end justify-between">
														<span>
															<InputField
																label="Update Number of Staff Members"
																name="numberOfMembers"
																placeholder="25"
																type="number"
															/>
														</span>
													</div>
													<div className="mt-10 lg:mt-0">
														<div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
															<div className="border-t border-gray-200 py-6 px-4 sm:px-6">
																<Button type="submit" label="Confirm" disabled={updatingMembers} />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default General;
