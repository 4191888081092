import { API, graphqlOperation } from "aws-amplify";
import { createShiftTemplate, updateShiftTemplate, deleteShiftTemplate } from "../../graphql/mutations.js";
import { getShiftTemplateByOrg } from "../../graphql/queries.js";

export const getShiftTemplates = async (organisationID) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID: organisationID,
			limit: 1000
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getShiftTemplateByOrg, queryParams));
			const { getShiftTemplateByOrg: legacyNoteParticularDay } = data;
			resolve(legacyNoteParticularDay ?? []);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveShiftTemplate = async (templateData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let roleMapped = {
				id: templateData.id || null,
				organisationID: templateData.organisationID,
				shiftStart: templateData.shiftStart,
				shiftEnd: templateData.shiftEnd,
				roleID: templateData.roleID,
				setAsClose: templateData.setAsClose ?? null,
				hasBreak: templateData.hasBreak ?? null,
				breakStart: templateData.breakstart ?? null,
				breakEnd: templateData.breakend ?? null,
				breakDuration: parseInt(templateData.breakDuration) ?? 0,
				unPaidBreak: templateData.unPaidBreak ?? null
			};
			const mutation = templateData.id ? updateShiftTemplate : createShiftTemplate;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: roleMapped }
			});
			const savedNode = data[templateData.id ? updateShiftTemplate : createShiftTemplate];

			const updatedNote = { ...templateData, ...savedNode };
			resolve(updatedNote);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyShiftTemplate = async (shiftTemplateId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteShiftTemplate,
				variables: { input: { id: shiftTemplateId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
