import { useState } from "react";
import { nth, Form, Button, Yup, useSimpleMessage, showFailure, showSuccess, TextArea, ConfirmModal, notifyUser, formateEpoch } from "ww-framework";
import { orgUtils } from "ww-stores";
import { XIcon } from "@heroicons/react/outline";

const noteSchema = Yup.object().shape({
	note: Yup.string().required()
});
const NoteParticularDayForm = ({ currentDay, setOverLayOpen, getNoteParticularDay, person, organisation, note, setOverNoteParticularDayLayOpen }) => {
	const { setMessage } = useSimpleMessage();
	const initValues = {
		note: note?.note || ""
	};
	const [noteSaving, setNoteSaving] = useState(false);
	const [open, setOpen] = useState(false);

	const confirmPopup = (e) => {
		e.preventDefault();
		setOpen(true);
	};
	const handlerModal = (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			deleteReminder(e);
		}
		setOpen(false);
	};
	const deleteReminder = async (e) => {
		if (note?.id) {
			await orgUtils.deleteNotePerticularDay(note?.id);
			getNoteParticularDay();
			setOverNoteParticularDayLayOpen(false);
			setMessage(
				showSuccess({
					title: "Reminder Deleted successfully."
				})
			);
			setOverLayOpen(false);
		}
	};
	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title="Delete this Reminder?"
				subTitle="Are you sure?"
			/>
			<div className="flex">
				<h4 className="text-xs font-semibold text-white uppercase tracking-wide">Add Reminder</h4>
				{note?.id && <XIcon className="h-6 w-6 text-white ml-auto" aria-hidden="true" onClick={(e) => confirmPopup(e, note.id)} />}
			</div>

			<Form
				validationSchema={noteSchema}
				enableReinitialize={true}
				disabled={noteSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						setNoteSaving(true);
						let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;

						const notes = {
							...data,
							...{
								id: note?.id || "",
								organisationID: organisation.id,
								date: currentDay.baseEpoch,
								memberID: person?.organisations?.items[activeOrg].id
							}
						};
						let memberIds = currentDay.shifts.map((k) => k?.member?.person?.person).filter((i) => i);
						memberIds.length > 0 &&
							(await notifyUser(
								memberIds,
								`Additional information has been added to your shift on ${formateEpoch(currentDay.baseEpoch)}`
							));
						await orgUtils.saveNoteParticularDay(notes);
						getNoteParticularDay();
						setNoteSaving(false);
						setOverNoteParticularDayLayOpen(false);
						resetForm();
						setMessage(
							showSuccess({
								title: "Note saved successfully."
							})
						);
						setOverLayOpen(false);
					} catch (error) {
						setMessage(
							showFailure({
								title: "Unable to save note.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-72 sm:w-full">
					<div className="mt-2 with-full ">
						<TextArea type="textarea" name="note" placeholder="Note" />
					</div>
					<div className="mt-5 flex flex-row justify-between">
						<div className="flex justify-end gap-5">
							<Button type="submit" label="Save" disabled={noteSaving} />
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

const NoteParticularDay = ({ note, activeDay, getNoteParticularDay, setOverLayOpen, organisation, person, setOverNoteParticularDayLayOpen }) => {
	return (
		<div className="overflow-y-scroll h-full pl-3 bg-darkww-900 shadow-lg rounded-lg ml-4 p-3">
			{activeDay?.day && (
				<div className="font-bold text-gray-700 text-white text-xl py-3">{`${activeDay?.dayDesc},  ${activeDay?.monthShortDesc} ${
					activeDay?.day
				}${nth(activeDay?.day)}`}</div>
			)}

			{person.isAdmin || person.assignedAdmin ? (
				<NoteParticularDayForm
					currentDay={activeDay}
					getNoteParticularDay={getNoteParticularDay}
					note={note}
					organisation={organisation}
					person={person}
					setOverLayOpen={setOverLayOpen}
					setOverNoteParticularDayLayOpen={setOverNoteParticularDayLayOpen}
				/>
			) : (
				<div className="mt-1 sm:mt-5 p-3 bg-white" style={{ whiteSpace: "pre-wrap" }}>
					{note?.note ? note?.note : "No reminders today"}
				</div>
			)}
		</div>
	);
};

export default NoteParticularDay;
