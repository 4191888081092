import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
// import { NumericKeyboard } from "react-numeric-keyboard";
import { Button, Form, InputField, Yup } from "ww-framework";
import { personUtils } from "ww-stores";

const identitySchema = Yup.object().shape({
	pin: Yup.string().required()
});
const PasswordForm = ({ handlerModal, member }) => {
	const [confirmingPassword, setConfirmingPassword] = useState(false);
	const [error, setError] = useState("");
	const [isOpen, setIsOpen] = useState(true);
	const [initValues, setInitValues] = useState({ pin: 0 });

	const handelChange = ({ value }) => {
		setInitValues({ pin: value });
	};
	return (
		<>
			<Form
				validationSchema={identitySchema}
				enableReinitialize={true}
				disabled={confirmingPassword}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					setConfirmingPassword(true);
					setError("");
					try {
						const { pin: memberPin } = member;
						const { pin } = data;
						if (memberPin === parseInt(pin)) handlerModal("", "confirm");
						else setError("Wrong Pin");
						setConfirmingPassword(false);
					} catch (error) {
						setConfirmingPassword(false);
					}
				}}
			>
				<div className="w-full">
					<h4 onClick={() => setIsOpen(!isOpen)}>{isOpen ? "Close KeyBoard" : "Open KeyBoard"}</h4>

					<div className="mt-5 mb-6">
						<InputField
							type="password"
							label={`Confirm Pin for - ${member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
								}`}
							name="pin"
							onChange={() => setError("")}
						/>
						{error && <p className="-mt-2 h-3 text-sm text-red-600">{error}</p>}
					</div>
					<div className="w-full mt-1">
						<Button type="submit" label="Submit" disabled={confirmingPassword} />
					</div>
				</div>
			</Form>
			<div className={`${isOpen ? "mt-2" : ""}`}>
				<CustomNumericKeyboard isOpen={isOpen} onChange={handelChange} />
			</div>
		</>
	);
};
const ConfirmIdentity = ({ record, open, handlerModal }) => {
	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
					<div className="flex items-center justify-center h-full w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="bg-white transform rounded-lg w-fit w-96">
								<span
									className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer"
									onClick={(e) => handlerModal(e, "close")}
								>
									<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								<div className="w-full py-3 px-2">
									<div className="justify-center gap-2">
										<PasswordForm handlerModal={handlerModal} member={record} />
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

const CustomNumericKeyboard = ({ isOpen, onChange }) => {
	const [value, setValue] = useState("");

	useEffect(() => {
		onChange({ value: value });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	if (!isOpen) {
		return null;
	}

	const handleClick = (number) => {
		if (number === "backspace") {
			setValue((prev) => prev.slice(0, -1));
		} else {
			setValue((prev) => prev + number);
		}
	};

	const handleBackspace = () => {
		handleClick("backspace");
	};

	return (
		<div className="grid grid-cols-3 gap-2 p-4 bg-gray-200 rounded-md">
			{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
				<button key={number} onClick={() => handleClick(number)} className="bg-white text-black p-4 rounded shadow hover:bg-gray-300">
					{number}
				</button>
			))}
			<div></div>
			<button onClick={() => handleClick(0)} className="bg-white text-black p-4 rounded shadow hover:bg-gray-300">
				0
			</button>
			<button onClick={handleBackspace} className="bg-white text-black p-4 rounded shadow hover:bg-gray-300">
				⌫
			</button>
		</div>
	);
};

export default ConfirmIdentity;
