import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { Button, showFailure, showSuccess, useNavigate, useSimpleMessage } from "ww-framework";
import { CalendarDataContext, OrganisationContext, orgUtils, personUtils } from "ww-stores";
import ClockInClockOutForm from "../ClockInClockOutForm";
// import ExportCsv from "./ExportCsv";
// import { MemberListItem } from "./ShiftForm";

const ShiftAndNoteButton = ({ handlerModal }) => {
	const { organisation } = useContext(OrganisationContext);
	const { editShift, activeDay, setSelectedDay, punchData } = useContext(CalendarDataContext);
	const { setMessage } = useSimpleMessage();
	const navigate = useNavigate();

	const navigateToMessage = () => {
		if (editShift?.memberID) {
			const member = organisation.members.find((orgMember) => orgMember.orgUserId === editShift.memberID);
			if (member) {
				const name = personUtils.displayName(member);
				navigate(`/messages?to=${name}`);
			}
		}
	};

	const rebuild = (setDay) => {
		setSelectedDay(setDay);
	};

	const deleteShift = async (shiftId, handlerModal) => {
		try {
			await orgUtils.deleteShift(shiftId, handlerModal);
			const punch = punchData.find((l) => l?.shiftID === shiftId);
			if (punch) {
				await orgUtils.deletePunch(punch.id);
			}
			rebuild(activeDay.date);
			setMessage(
				showSuccess({
					title: "Shift removed successfully."
				})
			);
			handlerModal(null, "close");
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to remove Shift.",
					subTitle: error.message
				})
			);
		}
	};
	return (
		<div className="w-full h-fit m-3">
			<div className="">
				<Button
					label="Edit Shift"
					colors="bg-darkww-500 hover:bg-darkww-700 my-4 focus:ring-white"
					onClick={(e) => handlerModal(e, "shift")}
				/>
				<Button
					type="button"
					label="Notes"
					colors="bg-lightww-500 hover:bg-darkww-400 my-4 focus:ring-2"
					onClick={(e) => handlerModal(e, "note")}
				/>
			</div>
			{editShift?.memberID && (
				<div className="">
					<Button label="Message" colors="bg-darkww-500 hover:bg-darkww-700 focus:ring-white w-fit" onClick={navigateToMessage} />
				</div>
			)}
			<Button
				type="button"
				colors="bg-red-500 hover:bg-red-600 focus:ring-white my-4"
				label="Delete"
				onClick={() => deleteShift(editShift?.id, handlerModal)}
				f
			/>
		</div>
	);
};
const CopyOrMove = ({ handlerModal }) => (
	<div className="w-full h-fit my-4 mx-3">
		<>
			<Button label="Copy" colors="bg-darkww-500 hover:bg-darkww-700 focus:ring-white mb-4" onClick={(e) => handlerModal(e, "copy")} />
			<Button type="button" label="Move" onClick={(e) => handlerModal(e, "move")} colors="bg-lightww-500 hover:bg-darkww-400 focus:ring-2" />
		</>
	</div>
);
const ReplaceOrSwap = ({ handlerModal }) => (
	<div className="w-full h-fit my-4 mx-3">
		<>
			<Button label="Replace" colors="bg-darkww-500 hover:bg-darkww-700 focus:ring-white mb-4" onClick={(e) => handlerModal(e, "replace")} />
			<Button type="button" label="Swap" onClick={(e) => handlerModal(e, "swap")} colors="bg-lightww-500 hover:bg-darkww-400 focus:ring-2" />
		</>
	</div>
);
const ChooseModuleCalendar = ({ open, handlerModal, type = "ShiftAndNote" }) => {
	const { editShift, setEditShift, activeDay, setChooseModuleOpen, setSelectedDay } = useContext(CalendarDataContext);

	const rebuild = (setDay) => {
		setSelectedDay(setDay);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform flex flex-row rounded-lg w-fit">
							<span className="inline-block absolute top-0 right-0 mr-2 mt-2 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full flex flex-row px-2 py-3">
								<div className="flex justify-center gap-2">
									{type === "ShiftAndNote" ? (
										<ShiftAndNoteButton handlerModal={handlerModal} />
									) : type === "checkInCheckOut" ? (
										<ClockInClockOutForm
											record={editShift}
											cancelCB={() => {
												setEditShift({});
												setChooseModuleOpen(false);
											}}
											rebuildCalendar={rebuild}
											activeDay={activeDay}
										/>
									) : type === "drag" ? (
										<CopyOrMove handlerModal={handlerModal} />
									) : (
										type === "dragOnShift" && <ReplaceOrSwap handlerModal={handlerModal} />
									)}
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ChooseModuleCalendar;
