import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	ComboBox,
	DatePickerField,
	epochToDateStr,
	Form,
	InputField,
	LinkButton,
	showFailure,
	showSuccess,
	TextArea,
	Tooltip,
	useSimpleMessage,
	Yup
} from "ww-framework";
import { OrganisationContext, orgUtils, personUtils } from "ww-stores";

const leaveRecordSchema = Yup.object().shape({
	leaveOption: Yup.object().shape({
		id: Yup.string().required(),
		name: Yup.string().required(),
		description: Yup.string().required()
	}),
	note: Yup.string().required().min(2)
});

const LeaveRecords = ({ currentMemberOrgUserId, updateOrgAndMembers }) => {
	const { organisation } = useContext(OrganisationContext);
	const [currentMember, setCurrentMember] = useState(organisation.members.find((member) => member.orgUserId === currentMemberOrgUserId));
	const [orgLeaveOptions, setOrgLeaveOptions] = useState([]);
	const [selectedLeaveOption, setSelectedLeaveOption] = useState(null);
	const [isSavingRecord, setIsSavingRecord] = useState(false);
	const [selectedLeaveRecord, setSelectedLeaveRecord] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	

	const { setMessage } = useSimpleMessage();

	const initValues = { leaveOption: selectedLeaveOption, note: "" };
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	useEffect(() => {
		const m = organisation.members.find((member) => member.orgUserId === currentMemberOrgUserId);
		setCurrentMember(m);
		let leaveOptions = organisation?.leaveOptions?.items.map((leaveOption) => ({
			id: leaveOption.id,
			name: leaveOption.name,
			maximumLength: leaveOption.maximumLength,
			isAnnual: leaveOption.isAnnual,
			unit: leaveOption.unit,
			description: `Maximum ${leaveOption.maximumLength} ${leaveOption.unit}${leaveOption.maximumLength === 1 ? "" : "s"} ${leaveOption.isAnnual ? "per year" : ""}`
		}));
		setOrgLeaveOptions(leaveOptions);
	}, [currentMemberOrgUserId, organisation]);
	const deleteLeaveRecord = async (id) => {
		try {
			setIsLoading(true);
			await orgUtils.removeLeaveRecord(id); // Assuming orgUtils.removeLeaveRecord handles the deletion
			updateOrgAndMembers(); // Refresh the organisation and members data after deletion
			setMessage(showSuccess({ title: "Leave record deleted successfully." }));
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			setMessage(showFailure({ title: "Failed to delete leave record", subTitle: error.message }));
		}
	};


	const leaveOptionsList = ({ selected, active, option: lO }) => {
		let leaveRecords = currentMember?.leaveRecords?.filter((lRecord) => lRecord.leaveOptionID === lO.id);
		if (lO.isAnnual) {
			leaveRecords = leaveRecords.filter((lRecord) => {
				const recordYear = new Date(lRecord.dateTaken * 1000).getFullYear();
				return recordYear === new Date().getFullYear();
			});
		}
		const hasRecord = !!leaveRecords?.length;
		let leaveDaysTaken = 0;
		const convertToDays = (length, unit) => {
			const multiplier = unit === "day" ? 1 : unit === "week" ? 7 : unit === "month" ? 30 : 365;
			return length * multiplier;
		};

		if (hasRecord) {
			leaveRecords.forEach((lR) => {
				leaveDaysTaken += convertToDays(lR.leaveLength, lR.unit);
			});
		}

		const maxLeaveInDays = convertToDays(lO.maximumLength, lO.unit);
		const isOverLimit = leaveDaysTaken > maxLeaveInDays;
		const leaveDaysLeft = maxLeaveInDays - leaveDaysTaken;
		const leaveDaysLeftText = leaveDaysLeft > 0 ? `${leaveDaysLeft} days left` : `${Math.abs(leaveDaysLeft)} days over`;

		return (
			<div
				onClick={() => {
					setSelectedLeaveOption(lO);
				}}
				className="flex flex-col items-start justify-between"
			>
				<div className={`flex items-center`}>
					<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-1 block truncate")}>{lO.name}</span>
					<span className="px-2">{"-"}</span>
					<span className={`text-xs sm:text-xs ${active ? "text-white" : "text-gray-500"}`}>{lO.description}</span>
				</div>

				{hasRecord ? (
					<div className="flex flex-col items-start space-y-2 mt-2">
						<Tooltip trigger={isOverLimit ? ["hover"] : []} overlay={"Days taken are over the limit"} placement="top">
							<div className={`flex items-center px-2 py-1 rounded-md ${!active && (isOverLimit ? "bg-red-500" : "bg-gray-100")}`}>
								<span className={classNames(active ? "text-white" : "text-darkww-600")}>
									{leaveDaysTaken} day{leaveDaysTaken === 1 ? "" : "s"} taken
								</span>
							</div>
						</Tooltip>
						<div className="w-24">
							<span className={classNames(active ? "text-white" : "text-darkww-600")}>{leaveDaysLeftText}</span>
						</div>

						{/* Dropdown for Leave Records */}
						{leaveRecords.map((leaveRecord) => (
							<div key={leaveRecord.id} className="leave-record-item mt-2">
								<button onClick={() => setSelectedLeaveRecord(leaveRecord.id)} className="text-xs text-blue-500">
									{epochToDateStr(leaveRecord.dateTaken)} - {leaveRecord.leaveLength} {leaveRecord.unit}
									{leaveRecord.leaveLength === 1 ? "" : "s"}
								</button>
								{selectedLeaveRecord === leaveRecord.id && (
									<div className="record-details mt-1">
										<span className="text-sm">{leaveRecord.note}</span>
										<LinkButton
											label={
												<div className="flex items-center">
													<TrashIcon className="h-5 w-5 text-red-500" />
												</div>
											}
											className="text-red-600 hover:text-red-900"
											onClick={() => deleteLeaveRecord(leaveRecord.id)}
										/>
									</div>
								)}
							</div>
						))}
					</div>
				) : null}
			</div>
		);
	};


	if (!currentMember) {
		return null;
	}
	const handleChange = async (value, type = "value") => {
		try {
			if (type === "Leave Record") await orgUtils.addLeaveRecord(value);

			updateOrgAndMembers();

			setMessage(
				showSuccess({
					title: `${type} Added for ${
						currentMember?.id
							? personUtils.displayName(currentMember) || currentMember.orgUsername
							: currentMember.orgUsername || "No Name Found"
					}`
				})
			);
			return true;
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: `Unable to save employee ${type}`,
					subTitle: error.message
				})
			);
			return false;
		}
	};

	return (
		<div>
			<div className="w-full mt-4 pt-2 pb-4 px-2 bg-gray-100 rounded-lg shadow-md">
				<h1 className="text-md text-darkww-700 font-semibold ">Record Leave</h1>
				<div className="flex flex-1 flex-col justify-start mt-2">
					<Form
						validationSchema={leaveRecordSchema}
						enableReinitialize={true}
						disabled={isSavingRecord}
						initialValues={initValues}
						onSubmit={async (data, { resetForm }) => {
							try {
								setIsSavingRecord(true);
								const leaveRecordData = {
									leaveOptionID: data.leaveOption.id,
									note: data.note,
									leaveLength: data.leaveLength,
									unit: data.unit,
									dateTaken: new Date(data.dateTaken).getTime() / 1000,
									organisationID: organisation.id,
									memberID: currentMember.orgUserId
								};
								await handleChange(leaveRecordData, "Leave Record");
								setIsSavingRecord(false);
								setSelectedLeaveOption(null);
								resetForm();
							} catch (error) {
								setIsSavingRecord(false);
							}
						}}
					>
						<div className="flex flex-1 flex-col justify-start mt-2">
							<span className="text-xs sm:text-xs text-gray-500">Select a leave option to register staff leave</span>
							{/* Display leave options as a list */}
							<div className="leave-options-list">
								{orgLeaveOptions.map((leaveOption) => {
									let leaveRecords = currentMember?.leaveRecords?.filter((lRecord) => lRecord.leaveOptionID === leaveOption.id);
									if (leaveOption.isAnnual) {
										leaveRecords = leaveRecords.filter((lRecord) => {
											const recordYear = new Date(lRecord.dateTaken * 1000).getFullYear();
											return recordYear === new Date().getFullYear();
										});
									}

									let leaveDaysTaken = 0;
									const convertToDays = (length, unit) => {
										const multiplier = unit === "day" ? 1 : unit === "week" ? 7 : unit === "month" ? 30 : 365;
										return length * multiplier;
									};

									if (leaveRecords?.length) {
										leaveRecords.forEach((lR) => {
											leaveDaysTaken += convertToDays(lR.leaveLength, lR.unit);
										});
									}

									const maxLeaveInDays = convertToDays(leaveOption.maximumLength, leaveOption.unit);
									const leaveDaysLeft = maxLeaveInDays - leaveDaysTaken;
									const isOverLimit = leaveDaysTaken > maxLeaveInDays;

									return (
										<div key={leaveOption.id} className="flex items-center justify-between bg-white p-2 rounded-lg mt-2 border border-gray-300">
											<div className="flex items-center">
												<span className="font-semibold">{leaveOption.name}</span>
												<span className="ml-2 text-xs text-gray-500">{leaveOption.description}</span>
											</div>

											<div className="flex space-x-4 items-center">
												{/* Show leave days taken */}
												<Tooltip trigger={isOverLimit ? ["hover"] : []} overlay={"Days taken are over the limit"} placement="top">
													<div className={`flex items-center px-2 py-1 rounded-md ${isOverLimit ? "bg-red-500" : "bg-gray-100"}`}>
														<span className="text-xs sm:text-xs text-darkww-600">
															{leaveDaysTaken} day{leaveDaysTaken === 1 ? "" : "s"} taken
														</span>
													</div>
												</Tooltip>
												{/* Show leave days left */}
												<div className="text-xs sm:text-xs">
													{leaveDaysLeft > 0 ? `${leaveDaysLeft} days left` : `${Math.abs(leaveDaysLeft)} days over`}
												</div>
											</div>

											<Button type="button" label="Add" onClick={() => setSelectedLeaveOption(leaveOption)} />
										</div>
									);
								})}
							</div>
						</div>

						{selectedLeaveOption && (
							<div>
								<div className="h-full flex mt-0.5 items-end pb-0 col-span-9 sm:col-span-9 lg:col-span-5 space-x-2">
									<InputField label="Leave Length" name="leaveLength" />
									<div className="flex items-center pb-1">
										<ComboBox
											name="unit"
											label="Unit"
											className="border border-gray-300 rounded-md px-3"
											options={[
												{ id: "day", name: "Day", description: "Day" },
												{ id: "week", name: "Week", description: "Week" },
												{ id: "month", name: "Month", description: "Month" },
												{ id: "year", name: "Year", description: "Year" }
											]}
										/>
									</div>
									<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-5">
										<div className="mt-3 w-full">
											<p className="block text-sm font-medium text-gray-700">Leave start date</p>
											<DatePickerField name="dateTaken" placeholderText="Start date" className="mt-1 border border-gray-300 rounded-md w-full" />
										</div>
									</div>
								</div>
								<div className="h-full flex pt-1 pb-2 items-end col-span-9 sm:col-span-9 lg:col-span-3">
									<div className="w-full">
										<TextArea label="Leave Notes" name="note" />
									</div>
								</div>
								<div className="inline-block pt-4 pl-4 flex justify-end">
									<div className="mr-4">
										<Button
											type="button"
											label="Cancel"
											colors="bg-red-500 hover:bg-red-700 focus:ring-white mb-4"
											onClick={() => setSelectedLeaveOption(null)}
										/>
									</div>
									<Button type="submit" label="Save" loading={isSavingRecord} />
								</div>
							</div>
						)}
					</Form>
				</div>
			</div>
			<LeaveRecordsList
				member={currentMember}
				leaveRecords={currentMember?.leaveRecords}
				leaveOptions={orgLeaveOptions}
				updateOrgAndMembers={updateOrgAndMembers}
			/>
		</div>
	);
};

const LeaveRecordsList = ({ member, leaveRecords, leaveOptions, updateOrgAndMembers }) => {
	const [selectedLeaveRecord, setSelectedLeaveRecord] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [filteredLeaveRecords, setFilteredLeaveRecords] = useState([]);
	const [selectedLeaveType, setSelectedLeaveType] = useState(""); // State for leave type filter
	const { setMessage } = useSimpleMessage();

	const deleteLeaveRecord = async (id) => {
		try {
			setIsLoading(true);
			await orgUtils.removeLeaveRecord(id);
			updateOrgAndMembers();
			setMessage(showSuccess({ title: "Leave record deleted successfully." }));
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};

	const yearsWithLeaveRecords = [...new Set(leaveRecords.map((record) => new Date(record.dateTaken * 1000).getFullYear()))].sort((a, b) => b - a);

	useEffect(() => {
		let filteredRecords = leaveRecords;

		if (selectedYear !== "All") {
			filteredRecords = filteredRecords.filter((record) => {
				const recordYear = new Date(record.dateTaken * 1000).getFullYear();
				return recordYear === Number(selectedYear);
			});
		}

		if (selectedLeaveType) {
			filteredRecords = filteredRecords.filter((record) => {
				const leaveOption = leaveOptions.find((lO) => lO.id === record.leaveOptionID);
				return leaveOption?.name === selectedLeaveType;
			});
		}
		filteredRecords.sort((a, b) => b.dateTaken - a.dateTaken);
		setFilteredLeaveRecords(filteredRecords);
	}, [selectedYear, selectedLeaveType, leaveRecords]);

	return (
		<div className="w-full mt-4 pt-2 pb-4 px-2 bg-gray-100 rounded-lg shadow-md">
			<div className="flex justify-between items-center space-x-4 mb-3">
				<h1 className="text-md text-darkww-700 font-semibold">
					Leave Records<span className="text-sm font-normal"> - {filteredLeaveRecords?.length}</span>
				</h1>
				<div className="flex flex-row items-center space-x-4">
					{/* Year selection */}
					<label htmlFor="yearSelect" className="text-sm font-medium text-gray-700">
						Year:
					</label>
					<select
						id="yearSelect"
						value={selectedYear}
						onChange={(e) => setSelectedYear(e.target.value)} // Don't cast "All" to a number
						className="ml-2 py-0 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
					>
						<option value="All">All Years</option>
						{/* Dynamically render years based on actual leave records */}
						{yearsWithLeaveRecords.map((year) => (
							<option key={year} value={year}>
								{year}
							</option>
						))}
					</select>

					{/* Leave type filter */}
					<label htmlFor="leaveTypeSelect" className="text-sm font-medium text-gray-700">
						Leave Type:
					</label>
					<select
						id="leaveTypeSelect"
						value={selectedLeaveType}
						onChange={(e) => setSelectedLeaveType(e.target.value)}
						className="ml-2 py-0 rounded-md border-gray-300 bg-white text-darkww-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
					>
						<option value="">All</option>
						{leaveOptions.map((leaveOption) => (
							<option key={leaveOption.id} value={leaveOption.name}>
								{leaveOption.name}
							</option>
						))}
					</select>
				</div>
			</div>

			{/* Render filtered leave records */}
			{filteredLeaveRecords.map((leaveRecord) => {
				const leaveOption = leaveOptions.find((lO) => lO.id === leaveRecord.leaveOptionID);
				return (
					<div key={leaveRecord.id} className="flex items-center justify-between bg-white border border-gray-300 p-2 rounded-lg mt-2">
						<div className="flex flex-col">
							<div className="flex flex-row items-center">
								<span className="text-sm sm:text-md">{leaveOption?.name ?? "_"}</span>
								<span className="text-xs sm:text-xs text-gray-500"> - {epochToDateStr(leaveRecord.dateTaken)}</span>
							</div>
							<hr className="w-full border border-gray-100 mb-1" />
							<div className="flex flex-row items-center">
								<PencilAltIcon className="h-4 w-4 mr-2 text-gray-400" aria-hidden="true" />
								<span className="text-sm sm:text-xs text-gray-900">{leaveRecord.note}</span>
							</div>
						</div>
						<div className="flex items-center space-x-6">
							<div className="flex flex-col">
								<span className="text-sm sm:text-md text-gray-800"> Duration taken</span>
								<span className="text-sm sm:text-sm text-gray-500">
									{leaveRecord.leaveLength} {leaveRecord.unit}
									{leaveRecord.leaveLength === 1 ? "" : "s"}
								</span>
							</div>
							<LinkButton
								label={
									<div className="flex items-center">
										<TrashIcon className="h-5 w-5 text-red-500" />
									</div>
								}
								className="text-red-600 hover:text-red-900"
								loading={selectedLeaveRecord?.id === leaveRecord?.id ? isLoading : false}
								disabled={isLoading}
								onClick={() => {
									setSelectedLeaveRecord(leaveRecord);
									deleteLeaveRecord(leaveRecord.id);
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};


export default LeaveRecords;
