import { API, graphqlOperation } from "aws-amplify";
import {
  createNoteParticularDay,
  updateNoteParticularDay,
  deleteNoteParticularDay
} from "../../graphql/mutations.js";
import { getOrgNoteParticularDay } from "../../graphql/queries.js";

export const getNoteParticularDay = async (
  organisationID,
  startRange,
  endRange
) => {
  return new Promise(async (resolve, reject) => {
    const inclLastDay = endRange + 23 * 60 * 60 + 59 * 60;
    const queryParams = {
      organisationID,
      limit: 1000,
      filter: { noteDate: { between: [startRange, inclLastDay] } }
    };
    try {
      const { data } = await API.graphql(
        graphqlOperation(getOrgNoteParticularDay, queryParams)
      );
      const { getOrgNoteParticularDay: legacyNoteParticularDay } = data;
      resolve(legacyNoteParticularDay);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveNoteParticularDay = async (noteData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let roleMapped = {
        id: noteData.id || null,
        note: noteData.note,
        organisationID: noteData.organisationID,
        noteDate: noteData.date,
        memberID: noteData.memberID
      };

      const mutation = noteData.id
        ? updateNoteParticularDay
        : createNoteParticularDay;
      const { data } = await API.graphql({
        query: mutation,
        variables: { input: roleMapped }
      });
      const savedNode =
        data[noteData.id ? updateNoteParticularDay : createNoteParticularDay];

      const updatedNote = { ...noteData, ...savedNode };
      resolve(updatedNote);
    } catch (error) {
      reject(error);
    }
  });
};
export const destroyNoteParticularDay = async (noteId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.graphql({
        query: deleteNoteParticularDay,
        variables: { input: { id: noteId } }
      });
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}
