import { useState, useContext, useEffect } from "react";
import { nth, Button, Avatar, epochToTimeStr, SearchableList, LinkButton, ConfirmModal, formateCurrency } from "ww-framework";
import { personUtils, orgUtils, OrganisationContext } from "ww-stores";
import { PencilIcon, ArrowNarrowRightIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { TagIcon, TrendingDownIcon, TrendingUpIcon } from "@heroicons/react/solid";
import TemplateForm from "./TemplateForm";

const ShiftTemplatesList = ({ shiftTemplates, editing = false, setEditRecord }) => {
	return (
		<div>
			<h3 className="text-xs font-semibold text-white uppercase tracking-wide mt-2">{`Total ShiftTemplates (${shiftTemplates.length})`}</h3>
			<ul role="presentation" className="mt-4 grid grid-cols-1 gap-4">
				{shiftTemplates.map((shiftTemplate, index) => {
					return (
						<li key={index}>
							<button
								type="button"
								className={`w-full h-full`}
								onClick={() => {
									if (setEditRecord) setEditRecord(shiftTemplate);
								}}
							>
								<div
									className={`bg-white group p-2 w-full h-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightww-300 `}
								>
									<span className="min-w-0 flex-1 flex items-center space-x-3">
										<span className="block flex-shrink-0">
											<div
												style={{ backgroundColor: shiftTemplate.role?.labelColour }}
												className="rounded-full border-4 border-darkww-600 h-16 w-16 pt-1 pl-1"
											></div>
										</span>
										<span className="block min-w-0 flex-1 pl-2">
											<span className="block text-sm font-medium text-gray-900 truncate">{shiftTemplate?.role?.name}</span>
										</span>
									</span>
									<span className="flex-1 h-full hidden sm:block">
										<div className="relative">
											<div
												className={`bg-darkww-700 absolute ${
													editing ? "-top-12" : "-top-4"
												} left-0 border-2 border-white shadow-lg rounded-lg px-2 w-fit`}
											>
												<div className="text-white text-lg flex flex-row">
													<div className="py-1 px-1">{epochToTimeStr(shiftTemplate.shiftStart)}</div>
													<div>
														<ArrowNarrowRightIcon className="text-white h-8 w-8 px-1" aria-hidden="true" />
													</div>
													<div className="py-1 px-1">{shiftTemplate.setAsClose ? "Cl" : epochToTimeStr(shiftTemplate.shiftEnd)}</div>
												</div>
											</div>
											<div className={`absolute ${editing ? "top-2" : "top-10"}`}>
												{shiftTemplate.hasBreak && (
													<div className="text-gray-500 text-sm flex flex-row items-center">
														<BriefcaseIcon className="h-6 w-6 px-1" />
														{shiftTemplate.breakDuration
															? `${shiftTemplate.breakDuration} Mins`
															: `${epochToTimeStr(shiftTemplate.breakStart)} - ${epochToTimeStr(
																	shiftTemplate.breakEnd
															  )}`}
													</div>
												)}
											</div>
										</div>
									</span>
									<span className="flex-shrink-0 h-10 w-10 items-center justify-center hidden sm:inline-flex">
										{!editing && <PencilIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
									</span>

									<span className="flex-shrink-0 h-10 w-10 pr-10 items-center justify-center inline-flex sm:hidden">
										<div className="text-gray-500 divide-y divide-gray-200">
											<div className="py-1">{epochToTimeStr(shiftTemplate.shiftStart)}</div>
											<div className="py-1">{epochToTimeStr(shiftTemplate.shiftEnd)}</div>
										</div>
									</span>
								</div>
							</button>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
const Template = ({ activeDay, shiftTemplates = [], getShiftTemplates }) => {
	const { organisation } = useContext(OrganisationContext);
	const [templateFormVisible, setTemplateFormVisible] = useState(false);
	// const [shiftTemplates, setShiftTemplates] = useState([]);
	const [editRecord, setEditRecord] = useState({});
	const addTemplate = () => {
		setTemplateFormVisible(true);
	};

	return (
		<>
			<div className="overflow-y-scroll h-full pl-3 bg-darkww-900 shadow-lg rounded-lg mt-1 ml-4 p-3">
				{activeDay?.day && (
					<div className="flex mt-4">
						<div className="font-bold text-gray-700 text-white text-xl py-3">{`${activeDay?.dayDesc},  ${activeDay?.monthShortDesc} ${
							activeDay?.day
						}${nth(activeDay?.day)}`}</div>
						{/* {!templateFormVisible && (
							<div className="mt-2 mr-4 max-w-sm mx-auto">
								<Button type="button" label="Clear" />
							</div>
						)} */}
					</div>
				)}
				{templateFormVisible || editRecord?.id ? (
					<TemplateForm
						record={editRecord}
						cancelCB={() => {
							setEditRecord({});
							setTemplateFormVisible(false);
						}}
						getShiftTemplates={getShiftTemplates}
					/>
				) : (
					<div className="flex">
						<div className="mt-5 w-full mx-2 max-w-sm mx-auto">
							<Button type="button" onClick={() => addTemplate()} label="Add Template" />
						</div>
					</div>
				)}
				{!(templateFormVisible || editRecord?.id) && (
					<>
						<ShiftTemplatesList shiftTemplates={shiftTemplates} setEditRecord={setEditRecord} />
					</>
				)}
			</div>
		</>
	);
};

export default Template;
