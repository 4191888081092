import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, Logo, Link } from "ww-framework";
import { PersonContext, orgUtils, OrganisationContext } from "ww-stores";
import { ClockIcon, BellIcon, ChatAlt2Icon } from "@heroicons/react/solid";
import orgBgDefault from "ww-images/org-bg-default.jpg";
const page = [
	{ name: "Requests", route: "/requests" },
	{ name: "Home", route: "/dashboard" }
];
const page2 = [
	{ name: "Messages", route: "/messages" },
	{ name: "Home", route: "/dashboard" }
];
export default function Background({ children, currentRoute = "", readCount = 0 }) {
	const [activeMenu, setActiveMenu] = useState(page[0]);
	const [activeMenu2, setActiveMenu2] = useState(page2[0]);
	const { organisation } = useContext(OrganisationContext);
	const { members } = organisation;
	const { person } = useContext(PersonContext);
	const [pendingtimeOff, setPendingTimeOff] = useState(0);
	const [pendingSwapShiftRequest, setPendingSwapShiftRequest] = useState(0);
	const [pendingGiveAwayRequest, setPendingGiveAwayRequest] = useState(0);
	const [unReadMessages, setUnReadMessages] = useState(0);

	let navigate = useNavigate();
	let location = useLocation();
	useEffect(() => {
		if (currentRoute === "requests") setActiveMenu(page[1]);
		if (currentRoute === "messages") setActiveMenu2(page2[1]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRoute]);
	useEffect(() => {
		if (readCount > 0 && unReadMessages >= readCount) setUnReadMessages(unReadMessages - readCount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readCount]);
	useEffect(() => {
		if (person.currentOrganisation.id && members) person.isAdmin ? loadRoles() : loadRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members, person]);

	const loadRoles = async () => {
		try {
			const memberId = members?.find((m) => m.person === person.person)?.orgUserId;
			const data = await orgUtils.getTimeOff(organisation.id, null, null, person.isAdmin, memberId);
			setPendingTimeOff(data?.items?.filter((i) => i.status === "PENDING").length ?? 0);
			await loadRequests();
		} catch (err) {
			console.error(err);
		}
	};
	const loadRequests = async () => {
		const member = members?.find((m) => m.person === person.person);
		const memberId = member?.orgUserId;
		const unReadMessages = await orgUtils.unreadIndividualMessages(organisation.id, memberId, member.roleIDs, member.departmentIDs);
		const unreadMessageCount = unReadMessages.individual.items.length + unReadMessages.role.length + unReadMessages.dept.length + unReadMessages.all.length;
		let pendingSwapShiftCount = 0;
		let pendingGiveAwayCount = 0;
		let forAllMembersShiftCount = 0;
		let forAllMembersGiveAwayCount = 0;
	
		if (organisation.id) {
			if (person.isAdmin || person.assignedRequests) {
				const orgRequests = await orgUtils.getOrgShiftSwapRequests(organisation.id);
				const orgGiveAway = await orgUtils.getMemberGiveAwayRequests(organisation.id, null);
	
				pendingSwapShiftCount = orgRequests?.items?.filter(i => 
					i.status === "APPROVE_BY_MEMBER" || 
					(i.status === "PENDING" && (i.fromMemberID === memberId || i.memberID === memberId))
				).length ?? 0;
	
				pendingGiveAwayCount = orgGiveAway?.items?.filter(i => 
					i.status === "APPROVE_BY_MEMBER" || 
					(i.status === "PENDING" && (i.fromMemberID === memberId || i.memberID === memberId))
				).length ?? 0;
	
			} else {
				const memberGiveAway = await orgUtils.getMemberGiveAwayRequests(organisation.id, null);
				const memberRequests = memberId && (await orgUtils.getMemberShiftSwapRequests(organisation.id, memberId));
	
				pendingSwapShiftCount = memberRequests?.items?.filter(i => i.status === "PENDING").length || 0;
				pendingGiveAwayCount = memberGiveAway?.items?.filter(i => i.status === "PENDING" && i.memberID === memberId).length || 0;
			}
			const forAllMembersRequests = await orgUtils.getAllRequest(
				member.roleIDs ?? [],
				organisation.id
			);
			const forAllMembersGiveAway = await orgUtils.getAllGiveAway(
				member.roleIDs,
				organisation.id,
				memberId
			);
	
			forAllMembersShiftCount = forAllMembersRequests.items.filter(k => k.fromMemberID !== memberId).length;
			forAllMembersGiveAwayCount = forAllMembersGiveAway?.items.length || 0;
		}
		setPendingSwapShiftRequest(pendingSwapShiftCount + forAllMembersShiftCount);
		setPendingGiveAwayRequest(pendingGiveAwayCount + forAllMembersGiveAwayCount);
		setUnReadMessages(unreadMessageCount);
	};
	const manageShifts = () => {
		navigate(`${location.pathname === "/dashboard" ? "" : location.pathname}/calendar`, {
			state: {
				from: location.pathname || "/dashboard",
				backgroundLocation: location.pathname || "/dashboard",
				file: "/Organisation/Calendar",
				fullScreen: true
			}
		});
	};

	return (
		<div>
			<div>
				<img className="w-full object-cover h-40 lg:h-80" src={orgBgDefault} alt="" />
				<div className="absolute inset-0 bg-gray-400 mix-blend-multiply h-48 lg:h-80" aria-hidden="true" />
			</div>
			<div className="relative z-10 max-w-7xl min-h-fit mx-auto -mt-48 sm:-mt-40 lg:-mt-[19rem] ">
				<div className="h-44 sm:h-30 sm:h-24 pt-10 pb-4 sm:pt-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
					<div>
						<div className="flex lg:pl-10 gap-2 mb-2 sm:ml-2 justify-center">
							<Logo org={organisation} size="h-16 w-16 mt-1" />
							<div>
								<p className="text-2xl font-extrabold tracking-tight text-white text-start lg:text-left md:text-3xl lg:text-4xl">
									{organisation.name}
								</p>
								<div className="text-xs font-bold tracking-tight text-white text-start lg:text-left md:text-md lg:text-md">
									{organisation.address}
								</div>
								<div className="text-xs font-bold tracking-tight text-white text-start lg:text-left md:text-md lg:text-md">
									{organisation.phone}
								</div>
							</div>
						</div>
						{/* */}
						{/*  */}
						<div className="flex flex-row"></div>
						{/* </div> */}
					</div>
					<div className="flex flex-row justify-center">
						{/* {person.isAdmin && ( */}
						<div className="min-w-fit mr-1 ml-2 sm:my-0 md:mr-4">
							<button
								type="button"
								onClick={() => manageShifts()}
								className="inline-flex items-center pl-1 border border-transparent text-base font-medium rounded-full shadow-sm bg-white text-darkww-500 hover:text-darkww-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500"
							>
								<div className="flex">
									<div className="rounded-full bg-darkww-500 w-8 md:w-10 h-8 md:h-10 my-1 md:mb-0">
										<span className="sr-only">Rosters</span>
										<ClockIcon className="h-6 md:h-8 w-6 md:w-8 ml-1 mt-1 text-lightww-300" aria-hidden="true" />
									</div>
									<div className="inline-flex items-center pl-1 pr-2 md:pl-4 md:pr-6 py-1 md:py-3">Rosters</div>
								</div>
							</button>
						</div>
						<div className="min-w-fit mr-1 sm:my-0 md:mr-4">
							<Link
								to={activeMenu2.route}
								className="inline-flex items-center pl-1 border border-transparent text-base font-medium rounded-full shadow-sm bg-white text-darkww-500 hover:text-darkww-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500"
							>
								<div className="flex">
									<div className="rounded-full bg-darkww-500 w-8 md:w-10 h-8 md:h-10 my-1 relative">
										<span className="sr-only">{activeMenu2.name}</span>
										<ChatAlt2Icon className="h-6 md:h-8 w-6 md:w-8 ml-1 mt-1 text-lightww-300" aria-hidden="true" />
										{unReadMessages > 0 && (
											<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center -top-2 left-5">
												{unReadMessages}
											</div>
										)}
									</div>
									<div className="inline-flex items-center pl-1 pr-2 md:pl-4 md:pr-6 py-1 md:py-3">{activeMenu2.name}</div>
								</div>
							</Link>
						</div>{" "}
						<div className="min-w-fit mr-3 sm:my-0 sm:mr-2 lg:mr-12">
							<Link
								to={activeMenu.route}
								className="inline-flex items-center pl-1 border border-transparent text-base font-medium rounded-full shadow-sm bg-white text-darkww-500 hover:text-darkww-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500"
							>
								<div className="flex">
									<div className="rounded-full bg-darkww-500 w-8 md:h-10 h-8 md:w-10 my-1 relative">
										<span className="sr-only">{activeMenu.name}</span>
										<BellIcon className="h-6 md:h-8 w-6 md:w-8 ml-1 mt-1 text-lightww-300" aria-hidden="true" />
										{pendingtimeOff + pendingSwapShiftRequest + pendingGiveAwayRequest > 0 && (
											<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center -top-2 left-5">
											{Number(
													parseInt(pendingtimeOff) + parseInt(pendingSwapShiftRequest) + parseInt(pendingGiveAwayRequest)
												)}
											</div>
										)}
									</div>
									<div className="inline-flex items-center pl-1 pr-2 md:pl-4 md:pr-6 py-1 md:py-3">{activeMenu.name}</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
				<div className="bg-white rounded-xl shadow-lg mx-2 min-h-fit lg:mx-6 mb-8">{children}</div>
			</div>
		</div>
	);
}
