import React, { Fragment, useRef, useState } from "react";
// import logoImage from "ww-images/workwall-logo.svg";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "ww-framework";

// Hate this but just import the few files that are modals until react router comes up with a nicer solution!!!
import CalendarWrapper from "../../Views/Organisation/Calendar/Calendar";
import AddNotice from "../../Views/Organisation/Messages/AddNotice";
import EditDepartment from "../../Views/Organisation/Settings/Departments/EditDepartment";
import EditRole from "../../Views/Organisation/Settings/Roles/EditRole";
import LabelColourPicker from "../../Views/Organisation/Settings/Roles/LabelColourPicker";
import LateCheckInsNotification from "../../Views/Organisation/Settings/Staff/LateCheckInsNotification";
import Invitations from "../../Views/Person/Invitations";
// const LoadingPulse = () => {
// 	return (
// 		<>
// 			<div className="pt-8 pb-3 flex flex-col bg-white">
// 				<main className="animate-pulse flex-grow flex flex-col justify-center max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8">
// 					<div className="flex-shrink-0 flex justify-center">
// 						<a href="/" className="inline-flex">
// 							<span className="sr-only">Workwall</span>
// 							<img className="h-12 lg:h-24 w-auto" src={logoImage} alt="" />
// 						</a>
// 					</div>
// 					<div className="py-8">
// 						<div className="text-center">
// 							<p className="text-sm font-semibold text-darkww-600 uppercase tracking-wide">Just a sec...</p>
// 							<p className="mt-2 text-base text-gray-500">We're getting your page.</p>
// 						</div>
// 					</div>
// 				</main>
// 			</div>
// 		</>
// 	);
// };

const ModalBody = ({ fileLocation, compProps }) => {
	switch (fileLocation) {
		case "/Organisation/Calendar":
			return <CalendarWrapper compProps={compProps} />;
		case "/Organisation/Settings/Roles/EditRole":
			return <EditRole compProps={compProps} />;
		case "/Organisation/Settings/Departments/EditDepartment":
			return <EditDepartment compProps={compProps} />;
		case "/Organisation/Settings/Roles/LabelColourPicker":
			return <LabelColourPicker compProps={compProps} />;
		case "/Person/Invitations":
			return <Invitations compProps={compProps} />;
		case "/Organisation/AddNotice":
			return <AddNotice compProps={compProps} />;
		case "/Organisation/Settings/Staff/LateCheckInsNotification":
			return <LateCheckInsNotification />;
		default:
			return null;
	}
};

export const RouteModal = ({ file, compProps }) => {
	const [open, setOpen] = useState(false);
	const cancelButtonRef = useRef(null);
	const navigate = useNavigate();

	const toggleOpen = (e) => {
		if (!e) return;
		e.stopPropagation();
		if (open) {
			setOpen(false);
			setTimeout(() => navigate(-1), 300);
		} else {
			setOpen(true);
		}
	};

	React.useEffect(() => {
		document.body.classList.add("overflow-hidden"); // Just to prevent background scroll whilst the modal is open.
		setOpen(true);

		return () => {
			document.body.classList.remove("overflow-hidden");
		};
	}, []);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-10 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				open={open}
				onClose={(e) => toggleOpen(e)}
			>
				<div className="flex items-center justify-center h-full w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit">
							<span
								className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer"
								onClick={(e) => toggleOpen(e)}
								ref={cancelButtonRef}
							>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<ModalBody fileLocation={file} compProps={compProps} />
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
