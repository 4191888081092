import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const SimpleSelect = ({ ...props }) => {
	const { type, name, label, value, options, onChange } = props;
	const [selected, setSelected] = useState(options.find((option) => option.id === value));

	const onSelection = (selection) => {
		setSelected(selection);
		onChange(selection);
	};

	return (
		<Listbox value={selected} onChange={onSelection}>
			{({ open }) => (
				<>
					{type !== "hidden" && (
						<label htmlFor={name} className="block text-sm font-medium text-gray-700">
							{label}
						</label>
					)}
					<div className="mt-1 relative">
						<Listbox.Button
							className={`relative w-full bg-white border border-gray-300 h-10 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-lightww-500 focus:border-lightww-500 sm:text-sm`}
						>
							<span className="block truncate">{selected?.description}</span>
							<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
								{options.map((option) => {
									return (
										<Listbox.Option
											key={option.id}
											className={({ active }) =>
												classNames(
													active ? "text-white bg-lightww-600" : "text-gray-900",
													"cursor-default select-none relative py-2 pl-8 pr-4"
												)
											}
											value={option}
										>
											{({ selected, active }) => {
												return (
													<>
														<span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
															{option.description}
														</span>

														{selected ? (
															<span
																className={classNames(
																	active ? "text-white" : "text-lightww-600",
																	"absolute inset-y-0 left-0 flex items-center pl-1.5"
																)}
															>
																<CheckIcon className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												);
											}}
										</Listbox.Option>
									);
								})}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};
