import { ChatIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React, { useContext } from "react";
import { formateEpochToShortMonthDate2, notifyUser, showFailure, useSimpleMessage } from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";

const GiveAwayRequests = ({ allGiveAway, pendingGiveAwayRequest, acceptAllGiveAway, addNote, acceptGiveAway, loadRoles, setIsLoading, memberID }) => {
	const personObject = useContext(PersonContext);
	const person = personObject.person;
	const { organisation } = useContext(OrganisationContext);
	const { setMessage } = useSimpleMessage();
	const deny = async (e, id, type = null, m = null, status = "NOT_APPROVE") => {
		try {
			setIsLoading(true);
			if (type === "Swap Shift") {
				const shiftData = {
					...{
						id: id?.id,
						organisationID: id?.organisationID,
						fromMemberID: id?.fromMemberID,
						fromShiftId: id?.fromShiftId,
						toShiftId: id?.toShiftId,
						memberID: id?.memberID,
						status: person.isAdmin || person.assignedRequests ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
						note: id?.note || null
					}
				};
				await orgUtils.saveShiftSwapRequest(shiftData);
			} else if (type === "Give Away") {
				const shiftData = {
					...{
						id: id.id,
						organisationID: id.organisationID,
						fromMemberID: id.fromMemberID,
						fromShiftId: id.fromShiftId,
						toShiftId: id.toShiftId,
						memberID: id.memberID,
						status: person.isAdmin ? "DENIED_BY_MANAGER" : "DENIED_BY_MEMBER",
						note: id?.note || null
					}
				};
				await orgUtils.saveGiveAwayRequest(shiftData);
			} else {
				await orgUtils.changeStatusTimeOff(id, status);
			}
			m && (await notifyUser([m], `Your ${type} request has been denied`));
			await loadRoles();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to change preference.",
					subTitle: error.message
				})
			);
		}
	};

	return (
		<ul className="flex flex-col divide-y w-full">
			{pendingGiveAwayRequest.map((giveAway, index) => (
				<li key={`${index}-requests`} className="flex flex-row">
					<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
						<div className="flex-1 pl-1">
							<span className="text-gray-600">Give Away: </span>
							<br className="sm:hidden" />
							<span className="font-medium text-lg">
								{giveAway?.fromMember?.person?.id === person.id
									? "You"
									: personUtils.displayName(giveAway?.fromMember?.person) || giveAway?.fromMember?.orgUsername || "User Removed"}
							</span>
							<span className="text-gray-600 text-xs">
								{" would like to give "}
								<span className={giveAway?.member?.person?.id === person.person.id ? "font-medium" : "font-medium text-lg"}>
									{giveAway?.member?.person?.id === person.id
										? "you"
										: personUtils.displayName(giveAway?.member?.person) || giveAway?.member?.orgUsername || "User Removed"}
								</span>
								<span style={{ color: giveAway?.shift?.role?.color }}>
									{" a "}
									{giveAway?.shift?.role?.name}
								</span>
								{" shift on "}
								<span className="text-gray-600 text-xs">
									{giveAway?.shift?.shiftStart && formateEpochToShortMonthDate2(giveAway.shift.shiftStart, true)}
								</span>
							</span>
							<span className="text-blue-900 inline-flex ml-1">
								{giveAway?.note && (
									<Tooltip trigger={["click", "hover"]} overlay={giveAway.note}>
										<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
									</Tooltip>
								)}
							</span>
						</div>

						{giveAway.status === "APPROVE_BY_MEMBER" && (person.isAdmin || person.assignedRequests) ? (
							<div className="flex flex-row">
								<button
									className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-3 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
									onClick={async (e) => {
										await addNote(e, giveAway, "Give Away", organisation.owner, "APPROVE_BY_MANAGER");
										acceptGiveAway(e, giveAway, "Give Away", organisation.owner, "APPROVE_BY_MANAGER");
									}}
								>
									<CheckCircleIcon className="w-6 h-6" />
									<span className="sm:block hidden pl-1">Confirm</span>
								</button>
								<button
									className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-3 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
									onClick={async (e) => {
										await addNote(e, giveAway, "Give Away", organisation.owner, "DENIED_BY_MANAGER");
										deny(e, giveAway, "Give Away", organisation.owner, "DENIED_BY_MANAGER");
									}}
								>
									<XCircleIcon className="w-6 h-6" />
									<span className="sm:block hidden pl-1">Deny</span>
								</button>
							</div>
						) : giveAway.status === "PENDING" ? (
							<div className="flex flex-row">
								{giveAway.fromMemberID === memberID && (
									<button
										className="inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
										onClick={(e) => deny(e, giveAway, "Give Away", organisation.owner)}
									>
										<XCircleIcon className="w-6 h-6" />
										<span className="sm:block hidden pl-1">Cancel</span>
									</button>
								)}
								{giveAway.memberID === memberID && (
									<>
										<button
											className="inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
											onClick={(e) => acceptGiveAway(e, giveAway, "Give Away", organisation.owner, "APPROVE_BY_MEMBER")}
										>
											<CheckCircleIcon className="w-6 h-6" />
											<span className="sm:block hidden pl-1">Accept</span>
										</button>
										<button
											className="ml-2 inline-flex items-center bg-pink-700 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
											onClick={(e) => deny(e, giveAway, "Give Away", organisation.owner)}
										>
											<XCircleIcon className="w-6 h-6" />
											<span className="sm:block hidden pl-1">Refuse</span>
										</button>
									</>
								)}
							</div>
						) : null}
					</div>
				</li>
			))}
			{allGiveAway &&
				allGiveAway.map((k, i) =>
					k.shiftId ? (
						<li key={`${i}-srequests`} className="flex flex-row">
							<div className="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-4">
								<div className="flex-1 pl-1">
									<span className="text-gray-600">Give Away: </span>
									<br className="sm:hidden" />
									<span className="font-medium">
										{k?.fromMember?.id
											? personUtils.displayName(k?.fromMember?.person) || k?.fromMember?.orgUsername
											: k?.fromMember?.orgUsername || "User Removed"}
									</span>
									<span className="text-gray-600 text-xs">
										{" "}
										<span className="text-darkww-800">
											would like to give away a {k?.shift?.role?.name} shift on{" "}
											<span className="text-gray-600 text-xs">
												{k?.shift?.shiftStart && formateEpochToShortMonthDate2(k.shift.shiftStart, true)}{" "}
												<span className="text-blue-900 inline-flex ml-1">
													{k?.note && (
														<Tooltip trigger={["click", "hover"]} overlay={k.note}>
															<ChatIcon className="w-5 h-5 ml-1 text-lightww-400" />
														</Tooltip>
													)}
												</span>{" "}
											</span>
										</span>
									</span>
								</div>
								<div className="flex flex-row">
									<button
										className="mr-2 inline-flex items-center bg-darkww-500 py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white text-right flex justify-end"
										onClick={(e) => acceptAllGiveAway(e, k, "Give Away", organisation, "APPROVE_BY_MEMBER")}
									>
										<CheckCircleIcon className="w-6 h-6" />
										<span className="sm:block hidden pl-1">Take</span>
									</button>
								</div>
							</div>
						</li>
					) : null
				)}
		</ul>
	);
};

export default GiveAwayRequests;
