import { SimpleMessage } from "../Modals/SimpleMessage.jsx";
import { PersonProvider, OrganisationProvider, SubscriptionProvider } from "ww-stores";

export const AppProviders = ({ children }) => {
	return (
		<PersonProvider>
			<OrganisationProvider>
				<SubscriptionProvider>
					<SimpleMessage>{children}</SimpleMessage>
				</SubscriptionProvider>
			</OrganisationProvider>
		</PersonProvider>
	);
};
