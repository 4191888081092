import { Formik } from "formik";

export const Form = ({ children, onSubmit, initialValues, validationSchema, enableReinitialize, disabled }) => {
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(data, { setSubmitting, resetForm }) => onSubmit(data, { setSubmitting, resetForm })}
			enableReinitialize={enableReinitialize}
			disabled={disabled}
		>
			{({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit} className={`space-y-4 ${disabled ? "opacity-70" : ""}`}>
						{children}
					</form>
				);
			}}
		</Formik>
	);
};
