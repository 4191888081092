import { useContext } from "react";
import { PersonContext } from "ww-stores";
import Welcome from "../Person/Welcome";
import Dashboard from "../Organisation/Dashboard";

// The Home screen just decides to either show the user an Organisation Dashboard, if they have an org, or the Welcome screen if not.
export default function Home() {
	const { person } = useContext(PersonContext);

	return <>{person.currentOrganisation ? <Dashboard /> : <Welcome profileCreated={person.id} />}</>;
}




