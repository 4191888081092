import { ClockIcon, ExclamationIcon, RefreshIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, InformationCircleIcon, UserAddIcon } from "@heroicons/react/solid";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    Avatar,
    Button,
    CheckBox,
    ConfirmModal,
    Form,
    InputField,
    LinkButton,
    Menu,
    Tooltip,
    Transition as Transitions,
    Yup,
    showFailure,
    showSuccess,
    useSimpleMessage
} from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import { determineCurrency, fetchLocationData } from "../../../../ww-framework/Utils/currencyUtils";
import { getDueCheckIns, getLateCheckIns } from "../../../../ww-stores/Organisation/checkIns";
import AddEmailToMember from "../AddEmailToMember";
import LeaveOptionsOverlay from "./LeaveOptionsOverlay";
import StaffCheckInsOverlay from "./StaffCheckInsOverlay";
import { StaffDetailsDialog } from "./StaffDetails/StaffDetailsDialog";

const OwnerTag = () => {
	return (
		<span className="inline-flex items-center px-3 py-0.5 mb-2 mr-3 rounded-full text-sm font-medium bg-darkww-100 text-white">
			<svg className="-ml-1 mr-1.5 h-2 w-2 text-lightww-100" fill="currentColor" viewBox="0 0 8 8">
				<circle cx={4} cy={4} r={3} />
			</svg>
			Owner
		</span>
	);
};

const HelpLabel = () => {
	return (
		<div className="rounded-md bg-lightww-50 mt-4 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<InformationCircleIcon className="h-5 w-5 text-lightww-400" aria-hidden="true" />
				</div>
				<div className="ml-3 flex-1 md:flex md:justify-between">
					<p className="text-sm text-darkww-900">
						The Name is only for your own reference. The name the user has chosen themselves will show once they join.
					</p>
				</div>
			</div>
		</div>
	);
};

const memberSchema = Yup.object().shape({
	name: Yup.string().required().min(3),
	email: Yup.string().email()
});

const memberSchemaWithEmail = Yup.object().shape({
	name: Yup.string().required().min(3),
	email: Yup.string().email().required()
});

async function inviteMemberByEmail(
	email,
	name,
	resetForm,
	allStaffMembers,
	organisation,
	staffMembers,
	otherLocationMembers,
	useEmail,
	setMessage,
	showFailure,
	setSavingMember,
	orgUtils,
	setOrganisation,
	showSuccess,
	getSubscription,
	setUseEmail
) {
	if (!allStaffMembers.find((member) => member.email?.toLowerCase() === email?.toLowerCase())) {
		try {
			const emailAddress = useEmail ? email?.toLowerCase() : null;
			const maxAllowedMembers = organisation?.maxAllowedMembers;
			const currentOrgMembersNumber = staffMembers?.length;
			const otherLocationMembersNumber = otherLocationMembers?.length;
			const membersExceedLimit = currentOrgMembersNumber + otherLocationMembersNumber >= maxAllowedMembers;

			if (emailAddress && membersExceedLimit) {
				let find = otherLocationMembers.find((k) => k === emailAddress);
				if (!find) {
					return setMessage(
						showFailure({
							title: "Limit Exhausted Unable to add Staff Member."
						})
					);
				}
			}

			setSavingMember(true);
			const results = await orgUtils.addMember({}, organisation.id, emailAddress, name);
			const {
				orgMember: { id: orgMemberID },
				organisation: updatedOrg
			} = results;

			if (emailAddress) {
				await orgUtils.createInvitation(orgMemberID, emailAddress);
			}

			const mergedOrg = {
				...updatedOrg,
				...(await orgUtils.mapOrgMembers(updatedOrg)),
				...{ report: await orgUtils.mapReport(updatedOrg?.report?.items || [], updatedOrg.id) },
				...{ departments: updatedOrg.departments?.items || [] },
				...{
					maxAllowedMembers: await getSubscription()
				}
			};
			setOrganisation(mergedOrg);
			setSavingMember(false);
			resetForm();
			setUseEmail(false);
			setMessage(
				showSuccess({
					title: `Staff Member Successfully Added${emailAddress ? " and Invited" : ""}.`
				})
			);
		} catch (error) {
			setSavingMember(false);
			console.log("::error", error);
			setMessage(
				showFailure({
					title: "Unable to add Staff Member.",
					subTitle: error.message
				})
			);
		}
	} else {
		setMessage(
			showFailure({
				title: "This email address is already registered to an existing user."
			})
		);
	}
}

const StaffMembers = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);
	const { members, deletedMembers, allMembers } = organisation;
	const { setMessage } = useSimpleMessage();
	const [allStaffMembers, setAllStaffMembers] = useState([]);
	const [staffMembers, setStaffMembers] = useState([]);
	const [deletedStaffMembers, setDeletedStaffMembers] = useState([]);
	const [rates, setRates] = useState({ values: [] });
	const [maxHours, setMaxHours] = useState({ values: [] });
	const [employeeNu, setEmployeeNu] = useState({ values: [] });
	const [savingMember, setSavingMember] = useState(false);
	const [useEmail, setUseEmail] = useState(false);
	const [roles, setRoles] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [open, setOpen] = useState(false);
	const [openFinancials, setOpenFinancials] = useState(false);
	const [openRequests, setOpenRequests] = useState(false);
	const [openSuperAdmin, setOpenSuperAdmin] = useState(false);
	const [openAddEmail, setOpenAddEmail] = useState(false);
	const [member, setMember] = useState();
	const [checked, setChecked] = useState(false);
	const [otherLocationMembers, setOtherLocationMembers] = useState([]);
	const [overlayOpen, setOverLayOpen] = useState(false);
	const [currentMemberDetail, setCurrentMemberDetail] = useState(false);
	const [openConfirmRemove, setOpenConfirmRemove] = useState(false);
	const [openConfirmRestore, setOpenConfirmRestore] = useState(false);
	const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
	const [openSetInvitationStatusPending, setOpenSetInvitationStatusPending] = useState(false);
	const [confirmMember, setConfirmMember] = useState(null);
	const [confirmInvitationID, setConfirmInvitationID] = useState(null);
	const [manageCheckInsOverlayOpen, setManageCheckInsOverlayOpen] = useState(false);
	const [manageLeavesOverlayOpen, setManageLeavesOverlayOpen] = useState(false);
	const [memberNameFilter, setMemberNameFilter] = useState("");
	const [filteredStaffMembers, setFilteredStaffMembers] = useState([]);
	const [deletedMemberNameFilter, setDeletedMemberNameFilter] = useState("");
	const [filteredDeletedStaffMembers, setFilteredDeletedStaffMembers] = useState([]);


	useEffect(() => {
		if (members) {
			setAllStaffMembers(allMembers);
			setStaffMembers(members);
			setDeletedStaffMembers(deletedMembers);
			setMaxHours({
				values: members.map((member) => member.maxHours || 48) // Default to 48 if no value exists
			});
		}

		if (organisation.id) loadRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members]);

	useEffect(() => {
		if (loggedInPerson?.organisations?.items?.length > 0) {
			getAllMembersOfSubOrganisation();
		} else {
			setOtherLocationMembers(members);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members, loggedInPerson]);

	const getAllMembersOfSubOrganisation = async () => {
		const allOtherOrganisations = loggedInPerson.organisations.items
			.filter((k) => k.organisationID !== loggedInPerson.currentOrganisation.id)
			.map((orgmember) => orgmember.organisation);

		const membersInOtherLocations = await Promise.all(
			allOtherOrganisations.map(async (org) => {
				let data = await orgUtils.getOrgMembers(org.id);
				let activeMembers = data?.items?.filter((member) => member.status !== "DELETED");

				return activeMembers;
			})
		);
		const mergedMembers = membersInOtherLocations.flat();
		let mem = [...new Set(mergedMembers.map((m) => m.email))];
		setOtherLocationMembers(mem);
	};

	const loadRoles = async () => {
		try {
			const { roles, departments } = await orgUtils.get(organisation);
			setRoles(roles?.items || []);
			setDepartments(departments?.items || []);
		} catch (err) {
			console.log(err);
		}
	};

	const updateOrgAndMembers = async () => {
		const org = organisation;
		const updatedOrganisation = await orgUtils.get(org);
		const { allMembers, members, deletedMembers, departments, report, subscription } = updatedOrganisation;
		const mappedAllMembers = await orgUtils.mapMembers(allMembers);
		const mappedMembers = await orgUtils.mapMembers(members);
		const mappedDeletedMembers = await orgUtils.mapMembers(deletedMembers);
		const mergedOrg = {
			...org,
			...(await orgUtils.mapOrgMembers(updatedOrganisation)),
			...{ report: await orgUtils.mapReport(report?.items || [], org.id) },
			...{ departments: departments?.items || [] },
			...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? subscription?.items?.[0]?.quantity }
		};
		setAllStaffMembers(mappedAllMembers);
		setStaffMembers(mappedMembers);
		setDeletedStaffMembers(mappedDeletedMembers);
		setOrganisation(mergedOrg);
	};
	const upsertToEmployee = async (record, staff, type) => {
		try {
			if (type === "Role") {
				let roles = staff?.roleIDs ?? [];
				!roles.includes(record.id) ? roles.push(record.id) : roles.splice(roles.indexOf(record.id), 1);

				await orgUtils.addRoleToMember(roles, staff.orgUserId);
			}
			if (type === "Department") {
				let departments = staff?.departmentIDs ?? [];
				!departments.includes(record.id) ? departments.push(record.id) : departments.splice(departments.indexOf(record.id), 1);
				await orgUtils.addDepartmentToMember(departments, staff.orgUserId);
			}
			if (type === "File") {
				let files = staff?.employeeFile ? [...staff.employeeFile] : []; // Spread to create a new array instance
				const newFile = JSON.stringify({ id: record.result.id, date: record.startDate, name: record.result.name });
				if (!files.includes(newFile)) {
					// Check if the file doesn't already exist in the array
					files.push(newFile);
				}
				await orgUtils.addFileToMember(files, staff.orgUserId, organisation.id);
				return files;
			}
			updateOrgAndMembers();
			loadRoles();
			setMessage(
				showSuccess({
					title: `${record.name} ${type} updated for ${
						staff?.id ? personUtils.displayName(staff) || staff.orgUsername : staff.orgUsername || "No Name Found"
					}.`
				})
			);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: `Unable to update ${type} for ${
						staff?.id ? personUtils.displayName(staff) || staff.orgUsername : staff.orgUsername || "No Name Found"
					}.`,
					subTitle: error.message
				})
			);
		}
	};

	const removeOrRestoreMember = async (member, restore = false) => {
		restore ? setOpenConfirmRestore(false) : setOpenConfirmRemove(false);
		try {
			await orgUtils.updateOrgMember({ status: restore ? "ACTIVE" : "DELETED" }, member.orgUserId);
			updateOrgAndMembers();
			loadRoles();
			setMessage(
				showSuccess({
					title: `Staff Member Successfully ${restore ? "Restored" : "Removed"}`
				})
			);
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: `Unable to ${restore ? "restore" : "remove"} Staff Member.`,
					subTitle: error.message
				})
			);
		}
	};

	const permanentlyDeleteMember = async (member, invitationID) => {
		setOpenConfirmDelete(false);

		try {
			if (invitationID) {
				await orgUtils.deleteInvitation(invitationID);
			}

			await orgUtils.removeMember(member.orgUserId, invitationID);
			const updatedOrg = await orgUtils.get(organisation);
			const { members, deletedMembers, allMembers, report, departments, subscription } = updatedOrg;
			const mappedAllMembers = await orgUtils.mapMembers(allMembers);
			const mappedMembers = await orgUtils.mapMembers(members);
			const mappedDeletedMembers = await orgUtils.mapMembers(deletedMembers);

			const mergedOrg = {
				...organisation,
				...(await orgUtils.mapOrgMembers(updatedOrg)),
				...{ report: await orgUtils.mapReport(report?.items || [], organisation.id) },
				...{ departments: departments?.items || [] },
				...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? subscription?.items[0].quantity }
			};

			setAllStaffMembers(mappedAllMembers);
			setStaffMembers(mappedMembers);
			setDeletedStaffMembers(mappedDeletedMembers);
			setOrganisation(mergedOrg);

			setMessage(
				showSuccess({
					title: "Staff Member Successfully Deleted."
				})
			);
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to remove Staff Member.",
					subTitle: error.message
				})
			);
		}
	};

	const confirmPopup = (e, member) => {
		if (e !== "") e.stopPropagation();
		setMember(member);
		setChecked(e.target.checked);
		setOpen(true);
	};
	const confirmPopupFinancials = (e, member) => {
		if (e !== "") e.stopPropagation();
		setMember(member);
		setChecked(e.target.checked);
		setOpenFinancials(true);
	};
	const confirmPopupRequests = (e, member) => {
		if (e !== "") e.stopPropagation();
		setMember(member);
		setChecked(e.target.checked);
		setOpenRequests(true);
	};
	const confirmPopupSuperAdmin = (e, member) => {
		if (e !== "") e.stopPropagation();
		setMember(member);
		setChecked(e.target.checked);
		setOpenSuperAdmin(true);
	};
	const handlerModal = async (e, type) => {
		e.preventDefault();
		setOpen(false);
		if (type === "confirm") {
			try {
				await orgUtils.assignAsAdminMember(checked, member.orgUserId);
				updateOrgAndMembers();
				setMessage(
					showSuccess({
						title: `${checked ? "Assigned" : "Unassigned"} ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin to edit data.`
					})
				);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: `Unable to assign ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin to edit data.`,
						subTitle: error.message
					})
				);
			}
		}
	};
	const handlerModalFinancials = async (e, type) => {
		e.preventDefault();
		setOpenFinancials(false);
		if (type === "confirm") {
			try {
				await orgUtils.assignFinancialsForAssignedAdmin(checked, member.orgUserId);
				updateOrgAndMembers();
				setMessage(
					showSuccess({
						title: `${checked ? "Assigned" : "Unassigned"} ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin to view financials.`
					})
				);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: `Unable to assign ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin for financials.`,
						subTitle: error.message
					})
				);
			}
		}
	};
	const handlerModalRequests = async (e, type) => {
		e.preventDefault();
		setOpenRequests(false);
		if (type === "confirm") {
			try {
				await orgUtils.assignAsRequestsMember(checked, member.orgUserId);
				updateOrgAndMembers();
				setMessage(
					showSuccess({
						title: `${checked ? "Assigned" : "Unassigned"} ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin to handle employee requests.`
					})
				);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: `Unable to assign ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin for Requests.`,
						subTitle: error.message
					})
				);
			}
		}
	};
	const handlerModalSuperAdmin = async (e, type) => {
		e.preventDefault();
		setOpenSuperAdmin(false);
		if (type === "confirm") {
			try {
				await orgUtils.assignAsSuperAdmin(checked, member.orgUserId);
				updateOrgAndMembers();
				setMessage(
					showSuccess({
						title: `${checked ? "Assigned" : "Unassigned"} ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin to view and edit the admin page.`
					})
				);
			} catch (error) {
				console.log(error);
				setMessage(
					showFailure({
						title: `Unable to assign ${
							member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
						} as admin for editing admin page.`,
						subTitle: error.message
					})
				);
			}
		}
	};

	const handlerConfirmRemoveOrRestoreModal = (e, type) => {
		e.preventDefault();

		if (type === "confirm" && confirmMember) {
			removeOrRestoreMember(confirmMember, openConfirmRestore);
		}
		openConfirmRestore ? setOpenConfirmRestore(false) : setOpenConfirmRemove(false);
	};

	const handlerConfirmDeleteModal = (e, type) => {
		e.preventDefault();

		if (type === "confirm" && confirmMember) {
			permanentlyDeleteMember(confirmMember, confirmInvitationID.id);
		}
		setOpenConfirmDelete(false);
	};

	const handlerSetInvitationStatusPendingModal = async (e, type) => {
		e.preventDefault();

		if (type === "confirm" && confirmMember) {
			const invitation = confirmMember?.invitations?.items?.[0];
			const inviteId = invitation?.id;
			const action = "PENDING";
			await personUtils.actionInvitation(inviteId, action);
			const updatedMember = { ...confirmMember, invitations: { items: [{ ...invitation, status: "Pending" }] } };
			const updatedMembers = staffMembers.map((m) => (m.orgUserId === confirmMember.orgUserId ? updatedMember : m));
			setStaffMembers(updatedMembers);
			setMessage(
				showSuccess({
					title: `Invitation status changed to pending for ${
						confirmMember?.id
							? personUtils.displayName(confirmMember) || confirmMember.orgUsername
							: confirmMember.orgUsername || "No Name Found"
					}`
				})
			);
		}
		setOpenSetInvitationStatusPending(false);
	};

	const handleChange = async (value, member, type = "value") => {
		try {
			if (type === "value") await orgUtils.addHourlyRateToMember(parseFloat(value), member.orgUserId);
			if (type === "maxhours") {
				await orgUtils.addMaxHoursToMember(parseFloat(value), member.orgUserId);
			}
			if (type === "unit") await orgUtils.addRateUnitToMember(value, member.orgUserId);
			if (type === "Employee No") await orgUtils.addEmployeeNuToMember(value, member.orgUserId);
			if (type === "Start Date") await orgUtils.addStartDateToMember(value, member.orgUserId);
			if (type === "completed-checkIn") await orgUtils.saveCompletedCheckIn(value);

			updateOrgAndMembers();
			loadRoles();

			setMessage(
				showSuccess({
					title: `${type} Added for ${
						member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found"
					}`
				})
			);
			return true;
		} catch (error) {
			console.log(error);
			setMessage(
				showFailure({
					title: "Unable to save employee details.",
					subTitle: error.message
				})
			);
			return false;
		}
	};
	const handleClickChange = async (e, i, type = "rates") => {
		try {
			if (type === "maxhours") {
				const updatedMaxHours = [...maxHours.values]; // Spread the existing state
				updatedMaxHours[i] = e.target.value; // Update the specific index
				setMaxHours({ values: updatedMaxHours }); // Update state with new value
			}
			type === "value"
				? setRates({
						values: { ...(rates.values || ""), [i]: e.target.value },
						unit: { ...(rates.unit || "HOURLY") }
				  })
				: type === "unit" &&
				  setRates({
						values: { ...(rates.values || "") },
						unit: { ...(rates.unit || "HOURLY"), [i]: e }
				  });
			if (type === "employeeNu")
				setEmployeeNu({
					values: { ...(employeeNu || ""), [i]: e.target.value }
				});
		} catch (error) {}
	};

	const handleAddEmail = (member) => {
		setOpenAddEmail(true);
		setMember(member);
	};
	const handlerEmailModal = () => {
		setOpenAddEmail(false);
		setMember({});
	};
	const getSubscription = async () => {
		let subscription = {};
		if (loggedInPerson?.organisations?.items?.filter((k) => k?.owner === loggedInPerson?.currentOrganisation?.owner)?.length ?? 0 > 1) {
			const firstOrg = loggedInPerson?.organisations?.items.reduce((a, b) =>
				a?.organisation?.createdAt < b?.organisation?.createdAt ? a : b
			)?.organisation;

			const first = await orgUtils.get(firstOrg); //get subscription inly instead.
			subscription = first?.subscription;
		} else subscription = organisation.subscription;
		window.sessionStorage.setItem("mam", subscription?.items?.[0]?.quantity ?? 0);
		return subscription?.items[0]?.quantity || 20;
	};
	const showStaffDetails = async (member) => {
		setOverLayOpen(true);
		setCurrentMemberDetail(member);
	};

	const showManageCheckIns = async (organisation) => {
		setManageCheckInsOverlayOpen(true);
	};

	function filterMemberByName(memberName, memberNameKey) {
		return memberName?.toLowerCase().startsWith(memberNameKey.toLowerCase()) || memberName?.toLowerCase().includes(memberNameKey.toLowerCase());
	}

	useEffect(() => {
		const setFilters = async (memberNameKey) => {
			if (memberNameKey) {
				setMemberNameFilter(memberNameKey);
				setFilteredStaffMembers(staffMembers.filter((m) => filterMemberByName(personUtils.displayName(m), memberNameKey)));
			} else setFilteredStaffMembers(staffMembers);
		};
		setFilters(memberNameFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberNameFilter]);

	useEffect(() => {
		const setFilters = async (memberNameKey) => {
			if (memberNameKey) {
				setDeletedMemberNameFilter(memberNameKey);
				setFilteredDeletedStaffMembers(deletedMembers.filter((m) => filterMemberByName(personUtils.displayName(m), memberNameKey)));
			} else setFilteredDeletedStaffMembers(deletedMembers);
		};
		setFilters(deletedMemberNameFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deletedMemberNameFilter]);

	return (
		<>
			<AddEmailToMember open={openAddEmail} record={member} handlerModal={handlerEmailModal} setOrganisation={setOrganisation} />
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title={`${checked ? "Assign" : "Remove"} this employee as admin to edit data?`}
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerModalFinancials}
				open={openFinancials}
				className="text-base font-medium text-gray-900"
				title={`${checked ? "Assign" : "Remove"} this assigned admin to view financials?`}
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerModalRequests}
				open={openRequests}
				className="text-base font-medium text-gray-900"
				title={`${checked ? "Assign" : "Remove"} this employee as admin to handle employee requests?`}
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerModalSuperAdmin}
				open={openSuperAdmin}
				className="text-base font-medium text-gray-900"
				title={`${checked ? "Assign" : "Remove"} this employee to view & edit the admin page?`}
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerConfirmRemoveOrRestoreModal}
				member={member}
				open={openConfirmRemove || openConfirmRestore}
				className="text-base font-medium text-gray-900"
				title={`${openConfirmRemove ? "Remove" : "Restore"} this employee ${
					openConfirmRemove ? "from" : "to"
				} your list of active employees?`}
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerConfirmDeleteModal}
				member={member}
				open={openConfirmDelete}
				className="text-base font-medium text-gray-900"
				title={`Delete this employee and all their data & work history from your organisation?`}
				subTitle="Are you sure?"
			/>
			<ConfirmModal
				handlerModal={handlerSetInvitationStatusPendingModal}
				member={member}
				open={openSetInvitationStatusPending}
				className="text-base font-medium text-gray-900"
				title={`Change this employee's invitation status to pending?`}
				subTitle="Please confirm to continue."
			/>
			<StaffDetailsDialog
				currentMemberOrgUserId={currentMemberDetail.orgUserId}
				overlayOpen={overlayOpen}
				setOverlayOpen={setOverLayOpen}
				updateOrgAndMembers={updateOrgAndMembers}
				upsertToEmployee={upsertToEmployee}
			/>
			<StaffCheckInsOverlay overlayOpen={manageCheckInsOverlayOpen} setOverlayOpen={setManageCheckInsOverlayOpen} />
			<LeaveOptionsOverlay overlayOpen={manageLeavesOverlayOpen} setOverlayOpen={setManageLeavesOverlayOpen} />
			<div className="divide-y divide-gray-200 lg:col-span-10 h-fit">
				<div className="p-6 px-4 sm:p-6 lg:pb-8">
					<div className="text-center">
						<svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 48 48" aria-hidden="true">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
							/>
						</svg>
						{organisation?.maxAllowedMembers > staffMembers?.length + otherLocationMembers?.length ? (
							<>
								<h2 className="mt-2 text-lg font-medium text-gray-900">Add Staff Members</h2>
								<p className="my-2 text-sm text-gray-500">
									Manage your staff members here. You can add/invite new members or update existing ones.
								</p>
							</>
						) : (
							<>
								<h2 className="mt-2 text-lg font-medium text-gray-900">
									You can add only {organisation?.maxAllowedMembers} Members to your Organisations
								</h2>
								<div className="inline-block items-center justify-center mt-2 px-8 py-1 bg-red-200 rounded mx-auto">
									<span className="text-black text-sm">
										You currently have {staffMembers?.length + otherLocationMembers?.length} active staff registered
									</span>
								</div>

								<p className="mt-1 text-sm text-gray-500">either remove an active employee or upgrade your subscription.</p>
							</>
						)}
					</div>
					{organisation?.maxAllowedMembers > staffMembers?.length + otherLocationMembers?.length && (
						<Form
							validationSchema={useEmail ? memberSchemaWithEmail : memberSchema}
							enableReinitialize={true}
							disabled={savingMember}
							initialValues={{ name: "", email: "" }}
							onSubmit={async (data, { resetForm }) => {
								try {
									const emailAddress = useEmail ? data.email?.toLowerCase() : null;
									const maxAllowedMembers = organisation?.maxAllowedMembers;
									const currentOrgMembersNumber = staffMembers?.length;
									const otherLocationMembersNumber = otherLocationMembers?.length;
									const membersExceedLimit = currentOrgMembersNumber + otherLocationMembersNumber >= maxAllowedMembers;

									if (allStaffMembers.find((member) => member.email?.toLowerCase() === emailAddress)) {
										return setMessage(
											showFailure({
												title: "This email address is already registered to an existing user."
											})
										);
									}

									if (emailAddress && membersExceedLimit) {
										let find = otherLocationMembers.find((k) => k === emailAddress);
										if (!find)
											return setMessage(
												showFailure({
													title: "Limit Exhausted Unable to add Staff Member."
												})
											);
									}

									setSavingMember(true);
									const results = await orgUtils.addMember({}, organisation.id, emailAddress, data.name);
									const {
										orgMember: { id: orgMemberID },
										organisation: updatedOrg
									} = results;

									if (emailAddress) {
										await orgUtils.createInvitation(orgMemberID, emailAddress);
									}

									const mergedOrg = {
										...updatedOrg,
										...(await orgUtils.mapOrgMembers(updatedOrg)),
										...{ report: await orgUtils.mapReport(updatedOrg?.report?.items || [], updatedOrg.id) },
										...{ departments: updatedOrg.departments?.items || [] },
										...{
											maxAllowedMembers: await getSubscription()
										}
									};
									setOrganisation(mergedOrg);
									setSavingMember(false);
									resetForm();
									setUseEmail(false);
									setMessage(
										showSuccess({
											title: `Staff Member Successfully Added${emailAddress ? " and Invited" : ""}.`
										})
									);
								} catch (error) {
									setSavingMember(false);
									console.log("::error", error);
									setMessage(
										showFailure({
											title: "Unable to add Staff Member.",
											subTitle: error.message
										})
									);
								}
							}}
						>
							<div className="grid grid-cols-9 gap-6 lg:h-32">
								<div className="h-full flex pb-5 items-end col-span-9 sm:col-span-9 lg:col-span-3">
									<div className="w-full">
										<InputField label="Name" name="name" />
									</div>
								</div>

								<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-4">
									<div className="w-full">
										<div>
											<CheckBox
												name="useEmailChkBox"
												label="Invite using email address"
												onChange={() => setUseEmail(!useEmail)}
												checked={useEmail}
											/>
										</div>
										<div className="mb-6">
											<InputField name="email" type="email" disabled={!useEmail} />
										</div>
									</div>
								</div>

								<div className="h-full pb-6 flex items-end col-span-9 sm:col-span-9 lg:col-span-2">
									<div className="w-full">
										<Button type="submit" label="Add/Invite" disabled={savingMember} />
									</div>
								</div>
							</div>
						</Form>
					)}
					{organisation?.maxAllowedMembers > staffMembers?.length + otherLocationMembers?.length && <HelpLabel />}
				</div>
				<div className="mt-4 pb-6">
					<div className="pt-6 flex items-center">
						<h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide pl-6 mr-5">
							Current staff members ({staffMembers?.length})
						</h3>
						<RefreshIcon className="h-6 w-6 text-gray-500 cursor-pointer" onClick={() => updateOrgAndMembers()} />
						<div className="ml-auto mr-4 flex flex-row">
							<Button
								name="manageStaffLeaves"
								label="Manage Leave Options"
								onClick={() => setManageLeavesOverlayOpen(true)}
								style={{ width: "auto" }}
							/>
							<div className="ml-5"></div>
							<Button
								name="Manage Staff Check Ins"
								label="Manage Staff Check Ins"
								onClick={() => {
									showManageCheckIns(organisation);
								}}
								style={{ width: "auto" }}
							/>
						</div>
					</div>
					<div className="mx-4 my-1 sticky top-0 z-50 py-1 bg-white">
						<div className="relative text-gray-600">
							<span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
								<svg
									fill="none"
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									viewBox="0 0 30 30"
									className="w-6 h-6 text-gray-300"
								>
									<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
								</svg>
							</span>
							<input
								type="search"
								className="block w-full py-1 pl-10 bg-gray-100 rounded-xl outline-none"
								name="search"
								placeholder="Search"
								required
								onChange={(e) => {
									setMemberNameFilter(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className="flex flex-col mt-3">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
									<table className="min-w-full divide-y divide-gray-200 table-auto">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
												>
													Employee Name
												</th>
												<th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
												>
													Payroll No.
												</th>
												<th
													scope="col"
													className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell"
												>
													Permission
												</th>
												<th
													scope="col"
													className="pl-7 px-4 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
												>
													Positions
												</th>
												<th
													scope="col"
													className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
												>
													Max Hours
												</th>
												{/* <th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
												>
													Dept
												</th> */}
												<th
													scope="col"
													className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell"
												>
													Wage
												</th>

												<th scope="col" className="relative px-6 py-3">
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											{(memberNameFilter !== "" ? filteredStaffMembers : staffMembers).map((memberData, memberIndex) => {
												return (
													<MemberListItem
														key={memberIndex}
														member={memberData}
														memberIndex={memberIndex}
														handleAddEmail={handleAddEmail}
														setConfirmMember={setConfirmMember}
														setOpenSetInvitationStatusPending={setOpenSetInvitationStatusPending}
														roles={roles}
														upsertToEmployee={upsertToEmployee}
														handleChange={handleChange}
														confirmPopupSuperAdmin={confirmPopupSuperAdmin}
														confirmPopupEditData={confirmPopup}
														confirmPopupFinancials={confirmPopupFinancials}
														confirmPopupRequests={confirmPopupRequests}
														departments={departments}
														setOpenConfirmRemove={setOpenConfirmRemove}
														showStaffDetails={showStaffDetails}
													/>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-4 pb-6">
					<h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide pl-6 pt-6">
						Removed staff members ({deletedStaffMembers?.length})
					</h3>
					<div className="mt-2 mx-4 my-1 sticky top-0 py-1 bg-white">
						<div className="relative text-gray-600">
							<span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
								<svg
									fill="none"
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									viewBox="0 0 30 30"
									className="w-6 h-6 text-gray-300"
								>
									<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
								</svg>
							</span>
							<input
								type="search"
								className="block w-full py-1 pl-10 bg-gray-100 rounded-xl outline-none"
								name="search"
								placeholder="Search"
								required
								onChange={(e) => {
									setDeletedMemberNameFilter(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="flex flex-col mt-2">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
									<table className="min-w-full divide-y divide-gray-200 table-auto">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
												>
													Employee Name
												</th>
												<th scope="col" className="relative px-6 py-3">
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											{(deletedMemberNameFilter !== "" ? filteredDeletedStaffMembers : deletedStaffMembers)?.length === 0 && (
												<tr>
													<td colSpan="3" className="px-6 py-4 whitespace-nowrap">
														<div className="text-center text-gray-500">No removed staff members</div>
													</td>
												</tr>
											)}
											{(deletedMemberNameFilter !== "" ? filteredDeletedStaffMembers : deletedStaffMembers)?.map(
												(member, index) => {
													const memberName = member?.id
														? personUtils.displayName(member) || member.orgUsername
														: member.orgUsername || "No Name Found";

													const { invitations } = member;
													let invitation = {
														id: "",
														status: ""
													};
													if (invitations && invitations.items?.length > 0) {
														const { status, id } = invitations.items[0]; // The graphql join should mean that only 1 invite is returned for this org. Profived there aren't duplicates!
														invitation = {
															id: id,
															status: status
														};
													}

													let statusColour = "bg-lightww-50 text-darkww-800";
													if (invitation.status === "Accepted") {
														statusColour = "bg-green-100 text-green-800";
													} else if (invitation.status === "Declined") {
														statusColour = "bg-red-100 text-red-800";
													}
													return (
														<tr key={index}>
															<td className="px-6 py-2 whitespace-nowrap">
																<div className="flex items-center">
																	<Avatar user={member} size="h-12 w-12" />
																	<div className="ml-4">
																		<div className="text-sm font-medium text-gray-900">{memberName}</div>
																		<div className="text-sm hidden sm:block text-gray-500">
																			{member.email ?? ""}
																		</div>
																		<span
																			className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColour}`}
																		>
																			{invitation.status}
																		</span>
																	</div>
																</div>
															</td>
															<td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
																<div className="flex flex-row justify-between">
																	<LinkButton
																		label="Delete"
																		className="text-red-600 mr-2 font-bold hover:text-red-900 mb-2 border border-red-600 rounded px-2 py-1"
																		onClick={() => {
																			setConfirmMember(member);
																			setConfirmInvitationID(invitation);
																			setOpenConfirmDelete(true);
																		}}
																	/>
																	{organisation?.maxAllowedMembers >
																		staffMembers?.length + otherLocationMembers?.length && (
																		<LinkButton
																			label="Restore"
																			className="text-green-600 mr-2 font-bold hover:text-green-900 mb-2"
																			onClick={() => {
																				setConfirmMember(member);
																				setOpenConfirmRestore(true);
																			}}
																		/>
																	)}
																	{organisation?.maxAllowedMembers <=
																		staffMembers?.length + otherLocationMembers?.length && (
																		<Tooltip
																			trigger={["hover"]}
																			overlay={
																				"The organisation has reached its maximum allowed active members. Please remove or delete first"
																			}
																			placement="top"
																		>
																			<span className="text-gray-600">Restore</span>
																		</Tooltip>
																	)}
																</div>
															</td>
														</tr>
													);
												}
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StaffMembers;
const MemberListItem = ({
	member,
	memberIndex,
	handleAddEmail,
	setConfirmMember,
	setOpenSetInvitationStatusPending,
	roles,
	upsertToEmployee,
	handleChange,
	confirmPopupSuperAdmin,
	confirmPopupEditData,
	confirmPopupFinancials,
	confirmPopupRequests,
	departments,
	setOpenConfirmRemove,
	showStaffDetails
}) => {
	const { organisation } = useContext(OrganisationContext);
	const { person: loggedInPerson } = useContext(PersonContext);

	const [payRate, setPayRate] = useState(member?.huorlyRate || "");
	const [payRateUnit, setPayRateUnit] = useState(member?.empRateUnit || "HOURLY");
	const [payRollNo, setPayRollNo] = useState(member?.employeeNu || "");
	const [currencySymbol, setCurrencySymbol] = useState("€");

	useEffect(() => {
		setPayRate(member?.huorlyRate || "");
		setPayRateUnit(member?.empRateUnit || "HOURLY");
		setPayRollNo(member?.employeeNu || "");
	}, [member]);

	useEffect(() => {
		// Detect the user's locale and set the currency symbol accordingly
		fetchLocationData().then((data) => {
			if (data) {
				const countryCode = data.countryCode;
				const currency = determineCurrency(countryCode);
				setCurrencySymbol(currency);
			}
		});
	}, []);

	const isAssignedRequests = member?.assignedRequests || false;
	const isSuperAdmin = member?.assignedSuperAdmin || false;
	const isAssignedAdmin = member?.assignedAdmin || false;
	const isAssignedFinancials = member?.assignedFinancials != null ? member?.assignedFinancials : member?.assignedAdmin ? true : false;

	const isOwner = organisation.owner === member.person ? true : false;
	if (isOwner) member.email = loggedInPerson.email;

	const memberName = member?.id ? personUtils.displayName(member) || member.orgUsername : member.orgUsername || "No Name Found";

	const { invitations } = member;
	let invitation = {
		id: "",
		status: ""
	};
	if (invitations && invitations.items?.length > 0) {
		const { status, id } = invitations.items[0]; // The graphql join should mean that only 1 invite is returned for this org. Profived there aren't duplicates!
		invitation = {
			id: id,
			status: status
		};
	}

	let statusColour = "bg-lightww-50 text-darkww-800";
	if (invitation.status === "Accepted") {
		statusColour = "bg-green-100 text-green-800";
	} else if (invitation.status === "Declined") {
		statusColour = "bg-red-100 text-red-800";
	}
	let hasLateCheckIns = false;
	let hasDueCheckIns = false;
	if (member?.startDate) {
		const memberStartDate = new Date(member.startDate * 1000);
		const lateCheckIns = getLateCheckIns({
			startDate: memberStartDate,
			checkIns: organisation?.checkIns || [],
			completedCheckIns: member.completedCheckIns
		});
		let dueCheckIns = getDueCheckIns({
			startDate: memberStartDate,
			checkIns: organisation?.checkIns || [],
			completedCheckIns: member.completedCheckIns
		});
		dueCheckIns = dueCheckIns.filter((checkIn) => checkIn.daysLeft < 14);
		hasLateCheckIns = lateCheckIns.length > 0;
		hasDueCheckIns = dueCheckIns.length > 0;
	}
	let hasFilesDueExpiry = false;
	let hasExpiredFiles = false;
	if (member.employeeFiles.length > 0) {
		const getDaysLeftTillFileExpiry = (date) => {
			if (!date) return null;
			const expiryDate = new Date(date);
			const today = new Date();
			const diffTime = expiryDate - today;
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			return diffDays;
		};
		member.employeeFiles.forEach((f) => {
			let file = JSON.parse(f);
			const daysToExpiry = getDaysLeftTillFileExpiry(file.date);
			if (daysToExpiry) {
				if (daysToExpiry < 30) hasFilesDueExpiry = true;
				if (daysToExpiry < 0) hasExpiredFiles = true;
			}
		});
	}

	const handleClickChange = async (e, i, type = "rates") => {
		try {
			type === "value" ? setPayRate(e.target.value) : type === "unit" && setPayRateUnit(e);
			if (type === "employeeNu") setPayRollNo(e.target.value);
		} catch (error) {}
	};

	return (
		<tr key={member.id} className={`${hasLateCheckIns ? "bg-red-100" : hasDueCheckIns ? "bg-yellow-100" : ""}`}>
			<td className="px-6 py-2 whitespace-nowrap">
				<div className="flex items-center">
					{(hasExpiredFiles || hasFilesDueExpiry) && (
						<Tooltip
							trigger={["hover"]}
							overlay={`${
								hasExpiredFiles ? "Staff has expired files/documents" : hasFilesDueExpiry ? "Staff has files/documents almost expiring " : ""
							}`}
							placement="top"
						>
							<div className={`w-8 h-8 flex items-center justify-center rounded-full ${hasExpiredFiles ? "bg-red-600" : "bg-amber-500"} mr-2`}>
								<ExclamationIcon className={`w-5 h-5 text-white`} />
							</div>
						</Tooltip>
					)}
					<div className="relative">
						{(hasLateCheckIns || hasDueCheckIns) && (
							<span
								className={`absolute top-0 right-0 block w-3 h-3 ${
									hasLateCheckIns ? "bg-red-500" : hasDueCheckIns ? "bg-yellow-500" : "bg-red-500"
								} bg-red-500 rounded-full`}
							></span>
						)}
						<div
							className={`relative ${
								hasLateCheckIns ? "border-4 border-red-500 rounded-full" : hasDueCheckIns ? "border-4 border-yellow-500 rounded-full" : ""
							}`}
						>
							<Avatar user={member} size="h-12 w-12" />
						</div>
					</div>

					<div className="ml-4">
						<div className="text-sm font-medium text-gray-900">{memberName}</div>
						<div className="text-sm hidden sm:block text-gray-500">
							{member.email ?? <UserAddIcon className="w-6 h-6 text-lightww-600 items-end" onClick={(e) => handleAddEmail(member)} />}
						</div>
						<span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColour}`}>
							{invitation.status === "Accepted" ? (
								<div
									onClick={() => {
										setConfirmMember(member);
										setOpenSetInvitationStatusPending(true);
									}}
									className="cursor-pointer"
								>
									{invitation.status}
								</div>
							) : (
								invitation.status
							)}
						</span>
						<div className="block sm:hidden mr-2 mt-2">
							<Menu as="div" className="">
								{({ open }) => (
									<>
										<Menu.Button className="px-3 py-1 text-md bg-darkww-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
											<span className="ml-3 text-gray-700 text-sm font-medium block text-white">Role</span>
											<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
										</Menu.Button>
										<Transitions
											show={open}
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items
												static
												className="absolute w-30 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
											>
												{roles &&
													roles.map((value, index) => (
														<div className="sm:block" key={index}>
															<Menu.Item
																onClick={() => {
																	upsertToEmployee(value, member, "Role");
																}}
															>
																<div
																	style={{
																		color: member?.roleIDs?.includes(value.id) ? value.labelColour : ""
																	}}
																	className={"block px-4 py-2 text-sm text-gray-700 cursor-pointer"}
																>
																	{value.name}
																</div>
															</Menu.Item>
														</div>
													))}
											</Menu.Items>
										</Transitions>
									</>
								)}
							</Menu>
						</div>
					</div>
				</div>
			</td>
			<td className="px-6 py-4 whitespace-nowrap hidden lg:table-cell">
				<div className="mt-1 relative rounded-md sm:w-20 shadow-sm">
					<input
						type="text"
						inputMode="numeric"
						name="employeenu"
						id="employeenu"
						className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md moz-appearance-none"
						placeholder="#123"
						value={payRollNo}
						onChange={(e) => handleClickChange(e, memberIndex, "employeeNu")}
						onBlur={(e) => handleChange(payRollNo, member, "Employee No")}
					/>
				</div>
			</td>
			<td className="lg:table-cell flex items-end justify-end pr-2">
				{!isOwner && (
					<div className="relative flex flex-col items-end">
						<div className="flex items-center mb-1 h-5">
							<label className="flex justify-between text-sm text-gray-500 pr-2" htmlFor="assignedRequests">
								View Admin
							</label>
							<input
								aria-describedby="invite-description"
								name="assignedSuperAdmin"
								type="checkbox"
								className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
								checked={isSuperAdmin}
								onChange={(e) => confirmPopupSuperAdmin(e, member)}
							/>
						</div>
						<div className="flex items-center mb-1 h-5">
							<label className="flex justify-between text-sm text-gray-500 pr-2" htmlFor="assignedAdmin">
								Edit Data
							</label>
							<input
								aria-describedby="invite-description"
								name="assignedAdmin"
								type="checkbox"
								className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
								checked={isAssignedAdmin}
								onChange={(e) => confirmPopupEditData(e, member)}
							/>
						</div>
						{isAssignedAdmin && (
							<Tooltip trigger={["hover"]} overlay="Allow employee to view financial data like take, totals and wages" placement="top">
								<div className="flex items-center mb-1 h-5">
									<label className="flex justify-between text-xs text-gray-600 pr-2" htmlFor="assignedFinancials">
										Financials
									</label>
									<input
										aria-describedby="invite-description"
										name="assignedFinancials"
										type="checkbox"
										className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
										checked={isAssignedFinancials}
										onChange={(e) => confirmPopupFinancials(e, member)}
									/>
								</div>
							</Tooltip>
						)}
						<div className="flex items-center h-5">
							<label className="flex justify-between text-sm text-gray-500 pr-2" htmlFor="assignedRequests">
								Requests
							</label>
							<input
								aria-describedby="invite-description"
								name="assignedRequests"
								type="checkbox"
								className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
								checked={isAssignedRequests}
								onChange={(e) => confirmPopupRequests(e, member)}
							/>
						</div>
					</div>
				)}
			</td>
			<td className="px-6 py-2 whitespace-nowrap hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell">
				<Menu as="div" className="mb-1">
					{({ open }) => (
						<>
							<Menu.Button className="px-2.5 py-1 text-md bg-darkww-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
								<span className="ml-3 text-gray-700 text-sm font-medium block text-white">Dept</span>
								<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
							</Menu.Button>
							<Transitions
								show={open}
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items
									static
									className="absolute w-30 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
								>
									{departments &&
										departments.map((value, index) => (
											<div className="sm:block" key={index}>
												<Menu.Item
													onClick={() => {
														upsertToEmployee(value, member, "Department");
													}}
												>
													<div
														style={{
															color: member?.departmentIDs?.includes(value.id) ? "green" : ""
														}}
														className={"block px-4 py-2 text-sm text-gray-700 cursor-pointer"}
													>
														{value.name}
													</div>
												</Menu.Item>
											</div>
										))}
								</Menu.Items>
							</Transitions>
						</>
					)}
				</Menu>
				<Menu as="div" className="">
					{({ open }) => (
						<>
							<Menu.Button className="px-3 py-1 text-md bg-darkww-700 rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkww-500 p-1 rounded-md">
								<span className="ml-3 text-gray-700 text-sm font-medium block text-white">Role</span>
								<ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-3 text-white sm:block" aria-hidden="true" />
							</Menu.Button>
							<Transitions
								show={open}
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items
									static
									className="absolute w-30 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
								>
									{roles &&
										roles.map((value, index) => (
											<div className="sm:block" key={index}>
												<Menu.Item
													onClick={() => {
														upsertToEmployee(value, member, "Role");
													}}
												>
													<div
														style={{
															color: member?.roleIDs?.includes(value.id) ? value.labelColour : ""
														}}
														className={"block px-4 py-2 text-sm text-gray-700 cursor-pointer"}
													>
														{value.name}
													</div>
												</Menu.Item>
											</div>
										))}
								</Menu.Items>
							</Transitions>
						</>
					)}
				</Menu>
			</td>
			{/* <td className="px-6 py-4 whitespace-nowrap hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell">
                {!isOwner && (
                    
                )}
            </td> */}
			<td className="px-3 py-1 whitespace-nowrap hidden lg:table-cell md:table-cell xl:table-cell 2xl:table-cell">
				<div className="flex">
					<div className="mt-1 relative rounded-md sm:w-16 shadow-sm">
						<div className="absolute py-1 pl-1 flex items-center pointer-events-none">
							<span className="text-gray-500 sm:text-sm mr-1">
								{/* <CurrencyEuroIcon className="h-5 w-5" aria-hidden="true" /> */}
								{currencySymbol}
							</span>
						</div>
						<input
							type="text"
							inputMode="numeric"
							name="price"
							id="price"
							className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm pl-3 py-1 pr-0 content-end border-gray-300 rounded-md moz-appearance-none"
							placeholder="0.00"
							value={payRate}
							onChange={(e) => handleClickChange(e, memberIndex, "value")}
							onBlur={(e) => handleChange(payRate, member, "value")}
						/>
						<Menu as="div" className="relative ml-1 self-center">
							{({ open }) => (
								<>
									<Menu.Button className="inline-flex justify-center w-full border border-gray-300 rounded-md text-sm font-medium text-gray-700 items-center">
										<span className="text-gray-500 sm:text-sm mr-1">/ {payRateUnit === "YEARLY" ? "yr" : "hr"}</span>
										<ChevronDownIcon className="flex-shrink-0 h-4 w-4 text-gray-400 sm:block" aria-hidden="true" />
									</Menu.Button>
									<Transitions
										show={open}
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											static
											className="-top-2 transform -translate-y-full absolute mt-1 w-fit rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-right z-20"
										>
											<div
												className="sm:block"
												onClick={async (e) => {
													await handleClickChange("HOURLY", memberIndex, "unit");
													handleChange("HOURLY", member, "unit");
												}}
											>
												<Menu.Item>
													<div className="flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
														<ClockIcon className="w-5 h-5 text-pink-600 mr-2" />
														Hourly
													</div>
												</Menu.Item>
											</div>
											<div
												className="sm:block"
												onClick={async (e) => {
													await handleClickChange("YEARLY", memberIndex, "unit");
													handleChange("YEARLY", member, "unit");
												}}
											>
												<Menu.Item>
													<div className="flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="currentColor"
															className="bi bi-calendar-week w-5 h-5 text-pink-600 mr-2"
															viewBox="0 0 16 16"
														>
															{" "}
															<path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />{" "}
															<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />{" "}
														</svg>{" "}
														Yearly
													</div>
												</Menu.Item>
											</div>
										</Menu.Items>
									</Transitions>
								</>
							)}
						</Menu>
					</div>
				</div>
			</td>
			<td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
				<div className="flex flex-col">
					{isOwner ? (
						<OwnerTag className=" mb-2" />
					) : (
						<LinkButton
							label="Remove"
							className="text-red-600 mr-2 font-bold hover:text-red-900 mb-2"
							onClick={() => {
								setConfirmMember(member);
								setOpenConfirmRemove(true);
							}}
							toolTip="Once your employee leaves, this will remove them from active employment and their access to your organisation, you can restore that later."
						/>
					)}
					<LinkButton
						label="Details"
						className={`mr-2 font-bold ${
							invitation.status === "Pending" || member?.email == null ? "text-gray-400 cursor-not-allowed" : "text-blue-600 hover:text-blue-900"
						}`}
						onClick={
							invitation.status === "Pending" || member?.email == null
								? null
								: () => {
										showStaffDetails(member);
								  }
						}
					/>
				</div>
			</td>
		</tr>
	);
};
