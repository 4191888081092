import { API } from "aws-amplify";
import { createProfile, updateProfile } from "../../graphql/mutations.js";
import { getProfileByPerson as AWSGetProfile } from "../../graphql/queries.js";

export const getProfile = async (sub) => {
	return new Promise(async (resolve, reject) => {
		let personProfile;
		try {
			const { data } = await API.graphql({ query: AWSGetProfile, variables: { person: sub } });

			const {
				getProfileByPerson: { items }
			} = data;

			if (items.length > 0) {
				personProfile = {
					...items[0]
				};
			}

			resolve(personProfile);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveProfile = async (profileDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			let profileMapped = {
				id: profileDetails.id,
				person: profileDetails.sub,
				firstName: profileDetails.firstName,
				lastName: profileDetails.lastName,
				username: profileDetails.username,
				salary: +profileDetails.salary,
				employeePhone: profileDetails.employeePhone,
				emergencyNo: profileDetails.emergencyNo,
				pin: profileDetails.pin,
				avatarURI: profileDetails.avatarURI
			};

			const mutation = profileDetails.id ? updateProfile : createProfile;
			const { data } = await API.graphql({ query: mutation, variables: { input: profileMapped } });
			const savedProfile = data[profileDetails.id ? "updateProfile" : "createProfile"];

			const updatedProfile = { ...profileDetails, ...savedProfile };
			resolve(updatedProfile);
		} catch (error) {
			reject(error);
		}
	});
};
