import { ChatAlt2Icon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Button, ComboBox, Form, InputField, LinkButton, Yup, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

export const checkInSchema = Yup.object().shape({
	name: Yup.string().required().min(2),
	duration: Yup.string().required(),
	unit: Yup.string().required()
});

const CheckInForm = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const [checkInName, setCheckInName] = useState();
	const [duration, setDuration] = useState("");
	const [timeUnit, setTimeUnit] = useState("days");
	const [isSaving, setIsSaving] = useState(false);
	const { setMessage } = useSimpleMessage();
	const initValues = { name: checkInName, duration: duration, unit: timeUnit };

	const resetForm = () => {
		setCheckInName(initValues.name);
		setDuration(initValues.duration);
		setTimeUnit(initValues.unit);
	};

	return (
		<Form
			validationSchema={checkInSchema}
			enableReinitialize={true}
			disabled={isSaving}
			initialValues={initValues}
			onSubmit={async (data) => {
				try {
					setIsSaving(true);

					await saveCheckInDetails(data, organisation, setOrganisation);
					setIsSaving(false);
					resetForm();
					setMessage(
						showSuccess({
							title: "Check in saved successfully."
						})
					);
				} catch (error) {
					setIsSaving(false);
					setMessage(
						showFailure({
							title: "Unable to save Check in.",
							subTitle: error.message
						})
					);
				}
			}}
		>
			<div className="grid grid-cols-9 gap-0 mt-2 py-2">
				<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-5">
					<InputField label="Check in name" name="name" value={checkInName} onChange={(value) => setCheckInName(value)} />
				</div>
				<div className="h-full flex mt-0.5 items-end pb-0 col-span-9 sm:col-span-9 lg:col-span-5 space-x-2">
					<InputField label="Check in after" name="duration" value={duration} onChange={(value) => setDuration(value)} />
					<div className="flex items-center pb-1.5">
						<ComboBox
							name="unit"
							className="border border-gray-300 rounded-md px-3"
							options={[
								{ id: "days", name: "Days", description: "Days" },
								{ id: "weeks", name: "Weeks", description: "Weeks" },
								{ id: "months", name: "Months", description: "Months" },
								{ id: "years", name: "Years", description: "Years" }
							]}
							initValue={"days"}
						/>
					</div>
				</div>
				<div className="h-full flex mt-0 items-end py-1.5 col-span-9 sm:col-span-9 lg:col-span-4 justify-end">
					<div className="">
						<Button type="submit" label="Save" disabled={isSaving} />
					</div>
				</div>
			</div>
		</Form>
	);
};

const CheckInsView = () => {
	const { organisation } = useContext(OrganisationContext);
	const [checkIns, setCheckIns] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { setMessage } = useSimpleMessage();

	const loadCheckIns = async () => {
		try {
			const { checkIns: savedCheckIns } = await orgUtils.get(organisation);
			setCheckIns(savedCheckIns ?? []);
		} catch (error) {
			setMessage(showFailure({ title: "Unable to load CheckIn Items.", subTitle: error.message }));
		}
	};

	useEffect(() => {
		if (organisation.id) loadCheckIns();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	const deleteCheckIn = async (id) => {
		try {
			setIsLoading(true);
			await orgUtils.deleteCheckIn(id);
			loadCheckIns();
			setMessage(showSuccess({ title: "CheckIn Item deleted successfully." }));
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			setMessage(showFailure({ title: "Unable to delete CheckIn Item.", subTitle: error.message }));
		}
	};
	return (
		<div className="overflow-y-scroll h-full pl-3 bg-white shadow-lg rounded-lg mt-1 ml-4 p-3">
			<div className="flex mt-4"></div>
			<div className="divide-y divide-gray-200 lg:col-span-9 h-fit">
				<div className="py-2 px-4 -mt-4 sm:px-6 lg:pb-3">
				<div className="text-left">
					<div className="flex items-center">
						<h2 className="mt-2 text-lg font-semibold text-darkww-400">Manage Check-ins</h2>
						<ChatAlt2Icon className="inline-block h-10 w-12 text-darkww-400 ml-2" />
					</div>
					<p className="mt-1 text-sm text-gray-500">
						Check-ins are reminders to see how your employees are feeling at set times from the start of their contract.<br/> We recommend: 2 weeks, 2 months, 23 weeks probation warning, 6 months, and every 6 months after.
					</p>
				</div>

					<CheckInForm />
					<div className="text-left">
						<p className="mt-1 text-sm text-red-600">The status of each employee's check-ins can be viewed under employee details.</p>
					</div>
				</div>

				<div className="mt-2 pb-6">
					<h3 className="text-xs font-semibold text-darkww-500 uppercase tracking-wide pl-6 pt-6">Current Check Ins ({checkIns?.length})</h3>
					<div className="flex flex-col mt-2">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden divide-y divide-gray-200 border-b border-gray-200 rounded-md sm:rounded-lg">
									{checkIns?.map((checkInItem) => {
										return (
											<div key={checkInItem.id} className="grid grid-cols-12 gap-6">
												<div className="ml-1 sm:ml-0 py-1 mt-1 col-span-8 sm:col-span-8 flex flex-col">
													<span className="px-2 text-sm sm:text-md">{checkInItem.name}</span>
													<span className="px-2 text-xs sm:text-xs text-gray-500">
														After {checkInItem.duration} {checkInItem.unit}
													</span>
												</div>
												<div className="col-span-2 px-2 lg:px-6 py-4 text-sm font-medium">
													<LinkButton
														label={
															<div className="flex items-center">
																<TrashIcon className="h-6 w-6 text-red-600" />
																<span className="ml-1">Delete</span>
															</div>
														}
														className="text-red-600 hover:text-red-900"
														loading={isLoading}
														onClick={() => {
															deleteCheckIn(checkInItem.id);
														}}
													/>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckInsView;
export async function saveCheckInDetails(data, organisation, setOrganisation) {
	const checkInDetails = {
		...data,
		...{ organisationID: organisation.id }
	};

	const updateOrg = await orgUtils.saveCheckIn(checkInDetails);
	setOrganisation({
		...updateOrg,
		...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? updateOrg.subscription?.items?.[0]?.quantity },
		...{ members: await orgUtils.mapMembers(updateOrg.members) },
		...{ deletedMembers: await orgUtils.mapMembers(updateOrg.deletedMembers) },
		...{ report: await orgUtils.mapReport(updateOrg?.report?.items || [], updateOrg.id) },
		...{ departments: updateOrg?.departments?.items || [] }
	});
}
