import { API, graphqlOperation } from "aws-amplify";
import { createExtraPayDay, updateExtraPayDay, deleteExtraPayDay } from "../../graphql/mutations.js";
import { getExtraPayByDate } from "../../graphql/queries.js";

export const getExtraPayDay = async (organisationID, startRange, endRange) => {
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			organisationID,
			limit: 1000,
			baseDay: { between: [startRange, endRange] }
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getExtraPayByDate, queryParams));
			const { getExtraPayByDate: legacyNoteParticularDay } = data;
			resolve(legacyNoteParticularDay);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveExtraPayDay = async (extraPayData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let extraPayDetail = {
				id: extraPayData.id || null,
				newPay: extraPayData.newPay,
				organisationID: extraPayData.organisationID,
				baseDay: extraPayData.baseDay
			};

			const mutation = extraPayData.id ? updateExtraPayDay : createExtraPayDay;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: extraPayDetail }
			});
			const savedNode = data[extraPayData.id ? updateExtraPayDay : createExtraPayDay];

			const updatedNote = { ...extraPayData, ...savedNode };
			resolve(updatedNote);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyNoteParticularDay = async (noteId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteExtraPayDay,
				variables: { input: { id: noteId } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
