export const nth = function (d) {
	if (d > 3 && d < 21) return "th";
	switch (d % 10) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
};

// Having Monday as start day needs to be catered for. There's a mathematical way of calculating this I'm just sicking of 2nd guessing it!!!
const daysToFillStart = (dayNum) => {
	switch (dayNum) {
		case 0:
			return 6;
		case 6:
			return 5;
		case 5:
			return 4;
		case 4:
			return 3;
		case 3:
			return 2;
		case 2:
			return 1;
		case 1:
			return 0;
		default:
			return 0;
	}
};

// Having Monday as start day needs to be catered for. There's a mathematical way of calculating this I'm just sicking of 2nd guessing it!!!
const daysToFillEnd = (dayNum) => {
	switch (dayNum) {
		case 0:
			return 0;
		case 6:
			return 1;
		case 5:
			return 2;
		case 4:
			return 3;
		case 3:
			return 4;
		case 2:
			return 5;
		case 1:
			return 6;
		default:
			return 0;
	}
};

export const addMonths = function (date, months) {
	var d = date.getDate();
	date.setMonth(date.getMonth() + +months);
	if (date.getDate() !== d) {
		date.setDate(0);
	}
	return date;
};

export const dateMeta = (date) => {
	let epochDate = new Date(date);
	epochDate = epochDate.getTime();
	let baseEpoch = "";
	//if (epochDate > 1679303038844) {
	//baseEpoch = date.setUTCHours(10, 0, 0, 0); //changed from setUTCHours to setHours
	//} else {
	baseEpoch = date.setUTCHours(7, 0, 0, 0); //changed from setUTCHours to setHours
	//}
	const baseDate = new Date(baseEpoch);
	return {
		day: new Intl.DateTimeFormat("en", { day: "numeric" }).format(baseDate),
		dayDesc: new Intl.DateTimeFormat("en", { weekday: "long" }).format(baseDate),
		month: new Intl.DateTimeFormat("en", { month: "numeric" }).format(baseDate),
		monthShortDesc: new Intl.DateTimeFormat("en", { month: "short" }).format(baseDate),
		year: new Intl.DateTimeFormat("en", { year: "numeric" }).format(baseDate),
		formatted: new Intl.DateTimeFormat().format(baseDate),
		date: baseDate,
		baseEpoch: date.setUTCHours(0, 0, 0, 0) / 1000 // Don't want the milliseconds, only seconds.
	};
};
export const currentDateEpoch = () => {
	let date = new Date();
	let epochDate = new Date(date);
	epochDate = epochDate.getTime();
	// if (epochDate > 1679303038844) {
	// 	return date.setUTCHours(7, 0, 0, 0) / 1000;
	//} else {
	return date.setUTCHours(0, 0, 0, 0) / 1000;
	//}
};

export const fillDays = (startDate, endDate) => {
	const dates = [];
	const currentDate = new Date(startDate);
	while (currentDate < endDate) {
		dates.push(dateMeta(new Date(currentDate)));
		currentDate.setDate(currentDate.getDate() + 1);
	}
	dates.push(dateMeta(endDate));
	return dates;
};

export const fillWeek = (date, startDay) => {
	startDay = startDay !== undefined ? startDay : 1;
	let now = new Date(date);
	let dayOfWeek = now.getUTCDay(); // Get day of the week (0 for Sunday, 1 for Monday, etc.)

	// Calculate days to subtract to get the startDay of the current week.
	let daysToSubtract = (dayOfWeek - startDay + 7) % 7; // Adjust to the specified start day

	let startDate = new Date(now);
	startDate.setUTCDate(startDate.getUTCDate() - daysToSubtract); // Subtract the days to get the start date

	let endDate = new Date(startDate);
	endDate.setUTCDate(endDate.getUTCDate() + 6); // Add 6 days to get the end date (7 days total)

	const days = fillDays(startDate, endDate) || [];
	return {
		first: days[0],
		last: days[days.length - 1],
		days: days
	};
};

export const fillMonth = (date, fillWeek = true, startDay) => {
	startDay = startDay !== undefined ? startDay : 1;
	let startDate = new Date(date.getFullYear(), date.getMonth(), 1, 2, 0, 0); // This may have been causing issues with Daylight Savings

	const daysToFillStart = (day) => (day - startDay + 7) % 7;
	const daysToFillEnd = (day) => (startDay + 6 - day + 7) % 7;

	if (fillWeek) startDate.setDate(startDate.getDate() - daysToFillStart(startDate.getDay()));

	let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 2, 0, 0); // This may have been causing issues with Daylight Savings
	if (fillWeek) endDate.setDate(endDate.getDate() + daysToFillEnd(endDate.getDay()));

	const days = fillDays(startDate, endDate) || [];

	return {
		first: days[0],
		last: days[days.length - 1],
		days: days
	};
};

export const DaySegments = (() => {
	const interval = 15; //minutes interval

	let startHourInMinute = 60 * 0; // start time in minutes

	const endHourInMinute = 60 * 23 + 45; // end time in minutes

	const times = [];

	for (let i = 0; startHourInMinute < 24 * 60; i++) {
		if (startHourInMinute > endHourInMinute) break;

		let hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

		let mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

		let hour = ("0" + (hh % 24)).slice(-2);
		let min = ("0" + mm).slice(-2);

		times[i] = {
			hour: hour,
			min: min,
			id: parseInt(hour) * 60 * 60 + parseInt(min) * 60, // Generate the time in seconds from 00:00 as we'll be working with epoch values in the DB.
			description: `${hour}:${min}`
		};

		startHourInMinute = startHourInMinute + interval;
	}

	return times;
})();

export const epochToTimeStr = (epoch, multipler = 1000) => {
	const epochDate = new Date(epoch * multipler);
	const hours = String(epochDate.getUTCHours()).padStart(2, "0");
	const minutes = String(epochDate.getUTCMinutes()).padStart(2, "0");
	return `${hours}:${minutes}`;
};

export const epochToDateStr = (epoch, multipler = 1000) => {
	const dateStr = new Date(epoch * multipler).toISOString();
	return dateStr.split("T")[0];
};

export const DayHourSegments = (() => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		hours.push({
			id: ("0" + (i % 24)).slice(-2),
			hour: ("0" + (i % 24)).slice(-2),
			description: ("0" + (i % 24)).slice(-2)
		});
	}
	return hours;
})();

export const DayMinuteSegments = (() => {
	return [
		{ id: "00", min: "00", description: "00" },
		{ id: "15", min: "15", description: "15" },
		{ id: "30", min: "30", description: "30" },
		{ id: "45", min: "45", description: "45" }
	];
})();

export const secondsToHm = (second, returnType) => {
	if (second === "" || isNaN(second)) return second;
	second = Number(second);

	const h = Math.floor(second / 3600);
	const m = Math.floor((second % 3600) / 60);

	const hDisplay = h > 0 ? ("0" + (h % 24)).slice(-2) : "00";
	const mDisplay = m > 0 ? ("0" + (m % 60)).slice(-2) : "00";
	if (returnType === "hour") return hDisplay;
	else if (returnType === "minute") return mDisplay;
	else return hDisplay + mDisplay;
};
export const formateEpochToShortMonthDate = (epoch, time = false) => {
	const format = time
		? {
				day: "numeric",
				month: "short",
				year: "2-digit",
				hour: "numeric",
				minute: "numeric",
				timeZone: "UTC"
		  }
		: {
				day: "numeric",
				month: "short",
				year: "2-digit"
		  };
	return new Intl.DateTimeFormat("default", format).format(new Date(epoch * 1000));
};

export const formateEpochToShortMonthDate2 = (epoch, time = false) => {
	const dateOptions = {
		weekday: "short",
		day: "numeric",
		month: "numeric",
		year: "2-digit",
		timeZone: "UTC"
	};

	const timeOptions = {
		hour: "numeric",
		minute: "numeric"
	};

	const dateTimeOptions = {
		...dateOptions,
		...timeOptions
	};

	const formattedDate = new Date(epoch * 1000);

	if (time) {
		return formattedDate.toLocaleString("default", dateTimeOptions);
	}

	return formattedDate.toLocaleDateString("default", dateOptions);
};

export const formateEpoch = (
	epoch,
	format = {
		weekday: "short",
		month: "numeric",
		day: "numeric",
		year: "2-digit"
	}
) => {
	const date = new Date(epoch * 1000);
	return new Intl.DateTimeFormat("default", format).format(date);
};
export const formateCurrency = (amuont = 0) => {
	return "€" + amuont.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const notifyUser = (userSub, message) => {
	const options = {
		method: "POST",
		headers: {
			Accept: "application/json",
			Authorization: "Basic YWYyMjQ2OWQtMjYwYS00NzliLTg4YTUtMTEwZTkyMjljYTQ1",
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			app_id: "4b328419-1b0f-48a6-b0ab-8a101539be5e",
			include_external_user_ids: userSub,
			channel_for_external_user_ids: "push",
			contents: { en: `${message}` }
		})
	};

	fetch("https://onesignal.com/api/v1/notifications", options)
		.then((response) => response.json())
		.then((response) => console.log(response))
		.catch((err) => console.error(err));
};

export const arrayDiff = (a1 = [], a2 = []) => {
	a1.sort();
	a2.sort();
	for (let i = 0; i < a1.length; i += 1) {
		if (a2.indexOf(a1[i]) > -1) {
			return true;
		}
	}
	return false;
};

export const checkIfFilesAreTooBig = (files = [], FILE_SIZE) => {
	let valid = true;
	if (files) {
		Object.values(files).forEach((file) => {
			const size = file?.size;
			if (size > FILE_SIZE) {
				valid = false;
			}
		});
	}
	return valid;
};

export const checkIfFilesAreCorrectType = (files = [], SUPPORTED_FORMATS) => {
	let valid = true;
	if (files) {
		Object.values(files).forEach((file) => {
			if (!SUPPORTED_FORMATS.includes(file?.type)) {
				valid = false;
			}
		});
	}
	return valid;
};
