import { CalendarIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Button, CheckBox, ComboBox, Form, InputField, LinkButton, Yup, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

export const leaveOptionSchema = Yup.object().shape({
	name: Yup.string().required().min(2),
	maximumLength: Yup.string().required(),
	unit: Yup.string().required()
});

const LeaveOptionForm = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const [leaveName, setLeaveName] = useState();
	const [maximumLength, setMaximumLength] = useState("");
	const [isAnnualLeave, setIsAnnualLeave] = useState(false);
	const [timeUnit, setTimeUnit] = useState("day");
	const [isSaving, setIsSaving] = useState(false);
	const { setMessage } = useSimpleMessage();
	const initValues = { name: leaveName, maximumLength: maximumLength, isAnnual: isAnnualLeave, unit: timeUnit };

	const resetForm = () => {
		setLeaveName(initValues.name);
		setMaximumLength(initValues.maximumLength);
		setIsAnnualLeave(initValues.isAnnual);
		setTimeUnit(initValues.unit);
	};

	return (
		<Form
			validationSchema={leaveOptionSchema}
			enableReinitialize={true}
			disabled={isSaving}
			initialValues={initValues}
			onSubmit={async (data) => {
				try {
					setIsSaving(true);

					await saveLeaveOptionDetails(data, organisation, setOrganisation);
					setIsSaving(false);
					resetForm();
					setMessage(
						showSuccess({
							title: "Leave option saved successfully."
						})
					);
				} catch (error) {
					setIsSaving(false);
					setMessage(
						showFailure({
							title: "Unable to save Leave option.",
							subTitle: error.message
						})
					);
				}
			}}
		>
			<div className="grid grid-cols-9 gap-0 mt-2 py-2">
				<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-5">
					<InputField label="Leave option name" name="name" value={leaveName} onChange={(value) => setLeaveName(value)} />
				</div>
				<div className="h-full py-2 flex items-end col-span-9 sm:col-span-9 lg:col-span-5">
					<CheckBox
						name="isAnnual"
						label="Set as annual leave"
						labelTextColour="text-gray-600"
						onChange={() => setIsAnnualLeave(!isAnnualLeave)}
						checked={isAnnualLeave}
					/>
				</div>
				<div className="h-full flex mt-0.5 items-end pb-0 col-span-9 sm:col-span-9 lg:col-span-5 space-x-2">
					<InputField label="Max Length" name="maximumLength" value={maximumLength} onChange={(value) => setMaximumLength(value)} />
					<div className="flex items-center pb-1">
						<ComboBox
							name="unit"
							className="border border-gray-300 rounded-md px-3"
							options={[
								{ id: "day", name: "Day", description: "Day" },
								{ id: "week", name: "Week", description: "Week" },
								{ id: "month", name: "Month", description: "Month" },
								{ id: "year", name: "Year", description: "Year" }
							]}
							initValue={"day"}
						/>
					</div>
				</div>
				<div className="h-full flex mt-0 items-end py-1.5 col-span-9 sm:col-span-9 lg:col-span-4 justify-end">
					<div className="">
						<Button type="submit" label="Save" disabled={isSaving} />
					</div>
				</div>
			</div>
		</Form>
	);
};

const LeaveOptionsView = () => {
	const { organisation } = useContext(OrganisationContext);
	const [leaveOptions, setLeaveOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
    const [activeOption, setActiveOption] = useState();
	const { setMessage } = useSimpleMessage();

	const loadLeaveOptions = async () => {
		try {
			const { leaveOptions: savedOptions } = await orgUtils.get(organisation);
			setLeaveOptions(savedOptions?.items ?? []);
		} catch (error) {
			setMessage(showFailure({ title: "Unable to load Leave options.", subTitle: error.message }));
		}
	};

	useEffect(() => {
		if (organisation.id) loadLeaveOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	const deleteLeaveOption = async (id) => {
		try {
			setIsLoading(true);
			await orgUtils.removeLeaveOption(id);
			loadLeaveOptions();
			setMessage(showSuccess({ title: "Leave option deleted successfully." }));
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			setMessage(showFailure({ title: "Unable to delete Leave option.", subTitle: error.message }));
		}
	};
	return (
		<div className="overflow-y-scroll h-full pl-3 bg-white shadow-lg rounded-lg mt-1 ml-4 p-3">
			<div className="flex mt-4"></div>
			<div className="divide-y divide-gray-200 lg:col-span-9 h-fit">
				<div className="py-2 px-4 -mt-4 sm:px-6 lg:pb-3">
					<div className="text-left">
						<div className="flex items-center mb-3">
							<h2 className="mt-2 text-lg font-semibold text-darkww-400">Manage Leave Options</h2>
							<CalendarIcon className="inline-block h-8 w-10 text-darkww-400 ml-2" />
						</div>
						<p className="mt-1 text-sm text-gray-500">
							Set up options you would like your staff to choose from when setting time off and leave requests.
						</p>
					</div>

					<LeaveOptionForm />
				</div>

				<div className="mt-2 pb-6">
					<h3 className="text-xs font-semibold text-darkww-500 uppercase tracking-wide pl-6 pt-6">
						Current Leave Options ({leaveOptions?.length})
					</h3>
					<div className="flex flex-col mt-2">
						<div className="overflow-x-auto lg:-mx-4">
							<div className="py-2 align-middle inline-block min-w-full px-6 sm:px-6 lg:px-8">
								<div className="shadow overflow-hidden divide-y divide-gray-200 border-b border-gray-200 rounded-md sm:rounded-lg">
									{leaveOptions?.map((leaveOption) => {
										return (
											<div key={leaveOption.id} className="grid grid-cols-12 gap-6">
												<div className="ml-1 sm:ml-0 py-1 mt-1 col-span-8 sm:col-span-8 flex flex-col">
													<span className="px-2 text-sm sm:text-md">{leaveOption.name}</span>
													<span className="px-2 text-xs sm:text-xs text-gray-500">
														Maximum length
														<span className="ml-1 bg-blue-100 px-2 py-1 rounded">
															{leaveOption.maximumLength} {leaveOption.unit}
															{leaveOption.maximumLength === 1 ? "" : "s"}
														</span>
													</span>
												</div>
												<div className="col-span-2 px-2 lg:px-6 py-4 text-sm font-medium">
													<LinkButton
														label={
															<div className="flex items-center">
																<TrashIcon className="h-6 w-6 text-red-600" />
																<span className="ml-1">Delete</span>
															</div>
														}
														className="text-red-600 hover:text-red-900"
														loading={activeOption?.id === leaveOption.id ? isLoading : false}
                                                        disabled={isLoading}
														onClick={() => {
                                                            setActiveOption(leaveOption);
															deleteLeaveOption(leaveOption.id);
														}}
													/>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeaveOptionsView;

export async function saveLeaveOptionDetails(data, organisation, setOrganisation) {
	const leaveOption = {
		...data,
		...{ organisationID: organisation.id }
	};

	const updateOrg = await orgUtils.addLeaveOption(leaveOption);
	setOrganisation({
		...updateOrg,
		...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? updateOrg.subscription?.items?.[0]?.quantity },
		...{ members: await orgUtils.mapMembers(updateOrg.members) },
		...{ deletedMembers: await orgUtils.mapMembers(updateOrg.deletedMembers) },
		...{ report: await orgUtils.mapReport(updateOrg?.report?.items || [], updateOrg.id) },
		...{ departments: updateOrg?.departments?.items || [] }
	});
}
