import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings";
import { SectionDescription } from "../misc/Typography";
import { Container, ContentWithPaddingXl } from "../misc/Layouts";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-100 hover:bg-gray-200 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
	${tw`ml-2 transition duration-300`}
	svg {
		${tw`w-6 h-6`}
	}
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
	${tw`pointer-events-none absolute -right-36 -top-20 h-56 w-56 opacity-50 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
	${tw`pointer-events-none absolute -left-40 -bottom-28 h-64 w-64 opacity-50 transform -translate-x-2/3 text-lightww`}
`;

const Faq = ({
	subheading = "FAQS",
	heading = "You have Questions ?",
	description = "If you can't find the right answer here please feel free to contact us through the link below.",
	faqs = [
		{
			question: "Is lunch provided free of cost ?",
			answer: "Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system."
		},
		{
			question: "Do you have 2 Bedroom suites ?",
			answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
		},
		{
			question: "Are Wi-Fi costs included in the price ?",
			answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
		},
		{
			question: "Where can I reach you for support ?",
			answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
		}
	],
	id = "faqs"
}) => {
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

	const toggleQuestion = (questionIndex) => {
		if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
		else setActiveQuestionIndex(questionIndex);
	};

	return (
		<Container id={id}>
			<ContentWithPaddingXl>
				<Column>
					<HeaderContent>
						{subheading && <Subheading>{subheading}</Subheading>}
						<Heading>{heading}</Heading>
						{description && <Description>{description}</Description>}
					</HeaderContent>
					<FAQSContainer>
						{faqs.map((faq, index) => (
							<FAQ
								key={index}
								onClick={() => {
									toggleQuestion(index);
								}}
								className="group"
							>
								<Question>
									<QuestionText>{faq.question}</QuestionText>
									<QuestionToggleIcon
										variants={{
											collapsed: { rotate: 0 },
											open: { rotate: -180 }
										}}
										initial="collapsed"
										animate={activeQuestionIndex === index ? "open" : "collapsed"}
										transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
									>
										<ChevronDownIcon />
									</QuestionToggleIcon>
								</Question>
								<Answer
									variants={{
										open: { opacity: 1, height: "auto", marginTop: "16px" },
										collapsed: { opacity: 0, height: 0, marginTop: "0px" }
									}}
									initial="collapsed"
									animate={activeQuestionIndex === index ? "open" : "collapsed"}
									transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
								>
									{faq.answer}
								</Answer>
							</FAQ>
						))}
					</FAQSContainer>
				</Column>
			</ContentWithPaddingXl>
			<DecoratorBlob1 />
			<DecoratorBlob2 />
		</Container>
	);
};
export default Faq;
