import { useContext, useRef, useState } from "react";
import {
    Avatar,
    Button,
    Form,
    InputField,
    Logo,
    Yup
} from "ww-framework";
import { OrganisationContext, PersonContext, personUtils } from "ww-stores";
import MessageBubble from "./MessageBubble";

const MessagesView = ({ currentMember, chatType, activeMemberChat, setActiveMemberChat, messages, sendMessage, deleteMessage }) => {
    const { organisation } = useContext(OrganisationContext);
    useContext(PersonContext);
    const messagesEndRef = useRef(null);
    const [messageSending, setMessageSending] = useState(false);

    const messageSchema = Yup.object().shape({
        message: Yup.string().required()
    });
    const initValues = {
        message: ""
    };

    return (
        <div className="w-full h-full flex flex-col justify-between	">
            <div className="relative flex items-center p-3 border-b border-darkww-100">
                {chatType === "members" && (
                    <>
                        <Avatar user={activeMemberChat} size="h-8 w-8" twClasses={`object-cover rounded-full`} />
                        <span className="block ml-2 font-bold text-gray-600">{personUtils.displayName(activeMemberChat)}</span>
                    </>
                )}
                {chatType === "roles" && (
                    <>
                        <div
                            style={{ backgroundColor: activeMemberChat?.labelColour }}
                            className="h-7 w-10 rounded-l-md z-10"
                        ></div>
                        <span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">{activeMemberChat?.name}</span>
                    </>
                )}
                {chatType === "departments" && (
                    <>
                        <span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">{activeMemberChat?.name}</span>
                    </>
                )}
                {chatType === "wholeOrganisation" && (
                    <>
                        <Logo org={organisation} size="h-8 w-8" />
                        <span className="block ml-8 font-bold text-xl md:text-3xl text-darkww-600">{organisation.name}</span>
                    </>
                )}
                <button
                    className="sm:hidden ml-auto order-2 mt-1 mr-1 flex p-1 text-white rounded-md bg-darkww-500 focus:outline-none focus:ring-2 ring-white sm:-mr-2"
                    onClick={() => setActiveMemberChat("")}
                >
                    back
                </button>
            </div>
            <div className="relative w-full p-6 overflow-y-auto mb-auto max-h-80">
                <ul className="space-y-2">
                    {messages && messages.length > 0
                        ? messages.map((m) => (
                            <MessageBubble key={m.id} message={m} currentMember={currentMember} chatType={chatType} deleteMessage={deleteMessage} />
                        ))
                        : "Start this conversation by sending the first message!"}
                    <div ref={messagesEndRef} />
                </ul>
            </div>
            <Form
                validationSchema={messageSchema}
                enableReinitialize={true}
                disabled={messageSending}
                initialValues={initValues}
                onSubmit={async (data, { resetForm }) => {
                    try {
                        setMessageSending(true);
                        await sendMessage(data);
                        resetForm();

                        setMessageSending(false);
                    } catch (error) {
                        console.log(error);
                        setMessageSending(false);
                    }
                }}
            >
                <div className="flex items-center justify-between w-full p-3 border-t border-darkww-100 gap-2">
                    <span className="block w-full mx-3">
                        <InputField name="message" placeholder="Message..." />
                    </span>

                    <Button
                        type="submit"
                        label={
                            <svg
                                className="w-6 h-6 text-white bg-lightww-500 origin-center transform rotate-90"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                            </svg>
                        }
                        colors="bg-white ring-darkww-600"
                        className="w-full flex justify-center bg-lightww-500 py-2 px-3 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 mb-3"
                        disabled={messageSending}
                    />
                </div>
            </Form>
        </div>
    );
};

export default MessagesView;