// IssuesModal.jsx
import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const IssuesModal = ({ isOpen, onClose, memberName, issues }) => {
	return (
		<Transition show={isOpen} as={React.Fragment}>
			<Dialog onClose={onClose} className="relative z-10">
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-10" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="max-w-md w-full bg-white rounded-lg p-6 shadow-lg">
								<Dialog.Title className="text-xl underline font-medium leading-6 text-darkww-800">Issues for {memberName}</Dialog.Title>

								<div className="mt-4">
									{issues.length > 0 ? (
										<ul className="list-disc list-inside">
											{issues.map((issue, idx) => (
												<li key={idx} className="text-darkww-600">
													{issue.message}
												</li>
											))}
										</ul>
									) : (
										<p className="text-green-600">No issues detected.</p>
									)}
								</div>

								<div className="mt-6">
									<button onClick={onClose} className="bg-pink-600 text-white px-4 py-2 rounded-md focus:outline-none">
										Close
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default IssuesModal;
