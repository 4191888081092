import React from "react";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "../misc/Layouts";

const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-lightww rounded-lg relative`;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none`;
const PrimaryLink = tw(Link)`bg-pink-600 text-gray-100 shadow-lg hocus:bg-pink-700 hocus:text-gray-200`;

const SecondaryLink = tw(Link)`text-gray-100 border-pink-600 hover:bg-gray-100 hover:text-lightww hover:border-lightww`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-44 -left-20 w-80 h-80 transform -translate-x-20 translate-y-32 text-darkww opacity-50`;
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-40 -right-28 w-80 h-80 transform  translate-x-20 -translate-y-64 text-darkww opacity-50`;
const GetStarted = ({
	text = "We're here to make the lives of everyone in the industry better, from managers and employees to customers and owners.",
	primaryLinkText = "Sign Up",
	primaryLinkUrl = "/employee-manager-signup",
	secondaryLinkText = "Contact Us",
	secondaryLinkUrl = "/",
	pushDownFooter = true
}) => {
	return (
		<Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
			<ContentWithPaddingXl>
				<PrimaryBackgroundContainer>
					<Row>
						<TextContainer>
							<Text>{text}</Text>
						</TextContainer>
						<LinksContainer>
							<PrimaryLink href={primaryLinkUrl} style={{ backgroundColor: "pink-600" }}>
								{primaryLinkText}
							</PrimaryLink>
							<SecondaryLink href={secondaryLinkUrl}>{secondaryLinkText}</SecondaryLink>
						</LinksContainer>
					</Row>
					<DecoratorBlobContainer>
						<DecoratorBlob1 />
						<DecoratorBlob2 />
					</DecoratorBlobContainer>
				</PrimaryBackgroundContainer>
			</ContentWithPaddingXl>
		</Container>
	);
};
export default GetStarted;
