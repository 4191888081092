import React, { Fragment } from "react";
import { Button } from "ww-framework";

import { Dialog, Transition } from "@headlessui/react";

export const ConfirmModal = ({ handlerModal, open, title, subTitle, confirm = "Confirm", cancel = "Cancel" }) => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-20 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
				<div className="font-sans-medium flex items-center justify-center h-1/2 w-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="bg-white transform rounded-lg w-fit w-96">
							<span className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer" onClick={(e) => handlerModal(e, "close")}>
								<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							<div className="w-full flex flex-col font-medium p-5">
								<h2 className="mt-4 text-2xl text-center mb-3">{title}</h2>
								<div className="text-center mb-3">{subTitle}</div>
								<div className="flex justify-center gap-3">
									<Button
										label={cancel}
										colors="bg-darkww-500 hover:bg-darkww-700 focus:ring-white"
										onClick={(e) => handlerModal(e, "close")}
									/>
									<Button
										type="button"
										label={confirm}
										onClick={(e) => handlerModal(e, "confirm")}
										colors="bg-lightww-500 hover:bg-darkww-400 "
									/>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
