import { API, graphqlOperation } from "aws-amplify";
import { fillWeek } from "ww-framework";
import { deleteShift as AWSDeleteShift, createShift, updateShift } from "../../graphql/mutations.js";
import { getOrgMemberShifts, getOrgShifts, getOrgWeekShifts, getShift, getWeekOrgShifts } from "../../graphql/queries.js";

const getEpochBaseDay = (epoch) => Math.floor(epoch / 86400) * 86400;

export const getShifts = async (organisationID, startRange, endRange, isAdmin) => {
	return new Promise(async (resolve, reject) => {
		// endRange will be the very start of the day, need to set it to 23:59 to include shifts on that day.
		const inclLastDay = endRange + 23 * 60 * 60 + 59 * 60;
		const filters = isAdmin
			? { shiftFilter: { between: [startRange, inclLastDay] } }
			: {
					and: [{ shiftFilter: { between: [startRange, inclLastDay] } }, { isShiftPublished: { eq: "PUBLISH" } }]
			  };
		const queryParams = {
			organisationID,
			sortDirection: "ASC",
			filter: filters,
			limit: 1000 // Set a reasonable limit for each fetch
		};
		const customGetWeekOrgShifts = `
			query GetWeekOrgShifts(
				$organisationID: ID
				$weekStartDayShiftStart: ModelShiftGetWeekOrgShiftsCompositeKeyConditionInput
				$sortDirection: ModelSortDirection
				$filter: ModelShiftFilterInput
				$limit: Int
				$nextToken: String
			) {
				getWeekOrgShifts(
					organisationID: $organisationID
					weekStartDayShiftStart: $weekStartDayShiftStart
					sortDirection: $sortDirection
					filter: $filter
					limit: $limit
					nextToken: $nextToken
				) {
					items {
						id
						organisationID
						baseDay
						shiftStart
						shiftFilter
						shiftEnd
						roleID
						role {
							id
							organisationID
							name
							labelColour
						}
						memberID
						member {
							id
							personID
							huorlyRate
							empRateUnit
							departmentID
							email
							person {
								id
								person
								firstName
								lastName
								username
								avatarURI
							}
							assignedAdmin
						}
						setAsClose
						hasBreak
						breakStart
						breakEnd
						weekStartDay
						breakDuration
						notes {
							items {
								id
								shiftID
								note
							}
						}
						shiftStatus
						isShiftPublished
						unPaidBreak
						newPay
					}
					nextToken
				}
			}
		`;
		try {
			// The legacy shifts refers to the shifts that were stored prior to the weekStartDay index being added to the shifts. This call can hopefully be removed in the future when ALL shifts have this index populated.
			// const { data } = await API.graphql(graphqlOperation(getOrgShifts, queryParams));
			// const { getOrgShifts: legacyShifts } = data;

			// The new method for retrieving shifts needs to loop through every "week start day" within the given startRange - endRange to retrieve ONLY the specific shifts for that week. (Only 1 call in weekly mode, 5 calls in monthly mode)
			let indexedShifts = [];
			let legacyShifts = []; // Placeholder for legacy shifts if needed
			const dateEnd = new Date(endRange * 1000);
			let dateLoop = new Date(startRange * 1000);
			while (dateLoop < dateEnd) {
				const {
					first: { year, month, day }
				} = fillWeek(dateLoop, null);
				const weekStartDay = parseInt(`${year}${month.padStart(2, "0")}${day.padStart(2, "0")}`);

				const params = { ...queryParams, ...{ weekStartDay } };
				let nextToken = null;
				do {
					const { data: dataByWeek } = await API.graphql(graphqlOperation(customGetWeekOrgShifts, { ...params, nextToken }));
					const { getWeekOrgShifts: weekShifts } = dataByWeek;
					indexedShifts = [...indexedShifts, ...weekShifts.items];
					nextToken = weekShifts.nextToken;
				} while (nextToken);

				// This iterates the week by bumping the week start day forward 7 days. Will keep going while end date is less than "dateLoop".
				dateLoop.setDate(dateLoop.getDate() + 7);
			}

			// Lastly merge the shifts returned using the weekStartDay index with the legacy shifts. Should also be able to remove this once every shift has "weekStartDay" index on it.
			const combinedShifts = [...legacyShifts, ...indexedShifts].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

			resolve(combinedShifts);
		} catch (error) {
			reject(error);
		}
	});
};
export const getShiftOnDayOfMember = async (baseDay, organisationID, memberID, sID) => {
	return new Promise(async (resolve, reject) => {
		const filters = {
			and: [{ baseDay: { eq: baseDay } }, { memberID: { eq: memberID } }, { id: { ne: sID } }]
		};
		const queryParams = {
			organisationID,
			filter: filters
		};
		try {
			const { data } = await API.graphql(graphqlOperation(getOrgShifts, queryParams));
			resolve(data.getOrgShifts);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveShift = async (shiftDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			const {
				day: { baseEpoch }
			} = shiftDetails;
			let add24Hours = false; // Add a day if end date is before  startdate as they must mean the next day.

			if (shiftDetails.end < shiftDetails.start) {
				add24Hours = true;
			}

			const {
				first: { year, month, day }
			} = fillWeek(new Date((baseEpoch + shiftDetails.start) * 1000), null);

			const weekStartDay = parseInt(`${year}${month.padStart(2, "0")}${day.padStart(2, "0")}`);

			let shiftMapped = {
				id: shiftDetails.id || null,
				organisationID: shiftDetails.organisationID,
				baseDay: baseEpoch,
				shiftStart: parseInt(baseEpoch + shiftDetails.start),
				shiftEnd: baseEpoch + shiftDetails.end + (add24Hours ? 86400 : 0),
				shiftFilter: parseInt(baseEpoch + shiftDetails.start),
				memberID: shiftDetails.person,
				roleID: shiftDetails.role,
				setAsClose: shiftDetails.setAsClose,
				hasBreak: shiftDetails.hasBreak,
				breakDuration: shiftDetails.breakDuration === "Set" ? 0 : shiftDetails.breakDuration,
				breakStart: shiftDetails.hasBreak ? shiftDetails.breakStart : null,
				breakEnd: shiftDetails.hasBreak ? shiftDetails.breakEnd : null,
				weekStartDay: weekStartDay,
				shiftStatus: shiftDetails.shiftStatus || "UN_SEEN",
				isShiftPublished: shiftDetails?.isShiftPublished || "NOT_PUBLISH",
				unPaidBreak: shiftDetails?.unPaidBreak || false,
				newPay: shiftDetails?.newPay ?? null
			};
			const mutation = shiftDetails.id ? updateShift : createShift;
			const { data } = await API.graphql({ query: mutation, variables: { input: shiftMapped } });
			const savedShift = data[shiftDetails.id ? "updateShift" : "createShift"];
			const updatedShift = { ...shiftDetails, ...savedShift };
			resolve(updatedShift);
		} catch (error) {
			reject(error);
		}
	});
};
export const swapShift = async (shiftsDetails) => {
	try {
		const draggedShiftStartTime = shiftsDetails.draggedShift.shiftStart - getEpochBaseDay(shiftsDetails.draggedShift.shiftStart);
		const draggedShiftEndTime = shiftsDetails.draggedShift.shiftEnd - getEpochBaseDay(shiftsDetails.draggedShift.shiftEnd);
		const draggedBreakStartTime = shiftsDetails.draggedShift.breakStart - getEpochBaseDay(shiftsDetails.draggedShift.breakStart);
		const draggedBreakEndTime = shiftsDetails.draggedShift.breakEnd - getEpochBaseDay(shiftsDetails.draggedShift.breakEnd);

		const targetShiftStartTime = shiftsDetails.targetShift.shiftStart - getEpochBaseDay(shiftsDetails.targetShift.shiftStart);
		const targetShiftEndTime = shiftsDetails.targetShift.shiftEnd - getEpochBaseDay(shiftsDetails.targetShift.shiftEnd);
		const targetBreakStartTime = shiftsDetails.targetShift.breakStart - getEpochBaseDay(shiftsDetails.targetShift.breakStart);
		const targetBreakEndTime = shiftsDetails.targetShift.breakEnd - getEpochBaseDay(shiftsDetails.targetShift.breakEnd);

		const draggedInput =
			shiftsDetails.draggedShift.baseDay === shiftsDetails.targetShift.baseDay
				? {
						id: shiftsDetails.draggedShift.id,
						note: shiftsDetails.targetShift.note,
						baseDay: shiftsDetails.targetShift.baseDay,
						roleID: shiftsDetails.targetShift.role.id,
						shiftStart: shiftsDetails.targetShift.baseDay + targetShiftStartTime,
						shiftEnd: shiftsDetails.targetShift.baseDay + targetShiftEndTime,
						shiftFilter: shiftsDetails.targetShift.baseDay + targetShiftStartTime,
						setAsClose: shiftsDetails.targetShift.setAsClose,
						hasBreak: shiftsDetails.targetShift.hasBreak,
						breakStart: shiftsDetails.targetShift.baseDay + targetBreakStartTime,
						breakEnd: shiftsDetails.targetShift.baseDay + targetBreakEndTime,
						weekStartDay: shiftsDetails.targetShift.weekStartDay
						// Add any other shift details you want to update here
				  }
				: {
						id: shiftsDetails.draggedShift.id,
						baseDay: shiftsDetails.targetShift.baseDay,
						memberID: shiftsDetails.targetShift.memberID,
						shiftStart: shiftsDetails.targetShift.baseDay + draggedShiftStartTime,
						shiftEnd: shiftsDetails.targetShift.baseDay + draggedShiftEndTime,
						breakStart: shiftsDetails.targetShift.baseDay + draggedBreakStartTime,
						breakEnd: shiftsDetails.targetShift.baseDay + draggedBreakEndTime,
						shiftFilter: shiftsDetails.targetShift.baseDay + draggedShiftStartTime
				  };
		const targetInput =
			shiftsDetails.draggedShift.baseDay === shiftsDetails.targetShift.baseDay
				? {
						id: shiftsDetails.targetShift.id,
						note: shiftsDetails.draggedShift.note,
						baseDay: shiftsDetails.draggedShift.baseDay,
						roleID: shiftsDetails.draggedShift.role.id,
						shiftStart: shiftsDetails.draggedShift.baseDay + draggedShiftStartTime,
						shiftEnd: shiftsDetails.draggedShift.baseDay + draggedShiftEndTime,
						shiftFilter: shiftsDetails.draggedShift.baseDay + draggedShiftStartTime,
						setAsClose: shiftsDetails.draggedShift.setAsClose,
						hasBreak: shiftsDetails.draggedShift.hasBreak,
						breakStart: shiftsDetails.draggedShift.baseDay + draggedBreakStartTime,
						breakEnd: shiftsDetails.draggedShift.baseDay + draggedBreakEndTime,
						weekStartDay: shiftsDetails.draggedShift.weekStartDay
						// Add any other shift details you want to update here
				  }
				: {
						id: shiftsDetails.targetShift.id,
						baseDay: shiftsDetails.draggedShift.baseDay,
						memberID: shiftsDetails.draggedShift.memberID,
						shiftStart: shiftsDetails.draggedShift.baseDay + targetShiftStartTime,
						shiftEnd: shiftsDetails.draggedShift.baseDay + targetShiftEndTime,
						breakStart: shiftsDetails.draggedShift.baseDay + targetBreakStartTime,
						breakEnd: shiftsDetails.draggedShift.baseDay + targetBreakEndTime,
						shiftFilter: shiftsDetails.draggedShift.baseDay + targetShiftStartTime
				  };

		const { data: first } = await API.graphql({
			query: updateShift,
			variables: {
				input: draggedInput
			}
		});

		const { data: second } = await API.graphql({
			query: updateShift,
			variables: {
				input: targetInput
			}
		});

		return { first: first.updateShift, second: second.updateShift };
	} catch (error) {
		if (error.errors && error.errors.length > 0) {
		} else {
			console.error("Error object:", error);
		}
		throw new Error("Failed to swap the shifts.");
	}
};
export const swapRequest = async (shiftDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftDetails.id,
						memberID: shiftDetails.member
					}
				}
			});
			const { second } = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftDetails.toShiftId,
						memberID: shiftDetails.memberID
					}
				}
			});

			resolve({ ...data, ...second });
		} catch (error) {
			reject(error);
		}
	});
};

export const replaceShift = async (shiftsDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			// Fetch the dragged shift details
			const draggedShiftResponse = await API.graphql({ query: getShift, variables: { id: shiftsDetails.id } });
			const draggedShift = draggedShiftResponse.data.getShift;

			if (!draggedShift) {
				reject(new Error("Dragged shift not found."));
				return;
			}

			const draggedShiftStartTime = draggedShift.shiftStart - getEpochBaseDay(draggedShift.shiftStart);
			const draggedShiftEndTime = draggedShift.shiftEnd - getEpochBaseDay(draggedShift.shiftEnd);
			const draggedBreakStartTime = draggedShift.breakStart - getEpochBaseDay(draggedShift.breakStart);
			const draggedBreakEndTime = draggedShift.breakEnd - getEpochBaseDay(draggedShift.breakEnd);
			const targetShiftBaseDay = shiftsDetails.targetShift.baseDay;

			// Update the target shift with the dragged shift details
			const updateResponse = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftsDetails.toShiftId,
						memberID: shiftsDetails.memberID,
						roleID: draggedShift.role.id,
						shiftStart: targetShiftBaseDay + draggedShiftStartTime,
						shiftEnd: targetShiftBaseDay + draggedShiftEndTime,
						shiftFilter: targetShiftBaseDay + draggedShiftStartTime,
						setAsClose: draggedShift.setAsClose,
						hasBreak: draggedShift.hasBreak,
						breakStart: targetShiftBaseDay + draggedBreakStartTime,
						breakEnd: targetShiftBaseDay + draggedBreakEndTime,
						weekStartDay: draggedShift.weekStartDay
						// Add any other shift details you want to update here
					}
				}
			});
			const updatedShift = updateResponse.data.updateShift;

			if (!updatedShift) {
				reject(new Error("Failed to update the target shift."));
				return;
			}

			// Delete the dragged shift
			await API.graphql({ query: AWSDeleteShift, variables: { input: { id: shiftsDetails.id } } });

			resolve(updatedShift);
		} catch (error) {
			if (error.errors && error.errors.length > 0) {
			} else {
				console.error("Error object:", error);
			}
		}
	});
};

export const moveShift = async (shiftDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			// Fetch the dragged shift details
			const draggedShiftResponse = await API.graphql({ query: getShift, variables: { id: shiftDetails.id } });
			const draggedShift = draggedShiftResponse.data.getShift;

			if (!draggedShift) {
				reject(new Error("Dragged shift not found."));
				return;
			}

			const draggedShiftStartTime = draggedShift.shiftStart - getEpochBaseDay(draggedShift.shiftStart);
			const draggedShiftEndTime = draggedShift.shiftEnd - getEpochBaseDay(draggedShift.shiftEnd);
			const draggedBreakStartTime = draggedShift.breakStart - getEpochBaseDay(draggedShift.breakStart);
			const draggedBreakEndTime = draggedShift.breakEnd - getEpochBaseDay(draggedShift.breakEnd);

			// Update the dragged shift with the desired employee details and other data
			const updateResponse = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: draggedShift.id,
						memberID: shiftDetails.memberID, // Update the employee name
						baseDay: shiftDetails.baseDay,
						roleID: draggedShift.role.id,
						shiftStart: shiftDetails.baseDay + draggedShiftStartTime,
						shiftEnd: shiftDetails.baseDay + draggedShiftEndTime,
						shiftFilter: shiftDetails.baseDay + draggedShiftStartTime,
						setAsClose: draggedShift.setAsClose,
						hasBreak: draggedShift.hasBreak,
						breakStart: shiftDetails.baseDay + draggedBreakStartTime,
						breakEnd: shiftDetails.baseDay + draggedBreakEndTime,
						weekStartDay: draggedShift.weekStartDay
						// Add any other shift details you want to update here
					}
				}
			});
			const updatedShift = updateResponse.data.updateShift;

			if (!updatedShift) {
				reject(new Error("Failed to update the dragged shift."));
				return;
			}

			resolve(updatedShift);
		} catch (error) {
			if (error.errors && error.errors.length > 0) {
				reject(error);
			} else {
				console.error("Error object:", error);
				reject(new Error("Unknown error."));
			}
		}
	});
};

export const giveAwayShift = async (shiftDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftDetails.id,
						memberID: shiftDetails.member
					}
				}
			});
			resolve({ ...data });
		} catch (error) {
			reject(error);
		}
	});
};
export const publishShift = async (shiftData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const shiftMapped = {
				id: shiftData?.id,
				baseDay: shiftData?.baseDay,
				breakDuration: shiftData?.breakDuration,
				breakEnd: shiftData?.breakEnd,
				breakStart: shiftData?.breakStart,
				clockIn: null,
				clockInBreak: null,
				clockOut: null,
				setAsClose: shiftData?.setAsClose,
				hasBreak: shiftData?.hasBreak,
				memberID: shiftData?.memberID,
				roleID: shiftData?.roleID,
				shiftEnd: shiftData?.shiftEnd,
				shiftFilter: shiftData?.shiftFilter,
				shiftStart: shiftData?.shiftStart,
				shiftStatus: shiftData?.shiftStatus,
				weekStartDay: shiftData?.weekStartDay,
				isShiftPublished: "PUBLISH"
			};
			const { data } = await API.graphql({ query: updateShift, variables: { input: shiftMapped } });
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteShift = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({ query: AWSDeleteShift, variables: { input: { id: id } } });
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};

export const addClockIn = async (shiftData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let clockInBreak = {
				clockInHasBreak: shiftData.clockInHasBreak,
				clockInBreakDuration: shiftData.clockInBreakDuration === "Set" ? 0 : shiftData.clockInBreakDuration,
				clockInBreakstart: shiftData.clockInHasBreak ? parseInt(shiftData.baseDay + shiftData.clockInBreakstart) : null,
				clockInBreakend: shiftData.clockInHasBreak ? parseInt(shiftData.baseDay + shiftData.clockInBreakend) : null
			};
			let clockIn = shiftData.baseDay + (shiftData.clockInHour * 3600 + shiftData.clockInMinute * 60) || shiftData.shiftStart;
			let clockOut = shiftData.baseDay + (shiftData.clockOutHour * 3600 + shiftData.clockOutMinute * 60) || shiftData.shiftEnd;
			const { data } = await API.graphql({
				query: updateShift,
				variables: { input: { id: shiftData.id, clockIn: clockIn, clockOut: clockOut, clockInBreak: JSON.stringify(clockInBreak) } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPendingClockIns = async (organisationID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const queryParams = {
				organisationID,
				sortDirection: "ASC",
				limit: 1000,
				filter: { clockIn: { gt: 1 } }
			};
			const { data } = await API.graphql(graphqlOperation(getOrgShifts, queryParams));
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const approvePendingClockIn = async (shiftDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			const updatedBreak = [];

			let breakData = JSON.parse(shiftDetail?.clockInBreak);
			updatedBreak["hasBreak"] = shiftDetail.hasBreak;
			updatedBreak["duration"] = shiftDetail.breakDuration;
			updatedBreak["breakStart"] = shiftDetail.breakStart;
			updatedBreak["breakEnd"] = shiftDetail.breakEnd;
			if (breakData.clockInHasBreak) {
				updatedBreak["hasBreak"] = true;
				updatedBreak["duration"] = breakData.clockInBreakDuration === "Set" ? 0 : breakData.clockInBreakDuration;
				updatedBreak["breakStart"] = breakData.clockInHasBreak ? parseInt(breakData.clockInBreakstart) : null;
				updatedBreak["breakEnd"] = breakData.clockInHasBreak ? parseInt(breakData.clockInBreakend) : null;
			}
			const { data } = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftDetail.id,
						weekStartDay: shiftDetail.weekStartDay,
						shiftStart: shiftDetail.clockIn,
						shiftEnd: shiftDetail.clockOut,
						clockIn: null,
						clockOut: null,
						clockInBreak: null,
						hasBreak: updatedBreak["hasBreak"],
						breakDuration: updatedBreak["duration"],
						breakStart: updatedBreak["breakStart"],
						breakEnd: updatedBreak["breakEnd"]
					}
				}
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const denyPendingClockIn = async (shiftDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftDetail.id,
						weekStartDay: shiftDetail.weekStartDay,
						clockIn: null,
						clockOut: null,
						clockInBreak: null
					}
				}
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const changeBaseDayAndMember = async (shiftDetail) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: updateShift,
				variables: {
					input: {
						id: shiftDetail.id,
						baseDay: shiftDetail.baseDay,
						memberID: shiftDetail.memberID,
						shiftFilter: shiftDetail.shiftFilter,
						shiftStart: shiftDetail.shiftStart,
						shiftEnd: shiftDetail.shiftEnd,
						weekStartDay: shiftDetail.weekStartDay
					}
				}
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getMemberWeekShift = async (organisationID, memberID, weekStartDay, shiftDates) => {
	return new Promise(async (resolve, reject) => {
		// and: [{ weekStartDay: { eq: weekStartDay } }, { shiftStart: { gt: new Date(new Date().toUTCString()).valueOf() / 1000 } }]
		const filters = {
			and: [{ weekStartDay: { eq: weekStartDay } }]
		};
		const queryParams = {
			weekStartDay,
			organisationID,
			sortDirection: "ASC",
			limit: 1000,
			filter: filters
		};
		try {
			const { data: dataByWeek } = await API.graphql(graphqlOperation(getWeekOrgShifts, queryParams));
			const { getWeekOrgShifts: weekShifts } = dataByWeek;
			// console.log(weekShifts);
			resolve(weekShifts);
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};

export const getShiftsForTheWeek = async (organisationID, startDay) => {
	const weekStartDay = parseInt(`${startDay.year}${startDay.month.padStart(2, "0")}${startDay.day.padStart(2, "0")}`);
	return new Promise(async (resolve, reject) => {
		const queryParams = {
			weekStartDay: { eq: weekStartDay },
			organisationID,
			sortDirection: "ASC",
			limit: 1000
		};
		try {
			const { data: dataByWeek } = await API.graphql(graphqlOperation(getOrgWeekShifts, queryParams));
			const { getOrgWeekShifts: weekShifts } = dataByWeek;
			resolve(weekShifts);
		} catch (error) {
			console.log("Failed to get week's shifts", error);
			reject(error);
		}
	});
};

export const getMemberYearShifts = async (organisationID, memberID, endDate) => {
	return new Promise(async (resolve, reject) => {
		const endDateEpoch = endDate.baseEpoch;
		const yearStartEpoch = new Date(endDate.year, 0, 1).getTime() / 1000;
		const filters = { shiftStart: { between: [yearStartEpoch, endDateEpoch + 23 * 60 * 60 + 59 * 60] } };
		const queryParams = {
			organisationID,
			memberID: { eq: memberID },
			sortDirection: "ASC",
			filters: filters,
			limit: 1000
		};
		try {
			const { data: dataByYear } = await API.graphql(graphqlOperation(getOrgMemberShifts, queryParams));
			const { getOrgMemberShifts: yearShifts } = dataByYear;
			resolve(yearShifts);
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};
// the above getMemberYearShifts hits a limit when fetching. Creating a new function getMemberYearShiftsByMonth that will fetch all shifts for the year. and avoid hitting the limit.
// It's broken down to months to fetch all shifts for each month upto the endDate and combine the shifts.
export const getMemberYearShiftsByMonth = async (organisationID, memberID, endDate) => {
	const promises = [];
	const endDateEpoch = endDate.baseEpoch;
	const yearStart = new Date(endDate.year, 0, 1);
	const lastMonthIndex = parseInt(endDate.month) - 1;

	for (let monthIndex = 0; monthIndex <= lastMonthIndex; monthIndex++) {
		console.log("Month: ", monthIndex);
		const monthStartEpoch = new Date(endDate.year, monthIndex, 1).getTime() / 1000;
		console.log("Month Start: ", monthStartEpoch);
		const monthEndEpoch = new Date(endDate.year, monthIndex + 1, 0, 23, 59, 59).getTime() / 1000;
		console.log("Month End: ", monthEndEpoch);

		const filters = { shiftStart: { between: [monthStartEpoch, Math.min(monthEndEpoch, endDateEpoch)] } };
		const queryParams = {
			organisationID,
			memberID: { eq: memberID },
			sortDirection: "ASC",
			filters,
			limit: 1000
		};

		promises.push(API.graphql(graphqlOperation(getOrgMemberShifts, queryParams)));
	}

	try {
		const results = await Promise.all(promises);
		// Combine all shift data into a list
		const allShifts = results.flatMap(({ data }) => data.getOrgMemberShifts || []);
		return allShifts;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const getCurrentYearShifts = async (organisationID, endDate) => {
	return new Promise(async (resolve, reject) => {
		try {
			const currentYear = new Date().getFullYear();
			const filters = { shiftFilter: { between: [new Date(currentYear, 0, 1).getTime() / 1000, endDate + 23 * 60 * 60 + 59 * 60] } };
			const queryParams = {
				organisationID,
				sortDirection: "ASC",
				filters: filters,
				limit: 1000
			};
			const customGetOrgShifts = /* GraphQL */ `
				query GetOrgShifts(
					$organisationID: ID
					$shiftStart: ModelIntKeyConditionInput
					$sortDirection: ModelSortDirection
					$filter: ModelShiftFilterInput
					$limit: Int
					$nextToken: String
				) {
					getOrgShifts(
						organisationID: $organisationID
						shiftStart: $shiftStart
						sortDirection: $sortDirection
						filter: $filter
						limit: $limit
						nextToken: $nextToken
					) {
						items {
							id
							organisationID
							baseDay
							shiftStart
							shiftFilter
							shiftEnd
							memberID
							setAsClose
							hasBreak
							breakStart
							breakEnd
							weekStartDay
							breakDuration
							shiftStatus
							isShiftPublished
							unPaidBreak
						}
						nextToken
					}
				}
			`;
			const {
				data: { getOrgShifts: data }
			} = await API.graphql(graphqlOperation(customGetOrgShifts, queryParams));
			resolve(data);
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};

export const getDayShifts = async (organisationID, baseDay) => {
	return new Promise(async (resolve, reject) => {
		try {
			const filters = { shiftFilter: { between: [baseDay, baseDay + 23 * 60 * 60 + 59 * 60] } };

			const queryParams = {
				organisationID,
				limit: 1000,
				filter: filters
			};
			const {
				data: { getOrgShifts: data }
			} = await API.graphql(graphqlOperation(getOrgShifts, queryParams));
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
