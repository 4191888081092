import logoImage from "ww-images/workwall-logo.svg";
export const Loading = ({ text = "Loading..." }) => {
	return (
		<>
			<div className="flex flex-col h-full m-2">
				<main className="animate-pulse flex-grow flex flex-col justify-center w-full">
					<div className="flex-shrink-0 flex justify-center">
						<a href="/dashboard" className="inline-flex">
							<span className="sr-only">Workwall</span>
							<img className="h-24 lg:h-48 w-auto" src={logoImage} alt="" />
						</a>
					</div>
					<div className="py-8">
						<div className="text-center">
							<h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{text}</h1>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};
