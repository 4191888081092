import React, { useContext, useState, useEffect } from "react";
import { CheckIcon, TagIcon } from "@heroicons/react/solid";
import {
	Form,
	Button,
	Yup,
	useSimpleMessage,
	showFailure,
	LinkButton,
	DaySegments,
	CheckBox,
	ComboBox,
	DayHourSegments,
	DayMinuteSegments,
	secondsToHm,
	ConfirmModal
} from "ww-framework";
import { orgUtils, OrganisationContext } from "ww-stores";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const RoleListOption = ({ selected, active, option: role }) => {
	return (
		<>
			<div className="flex items-center">
				<TagIcon style={{ color: role?.labelColour }} className="h-6 w-6" aria-hidden="true" />
				<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{role?.name}</span>
			</div>

			{selected ? (
				<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
					<CheckIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			) : null}
		</>
	);
};

const TemplateForm = ({ record = {}, getShiftTemplates, cancelCB }) => {
	const { organisation } = useContext(OrganisationContext);
	const [shiftSaving, setShiftSaving] = useState(false);
	const [showBreak, setShowBreak] = useState(false);
	const [unPaidBreak, setUnPaidBreak] = useState(false);
	const [breakValue, setBreakValue] = useState();
	const [roles, setRoles] = useState([]);
	const [showDelete, setShowDelete] = useState(false);
	const { setMessage } = useSimpleMessage();
	const [open, setOpen] = useState(false);
	const [breakSet] = useState([
		{ id: 15, name: 15, description: "15 Mins" },
		{ id: 30, name: 30, description: "30 Mins" },
		{ id: 60, name: 60, description: "60 Mins" },
		{ id: "Set", name: "Set", description: "Set" }
	]);
	const [showBreakTime, setShowBreakTime] = useState(
		record?.breakDuration === (0 || null) && record?.breakStart && record?.breakEnd ? true : false
	);
	const shiftSchema = Yup.object().shape({
		startHour: Yup.string().required(),
		endHour: Yup.string().required(),
		startMinute: Yup.string().required(),
		endMinute: Yup.string().required(),
		roleID: Yup.string().required(),
		setAsClose: Yup.boolean(),
		hasBreak: Yup.boolean(),
		breakDuration: Yup.string().when("hasBreak", {
			is: (hasBreak) => hasBreak === true,
			then: Yup.string().required()
		}),
		breakstart: Yup.number().when("breakDuration", {
			is: (breakDuration) => breakDuration === "Set",
			then: Yup.number()
				.required("Required")
				.when("startHour", (startHour, schema) => {
					return schema.test({
						test: (breakstart) => !!breakstart && breakstart > parseInt(startHour) * 3600,
						message: "Break starts before shift!"
					});
				})
				.when("endHour", (endHour, schema) => {
					return schema.test({
						test: (breakstart) => !!breakstart && breakstart < parseInt(endHour) * 3600,
						message: "Break starts before shifts!"
					});
				})
		}),
		breakend: Yup.number().when("breakDuration", {
			is: (breakDuration) => breakDuration === "Set",
			then: Yup.number()
				.required("Required")
				.when("breakstart", (breakstart, schema) => {
					return schema.test({
						test: (breakend) => !!breakstart && breakend > breakstart,
						message: "Break ends before it even starts!"
					});
				})
				.when("endHour", (endHour, schema) => {
					return schema.test({
						test: (breakend) => !!breakend && breakend < parseInt(endHour) * 3600,
						message: "Break ends after shift!"
					});
				})
		})
	});
	let endTime = "";
	const breakList = ({ selected, active, option: person }) => {
		return (
			<div onClick={() => updateBreak(person.id)}>
				<div className="flex items-center">
					<span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{person.name}</span>
				</div>

				{selected ? (
					<span className={classNames(active ? "text-white" : "text-darkww-600", "absolute inset-y-0 right-0 flex items-center px-4")}>
						<CheckIcon className="h-5 w-5" aria-hidden="true" />
					</span>
				) : null}
			</div>
		);
	};

	if (record) {
		endTime = record?.shiftEnd;
		if (endTime > 86400) {
			endTime = endTime - 86400;
		}
	}

	const initValues = {
		id: record?.id || "",
		startHour: secondsToHm(record?.shiftStart, "hour") || "12",
		startMinute: secondsToHm(record?.shiftStart, "minute") || "00",
		endHour: secondsToHm(endTime, "hour") || "20",
		endMinute: secondsToHm(endTime, "minute") || "00",
		roleID: record?.roleID || "",
		setAsClose: record?.setAsClose || false,
		hasBreak: record?.hasBreak || false,
		unPaidBreak: organisation?.paidBreak || record?.unPaidBreak ? true : false,
		breakDuration: record?.breakDuration > 0 ? record?.breakDuration : record?.breakStart && record?.breakEnd ? "Set" : 0,
		breakstart: record?.hasBreak > 0 && record?.breakStart > 0 ? record?.breakStart : "",
		breakend: record?.hasBreak > 0 && record?.breakStart > 0 ? record?.breakEnd : ""
	};
	const deleteShift = async (shiftId) => {
		try {
			await orgUtils.deleteShiftTemplate(shiftId);
			cancelCB();
			getShiftTemplates();
			/*setMessage(
		showSuccess({
		  title: "Shift removed successfully."
		})
	  );*/
		} catch (error) {
			setMessage(
				showFailure({
					title: "Unable to remove Shift.",
					subTitle: error.message
				})
			);
		}
	};

	useEffect(() => {
		const { roles } = organisation;
		const orgRoles = Array.isArray(roles) ? roles : Array.isArray(roles?.items) ? roles.items : [];
		const rolesCombo = orgRoles.map((role) => {
			return { ...role, description: role?.name };
		});
		setRoles(rolesCombo); // TODO: fix the way "roles" is built up in the system. It's inconsistent, should be set in organisation.js for consistency.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	useEffect(() => {
		setShowBreak(initValues.hasBreak);
	}, [initValues.hasBreak]);

	const updateBreak = (value) => {
		if (value === "Set") {
			setShowBreakTime(true);
		} else {
			setShowBreakTime(false);
		}
	};
	const handlerModal = (e, type) => {
		e.preventDefault();
		setOpen(false);
	};
	return (
		<>
			<ConfirmModal
				handlerModal={handlerModal}
				open={open}
				className="text-base font-medium text-gray-900"
				title="This employee has booked this day off"
				subTitle="Are you sure?"
				confirm="Assign this Employee"
				cancel="Choose another "
			/>
			<Form
				validationSchema={shiftSchema}
				enableReinitialize={true}
				disabled={shiftSaving}
				initialValues={initValues}
				onSubmit={async (data, { resetForm }) => {
					try {
						setShiftSaving(true);
						const shiftDetails = {
							...data,
							...{
								breakDuration: data?.breakDuration ?? 0,
								breakstart: data?.breakstart ? data?.breakstart : null,
								breakend: data?.breakend ? data?.breakend : null,
								roleID: data.roleID,
								organisationID: organisation.id,
								shiftStart: parseInt(data.startHour) * 60 * 60 + parseInt(data.startMinute) * 60,
								shiftEnd: parseInt(data.endHour) * 60 * 60 + parseInt(data.endMinute) * 60
							}
						};
						await orgUtils.saveShiftTemplate(shiftDetails);
						setShiftSaving(false);
						resetForm();
						cancelCB();
						getShiftTemplates();
						/*setMessage(
				showSuccess({
				  title: "Shift saved successfully."
				})
			  );*/
					} catch (error) {
						console.log(error);
						setShiftSaving(false);
						setMessage(
							showFailure({
								title: "Unable to save Shift.",
								subTitle: error.message
							})
						);
					}
				}}
			>
				<div className="w-72 flex flex-col mt-5 pl-10">
					<div className="mt-5 ">
					<div className="flex justify-between mb-2 ">
					<label className="flex justify-start text-sm font-medium text-white">Start Time</label>
						<div className=" w-fit flex justify-end">
						<CheckBox
							name="hasBreak"
							label="Include break"
							labelTextColour="text-white"
							onChange={() => setShowBreak(!showBreak)}
							checked={record?.hasBreak || false}
						/>
						</div>
					</div>
						<div className="flex justify-center">
							<ComboBox
								name="startHour"
								labelTextColour="text-gray-700 xl:text-white"
								options={DayHourSegments}
								initValue={initValues.startHour}
							/>

							<div className="text-gray-700 xl:text-white px-4 self-center">:</div>
							<ComboBox
								name="startMinute"
								labelTextColour="text-gray-700 xl:text-white"
								options={DayMinuteSegments}
								initValue={initValues.startMinute}
							/>
						</div>
					</div>
					{showBreak && (
						<>
							<div className="flex justify-center mt-5">
								<ComboBox
									label="Break"
									name="breakDuration"
									labelTextColour="text-gray-700 xl:text-white"
									options={breakSet}
									ComboBoxOption={breakList}
									initValue={initValues.breakDuration}
									getSelectedValueOnChange={(value) => updateBreak(value.id)}
								/>
								{/* <select
								className="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								onChange={(e) => updateBreak(e.target.value)}
							>
								<option>Please select brake duration</option>
								{breakSet.map((item, index) => {
									return <option value={item}>{item} Mins</option>;
								})}
								<option value={"set"}>Set</option>
							</select> */}
								<CheckBox
									name="unPaidBreak"
									label="Unpaid"
									labelTextColour="text-white"
									onChange={() => setUnPaidBreak(!unPaidBreak)}
									checked={record?.unPaidBreak || false}
								/>
							</div>
							{showBreakTime && (
								<div className="flex justify-center">
									<div className="w-32">
										<ComboBox
											label="Start"
											name="breakstart"
											labelTextColour="text-white"
											options={DaySegments}
											initValue={initValues.breakstart}
										/>
									</div>
									<div className=" text-white px-4 self-center">to</div>
									<div className="w-32">
										<ComboBox
											label="End"
											name="breakend"
											labelTextColour="text-white"
											options={DaySegments}
											initValue={initValues.breakend}
										/>
									</div>
								</div>
							)}
						</>
					)}
					<div className="mt-5">
					<div className="flex justify-between mb-2 ">
						<label className="flex justify-start text-sm font-medium text-white">End Time</label>
						<div className="flex items-center">
						<CheckBox
							name="setAsClose"
							label="To Close"
							labelTextColour="text-white"
							checked={record?.setAsClose || false}
							toolTip="Display this time as Close on their shift"
						/>
						</div>
						</div>
						
						<div className="flex justify-center">
							<ComboBox
								name="endHour"
								labelTextColour="text-gray-700 xl:text-white"
								options={DayHourSegments}
								initValue={initValues.endHour}
							/>

							<div className="text-gray-700 xl:text-white px-4 self-center">:</div>
							<ComboBox
								name="endMinute"
								labelTextColour="text-gray-700 xl:text-white"
								options={DayMinuteSegments}
								initValue={initValues.endMinute}
							/>
						</div>
					</div>
					<div className="mt-5">
						<ComboBox
							label="Roles"
							name="roleID"
							labelTextColour="text-white"
							custom={true}
							options={roles}
							ComboBoxOption={RoleListOption}
							initValue={initValues.roleID}
						/>
					</div>

					<div className="mt-5 flex flex-row justify-between">
						<div className="py-2">
							{record?.id && (
								<CheckBox
									name="delete"
									label="Delete"
									labelTextColour="text-white"
									onChange={() => setShowDelete(!showDelete)}
									checked={showDelete}
								/>
							)}
						</div>
						<div className="flex justify-end gap-5">
							{!showDelete ? (
								<>
									<LinkButton label="Cancel" className=" text-white font-semibold hover:text-gray-400" onClick={cancelCB} />
									<Button type="submit" label="Save" disabled={shiftSaving} />
								</>
							) : (
								<>
									<LinkButton
										label="Cancel"
										className=" text-white font-semibold hover:text-gray-400"
										onClick={() => setShowDelete(!showDelete)}
									/>
									<Button
										type="button"
										label="Confirm"
										onClick={() => deleteShift(record?.id)}
										colors="bg-red-500 hover:bg-red-600 focus:ring-red-600"
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

export default TemplateForm;
