import { API } from "aws-amplify";
import {
	deleteCheckIn as AWSDeleteCheckIn,
	createCheckIn,
	createCompletedCheckIn,
	updateCheckIn,
	updateCompletedCheckIn
} from "../../graphql/mutations.js";

export const calculateCheckInDate = (startDate, duration, unit) => {
	duration = parseInt(duration);
	let checkInDate = new Date(startDate);
	switch (unit) {
		case "days":
			checkInDate.setDate(checkInDate.getDate() + duration);
			break;
		case "weeks":
			checkInDate.setDate(checkInDate.getDate() + duration * 7);
			break;
		case "months":
			checkInDate.setMonth(checkInDate.getMonth() + duration);
			break;
		case "years":
			checkInDate.setFullYear(checkInDate.getFullYear() + duration);
			break;
		default:
			// Handle invalid unit
			console.error("Invalid unit:", unit);
			break;
	}
	return checkInDate;
};

export const getDueCheckIns = ({ startDate, checkIns, completedCheckIns }) => {
	if (!startDate || !checkIns || !completedCheckIns) {
		return [];
	}
	let dueCheckIns = checkIns.filter((checkIn) => {
		return !completedCheckIns.some((completedCheckIn) => completedCheckIn.checkInID === checkIn.id);
	});

	dueCheckIns = dueCheckIns.map((checkIn) => {
		const checkInDuration = checkIn.duration;
		const checkInUnit = checkIn.unit;
		const checkInDate = calculateCheckInDate(startDate, checkInDuration, checkInUnit);
		const currentDate = new Date();
		const daysLeft = Math.floor((checkInDate - currentDate) / (1000 * 60 * 60 * 24));
		return {
			...checkIn,
			description: `at ${checkInDuration} ${checkInUnit}`,
			daysLeft: daysLeft
		};
	});

	dueCheckIns = dueCheckIns.filter((checkIn) => {
		return checkIn.daysLeft <= 30 && checkIn.daysLeft >= 0;
	});
	return dueCheckIns;
};

export const getLateCheckIns = ({ startDate, checkIns, completedCheckIns }) => {
	if (!startDate || !checkIns || !completedCheckIns) {
		return [];
	}
	let lateCheckIns = checkIns.filter((checkIn) => {
		return !completedCheckIns.some((completedCheckIn) => completedCheckIn.checkInID === checkIn.id);
	});
	lateCheckIns = lateCheckIns.map((checkIn) => {
		const checkInDuration = checkIn.duration;
		const checkInUnit = checkIn.unit;
		const checkInDate = calculateCheckInDate(startDate, checkInDuration, checkInUnit);
		const currentDate = new Date();
		const daysLate = Math.floor((currentDate - checkInDate) / (1000 * 60 * 60 * 24));
		return {
			...checkIn,
			description: `at ${checkInDuration} ${checkInUnit}`,
			daysLate: daysLate
		};
	});
	lateCheckIns = lateCheckIns.filter((checkIn) => {
		return checkIn.daysLate > 0;
	});
	return lateCheckIns;
};

export const saveCheckIn = async (checkInDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			let checkInMapped = {
				id: checkInDetails.id,
				name: checkInDetails.name,
				duration: checkInDetails.duration,
				unit: checkInDetails.unit,
				organisationID: checkInDetails.organisationID
			};

			const mutation = checkInDetails.id ? updateCheckIn : createCheckIn;
			const { data } = await API.graphql({ query: mutation, variables: { input: checkInMapped } });
			const savedCheckIn = data[checkInDetails.id ? "updateCheckIn" : "createCheckIn"];

			const updatedCheckIn = { ...checkInDetails, ...savedCheckIn };
			resolve(updatedCheckIn);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveCompletedCheckIn = async (completedCheckInDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			let completedCheckInMapped = {
				id: completedCheckInDetails.id,
				checkInID: completedCheckInDetails.checkInID,
				note: completedCheckInDetails.checkInNote,
				date: completedCheckInDetails.date,
				organisationID: completedCheckInDetails.organisationID,
				memberID: completedCheckInDetails.memberID
			};

			const mutation = completedCheckInDetails.id ? updateCompletedCheckIn : createCompletedCheckIn;
			const { data } = await API.graphql({ query: mutation, variables: { input: completedCheckInMapped } });
			const savedCompletedCheckIn = data[completedCheckInDetails.id ? "updateCompletedCheckIn" : "createCompletedCheckIn"];

			const updatedCompletedCheckIn = { ...completedCheckInDetails, ...savedCompletedCheckIn };
			resolve(updatedCompletedCheckIn);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteCheckIn = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({ query: AWSDeleteCheckIn, variables: { input: { id: id } } });
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};

export const notifyLateCheckIns = async ({ currentUser, org, navigate, location }) => {
	if (currentUser?.isAdmin || currentUser?.assignedSuperAdmin) {
		const orgCheckIns = org?.checkIns || [];
		const anyMemberWithLateCheckIns = org.members.some((member) => {
			const memberStartDate = member?.startDate ? new Date(member.startDate * 1000) : undefined;
			if (!memberStartDate) {
				return false;
			}
			const memberCompletedCheckIns = member.completedCheckIns;
			const memberLateCheckIns = getLateCheckIns({
				startDate: memberStartDate,
				checkIns: orgCheckIns,
				completedCheckIns: memberCompletedCheckIns
			});
			if (memberLateCheckIns.length > 0) {
				return true;
			}
			return false;
		});
		if (anyMemberWithLateCheckIns) {
			setTimeout(
				() =>
					navigate(``, {
						state: {
							from: location,
							backgroundLocation: "/dashboard",
							file: "/Organisation/Settings/Staff/LateCheckInsNotification"
						}
					}),
				2000
			);
		}
	}
};
