import { Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Button, useNavigate } from "ww-framework";

export default function LateCheckInsNotification() {
	const navigate = useNavigate();

	const navigateToSettings = () => {
		navigate("/dashboard");
	};

	return (
		<div className="p-5">
			<div className="sm:flex sm:items-start">
				<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-white border border-rose-200 sm:mx-0 sm:h-10 sm:w-10">
					<ExclamationCircleIcon className="h-6 w-6 text-rose-600" aria-hidden="true" />
				</div>
				<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
					<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
						You need to check-in on your staff!
					</Dialog.Title>
					<div className="mt-2">
						<div className="text-sm text-gray-500 flex flex-col">
							{`It's been a while since you checked in on some of your staff. `}
							{/* <p /> */}
							<p className="mt-3" />
							{"You should have sit down with these employees 1 on 1 to see if everything is Ok."}
							<p />
							{" Write down any feedback and check back in to see if things have improved in a few weeks"}
							<span className="text-xs text-rose-500 mt-3">
								{"Please visit administration to complete these, in the details section of each employee in the staff members list."}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-5 flex justify-end gap-5">
				<Button label="Okay" onClick={() => navigateToSettings()} />
			</div>
		</div>
	);
}
