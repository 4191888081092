import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputField, Yup, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import { determineCurrency, fetchLocationData } from "../../ww-framework/Utils/currencyUtils";
const subscriptionSchema = Yup.object().shape({
	name: Yup.string().required().min(2),
	numberOfMembers: Yup.number().required("Number of Staff Members ?").min(20, "Please put at least 20.")
});

const CardForm = () => {
	const stripe = useStripe();
	const elements = useElements();
	const location = window.location.href;
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const { person, setPerson } = useContext(PersonContext);
	const { setMessage } = useSimpleMessage();
	const [orgSaving, setOrgSaving] = useState(false);
	const [members, setMembers] = useState(20);

	const [currencySymbol, setCurrencySymbol] = useState("€"); // Default to €

	useEffect(() => {
		// Detect the user's locale and set the currency symbol accordingly
		fetchLocationData().then((data) => {
			if (data) {
				const countryCode = data.countryCode;
				const currency = determineCurrency(countryCode);
				setCurrencySymbol(currency);
			}
		});
	}, []);

	const handlePriceCalculation = async (e) => {
		try {
			setMembers(e);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			<Form
				validationSchema={subscriptionSchema}
				enableReinitialize={true}
				initialValues={{ ...organisation, numberOfMembers: 20 }}
				disabled={false}
				onSubmit={async (data) => {
					try {
						setOrgSaving(true);
						if (!stripe || !elements) {
							// Stripe.js has not loaded yet. Make sure to disable
							// form submission until Stripe.js has loaded.
							return;
						}
						let updatedPerson = null;
						if (!person.id) {
							updatedPerson = await personUtils.saveProfile(person);
						}
						data.StaffCost = data.StaffCost ? parseInt(data.StaffCost) : null;
						let org = await orgUtils.save(data);
						if (!organisation.id) {
							const member = updatedPerson || person; // updatedPerson will be then newly created profile if needed.
							const { person: savedPerson, organisation: updatedOrg } = await orgUtils.addMember(member, org.id, member.email);
							updatedPerson = savedPerson;
							org = { ...org, ...updatedOrg };
						} else {
							updatedPerson = await personUtils.getProfile(person);
						}

						const mergedOrg = {
							...org,
							...(await orgUtils.mapOrgMembers(org)),
							...{ report: await orgUtils.mapReport(org?.report?.items || [], organisation.id) }
						};

						setPerson({ ...person, ...updatedPerson, ...{ isAdmin: true }, ...{ currentOrganisation: mergedOrg } });
						setOrganisation(mergedOrg);
						if (organisation?.id || org?.id) {
							const param = {
								quantity: parseInt(data?.numberOfMembers || 20),
								orgId: organisation.id || org?.id,
								successUrl: `${location}?stripeSuccess`,
								cancelUrl: `${location}?stripeCancel`
							};
							await orgUtils
								.createSessionId(param)
								.then(function (response) {
									return JSON.parse(response?.result);
								})
								.then(function (response) {
									if (!response?.success) {
										setMessage(
											showFailure({
												title: "Try again after sometime.",
												subTitle: response.message
											})
										);
									} else {
										stripe.redirectToCheckout({
											sessionId: response.data.id
										});
									}
								});
						}
						setOrgSaving(false);
						setMessage(
							showSuccess({
								title: "Organisation saved successfully."
							})
						);
					} catch (error) {
						setOrgSaving(false);
						setMessage(
							showFailure({
								title: "Unable to save Organisation.",
								subTitle: error.message
							})
						);
					}
				}}>
				{/*  */}
				<div className="bg-gray-50">
					<main className="max-w-7xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:px-8">
						<div className="max-w-2xl mx-auto lg:max-w-none">
							<div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
								<div>
									<div className="">
										<h2 className="text-lg font-medium text-gray-900">Billing Information</h2>

										<div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
											<InputField label="Organisation Name" name="name" placeholder="Enter an Organisation name" />

											<InputField label="Organisation Phone Number" name="phone" placeholder="Enter an Organisation phone number" />
										</div>
										<InputField label="Organisation Address" name="address" placeholder="Enter an Organisation address" />
									</div>

									{/* Payment */}
								</div>

								{/* Order summary */}
								<div className="mt-10 lg:mt-0">
									<h2 className="text-lg font-medium text-gray-900">Order summary</h2>

									<div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
										<h3 className="sr-only">Items in your cart</h3>
										<div className="flex py-6 px-4 sm:px-6">
											<div className="ml-6 flex-1 flex flex-col">
												<div className="flex">
													<div className="min-w-0 flex-1">
														<h4 className="text-sm">WorkWall Subscription</h4>
														<p className="mt-1 text-sm text-gray-500">
															{currencySymbol}4 per user per month (min. 20 users or {currencySymbol}80)
														</p>
													</div>
												</div>

												<div className="flex-1 pt-2 flex items-end justify-between">
													<span>
														<InputField
															label="Number Of Staff Member"
															name="numberOfMembers"
															placeholder="25"
															type="number"
															onChange={(e) => handlePriceCalculation(e)}
														/>
													</span>
													<span>
														{" "}
														<p className="mb-6 text-sm font-medium text-gray-900">
															{currencySymbol} {4 * members}
														</p>
													</span>
												</div>
												<div className="flex-1 -mt-1 text-sm flex items-end justify-between">
													A minimum charge of {currencySymbol}80 (so up to 20 users), {currencySymbol}4 each additional user.
												</div>
											</div>
										</div>

										<dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
											<div className="flex items-center justify-between">
												<dt className="text-base font-medium">Total</dt>
												<dd className="text-base font-medium text-gray-900">
													{currencySymbol} {4 * members}
												</dd>
											</div>
										</dl>

										<div className="border-t border-gray-200 py-6 px-4 sm:px-6">
											<Button type="submit" label="Confirm Order" disabled={orgSaving} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</Form>
		</>
	);
};

export default CardForm;
