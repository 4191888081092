import { Dialog, Transition } from "@headlessui/react";
import { BriefcaseIcon, XIcon } from "@heroicons/react/outline";
import React, { Fragment, memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import {
	Avatar,
	ConfirmModal,
	Loading,
	arrayDiff,
	dateMeta,
	epochToTimeStr,
	fillMonth,
	fillWeek,
	formateEpochToShortMonthDate,
	notifyUser,
	showFailure,
	showSuccess,
	useSimpleMessage
} from "ww-framework";
import { CalendarDataContext, CalendarDataProvider, OrganisationContext, PersonContext, orgUtils, personUtils } from "ww-stores";
import { MiniCalendar } from "../../Person/MiniCalendar";
import DaySummary from "../DaySummary";
import NoteParticularDay from "../NoteParticularDay";
import RosterTemplate from "../RosterTemplate";
import ShiftNote from "../ShiftNote";
import Template from "../Template";
// import ExportCsv from "./ExportCsv";
// import { MemberListItem } from "./ShiftForm";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EmailWorkHour from "../EmailReport";
import { getTimeOffData, isMemberAlreadyRostered, notifyEarlyClockIn, notifyNotClockedOut } from "./calendar_utils";
import ChooseModuleCalendar from "./ChooseModuleCalendar";
import DayCards from "./DayCards";
import HeaderControlPanel from "./HeaderControlPanel";
import NotificationModal from "./Notification/NotificationModal";
import ChooseOffDays from "./TimeOff";

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}
export const MemberAvatar = memo(({ member, style = "" }) => <Avatar user={member} size="h-8 w-8" twClasses={`${style} z-20`} />); // TODO: find out why this isn't working. Still seeing flickering on avatars!!!

export const calculateTotalTime = (shiftArray) => {
	return shiftArray.reduce((previousTotal, currentShift) => {
		const { shiftStart, shiftEnd, unPaidBreak, breakDuration, breakStart, breakEnd } = currentShift;
		let adjustedShiftEnd = shiftEnd;

		// Check if the shift ends after midnight (i.e., the next day)
		if (shiftEnd < shiftStart) {
			adjustedShiftEnd = shiftEnd + 24 * 3600; // Add 24 hours in seconds
		}

		const shiftDuration = adjustedShiftEnd - shiftStart;
		const breakTime = unPaidBreak ? (breakDuration ? breakDuration * 60 : breakEnd - breakStart) : 0;

		return previousTotal + (shiftDuration - breakTime) / 3600;
	}, 0);
};
const calculateTimeOffEarnings = (timeOffData, paidTimeOff, filterByDepartment) => {
	let timeOffEarnings = 0;
	if (paidTimeOff && timeOffData) {
		timeOffEarnings = timeOffData.reduce((previousTotal, timeOff) => {
			if (!timeOff.isPaid) {
				return previousTotal;
			}
			timeOffData?.forEach((timeOff) => {
				if (
					filterByDepartment !== "ALL" &&
					(!timeOff?.member?.departmentID || !timeOff?.member?.departmentID?.includes(filterByDepartment))
				) {
				} else {
					return previousTotal;
				}
			});
			// Calculate rate
			let rate = timeOff?.member?.empRateUnit === "YEARLY" ? timeOff?.member?.huorlyRate / 260 : timeOff?.member?.huorlyRate;
			let earningsForTimeOff = rate * (timeOff?.member?.empRateUnit === "YEARLY" ? 1 : 7.5);

			// Adjust for multiple departments
			const numDepartments = timeOff?.member?.departmentID?.length || 1;
			if (numDepartments > 1) {
				earningsForTimeOff /= numDepartments;
			}
			return previousTotal + earningsForTimeOff;
		}, 0);
	}
	return timeOffEarnings;
};
export const calculateTotalEarnings = (shiftArray, extraPay = 0, timeOffData, paidTimeOff, filterByDepartment, live = false) => {
	const timeOffEarnings = calculateTimeOffEarnings(timeOffData, paidTimeOff, filterByDepartment);
	// Calculate shift earnings
	let shiftEarnings = shiftArray
		.filter((shift) => shift.member)
		.reduce((previousTotal, currentShift) => {
			const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
			const shiftStart = currentShift?.shiftStart;
			let shiftEnd = live && currentShift?.shiftEnd > new Date().getTime() / 1000 ? new Date().getTime() / 1000 : currentShift?.shiftEnd;
			if (shiftEnd < shiftStart) {
				shiftEnd += 24 * 3600; // Add 24 hours in seconds
			}

			let rate = isSalaried ? currentShift?.member?.huorlyRate / 260 : currentShift?.member?.huorlyRate;

			if (!rate) {
				return previousTotal;
			}

			if (extraPay) {
				rate *= 1 + extraPay / 100;
			}

			const hoursWorked =
				currentShift?.member?.empRateUnit === "YEARLY"
					? 1
					: (shiftEnd -
							shiftStart -
							(currentShift?.unPaidBreak
								? currentShift?.breakDuration
									? currentShift?.breakDuration * 60
									: currentShift?.breakEnd - currentShift?.breakStart
								: 0)) /
					  3600;

			const earnings = hoursWorked * rate;
			return previousTotal + earnings;
		}, 0);
	return timeOffEarnings + shiftEarnings;
};

export const calculateLiveRosteredCost = (shiftArray, extraPay = 0, timeOffData, paidTimeOff, filterByDepartment) => {
	const timeOffEarnings = calculateTimeOffEarnings(timeOffData, paidTimeOff, filterByDepartment);
	let shiftEarnings = shiftArray
		.filter((shift) => shift.member)
		.reduce((previousTotal, currentShift) => {
			const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
			const shiftStart = currentShift?.shiftStart;
			const currentTime = new Date().getTime() / 1000;
			const adjustedCurrentTime = currentTime - new Date().getTimezoneOffset() * 60;
			const shiftEnd = currentShift?.shiftEnd > adjustedCurrentTime ? adjustedCurrentTime : currentShift?.shiftEnd;

			// Handle shifts crossing midnight
			const adjustedShiftEnd = shiftEnd < shiftStart ? shiftEnd + 86400 : shiftEnd;

			let rate = isSalaried ? currentShift?.member?.huorlyRate / 260 : currentShift?.member?.huorlyRate;
			const shiftNotStarted = shiftStart > new Date().getTime() / 1000;
			if (!rate || shiftNotStarted) {
				return previousTotal;
			}
			if (extraPay) {
				rate *= 1 + extraPay / 100;
			}

			let actualHoursElapsed = 0;
			if (isSalaried) {
				actualHoursElapsed = 1;
			} else {
				actualHoursElapsed =
					(adjustedShiftEnd -
						shiftStart -
						(currentShift?.unPaidBreak
							? currentShift?.breakDuration
								? currentShift?.breakDuration * 60
								: currentShift?.breakEnd - currentShift?.breakStart
							: 0)) /
					3600;
			}
			const earnings = actualHoursElapsed * rate;
			return previousTotal + earnings;
		}, 0);
	return timeOffEarnings + shiftEarnings;
};

export const calculateLiveActualEarnings = (shiftArray, punchesArray, extraPay = 0, timeOffData, paidTimeOff, filterByDepartment) => {
	// calculates the earnings for given shift that were actually clocked in
	const timeOffEarnings = calculateTimeOffEarnings(timeOffData, paidTimeOff, filterByDepartment);
	let attendedShiftEarning = punchesArray
		.filter((punch) => punch.in)
		.reduce((previousTotal, currentPunch) => {
			let currentShift = shiftArray.find((shift) => shift.id === currentPunch.shiftID);
			const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
			const clockIn = currentPunch.in;
			const clockOut = currentPunch.out ?? new Date().getTime() / 1000 - new Date().getTimezoneOffset() * 60; // if it's not yet clocked out, use current time
			// Handle punches crossing midnight
			const adjustedClockOut = clockOut < clockIn ? clockOut + 86400 : clockOut;

			if (!currentShift) {
				return previousTotal;
			}
			let rate = isSalaried ? currentShift?.member?.huorlyRate / 260 : currentShift?.member?.huorlyRate;
			if (!rate) {
				return previousTotal;
			}
			// for salaried employees, for rate, we consider a whole day's pay, regardless of the hours worked
			if (extraPay) {
				rate *= 1 + extraPay / 100;
			}
			let actualHoursWorked = 0;
			if (isSalaried) {
				actualHoursWorked = 1;
			} else {
				actualHoursWorked =
					(adjustedClockOut -
						clockIn -
						(currentShift?.unPaidBreak
							? currentShift?.breakDuration
								? currentShift?.breakDuration * 60
								: currentShift?.breakEnd - currentShift?.breakStart
							: 0)) /
					3600;
			}
			const earnings = actualHoursWorked * rate;
			return previousTotal + earnings;
		}, 0);
	return timeOffEarnings + attendedShiftEarning;
};

const Calendar = () => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const [member] = useState(organisation.members?.find((m) => m.person === person.person));
	const { setMessage } = useSimpleMessage();
	const {
		selectedDay,
		setSelectedDay,
		mode,
		chooseModuleOpen,
		setChooseModuleOpen,
		emailModuleOpen,
		setEmailModuleOpen,
		holidayPay,
		extraPays,
		chooseModuleType,
		setChooseModuleType,
		calendarDays,
		setCalendarDays,
		activeDay,
		setActiveDay,
		editShift,
		setEditShift,
		isLoading,
		setIsLoading,
		width,
		height,
		overLayOpen,
		setOverLayOpen,
		shift,
		setShift,
		setTotalHours,
		setCurrentCalenderAverage,
		setTotalRate,
		setClockedTotalRate,
		setWeeklyLiveCost,
		shiftTemplate,
		setShiftTemplate,
		preferenceData,
		setPreferenceData,
		timeOff,
		setTimeOff,
		moduleType,
		setModuleType,
		overLayNoteParticularDayOpen,
		setOverNoteParticularDayLayOpen,
		overLayRosterTemplateOpen,
		setOverLayRosterTemplateOpen,
		noteParticularDayData,
		setNoteParticularDay,
		punchData,
		setPunchData,
		setConfirmHours,
		setAverageTaking,
		chooseTimeOffModuleOpen,
		setTimeOffModuleOpen,
		filterByDepartment,
		paidTimeOff,
		zoom,
		templatesVisible,
		moveOrCopyShift,
		setMoveOrCopyShift,
		isHoursConfirm,
		setIsHoursConfirm,
		toMailData,
		setToMailData,
		openConfirm,
		setOpenConfirm,
		setExtraPays,
		setIsDraggingOver,
		shiftsThisWeek,
		setLateClockOutData,
		setEarlyClockInData
	} = useContext(CalendarDataContext);

	useEffect(() => {
		if (window.location.pathname === "/calendar/calendar") {
			window.location.href = "/dashboard";
		}
	}, []);

	const componentRef = useRef();
	const pickNewDay = (currentDay, shift = "", e = "", type = "") => {
		if (e !== "") e.stopPropagation();
		if (!(person?.isAdmin || person?.assignedAdmin) && shift === "") return null;
		setActiveDay(currentDay);
		setEditShift(shift);
		setModuleType(type);
		setOverLayOpen(true);
		setOverNoteParticularDayLayOpen(false);
		setOverLayRosterTemplateOpen(false);
	};

	useEffect(() => {
		if (organisation.members)
			setIsHoursConfirm({
				values: { ...organisation.members.map(() => false) }
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);
	useEffect(() => {
		if (Object.values(isHoursConfirm.values).every((k) => k)) {
			setEmailModuleOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isHoursConfirm]);

	const saveFromTemplate = async (shiftDetail) => {
		try {
			const { templateId, baseDay: basDate, memberID } = shiftDetail;
			const templateData = shiftTemplate.find((k) => k.id === templateId);
			const baseDay = { baseEpoch: parseInt(basDate) };
			const shiftDetails = {
				organisationID: templateData.organisationID,
				start: templateData.shiftStart,
				end: templateData.shiftEnd,
				role: templateData.roleID,
				hasBreak: templateData.hasBreak,
				breakstart: templateData.breakStart,
				breakend: templateData.breakEnd,
				breakDuration: templateData.breakDuration,
				unPaidBreak: templateData.unPaidBreak,
				setAsClose: templateData.setAsClose,
				person: memberID,
				day: baseDay
			};
			const saved = await orgUtils.saveShift(shiftDetails);
			addShifts([...shift, saved]);
			// await buildCalendar();
		} catch (error) {
			console.error(error);
		}
	};
	const onDragEnd = async (result) => {
		setIsDraggingOver(false);

		//role check might be added
		// if (e !== "") e.stopPropagation();
		const { combine, draggableId, destination, source } = result;
		if (source.droppableId === "template" && combine == null) return;
		if (combine == null) return;
		if (combine && draggableId) {
			if (person.isAdmin || person.assignedAdmin) {
				if (source.droppableId === "template") {
					if (combine.draggableId.split("--|--")[1] !== "grayed") return;
					const shiftDetail = {
						...{
							templateId: draggableId,
							baseDay: parseInt(combine.draggableId.split("--|--")[2]),
							memberID: combine.draggableId.split("--|--")[0],
							toPerson: combine.draggableId.split("--|--")[1]
						}
					};
					saveFromTemplate(shiftDetail);
					return;
				}
				if (combine.draggableId.split("--|--")[1] === "grayed") {
					const shiftDetail = {
						...{
							id: draggableId.split("--|--")[1],
							baseDay: parseInt(combine.draggableId.split("--|--")[2]),
							memberID: draggableId.split("--|--")[0],
							toPerson: combine.draggableId.split("--|--")[0]
						}
					};
					setMoveOrCopyShift(shiftDetail);
					setChooseModuleType("drag");
					setChooseModuleOpen(true);
					return;
				}
				const shiftData = {
					...{
						id: draggableId.split("--|--")[1],
						member: combine.draggableId.split("--|--")[0],
						toShiftId: combine.draggableId.split("--|--")[1],
						memberID: draggableId.split("--|--")[0],
						check: false,
						drop: parseInt(combine.droppableId),
						drag: parseInt(draggableId)
					}
				};
				//except for same day!
				if (source.droppableId !== combine.droppableId) {
					//lets check id member alredy has a shift on this day
					shiftData.check = true;
				}
				setMoveOrCopyShift(shiftData);
				setChooseModuleType("dragOnShift");
				setChooseModuleOpen(true);
				return;
			} else {
				let fromShift = shift.find((s) => s.id === draggableId.split("--|--")[1]);
				let toShift = shift.find((s) => s.id === combine.draggableId.split("--|--")[1]);
				const shiftData = {
					...{
						organisationID: organisation.id,
						fromMemberID: fromShift.memberID,
						fromShiftId: fromShift.id,
						toShiftId: toShift?.toShiftId || " ",
						memberID: toShift.memberID,
						expirationTime: fromShift?.shiftStart + 172800,
						note: "Swaped Request created from Drag & Drop"
					}
				};
				await orgUtils.saveShiftSwapRequest(shiftData);
				await notifyUser(
					[toShift.member.personID],
					`${personUtils.displayName(fromShift.member.person) || "Someone"} asked to swap one of your shifts`
				);
				setMessage(
					showSuccess({
						title: "Swap Shift Requested."
					})
				);
			}
		} else if (destination && draggableId) {
			if (person.isAdmin || person.assignedAdmin) {
				const shiftDetail = {
					...{
						id: draggableId.split("--|--")[1],
						baseDay: parseInt(destination.droppableId),
						memberID: draggableId.split("--|--")[0]
					}
				};
				setMoveOrCopyShift(shiftDetail);
				setChooseModuleType("drag");
				setChooseModuleOpen(true);
			} else {
				let fromShift = shift.find((s) => s.id === draggableId.split("--|--")[1]);
				//request to all
				const shiftData = {
					...{
						organisationID: organisation.id,
						fromMemberID: fromShift.memberID,
						fromShiftId: fromShift.id,
						roleID: fromShift?.roleID,
						expirationTime: fromShift?.shiftStart + 172800,
						note: "ALL - Swaped Request created from Drag & Drop"
					}
				};
				await orgUtils.saveAllRequest(shiftData);
				await notifyUser(
					organisation.members.filter((l) => l.id !== person.id).map((k) => k.person),
					`${personUtils.displayName(fromShift.member.person) || "Someone"} asked to a shift alternative`
				);
				setMessage(
					showSuccess({
						title: "Swap Shift Requested to ALL."
					})
				);
			}
		}
	};

	useEffect(() => {
		const handleMouseMove = () => {};

		window.addEventListener("mousemove", handleMouseMove);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	const getPreferences = async () => {
		try {
			if (selectedDay) {
				let newCalendarDays;

				if (mode.id === "M") {
					newCalendarDays = fillMonth(selectedDay, true, organisation.startDay);
				}

				if (mode.id === "W") {
					newCalendarDays = fillWeek(selectedDay, organisation.startDay);
				}

				const {
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = newCalendarDays;

				const data = await orgUtils.getPreferences(organisation.id, startDate, endDate);
				setPreferenceData(data.items);
			}
		} catch (err) {
			console.error(err);
		}
	};
	useEffect(() => {
		buildCalendar();
	}, [paidTimeOff]);
	useEffect(() => {
		getShiftTemplates();
	}, [organisation]);
	const getShiftTemplates = async () => {
		try {
			if (organisation?.id) {
				const data = await orgUtils.getShiftTemplates(organisation.id);
				if (data) setShiftTemplate(data?.items.sort((a, b) => a?.role?.name?.localeCompare(b?.role?.name)));
			}
		} catch (err) {
			console.error(err);
		}
	};
	const getExtraPay = async () => {
		try {
			if (selectedDay) {
				let newCalendarDays;
				if (mode.id === "M") {
					newCalendarDays = fillMonth(selectedDay, true, organisation.startDay);
				}
				if (mode.id === "W") {
					newCalendarDays = fillWeek(selectedDay, organisation.startDay);
				}
				const {
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = newCalendarDays;
				const data = await orgUtils.getExtraPayDay(organisation.id, startDate, endDate);
				setExtraPays(data.items);
			}
		} catch (err) {
			console.error(err);
		}
	};
	const getTimeOff = async () => {
		try {
			if (selectedDay) {
				let newCalendarDays;
				if (mode.id === "M") {
					newCalendarDays = fillMonth(selectedDay, true, organisation.startDay);
					const {
						first: { baseEpoch: startDate },
						last: { baseEpoch: endDate }
					} = newCalendarDays;
					const data = await orgUtils.getTimeOff(organisation.id, startDate, endDate);
					setTimeOff(data?.items ?? []);
				} else if (mode.id === "W") {
					newCalendarDays = fillWeek(selectedDay, organisation.startDay);
					const {
						first: { baseEpoch: startDate },
						last: { baseEpoch: endDate }
					} = newCalendarDays;
					const data = await orgUtils.getTimeOff(organisation.id, startDate, endDate);
					setTimeOff(data?.items ?? []);
				}
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getNoteParticularDay = async () => {
		try {
			if (selectedDay) {
				let newCalendarDays;

				if (mode.id === "M") {
					newCalendarDays = fillMonth(selectedDay, true, organisation.startDay);
				}

				if (mode.id === "W") {
					newCalendarDays = fillWeek(selectedDay, organisation.startDay);
				}

				const {
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = newCalendarDays;

				const data = await orgUtils.getNoteParticularDay(organisation.id, startDate, endDate);
				setNoteParticularDay(data.items);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getPunch = async () => {
		let _punchData = [];
		try {
			if (selectedDay) {
				let newCalendarDays;

				if (mode.id === "M") {
					newCalendarDays = fillMonth(selectedDay, true, organisation.startDay);
				}

				if (mode.id === "W") {
					newCalendarDays = fillWeek(selectedDay, organisation.startDay);
				}

				const {
					first: { baseEpoch: startDate },
					last: { baseEpoch: endDate }
				} = newCalendarDays;

				const data = await orgUtils.getPunchBetweenDay(organisation.id, startDate, endDate);
				setPunchData(data.items);
				_punchData = [...data.items];
			}
		} catch (err) {
			console.error(err);
		}
		return _punchData;
	};

	const buildCalendar = () => {
		async function loadCalendar() {
			try {
				if (selectedDay) {
					let newCalendarDays;

					if (mode.id === "M") {
						newCalendarDays = fillMonth(selectedDay, true, organisation.startDay);
					}

					if (mode.id === "W") {
						newCalendarDays = fillWeek(selectedDay, organisation.startDay);
					}

					const {
						first: { baseEpoch: startDate },
						last: { baseEpoch: endDate },
						days
					} = newCalendarDays;

					const member = organisation?.members?.find((k) => k?.person === person?.person);
					let shifts = await orgUtils.getShifts(organisation.id, startDate, endDate, person?.isAdmin || person?.assignedAdmin);
					let punches = await getPunch();
					let allUnfilteredShift = [...shifts];

					if (organisation?.departments && Array.isArray(organisation?.departments) && organisation?.departments?.length > 0) {
						if (!person?.isAdmin) {
							shifts = shifts.filter((s) =>
								s.member?.departmentID !== null && member?.departmentIDs.length > 0
									? arrayDiff(s.member?.departmentID || [], member?.departmentIDs)
									: s?.memberID === member?.orgUserId
							);
						}
						if ((person?.isAdmin || person?.assignedAdmin) && filterByDepartment !== "ALL") {
							shifts = shifts.filter((s) => {
								if (s?.member?.departmentID === null) return false;
								else if (s?.member?.departmentID.includes(filterByDepartment)) return true;
								else return false;
							});
						}
					}

					let toSetInState = [];
					// Merge the shifts found for the given date range to the mapped days for the same range.
					const orgReport = await orgUtils.mapReport(organisation?.report, organisation.id);

					// Array to store valid baseDay values for rostered days
					let validRosterDays = [];

					// Compute the days with shifts and filter to find valid days
					const daysWithShifts = days.map((calDay) => {
						let previous = orgReport?.filter((k) => k.reportDay === calDay?.dayDesc?.toUpperCase() && k.reportDate < calDay.baseEpoch);
						previous = previous.filter((k) => k.takings !== null);
						let average =
							previous.length > 0
								? previous.reduce(
										(p, c) => {
											p.takings += c?.takings ?? 0;
											p.target += c?.target ?? 0;
											p.food += c?.food ?? 0;
											p.drinks += c?.drinks ?? 0;
											return p;
										},
										{ takings: 0, target: 0, food: 0, drinks: 0 }
								  )
								: 0;

						for (const [key, value] of Object.entries(average)) {
							average[key] = (value / previous.length).toFixed(2);
						}
						let previousWeek = [];
						let report = orgReport?.filter((k) => {
							if (k.reportDay === calDay?.dayDesc?.toUpperCase() && k.reportDate === calDay.baseEpoch - 604800) previousWeek.push(k);
							return k.reportDay === calDay?.dayDesc?.toUpperCase() && k.reportDate === calDay.baseEpoch;
						});
						const { baseEpoch } = calDay;
						const foundAllUnfilteredShift = allUnfilteredShift.filter((shift) => shift.baseDay === baseEpoch);
						const shiftsFound = shifts.filter((shift) => shift.baseDay === baseEpoch);
						let hoursAndEarnings = shiftsFound.reduce(
							(previousTotal, currentShift) => {
								let h =
									(currentShift.shiftEnd -
										currentShift.shiftStart -
										(currentShift.unPaidBreak &&
											(currentShift.breakDuration
												? currentShift.breakDuration * 60
												: currentShift.breakEnd - currentShift.breakStart))) /
									3600;
								previousTotal.e +=
									currentShift?.member?.empRateUnit === "YEARLY"
										? currentShift?.member?.huorlyRate / 260
										: h * currentShift?.member?.huorlyRate || 0;
								previousTotal.h += h;
								return previousTotal;
							},
							{ e: 0, h: 0 }
						);
						let actualClockedHoursAndEarnings = punches.reduce(
							(previousTotal, currentPunch) => {
								let currentShift = shiftsFound.find((s) => s.id === currentPunch.shiftID);
								if (!currentShift || !currentPunch.in) {
									return previousTotal;
								}
								const isSalaried = currentShift?.member?.empRateUnit === "YEARLY";
								const clockIn = currentPunch.in;
								const clockOut = currentPunch.out ?? Math.floor(new Date().getTime() / 1000 - new Date().getTimezoneOffset() * 60); // Use current time if no clock-out
								const adjustedClockOut = clockOut < clockIn ? clockOut + 86400 : clockOut; // Adjust for midnight crossing

								let h =
									isSalaried || currentShift?.member?.empRateUnit === "YEARLY"
										? 1
										: (adjustedClockOut -
												clockIn -
												(currentShift?.unPaidBreak
													? currentShift?.breakDuration
														? currentShift?.breakDuration * 60
														: currentShift?.breakEnd - currentShift?.breakStart
													: 0)) /
										  3600;
								previousTotal.e += isSalaried ? currentShift?.member?.huorlyRate / 260 : h * currentShift?.member?.huorlyRate || 0;
								previousTotal.h += h;
								return previousTotal;
							},
							{ e: 0, h: 0 }
						);
						toSetInState.push({
							calculation: report.length
								? {
										actual: (hoursAndEarnings.e / report[0]?.takings) * 100,
										food:
											(hoursAndEarnings.e /
												(organisation.vat === true && organisation.foodPercentage
													? report?.[0]?.food - report?.[0]?.food * (organisation.foodPercentage / 100)
													: report?.[0]?.food)) *
											100,
										drinks:
											(hoursAndEarnings.e /
												(organisation.vat === true && organisation.drinkPercentage
													? report?.[0]?.drinks - report?.[0]?.drinks * (organisation.drinkPercentage / 100)
													: report?.[0]?.drinks)) *
											100
								  }
								: { actual: 0, food: 0, drinks: 0 },
							d: hoursAndEarnings,
							ad: actualClockedHoursAndEarnings
						});
						// Only add to validRosterDays if there's a valid report with actual costs
						if (report.length > 0 && report[0].takings !== null) {
							validRosterDays.push(baseEpoch);
						}

						return {
							...calDay,
							...{ shifts: shiftsFound },
							...{ allUnfilteredShift: foundAllUnfilteredShift },
							...{ average: average },
							...{ report: report },
							...{ previousWeekReport: previousWeek }
						};
					});
					let totalWeeklyTimeOffCost = 0;
					let timeOffCostForReportDays = 0;

					daysWithShifts.forEach((calDay) => {
						// Filter timeOff requests that include the current day
						const timeOffDataForDay = timeOff.filter((k) => k.fromDate <= calDay.baseEpoch && k.toDate >= calDay.baseEpoch);
						const earningsForDay = calculateTotalEarnings([], 0, timeOffDataForDay, paidTimeOff, filterByDepartment);
						if (calDay.report && calDay.report.length > 0 && calDay.report[0].takings !== null) {
							timeOffCostForReportDays += earningsForDay;
						}
						totalWeeklyTimeOffCost += earningsForDay;
					});

					const findActiveDay = daysWithShifts.find((day) => {
						return day.formatted === new Intl.DateTimeFormat().format(selectedDay);
					});
					// Access the earnings from toSetInState array and add timeOffCostForReportDays
					if (findActiveDay) setActiveDay(findActiveDay);

					setShift(shifts);
					let average = daysWithShifts.reduce(
						(p, c, index) => {
							p.takings += parseFloat(c?.average?.takings ?? 0);
							p.target += parseFloat(c?.report[0]?.target ?? 0);
							p.actual += parseFloat(c?.report[0]?.takings ?? 0);
							if (c?.report[0]?.takings && parseFloat(c?.report[0]?.takings) !== 0) {
								p.targetToDate += parseFloat(c?.report[0]?.target ?? 0);
								p.earningsToDate += toSetInState[index].d.e;
							}
							p.food +=
								organisation?.vat === true && organisation?.foodPercentage
									? parseFloat(c?.report[0]?.food ?? 0) - parseFloat(c?.report[0]?.food ?? 0) * (organisation?.foodPercentage / 100)
									: parseFloat(c?.report[0]?.food ?? 0);
							p.drinks +=
								organisation?.vat === true && organisation?.drinkPercentage
									? parseFloat(c?.report[0]?.drinks ?? 0) -
									  parseFloat(c?.report[0]?.drinks ?? 0) * (organisation?.drinkPercentage / 100)
									: parseFloat(c?.report[0]?.drinks ?? 0);
							return p;
						},
						{
							takings: 0,
							targetToDate: 0,
							target: 0,
							actual: 0,
							food: 0,
							drinks: 0,
							earningsToDate: 0
						}
					);
					average.earningsToDate += timeOffCostForReportDays;
					setAverageTaking(average);
					if (toSetInState.length) {
						const totalHours = toSetInState.reduce((p, c) => p + c.d.h, 0);
						setTotalHours(totalHours.toFixed(2).replace(/[.,]00$/, ""));
						const totalRate = parseFloat(toSetInState.reduce((p, c) => p + c.d.e, 0).toFixed(2)) + totalWeeklyTimeOffCost;
						setTotalRate(totalRate.toFixed(2).replace(/[.,]00$/, ""));
						let weeklyLiveCost = parseFloat(toSetInState.reduce((p, c) => p + c.ad.e, 0).toFixed(2)) + totalWeeklyTimeOffCost;
						weeklyLiveCost = weeklyLiveCost.toFixed(2).replace(/[.,]00$/, "");

						let weekTotalClockedRate = weeklyLiveCost;
						let today = dateMeta(new Date());
						let todayLiveActualCost = 0;

						if (today.baseEpoch > startDate && today.baseEpoch < endDate) {
							let timeOffData = getTimeOffData(timeOff, today);
							const extraPayOfDay = extraPays.find((e) => e.baseDay === today.baseEpoch);
							const todayShifts = shifts.filter((k) => k.baseDay === today.baseEpoch);
							const todayPunches = punches.filter((k) => k.baseDay === today.baseEpoch);

							todayLiveActualCost = holidayPay
								? 1.08 *
								  calculateLiveActualEarnings(
										todayShifts,
										todayPunches,
										extraPayOfDay?.newPay ?? 0,
										timeOffData,
										paidTimeOff,
										filterByDepartment
								  )
								: calculateLiveActualEarnings(
										todayShifts,
										todayPunches,
										extraPayOfDay?.newPay ?? 0,
										timeOffData,
										paidTimeOff,
										filterByDepartment
								  );
							weekTotalClockedRate = parseFloat(weekTotalClockedRate) - todayLiveActualCost;
						}
						setClockedTotalRate(weekTotalClockedRate);
						setWeeklyLiveCost(weeklyLiveCost);
						let validClockIns = punches.filter((p) => validRosterDays.includes(p.baseDay));
						let totalClockedInCost = calculateLiveActualEarnings(shifts, validClockIns, 0, timeOff, paidTimeOff, filterByDepartment);
						setClockedTotalRate(totalClockedInCost);
						let currentReportCount = validRosterDays.length; // Corrected to use validRosterDays
						// Correctly calculate averages based on valid roster days
						setCurrentCalenderAverage(
							daysWithShifts
								.filter((s) => validRosterDays.includes(s.baseEpoch))
								.reduce(
									(p, c) => {
										p.takings += (c.report[0].takings ?? 0) / currentReportCount;
										p.food += (c.report[0].food ?? 0) / currentReportCount;
										p.drinks += (c.report[0].drinks ?? 0) / currentReportCount;

										if (c?.report?.takings && parseFloat(c.report.takings) !== 0) {
											p.targetToDate += parseFloat(c?.report?.target ?? 0);
										}
										return p;
									},
									{ takings: 0, food: 0, drinks: 0 }
								)
						);
					}

					setCalendarDays(daysWithShifts);
					setIsLoading(false);
					scrollEventListener();

					if (person?.isAdmin || person?.assignedAdmin) {
						const someShiftsNotClockedOut = await notifyNotClockedOut({
							shifts: shifts,
							punches: punches,
							lateClockOutNotificationHours: organisation.lateClockOutNotificationHours
						});
						setLateClockOutData(someShiftsNotClockedOut);
						const shiftsClockedInEarly = await notifyEarlyClockIn({
							shifts: shifts,
							punches: punches,
							earlyClockInNotificationHours: organisation.earlyClockInNotificationHours
						});
						setEarlyClockInData(shiftsClockedInEarly);
					}
				}
			} catch (err) {
				console.error(err);
			}
		}
		setIsLoading(true);

		setTimeout(() => {
			loadCalendar();
		}, 500); // Just add a half second delay to avoid jumpyness.
	};
	const addShifts = (shifts) => {
		setIsLoading(true);
		try {
			if (selectedDay) {
				const daysWithShifts = calendarDays.map((calDay) => {
					const { baseEpoch } = calDay;
					const shiftsFound = shifts.filter((shift) => shift.baseDay === baseEpoch);
					return {
						...calDay,
						...{ shifts: shiftsFound }
					};
				});
				setCalendarDays(daysWithShifts);
				setShift(shifts);
			}
		} catch (err) {
			console.error(err);
		}
		setIsLoading(false);
	};

	const rebuild = (setDay) => {
		setSelectedDay(setDay);
	};
	const setOrg = async () => {
		const { report, subscription } = await orgUtils.get(organisation);
		const mappedReport = await orgUtils.mapReport(report);
		const mergedOrg = {
			...organisation,
			...{ report: mappedReport.items },
			...{
				maxAllowedMembers: window.sessionStorage.getItem("mam") ?? subscription?.items?.[0]?.quantity
			}
		};
		setOrganisation(mergedOrg);
	};

	const checkIfTransferringPunchedShift = (shiftId, action) => {
		const shiftPunchData = punchData.find((p) => p.shiftID === shiftId);
		if (shiftPunchData) {
			setMessage(
				showFailure({
					title: "You cannot perform this action",
					subTitle: `Cannot ${action} a shift that has been clocked-in already.`
				})
			);
			return true;
		}
		return false;
	};

	const handlerChooseModuleModal = async (e, type) => {
		if (
			type !== "close" &&
			type !== "copy" &&
			type !== "move" &&
			moveOrCopyShift &&
			shift.some((s) => s.memberID === moveOrCopyShift.memberID && s.baseDay === moveOrCopyShift.baseDay)
		) {
			setMessage(
				showFailure({
					title: "You cannot perform this action",
					subTitle: `Employee already has a shift on ${formateEpochToShortMonthDate(moveOrCopyShift.baseDay)}`
				})
			);
			return;
		}
		setChooseModuleOpen(false);
		if (type === "copy") {
			const toCopy = shift.find((s) => s.id === moveOrCopyShift.id);
			if (moveOrCopyShift.id === moveOrCopyShift.toShiftId) {
				setMessage(
					showFailure({
						title: "You cannot perform this action",
						subTitle: "The target shift cannot be the same as the dragged shift."
					})
				);
				return;
			}
			const targetMember = organisation.members.find((member) => member.orgUserId === moveOrCopyShift.toPerson);
			const rosteredAlready = await isMemberAlreadyRostered({
				member: targetMember, // Use the target member
				day: dateMeta(new Date(moveOrCopyShift?.baseDay * 1000)),
				loggedInPerson: person,
				selectedWeekShifts: shift,
				orgMembers: organisation.members,
				setMessage,
				currentShiftId: moveOrCopyShift.id
			});

			if (rosteredAlready || !targetMember?.orgUserId) return;
			const baseDay = { baseEpoch: parseInt(moveOrCopyShift.baseDay) };
			const shiftDetail = {
				organisationID: toCopy.organisationID,
				day: baseDay,
				start: Math.abs(toCopy.shiftStart - toCopy.baseDay),
				end: Math.abs(toCopy.shiftEnd - toCopy.baseDay),
				role: toCopy.roleID,
				person: targetMember?.orgUserId, // Assign shift to target member
				hasBreak: toCopy.hasBreak,
				breakstart: Math.abs(toCopy.breakStart - toCopy.baseDay),
				breakend: Math.abs(toCopy.breakEnd - toCopy.baseDay),
				breakDuration: toCopy.breakDuration,
				isShiftPublished: false,
				unPaidBreak: toCopy.unPaidBreak,
				setAsClose: toCopy.setAsClose
			};

			const saved = await orgUtils.saveShift(shiftDetail);
			addShifts([...shift, saved]);
			setMoveOrCopyShift();
			setChooseModuleType();
			return;
		}

		if (type === "move") {
			const targetMember = organisation.members.find((member) => member.orgUserId === moveOrCopyShift.toPerson);
			const fromShift = shift.find((s) => s.id === moveOrCopyShift.id);
			const rosteredAlready = await isMemberAlreadyRostered({
				member: targetMember, // Use the target member
				day: dateMeta(new Date(moveOrCopyShift?.baseDay * 1000)),
				loggedInPerson: person,
				selectedWeekShifts: shift,
				orgMembers: organisation.members,
				setMessage,
				currentShiftId: moveOrCopyShift.id
			});
			if (rosteredAlready) return;
			const _isPunched = checkIfTransferringPunchedShift(moveOrCopyShift.id, "move");
			if (_isPunched) return;

			const data = {
				id: moveOrCopyShift.id,
				memberID: targetMember.orgUserId,
				baseDay: moveOrCopyShift.baseDay // Include the baseDay
			};
			try {
				const updatedShift = await orgUtils.moveShift(data);
				// Update the local state with the new shifts
				const updatedShifts = shift.map((s) => {
					if (s.id === fromShift.id) {
						return updatedShift;
					}
					return s;
				});
				addShifts(updatedShifts);
				setMoveOrCopyShift();
				setChooseModuleType();
			} catch (error) {
				setMessage(
					showFailure({
						title: "Error occurred",
						subTitle: error.message || "Failed to move the shift."
					})
				);
			}

			return;
		}

		if (type === "swap") {
			const draggedShift = shift.find((s) => s.id === moveOrCopyShift.id);
			const targetShift = shift.find((s) => s.id === moveOrCopyShift.toShiftId);

			if (!targetShift) {
				setMessage(
					showFailure({
						title: "You cannot perform this action",
						subTitle: "No shift found for the target member."
					})
				);
				return;
			}
			const _draggedIsPunched = checkIfTransferringPunchedShift(draggedShift.id, "swap");
			const _targetIsPunched = checkIfTransferringPunchedShift(targetShift.id, "swap");
			if (_draggedIsPunched || _targetIsPunched) return;

			const shiftDetails = {
				id: draggedShift.id,
				targetShiftId: targetShift.id,
				draggedShift: draggedShift,
				targetShift: targetShift
			};

			try {
				const updatedShifts = await orgUtils.swapShift(shiftDetails);

				// Update the local state with the new shifts
				const newShifts = shift.map((s) => {
					if (s.id === targetShift.id) {
						return {
							...updatedShifts.first,
							id: s.id,
							organisationID: s.organisationID
						};
					}
					return s.id === draggedShift.id
						? {
								...updatedShifts.second,
								id: s.id,
								organisationID: s.organisationID
						  }
						: s;
				});

				addShifts(newShifts);
				await buildCalendar();
				setMoveOrCopyShift();
				setChooseModuleType();
			} catch (error) {
				setMessage(
					showFailure({
						title: "Error occurred",
						subTitle: error.message || "Failed to swap the shifts."
					})
				);
			}

			return;
		}

		if (type === "replace") {
			const draggedShift = shift.find((s) => s.id === moveOrCopyShift.id);
			const targetShift = shift.find((s) => s.id === moveOrCopyShift.toShiftId);

			if (!targetShift) {
				setMessage(
					showFailure({
						title: "You cannot perform this action",
						subTitle: "No shift found for the target member."
					})
				);
				return;
			}
			const _isDraggedPunched = checkIfTransferringPunchedShift(draggedShift.id, "replace");
			const _isTargetPunched = checkIfTransferringPunchedShift(targetShift.id, "replace");
			if (_isDraggedPunched || _isTargetPunched) return;

			const shiftDetails = {
				id: draggedShift.id,
				memberID: draggedShift.memberID,
				toShiftId: targetShift.id,
				draggedShift: draggedShift,
				targetShift: targetShift
			};

			try {
				const updatedShift = await orgUtils.replaceShift(shiftDetails);

				// Update the local state with the new shifts
				const updatedShifts = shift
					.map((s) => {
						if (s.id === targetShift.id) {
							return updatedShift;
						}
						return s.id === draggedShift.id ? null : s;
					})
					.filter((s) => s !== null);

				addShifts(updatedShifts);
				setMoveOrCopyShift();
				setChooseModuleType();
			} catch (error) {
				setMessage(
					showFailure({
						title: "Error occurred",
						subTitle: error.message || "Failed to replace the shift."
					})
				);
			}

			return;
		}

		if (type !== "close" && type !== "copy" && type !== "move") {
			if (width < 2100) setOverLayOpen(true);
			setModuleType(type);
		}
		setMoveOrCopyShift();
		setChooseModuleType();
	};

	const handlerTimeOffModal = (e, type) => {
		setTimeOffModuleOpen(false);
		if (type !== "close") {
			setModuleType(type);
		}
	};

	useEffect(() => {
		if (organisation.id) {
			buildCalendar();
			getPreferences();
			getTimeOff();
			getNoteParticularDay();
			getExtraPay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, selectedDay, organisation.id, filterByDepartment]);
	useEffect(() => {
		if (organisation.id) {
			getPunch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation.id, shift, selectedDay]);

	const leftShadowView = `<div style = "box-shadow: 0 0px 20px 8px #0000008c;left: 0px;position: sticky;" class="shadowLeft" ></div>`;
	const rightShadowView = `<div style="box-shadow: 0 0px 20px 8px #0000008c;right: 0px;position: sticky;" class="shadowRight"></div>`;

	const scrollEventListener = useCallback(() => {
		let scrollView = document.querySelector(".scrollView");
		if (!scrollView) return;
		let shadowLeft = document.querySelector(".shadowLeft");
		let shadowRight = document.querySelector(".shadowRight");
		if (shadowRight) shadowRight.remove();
		if (shadowLeft) shadowLeft.remove();
		if (scrollView.scrollLeft > 0 && scrollView.scrollLeft <= scrollView.scrollWidth - scrollView.clientWidth - 10) {
			scrollView.insertAdjacentHTML("afterbegin", leftShadowView);
			scrollView.insertAdjacentHTML("beforeend", rightShadowView);
		} else if (scrollView.scrollLeft > 5) {
			scrollView.insertAdjacentHTML("afterbegin", leftShadowView);
		} else if (scrollView.scrollWidth - scrollView.clientWidth > scrollView.scrollLeft) {
			scrollView.insertAdjacentHTML("beforeend", rightShadowView);
		}
	}, [leftShadowView, rightShadowView]);

	useEffect(() => {
		let scrollView = document.body;
		scrollView.addEventListener(
			"scroll",
			() => {
				scrollEventListener();
			},
			true
		);
		window.addEventListener("resize", scrollEventListener);
		return () => {
			scrollView.removeEventListener("scroll", scrollEventListener);
			window.removeEventListener("resize", scrollEventListener);
		};
	}, [scrollEventListener]);

	const handlerEmailModal = (e, type) => {
		setEmailModuleOpen(false);
		setConfirmHours(false);
		setIsHoursConfirm({ values: { ...organisation.members.map(() => false) } });
		if (type === "saved") {
			setOpenConfirm(true);
		}
	};

	const handlerConfirmModal = async (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			orgUtils
				.sendReportEmail(toMailData)
				.then(setOpenConfirm(false))
				.then(setToMailData({}))
				.then(
					setMessage(
						showSuccess({
							title: "Email to payroll sent successfully."
						})
					)
				);
		}
		setToMailData({});
		setOpenConfirm(false);
	};

	const DayCardsWithParameters = () => <DayCards getPreferences={getPreferences} getExtraPay={getExtraPay} getTimeOff={getTimeOff} />;

	return (
		<>
			{(person?.isAdmin || person?.assignedAdmin) && <NotificationModal rebuildCalendar={rebuild} editShiftAction={handlerChooseModuleModal} />}
			{width > 2100 || width < 0 ? (
				<div className="p-1 mt-5" style={{ height: height - 40, width: width - 20 }}>
					<EmailWorkHour
						open={emailModuleOpen}
						calendarDays={calendarDays}
						activeDay={selectedDay}
						handlerModal={handlerEmailModal}
						setToMailData={setToMailData}
						timeOff={timeOff}
					/>
					<ConfirmModal
						handlerModal={handlerConfirmModal}
						open={openConfirm}
						className="text-base font-medium text-gray-900"
						title="Email these hours to payroll?"
						subTitle="Are you sure?"
					/>
					<ChooseModuleCalendar open={chooseModuleOpen} handlerModal={handlerChooseModuleModal} type={chooseModuleType} />
					<ChooseOffDays
						open={chooseTimeOffModuleOpen}
						handlerModal={handlerTimeOffModal}
						month={calendarDays}
						timeOff={timeOff}
						getTimeOff={getTimeOff}
					/>
					<div className="w-full sm:h-full my-6 sm:hidden">
						<MiniCalendar updateDay={pickNewDay} person={person} />
					</div>
					<div style={{ visibility: isLoading ? "visible" : "hidden" }} className="bg-white w-full h-full opacity-50 absolute">
						<Loading text="Building Calendar..." />
					</div>
					<div className="w-full h-full grid grid-cols-12 gap-4 p-2 sm:p-3">
						<div
							className={`${
								person?.isAdmin || person?.assignedAdmin || moduleType === "note" ? "col-span-9" : "col-span-12"
							} h-full overflow-auto hidden sm:block`}
						>
							<div className={`w-full h-full  "flex flex-col`}>
								<HeaderControlPanel buildCalendar={buildCalendar} componentRef={componentRef} />
								{mode.id === "W" ? (
									<div className="mt-4 h-full">
										<div className="flex scrollView overflow-auto w-full">
											<DragDropContext onDragEnd={onDragEnd}>
												<DayCardsWithParameters />
											</DragDropContext>
										</div>
									</div>
								) : (
									<DragDropContext onDragEnd={onDragEnd}>
										<DayCardsWithParameters />
									</DragDropContext>
								)}
							</div>
						</div>
						{overLayNoteParticularDayOpen ? (
							<div className="col-span-12 sm:col-span-3">
								<NoteParticularDay
									note={noteParticularDayData}
									activeDay={activeDay}
									getNoteParticularDay={getNoteParticularDay}
									setOverLayOpen={setOverLayOpen}
									organisation={organisation}
									person={person}
									setOverNoteParticularDayLayOpen={setOverNoteParticularDayLayOpen}
								/>
							</div>
						) : moduleType === "note" ? (
							<div className="col-span-12 sm:col-span-3">
								<ShiftNote shift={editShift} activeDay={activeDay} rebuildCalendar={rebuild} setOverLayOpen={setOverLayOpen} />
							</div>
						) : moduleType === "template" ? (
							<div className="col-span-12 sm:col-span-3">
								<Template
									setOverLayOpen={setOverLayOpen}
									activeDay={activeDay}
									shiftTemplates={shiftTemplate}
									getShiftTemplates={getShiftTemplates}
								/>
							</div>
						) : overLayRosterTemplateOpen ? (
							<div className="col-span-12 sm:col-span-3">
								<RosterTemplate
									activeWeek={calendarDays}
									setOverLayOpen={setOverLayOpen}
									organisation={organisation}
									person={person}
									setOverLayRosterTemplateOpen={setOverLayRosterTemplateOpen}
									shift={shift}
								/>
							</div>
						) : (
							(person?.isAdmin || person?.assignedAdmin) && (
								<div className="col-span-12 sm:col-span-3">
									<DaySummary
										rebuildCalendar={rebuild}
										shiftsThisWeek={shiftsThisWeek}
										preferences={
											preferenceData &&
											preferenceData?.filter((preference) => preference.preferenceDate === activeDay?.baseEpoch)
										}
										isAdmin={person?.isAdmin || person?.assignedAdmin}
										timeOff={
											timeOff && timeOff.filter((k) => k.fromDate <= activeDay?.baseEpoch && k.toDate >= activeDay?.baseEpoch)
										}
										report={activeDay?.report}
										setOrg={setOrg}
										shift={calendarDays}
										punchData={punchData}
										selectedDepartmentIds={filterByDepartment}
									/>
								</div>
							)
						)}
					</div>
				</div>
			) : (
				<div className="p-2 mt-4 px-0 sm:px-2 " style={{ height: height - 50, width: width - 20 }}>
					<div style={{ visibility: isLoading ? "visible" : "hidden" }} className="bg-white w-full z-40 h-full opacity-50 absolute">
						<Loading text="Building Calendar..." />
					</div>
					<div className="w-full h-full grid grid-cols-12 gap-4 p-2 sm:p-3">
						<div className="col-span-12 h-full overflow-auto  sm:block">
							<div className={`w-full h-full ${mode.id === "M" ? "grid grid-cols-7 grid-rows-7 gap-4" : "flex flex-col"}`}>
								<HeaderControlPanel buildCalendar={buildCalendar} componentRef={componentRef} />
								{mode.id === "W" ? (
									<DragDropContext onDragEnd={onDragEnd}>
										<div className="mt-1 h-full" ref={componentRef}>
											<div className={`overflow-y-auto ${templatesVisible ? "-mt-2 mb-2" : "mt-2"} h-full`}>
												{templatesVisible && (
													//fixed top-12 w-full
													<div className="flex bg-white items-center overflow-y-auto sticky mt-2 top-0 z-40 ">
														<div className="shadow rounded-md bg-white w-full">
															<div>
																<Droppable
																	droppableId="template"
																	key={"templatesVisible"}
																	index={9}
																	type="DAY"
																	ignoreContainerClipping={Boolean(true)}
																	isScrollable={true}
																	isCombineEnabled={true}
																	isDropDisabled={true}
																>
																	{(provided) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.droppableProps}
																			{...provided.dragHandleProps}
																			style={{ flexGrow: 1 }}
																			className="flex scrollView overflow-scroll w-full py-1"
																		>
																			<span
																				className="row-span-7 flex justify-center items-center sticky left-0 col-span-1 cursor-pointer min-w-[80px] max-w-[80px] mr-2 z-40 row-span-7 mt-2 m-1 bg-darkww-600 hover:bg-darkww-300 rounded-full px-2 font-bold text-white text-sm leading-loose cursor-pointer"
																				onClick={(e) => pickNewDay("", e, "", "template")}
																			>
																				{width < 740 ? "Add" : "Add New"}
																			</span>

																			{shiftTemplate.map((t, i) => {
																				return (
																					<Draggable
																						key={i}
																						draggableId={t.id}
																						index={i}
																						isCombineEnabled={true}
																						isScrollable={false}
																					>
																						{(provided) => (
																							<div
																								ref={provided.innerRef}
																								{...provided.draggableProps}
																								{...provided.dragHandleProps}
																								className={`mx-2 row-span-7 col-span-1 w-40 row-span-7 mt-2 col-span-1 cursor-pointer rounded-lg shadow-lg  min-w-[183.4px]  w-full mr-1`}
																								key={i}
																							>
																								{t.hasBreak && (
																									<div className="relative">
																										<div className=" text-darkww-700 rounded-md text-sm flex flex-row items-center absolute right-2 -top-4 z-50">
																											<BriefcaseIcon className="h-6 w-6 px-1" />

																											{t.breakDuration
																												? `${t.breakDuration} Mins`
																												: `${epochToTimeStr(
																														t.breakStart
																												  )} - ${epochToTimeStr(t.breakEnd)}`}
																										</div>
																									</div>
																								)}

																								<div className="relative">
																									<div
																										className={`bg-darkww-500 absolute left-6 top-1.5 border-2 border-white shadow-sm rounded-md px-2 z-20`}
																									>
																										<div className="text-white text-sm">
																											{epochToTimeStr(t.shiftStart)}
																										</div>
																									</div>
																								</div>
																								<div className={`h-9 flex items-center`}>
																									<div
																										style={{
																											backgroundColor: t.role?.labelColour
																										}}
																										className={`flex flex-row flex-1 py-0 rounded-md h-8`}
																									></div>
																								</div>
																								<div className="relative">
																									<div
																										className={`bg-darkww-700 absolute bottom-1.5 right-1 right-0 border-2 border-white shadow-sm rounded-md px-2 w-fit`}
																									>
																										<div className="text-white text-sm">
																											{t.setAsClose
																												? "Close"
																												: epochToTimeStr(t.shiftEnd)}
																										</div>
																									</div>
																								</div>
																								{provided.placeholder}
																							</div>
																						)}
																					</Draggable>
																				);
																			})}
																			{provided.placeholder}
																		</div>
																	)}
																</Droppable>
															</div>
														</div>
													</div>
												)}

												<div className={`flex w-full overflow-auto ${zoom ? "zoomed" : ""}`} style={{ flexGrow: 1 }}>
													<DayCardsWithParameters />
												</div>
											</div>
										</div>
									</DragDropContext>
								) : width < 500 ? (
									<div className="mt-4 h-calendar col-span-7">
										<DragDropContext onDragEnd={onDragEnd}>
											<DayCardsWithParameters />
										</DragDropContext>
									</div>
								) : (
									<div className="mt-4 h-calendar col-span-7">
										<DragDropContext onDragEnd={onDragEnd}>
											<DayCardsWithParameters />
										</DragDropContext>
									</div>
								)}
							</div>
						</div>
						{mode.id === "M" && (
							<ChooseOffDays
								open={chooseTimeOffModuleOpen}
								handlerModal={handlerTimeOffModal}
								month={calendarDays}
								timeOff={timeOff}
								getTimeOff={getTimeOff}
							/>
						)}
						<EmailWorkHour
							open={emailModuleOpen}
							calendarDays={calendarDays}
							activeDay={selectedDay}
							handlerModal={handlerEmailModal}
							setToMailData={setToMailData}
							timeOff={timeOff}
						/>
						<ConfirmModal
							handlerModal={handlerConfirmModal}
							open={openConfirm}
							className="text-base font-medium text-gray-900"
							title="Email these hours to payroll?"
							subTitle="Are you sure?"
						/>
						<ChooseModuleCalendar open={chooseModuleOpen} handlerModal={handlerChooseModuleModal} type={chooseModuleType} />
						<Transition.Root show={overLayOpen} as={Fragment}>
							<Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={setOverLayOpen}>
								<div className="absolute inset-0 overflow-hidden">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<Dialog.Overlay className="absolute z-20 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
									</Transition.Child>
									<div className="fixed inset-y-0 right-0 max-w-full flex z-20 my-2">
										<Transition.Child
											as={Fragment}
											enter="transform transition ease-in-out duration-500 sm:duration-700"
											enterFrom="translate-x-full"
											enterTo="translate-x-0"
											leave="transform transition ease-in-out duration-500 sm:duration-700"
											leaveFrom="translate-x-0"
											leaveTo="translate-x-full"
										>
											<div style={{ width: 500 }} className="relative px-5">
												<Transition.Child
													as={Fragment}
													enter="ease-in-out duration-500"
													enterFrom="opacity-0"
													enterTo="opacity-100"
													leave="ease-in-out duration-500"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<div className="absolute top-1 right-8 -ml-8 pt-2 pr- flex sm:-ml-10 sm:pr-4">
														<button
															type="button"
															className="rounded-md text-gray-300 hover:text-white focus:outline-none"
															onClick={() => setOverLayOpen(false)}
														>
															<span className="sr-only">Close panel</span>
															<XIcon className="h-6 w-6" aria-hidden="true" />
														</button>
													</div>
												</Transition.Child>
												{overLayNoteParticularDayOpen && (
													<NoteParticularDay
														note={noteParticularDayData}
														activeDay={activeDay}
														getNoteParticularDay={getNoteParticularDay}
														setOverLayOpen={setOverLayOpen}
														organisation={organisation}
														person={person}
														setOverNoteParticularDayLayOpen={setOverNoteParticularDayLayOpen}
													/>
												)}
												{overLayRosterTemplateOpen && (
													<RosterTemplate
														activeWeek={calendarDays}
														setOverLayOpen={setOverLayOpen}
														organisation={organisation}
														person={person}
														setOverLayRosterTemplateOpen={setOverLayRosterTemplateOpen}
														shift={shift}
													/>
												)}
												{moduleType === "note" ? (
													<ShiftNote
														shift={editShift}
														activeDay={activeDay}
														rebuildCalendar={rebuild}
														setOverLayOpen={setOverLayOpen}
													/>
												) : moduleType === "template" ? (
													<Template
														setOverLayOpen={setOverLayOpen}
														activeDay={activeDay}
														shiftTemplates={shiftTemplate}
														getShiftTemplates={getShiftTemplates}
													/>
												) : (
													<DaySummary
														rebuildCalendar={rebuild}
														shiftsThisWeek={shiftsThisWeek}
														preferences={
															preferenceData &&
															preferenceData?.filter((preference) => preference.preferenceDate === activeDay?.baseEpoch)
														}
														isAdmin={person?.isAdmin || person?.assignedAdmin}
														timeOff={
															timeOff &&
															timeOff.filter(
																(k) => k.fromDate <= activeDay?.baseEpoch && k.toDate >= activeDay?.baseEpoch
															)
														}
														report={activeDay?.report}
														setOrg={setOrg}
														shift={calendarDays}
														punchData={punchData}
														selectedDepartmentIds={filterByDepartment}
													/>
												)}
											</div>
										</Transition.Child>
									</div>
								</div>
							</Dialog>
						</Transition.Root>
					</div>
				</div>
			)}
		</>
	);
};

const CalendarWrapper = ({ compProps }) => {
	return (
		<CalendarDataProvider>
			<Calendar compProps={compProps} />
		</CalendarDataProvider>
	);
};

export default CalendarWrapper;
