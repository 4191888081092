import { createContext, useState } from "react";
import { Storage } from "ww-framework";
import { checkAlreadyAuthenticated, confirmSignUp, forgotPassword, forgotPasswordSubmit, getToken, signIn, signOut, signUp } from "./auth";
import { getAllInvitations, getInvitations, updateInvitation } from "./invitations";
import { getProfile, saveProfile } from "./profile";

const personSchema = {
	isAuthenticated: false,
	sub: "",
	email: "",
	username: "",
	firstName: "",
	lastName: "",
	employeePhone: "",
	emergencyNo: "",
	currentOrganisation: "",
	invitations: [],
	isAdmin: false,
	salary: 0,
	assignedAdmin: false,
	assignedRequests: false,
	assignedSuperAdmin: false,
	assignedFinancials: null
};

// The context for the current logged in person.
export const PersonContext = createContext({
	person: {},
	hours: 0,
	setHours: () => {},
	setPerson: () => {}
});

export const PersonProvider = ({ children }) => {
	const [person, setPerson] = useState(personSchema);
	const [hours, setHours] = useState(35);
	return <PersonContext.Provider value={{ person, setPerson, hours, setHours }}>{children}</PersonContext.Provider>;
};

// Methods to action details about the person.
export const personUtils = {
	getAccessToken: function () {
		return new Promise(async (resolve, reject) => {
			try {
				const authenticatedUser = await getToken();
				resolve(authenticatedUser);
			} catch (error) {
				reject(error);
			}
		});
	},
	checkAuth: function () {
		return new Promise(async (resolve, reject) => {
			try {
				const authenticatedUser = await checkAlreadyAuthenticated();
				resolve(authenticatedUser);
			} catch (error) {
				reject(error);
			}
		});
	},
	signIn: function (email, password) {
		return new Promise(async (resolve, reject) => {
			try {
				const authDetails = await signIn(email, password);
				resolve({ ...personSchema, ...authDetails });
			} catch (error) {
				reject(error);
			}
		});
	},
	signOut: async () => {
		await signOut();
		return personSchema;
	},
	signUp: (email, password) => {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await signUp(email, password);
				resolve({ ...personSchema, sub: data.userSub });
			} catch (error) {
				reject(error);
			}
		});
	},
	confirmSignUp: (email, authcode) => {
		return new Promise(async (resolve, reject) => {
			try {
				await confirmSignUp(email, authcode);
				resolve(personSchema);
			} catch (error) {
				reject(error);
			}
		});
	},
	forgotPassword: (email) => {
		return new Promise(async (resolve, reject) => {
			try {
				await forgotPassword(email);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	forgotPasswordSubmit: (email, code, new_password) => {
		return new Promise(async (resolve, reject) => {
			try {
				await forgotPasswordSubmit(email, code, new_password);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	getProfile: (person) => {
		return new Promise(async (resolve, reject) => {
			try {
				let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;
				const dbProfile = await getProfile(person.sub);
				let newProfile = {};
				if (dbProfile) {
					newProfile = { ...person, ...dbProfile };

					const { organisations } = dbProfile;
					const activeOrgs = organisations?.items?.filter((org) => org.status !== "DELETED"); // Statuses: null, ACTIVE, DELETED
					const activeOrganisations = { ...organisations, items: activeOrgs };
					if (activeOrganisations && activeOrganisations.items.length > 0) {
						const itemsLength = activeOrganisations?.items?.length ?? 0;
						activeOrg = Math.min(window.sessionStorage.getItem("activeOrg") ?? itemsLength - 1, itemsLength - 1);
						if (activeOrganisations.items[activeOrg]?.isPayRoll) {
							newProfile = { ...newProfile, isPayRoll: true };
						}
						const { organisation } = activeOrganisations.items[activeOrg];
						const orgDetails = {
							currentOrganisation: {
								id: organisation.id,
								owner: organisation.owner,
								name: organisation.name,
								address: organisation.address,
								phone: organisation.phone,
								hrEmail: organisation.hrEmail,
								paidBreak: organisation?.paidBreak || false,
								allPaidBreak: organisation?.allPaidBreak || false,
								autoBreak: organisation?.autoBreak || false,
								StaffCost: organisation.StaffCost,
								vat: organisation.vat,
								drinkPercentage: organisation.drinkPercentage,
								foodPercentage: organisation.foodPercentage,
								startDay: organisation.startDay,
								test: "yes"
							}
						};

						newProfile = { ...newProfile, ...orgDetails };
					}
				}

				const updatedProfile = { ...person, ...newProfile };
				resolve(updatedProfile);
			} catch (error) {
				reject(error);
			}
		});
	},
	saveProfile: (profileDetails) => {
		return new Promise(async (resolve, reject) => {
			try {
				const profile = await saveProfile(profileDetails);
				resolve(profile);
			} catch (error) {
				reject(error);
			}
		});
	},
	displayName: (person) => {
		return person?.username
			? person.username
			: person?.firstName || person?.lastName
			? `${person?.firstName} ${person?.lastName}`
			: person?.orgUsername;
	},
	getInvitations: (email) => {
		return new Promise(async (resolve, reject) => {
			try {
				const invitations = await getInvitations(email);
				resolve(invitations);
			} catch (error) {
				reject(error);
			}
		});
	},
	getAllInvitations: (email) => {
		return new Promise(async (resolve, reject) => {
			try {
				const invitations = await getAllInvitations(email);
				resolve(invitations);
			} catch (error) {
				reject(error);
			}
		});
	},
	actionInvitation: (inviteId, action) => {
		return new Promise(async (resolve, reject) => {
			try {
				const status = action === "ACCEPT" ? "Accepted" : "PENDING" ? "Pending" : "Declined";
				// Join the org member table first.
				await updateInvitation(inviteId, status);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	loadAvatar: (person) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { avatarURI } = person;
				let signedURI;

				if (avatarURI) {
					signedURI = await Storage.get(avatarURI, { expires: 60 * 60 });
				}
				resolve({
					uri: avatarURI,
					signedImageURL: signedURI,
					lastRetrieved: new Date()
				});
			} catch (error) {
				reject(error);
			}
		});
	}
};
