import { useState, useContext } from "react";
import logoImage from "ww-images/workwall-logo.svg";
import { Form, InputField, Button, Link, Yup, useNavigate, useSimpleMessage, showFailure, LinkButton } from "ww-framework";
import { personUtils, PersonContext } from "ww-stores";
import { CheckBox } from "../../ww-framework";

const signUpDetails = {
	email: "",
	password: "",
	confirmPassword: ""
};

const confirmDetails = {
	email: "",
	authcode: "",
	terms: false
};

let passwordUsed = null;

const signUpSchema = Yup.object().shape({
	email: Yup.string().required().email(),
	password: Yup.string().required().min(8),
	confirmPassword: Yup.string()
		.required()
		.min(8)
		.oneOf([Yup.ref("password"), null], "Passwords must match"),
	terms: Yup.boolean().oneOf([true], "Please Terms Of Use")
});

const confirmSchema = Yup.object().shape({
	email: Yup.string().required().email(),
	authcode: Yup.string()
		.required()
		.matches(/^[0-9]+$/, "Must be only digits")
		.min(6, "Must be exactly 6 digits")
		.max(6, "Must be exactly 6 digits")
});

export default function SignUp() {
	const { setPerson } = useContext(PersonContext);
	const [verifyForm, showVerifyForm] = useState(false);
	const { setMessage } = useSimpleMessage();
	const navigate = useNavigate();

	const createUser = async (details) => {
		let { email, password } = details;
		email = email.toLowerCase(); 
		try {
			await personUtils.signUp(email, password);
			confirmDetails.email = email;
			passwordUsed = password; // Need the password again to confirm the account
			showVerifyForm(true);
		} catch (error) {
			setMessage(
				showFailure({
					title: "Sign Up Failed",
					subTitle: error.message
				})
			);
		}
	};

	const confirmAccount = async (details) => {
		let { email, authcode } = details;
		email = email.toLowerCase(); 
		try {
			await personUtils.confirmSignUp(email, authcode);
			if (!passwordUsed) {
				navigate("/employee-manager-signin");
				return;
			}
			const authenticatedPerson = await personUtils.signIn(email, passwordUsed);
			setPerson(authenticatedPerson);
			navigate("/dashboard");
			passwordUsed = null;
		} catch (error) {
			setMessage(
				showFailure({
					title: "Code Confirmation Failed",
					subTitle: error.message
				})
			);
		}
	};

	return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-32 w-auto" src={logoImage} alt="Workwall" />
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign up for Workwall</h2>
				<p className="mt-2 text-center text-sm text-gray-600 max-w">
					Already have an account?{" "}
					<Link className="text-lightww-600 font-bold hover:text-lightww-900" to="/employee-manager-signin">
						Sign In
					</Link>
				</p>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10">
					{verifyForm ? (
						<Form initialValues={confirmDetails} validationSchema={confirmSchema} onSubmit={confirmAccount} enableReinitialize={true}>
							<InputField label="Email" name="email" placeholder="e.g. name@email.com" />
							<InputField label="Verfication Code" placeholder="Verification Code" type="text" name="authcode" />
							<div className="pt-2">
								<Button type="submit" label="Confirm" />
							</div>
						</Form>
					) : (
						<>
							<Form initialValues={signUpDetails} validationSchema={signUpSchema} onSubmit={createUser} enableReinitialize={true}>
								<InputField label="Email" name="email" placeholder="Email" autoComplete="email" />
								<InputField label="Password" name="password" type="password" placeholder="Password" />
								<InputField label="Confirm Password" name="confirmPassword" type="password" placeholder="Password" />
								<CheckBox
									type="checkbox"
									label={
										<>
											I accept the WorkWall{" "}
											<a className="text-lightww-600" target="_blank" href="/terms-of-service">
												Terms of Use
											</a>{" "}
											&{" "}
											<a className="text-lightww-600" target="_blank" href="privacy">
												Privacy Policy
											</a>
										</>
									}
									name="terms"
									labelTextColour="-mx-1 block text-sm font-medium text-gray-700"
									className="focus:ring-darkww-500 h-5 w-4 p-0 py-8 text-darkww-600 border-gray-300"
								/>
								<div className="pt-2">
									<Button type="submit" label="Create Account" />
								</div>
							</Form>

							<div className="mt-2 text-center text-sm text-gray-600 max-w">
								Already have a verfication code?{" "}
								<LinkButton
									label="Verify"
									onClick={() => {
										showVerifyForm(!verifyForm);
									}}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
