/* This example requires Tailwind CSS v2.0+ */
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { XCircleIcon, XIcon } from "@heroicons/react/solid";
import { Fragment, createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";

const MESSAGE_TYPE_ERROR = "ERROR";
const MESSAGE_TYPE_SUCCESS = "SUCCESS";

const messageConfig = {
	show: false,
	title: "",
	type: MESSAGE_TYPE_SUCCESS,
	subTitle: "",
	duration: null
};

const MessageContext = createContext();

const Message = () => {
	const { message, setMessage } = useContext(MessageContext);
	const bgColor = message.type === MESSAGE_TYPE_ERROR ? "bg-red-50" : "bg-white";
	const textColor = message.type === MESSAGE_TYPE_ERROR ? "text-red-800" : " text-gray-900";
	const duration = (message?.duration ?? 3.5) * 1000;
	if (message?.show) {
		setTimeout(() => {
			const hideMessage = { show: false };
			setMessage({ ...message, ...hideMessage });
		}, duration);
	}
	return ReactDOM.createPortal(
		<div
			aria-live="assertive"
			className="z-50 fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
		>
			<Transition
				show={message.show}
				as={Fragment}
				enter="transform ease-out duration-300 transition"
				enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enterTo="translate-y-0 opacity-100 sm:translate-x-0"
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div
					className={`${bgColor} shadow-lg max-w-sm w-full rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
				>
					<div className="p-4">
						<div className="flex items-start">
							<div className="flex-shrink-0">
								{message.type === MESSAGE_TYPE_ERROR ? (
									<XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
								) : (
									<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
								)}
							</div>
							<div className="ml-3 w-0 flex-1 pt-0.5">
								<p className={`text-sm font-medium ${textColor}`}>{message.title}</p>
								{message.subTitle && <p className="mt-1 text-sm text-gray-500">{message.subTitle}</p>}
							</div>
							<div className="ml-4 flex-shrink-0 flex">
								<button
									className={`${bgColor} rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none`}
									onClick={() => {
										const hideMessage = { show: false };
										setMessage({ ...message, ...hideMessage });
									}}
								>
									<span className="sr-only">Close</span>
									<XIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>,
		document.getElementById("simple-message")
	);
};

// Just 2 simple functions to make showing a message less verbose.
export const showSuccess = (config) => {
	const showConfig = {
		show: true,
		type: MESSAGE_TYPE_SUCCESS,
		duration: 3.5
	};
	return { ...showConfig, ...config };
};
export const showFailure = (config) => {
	const showConfig = {
		show: true,
		type: MESSAGE_TYPE_ERROR
	};
	return { ...showConfig, ...config };
};

export const SimpleMessage = ({ children }) => {
	const [message, setMessage] = useState(messageConfig);

	return (
		<MessageContext.Provider value={{ message, setMessage }}>
			{children}
			<Message />
		</MessageContext.Provider>
	);
};

export const useSimpleMessage = () => useContext(MessageContext);
