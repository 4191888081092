import Background from "./Background";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { orgUtils, OrganisationContext, PersonContext } from "ww-stores";
import {
	Storage,
	Form,
	InputField,
	Button,
	Yup,
	checkIfFilesAreTooBig,
	checkIfFilesAreCorrectType,
	formateEpoch,
	LinkButton,
	ConfirmModal
} from "ww-framework";
import { Dialog, Transition } from "@headlessui/react";
import { PhotographIcon, VideoCameraIcon, DocumentIcon, TrashIcon, ArrowDownIcon, DocumentTextIcon } from "@heroicons/react/outline";
import { FolderIcon } from "@heroicons/react/solid";

const Drive = () => {
	const { organisation } = useContext(OrganisationContext);
	const [uploadFileOpen, setUploadFileOpen] = useState(false);
	const [chooseModuleType, setChooseModuleType] = useState("folder");
	const [fileUploading, setFileUploading] = useState(false);
	const [files, setFiles] = useState([]);
	const [activeFolder, setActiveFolder] = useState(null);
	const [folders, setFolders] = useState([]);
	const { person } = useContext(PersonContext);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [toDeleteRecord, setToDeleteRecord] = useState({});

	const SUPPORTED_FORMATS = [
		"image/png",
		"image/gif",
		"image/jpeg",
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-excel",
		"image/x-eps",
		"video/mp4",
		"video/x-m4v",
		"video/*",
		"video/quicktime",
		"text/csv",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"text/plain"
	];
	const FILE_SIZE = 5 * 1024 * 1024;
	const fileSchema = Yup.object().shape({
		file: Yup.mixed()
			.test("is-correct-file", "Max 5 MB file allowed to upload", (value) => checkIfFilesAreTooBig(value, FILE_SIZE))
			.test("is-big-file", "File Type not supported ", (value) => checkIfFilesAreCorrectType(value, SUPPORTED_FORMATS)),
		folder: Yup.string().min(3).max(20)
	});
	useEffect(() => {
		getOrganisationFolders(organisation.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);
	const getOrganisationFolders = async () => {
		if (organisation?.id) {
			const data = await orgUtils.getStoredFolders(organisation.id);
			if (data?.items && organisation?.members) {
				const memberPersonIds = organisation.members.map(member => member.person);
				const matchingFolders = data.items.filter(folder => {
					// Assuming folder.name holds the name of the folder
					return memberPersonIds.includes(folder.name);
				});
				const nonMatchingFolders = data.items.filter(folder => !matchingFolders.includes(folder));
	
				setFolders(nonMatchingFolders);
			}
		}
	};
	useEffect(() => {
		if (activeFolder) setFiles(folders.find((k) => k.id === activeFolder)?.files?.items ?? []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFolder]);
	async function saveFileToStorage(file) {
		if (!file) return;
		try {
			const { key } = await Storage.put(`${organisation.id}-file-Doc-${Date.now()}-${file.name}`, file, {
				contentType: file?.type ?? "image/png"
			});
			return key;
		} catch (e) {
			console.log(e);
		}
	}
	async function getSignnedUrl(fileURI) {
		if (!fileURI) return;
		try {
			const signedURI = await Storage.get(fileURI, { download: false });
			return signedURI;
		} catch (e) {
			console.log(e);
		}
	}
	const handlerChooseModuleModal = (e, type) => {
		setUploadFileOpen(false);
		if (type !== "close") {
		}
	};
	const initValues = {
		file: null,
		folder: ""
	};
	const deleteFile = async (e, file) => {
		try {
			await Storage.remove(file.fileURI);
			const deleted = await orgUtils.deleteFile(file.id);
			let inState = files.filter((d) => d.id !== deleted.id);
			setFiles(inState);
			setToDeleteRecord({});
		} catch (error) {
			console.log(error);
		}
	};
	const deleteFolder = async (folder) => {
		try {
			if (folder?.files?.items?.length > 0)
				await Promise.all(
					folder.files.items.map(async (f) => {
						await deleteFile("", f);
					})
				);
			const deleted = await orgUtils.deleteFolder(folder.id);
			let inState = folders.filter((d) => d.id !== deleted.id);
			setFolders(inState);
			setToDeleteRecord({});
		} catch (error) {
			console.log(error);
		}
	};
	const download = async (uri) => {
		let toRedirect = await getSignnedUrl(uri);
		window.open(toRedirect, "_blank", "noopener,noreferrer");
	};
	const UploadFile = ({ open, handlerModal, type = "file" }) => {
		return (
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={(e) => handlerModal(e, "close")}>
					<div className="flex items-center justify-center h-full w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="bg-white transform rounded-lg w-fit w-72">
								<span
									className="inline-block absolute top-0 right-0 mr-4 mt-4 cursor-pointer"
									onClick={(e) => handlerModal(e, "close")}
								>
									<svg className="w-6 h-6 text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
								<div className="w-full flex flex-col px-2 py-3">
									<div className="flex flex justify-center gap-2">
										<Form
											validationSchema={fileSchema}
											enableReinitialize={true}
											disabled={fileUploading}
											initialValues={initValues}
											onSubmit={async (data, { resetForm }) => {
												try {
													setFileUploading(true);
													if (chooseModuleType === "folder") {
														const { folder: folderName } = data;
														let tosaveFlder = {
															name: folderName,
															organisationID: organisation.id
														};
														const save = await orgUtils.storeFolder(tosaveFlder);
														setFolders([...folders, save]);
													} else if (chooseModuleType === "file") {
														let fileURI = await saveFileToStorage(data.file[0]);
														const fileData = {
															name: data.file[0].name,
															size: data.file[0].size,
															type: data.file[0].type,
															folderId: activeFolder,
															fileURI: fileURI,
															organisationID: organisation.id
														};
														const save = await orgUtils.storeFile(fileData);
														setFiles([...files, save]);
														await getOrganisationFolders();
													}
													resetForm();
													handlerModal("", "close");
													setFileUploading(false);
												} catch (error) {
													console.log(error);
													setFileUploading(false);
												}
											}}
										>
											<div className="w-full">
												<div className="mt-5">
													{type === "file" ? (
														<InputField
															type="file"
															label="Choose File"
															name="file"
															accept={SUPPORTED_FORMATS.toString()}
															value={undefined}
														/>
													) : (
														<InputField type="text" label="Folder" name="folder" value={""} />
													)}
												</div>
												<div className="w-full mt-1">
													<Button type="submit" label="Save" disabled={fileUploading} />
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		);
	};
	const handlerConfirmModal = (e, type) => {
		e.preventDefault();
		if (type === "confirm") {
			if (toDeleteRecord?.type === "file") deleteFile(e, toDeleteRecord.record);
			if (toDeleteRecord?.type === "folder") deleteFolder(toDeleteRecord.record);
			setOpenConfirm(false);
			return;
		}
		setOpenConfirm(false);
		setToDeleteRecord({});
	};
	const toDelete = (type = "file", record) => {
		setToDeleteRecord({
			type: type,
			record: record
		});
		setOpenConfirm(true);
	};
	return (
		<Background>
			<UploadFile open={uploadFileOpen} handlerModal={handlerChooseModuleModal} type={chooseModuleType} />
			<ConfirmModal
				handlerModal={handlerConfirmModal}
				open={openConfirm}
				className="text-base font-medium text-gray-900"
				title={`Delete this ${toDeleteRecord?.type ?? "File / Folder"} !`}
				subTitle="Are you sure?"
			/>
			<div className="w-full rounded-xl min-h-[430px]">
				<div className="px-4 md:px-10 py-4 md:py-7">
					<div className="sm:flex items-center justify-between">
						<p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
							{chooseModuleType === "file" ? "Files" : "Folders"}
						</p>
						<div className="mt-4 sm:mt-0">
							{(person.isAdmin || person.assignedAdmin) && (
								<button
									className="inline-flex mr-2 sm:ml-3 items-start justify-start px-6 py-3 bg-darkww-500 hover:bg-lightww-600 focus:outline-none rounded"
									onClick={(e) => setUploadFileOpen(true)}
								>
									<p className="text-sm font-medium leading-none text-white">
										{chooseModuleType === "file" ? "Upload File" : "Create Folder"}
									</p>
								</button>
							)}
							{chooseModuleType === "file" && (
								<button
									className="inline-flex sm:ml-3 items-start justify-start px-6 py-3 bg-darkww-500 hover:bg-lightww-600 focus:outline-none rounded"
									onClick={(e) => setChooseModuleType("folder")}
								>
									<p className="text-sm font-medium leading-none text-white">Back</p>
								</button>
							)}
						</div>
					</div>
				</div>
				<div className="bg-white px-4 md:px-10 pb-5 rounded overflow-x-auto"> 
					<div className="overflow-x-auto">
						{chooseModuleType === "folder" ? (
							<div className="mx-auto container py-5 px-6">
								{folders && folders.length > 0 && (
									<div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
										{folders.map((folder, index) => (
											<div
												className="w-full h-auto flex flex-col justify-between dark:bg-darkww-300 bg-white dark:border-gray-700 rounded-lg border border-gray-400 mb-5 py-2 px-3"
												key={`${index}-folder`}
											>
												{(person.isAdmin || person.assignedAdmin) && (
													<div className="relative">
														<div className="center absolute top-0 right-0">
															<div className="w-6 h-6 rounded-full bg-white flex">
																<LinkButton
																	label={
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 24 24"
																			strokeWidth={1.5}
																			stroke="currentColor"
																			className="w-6 h-6"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
																			/>
																		</svg>
																	}
																	onClick={() => toDelete("folder", folder)}
																	className=""
																/>
															</div>
														</div>
													</div>
												)}
												<div
													className="flex items-center"
													onClick={(e) => {
														setChooseModuleType("file");
														setActiveFolder(folder.id);
													}}
												>
													<FolderIcon className="w-10 h-10 mr-2 drop-shadow-xl text-lightww-300" />
													<h4 className="text-gray-800 dark:text-gray-100 font-bold">{folder.name}</h4>
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						) : (
							chooseModuleType === "file" && (
								<table className="w-full whitespace-nowrap">
									{files?.length > 0 ? (
										<tbody>
											{files.map((f, i) => (
  <tr className="text-sm leading-none text-gray-600 h-16" key={i}>
    <td className="w-1/2">
      <div className="flex items-center">
        <div className="w-10 h-10 bg-gray-200 rounded-lg flex items-center justify-center">
          {f.type.includes("application") ? (
            <DocumentIcon className="w-5 h-5 " />
          ) : f.type.includes("video") ? (
            <VideoCameraIcon className="w-5 h-5 " />
          ) : f.type.includes("text") ? (
            <DocumentTextIcon className="w-5 h-5 " />
          ) : (
            f.type.includes("image") && <PhotographIcon className="w-5 h-5 " />
          )}
        </div>
        <div className="pl-2">
          <div className="text-sm font-medium leading-none text-gray-800 truncate max-w-xs md:max-w-md lg:max-w-lg">
            <div style={{ width: '200px', overflowX: 'auto' }}>{f.name}</div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <p className="pl-16 hidden md:inline-block">
        {f.size / 1024 / 1024 > 1
          ? (f.size / 1024 / 1024).toFixed(2) + "MB"
          : (f.size / 1024).toFixed(2) + "KB"}
      </p>
    </td>
    <td>
      <p className="pl-16 hidden lg:inline-block">
        Shared on {formateEpoch(Date.parse(f.createdAt) / 1000)}
      </p>
    </td>
    <td>
      <div className="flex items-center">
        <button
          className="inline-flex items-center justify-center px-3 sm:px-6 py-3 bg-darkww-700 ml-2 hover:bg-indigo-600 focus:outline-none rounded"
          onClick={async (e) => download(f.fileURI)}
        >
          <p className="sm:block hidden text-sm font-medium leading-none text-white">Download</p>
          <ArrowDownIcon className="w-5 h-5 text-white" />
        </button>
        {person.isAdmin && (
          <button
            className="inline-flex items-center justify-center px-3 sm:px-6 py-3 bg-pink-700 hover:bg-pink-600 focus:outline-none rounded ml-2"
            onClick={(e) => toDelete("file", f)}
          >
            <p className="sm:block hidden text-sm font-medium leading-none text-white">Delete</p>
            <TrashIcon className="w-5 h-5 text-white" />
          </button>
        )}
      </div>
    </td>
  </tr>
))}

										</tbody>
									) : (
										<h3 className="text-md font-bold text-gray-500 uppercase tracking-wide text-center py-6 mx-auto">
											This Folder is Empty !
										</h3>
									)}
								</table>
							)
						)}
					</div>
				</div>
			</div>
		</Background>
	);
};

export default Drive;
