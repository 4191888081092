import LogoImage from "../images/logo-light.svg";

export const PrivacyPoclicy = () => (
	<>
		<div className="min-h-full pt-2 pb-12 flex flex-col bg-white">
			<main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex-shrink-0 flex justify-center">
					<a href="/" className="inline-flex">
						<span className="sr-only">Workwall</span>
						<img className="h-12 w-auto" src={LogoImage} alt="" />
					</a>
				</div>
				<div className="py-8">
					<h2 className="text-xl mt-4 mb-2 font-bold">Privacy Statement</h2>
					This website, including web and mobile applications (collectively referred to as the “Site”) is operated by WorkWall Limited (CRO:
					718956), having its registered address at Cork City, Ireland, VAT registration number: 4010479DH) (“WorkWall”, “We”, “Us” and
					“Our”). Please review this privacy statement carefully, as by submitting personal data through this Site you are consenting to the
					collection, use and disclosure of your personal data as described in this privacy statement.
					<h3 className="text-lg mt-4 mb-2 font-bold"> Your Privacy </h3>
					<p>
						We are committed to protecting the privacy of our Site visitors and customers. We will not disclose information about our
						customers to third parties except where it is part of providing a service to you, e.g. arranging for a product to be sent to
						you, carrying out credit and other security checks and for the purpose of customer research and profiling, or where we have
						your express permission to do so.
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Collection and Use of Personal Data </h3>
					<p>
						We do not collect any personal data about you on this Site unless you volunteer that information to us in the course of
						placing your order and/or making an enquiry to one of the addresses listed on our Site.
					</p>
					<p>
						Such personal data might include (but is not limited to): your name; your email address; your order details; details of the
						enquiry.
					</p>
					<p>
						We use this personal data for the purpose it was provided (i.e. to process your online order or to respond to your enquiry).
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Data Retention </h3>
					<p>
						It is our aim to only hold your data for as long as this is necessary. While you continue as a customer with us we will retain
						your data, including Personal Data, as detailed above in this statement. The retention period will be 24 months from the date
						of termination of our terms of service. We have a high number of customers who re-engage with our service after a period of
						absence from the end of their contract. This retention period allows for the data to be reused where necessary. However, you
						can request that your Data is deleted at any time within this period. We will respond to your request and complete the full
						deletion within 30 days.
					</p>
					<p>
						Specific legal periods are in place for payments details, tax and invoicing data, and this would be retained in accordance
						with the law (which is currently six years).
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Disclosure of Personal Data </h3>
					<p>
						We will not disclose your personal data to third parties, except where it is necessary to assist us with the efficient
						processing of your order. For example, your contact details will be passed through a third-party online payment service
						processor along with your payment instructions. We have appropriate contractual arrangements in place with any third party
						service providers acting on our behalf to ensure that your data is only processed in accordance with our instructions and that
						the third party is obliged to take appropriate security measures to ensure that your data is secure.
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Collection and Use of Statistical data</h3>
					<p>
						In addition to collecting personal data voluntarily supplied by you, we also collect aggregate statistical data regarding the
						use of our Site, in the interests of assessing its effectiveness and continually improving its content. Such information
						cannot and does not personally identify individual visitors to our Site, so does not compromise any individual’s privacy.
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Right of Access and Amendment </h3>
					<p className="my-2 ">In accordance with applicable law, you may have the right to:</p>
					<ul class="list-disc list-inside">
						<li>
							Access Personal Information about you, including: (i) confirming whether we are processing your personal information; (ii)
							obtaining access to or a copy of your personal information; and (iii) receiving an electronic copy of personal information
							that you have provided to us, or asking us to send that information to another company (the “right of data portability”);
						</li>
						<li>
							Request Correction of your personal information where it is inaccurate or incomplete. In some cases, we may provide
							self-service tools that enable you to update your personal information;
						</li>
						<li>Request Deletion of your personal information;</li>
						<li>Request Restriction of or Object to our processing of your personal information; and</li>
						<li>Withdraw your Consent to our processing of your personal information.</li>
						<li>If you would like to exercise any of these rights, please contact us as by email via info@theworkwall.com</li>
						<li>We will process such requests in accordance with applicable laws.</li>
					</ul>
					<p className="mt-4 ">
						Notice to EU/UK individuals: this Privacy Statement is established to comply with Regulation (EU) 2016/679 of the European
						Parliament and of the Council of the European Union 27 April 2016 (the “General Data Protection Regulation” or “GDPR”), and
						the UK equivalent (the “UK GDPR”), and provide appropriate protection and care with respect to the treatment of your user
						information in accordance with the GDPR.
					</p>
					<p>
						As per GDPR regulations you have a right to request a copy of any personal data we might hold about you (e.g. your name and
						email address) and to have any inaccuracies in that data corrected or deleted. If you wish to avail of any of these rights,
						please contact us our Data Protection Officer by email via info@theworkwall.com
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Cookies </h3>
					<p>
						Please note that cookies are used on this Site, so a cookie may be placed on your computer browser. Cookies are small text
						files which provide us with information about how often someone visits our Site and what they do during those visits. Cookies
						do not themselves contain any personally identifying information but if you provide information to us, it may be linked to the
						data stored in that cookie.
					</p>
					<p>
						If you do not want your browser to accept cookies, you can turn off the cookie acceptance option in your browser setting.
						Disabling cookie support may prevent this Site from functioning properly and you may experience difficulties in ordering
						online from us.
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold"> Changes to this Privacy Statement </h3>
					<p>
						From time to time, we may decide to change this privacy statement, but any changes will be posted on our Site to keep you up
						to date on how we collect, use and disclose personal data. If at any time we decide to use personal data in a manner
						significantly different from that stated in this privacy statement, or otherwise disclosed to you at the time it was
						collected, we will notify you, and you will have the choice as to whether or not we use your information in the new manner.
					</p>
					<h3 className="text-lg mt-4 mb-2 font-bold">
						{" "}
						Important Links:{" "}
						<a href="/terms-of-service" className="underline decoration-4 text-sky-800">
							Terms Of Service
						</a>
					</h3>
				</div>
			</main>
		</div>
	</>
);
