import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";
import React, { forwardRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Form, InputField, Tooltip, showFailure, useSimpleMessage } from "ww-framework";

export const PreferenceDialog = ({ person, currentDay, preferenceSave, preferences }) => {
	let activeOrg = window.sessionStorage.getItem("activeOrg") ?? 0;
	const memberPreference = preferences && preferences.find((value) => value.memberID === person?.organisations?.items[activeOrg].id);

	const [repeatWeekly, setRepeatWeekly] = useState(memberPreference?.repeat || false);
	const [selectedEndDate, setSelectedEndDate] = useState(memberPreference?.repeatEndDate ? new Date(memberPreference?.repeatEndDate * 1000) : null);
	const [showDatePicker, setShowDatePicker] = useState(selectedEndDate ? true : false);
	const { setMessage } = useSimpleMessage();
	const [preferenceNote, setPreferenceNote] = useState(memberPreference?.note);
	const initialPreferenceNoteValue = {
		repeatNote: memberPreference?.note
	};

	const DateButtonInput = forwardRef(({ value, onClick, ...props }, ref) => (
		<button className="mx-1 mb-1 p-0.5 px-2 rounded border-b border-gray-300" onClick={onClick} ref={ref}>
			<p className="text-sm text-gray-700">{value ? value : props.placeholder ? props.placeholder : "Enter"}</p>
		</button>
	));

	return (
		<>
			<div>
				<div className="mx-2 px-1 flex-col items-center">
					<div className="p-1 mr-2 flex items-center">
						<input
							id="repeatWeekly"
							aria-describedby="repeatWeekly"
							name="repeatWeekly"
							type="checkbox"
							className="focus:ring-darkww-500 h-4 w-4 text-darkww-600 border-gray-300 rounded"
							defaultChecked={repeatWeekly}
							onChange={(e) => {
								setShowDatePicker(e.target.checked ? true : false);
								setRepeatWeekly(e.target.checked);
							}}
						/>
						<Tooltip trigger={["hover"]} overlay={"Repeat this preference until the selected date"} placement="top">
							<label htmlFor="repeatWeekly" className="ml-2 block text-sm font-medium text-darkww-700">
								Repeat
							</label>
						</Tooltip>
					</div>
					<div className="flex items-center justify-between gap-5">
						{showDatePicker && (
							<DatePicker
								selected={selectedEndDate}
								onChange={(date) => setSelectedEndDate(date)}
								placeholderText="Select end date"
								minDate={currentDay.date}
								dateFormat="dd/MM/yyyy"
								calendarStartDay={1}
								customInput={<DateButtonInput />}
							/>
						)}
					</div>
				</div>
				<Form initialValues={initialPreferenceNoteValue}>
					<div className="mt-2 px-2 with-full ">
						<InputField
							label="Add a Note?"
							name="repeatNote"
							labelTextColour="text-dark"
							onChange={(e) => {
								// e.target.value
								setPreferenceNote(e);
							}}
						/>
					</div>
				</Form>

				{memberPreference?.status !== "PREFER_NOT" && (
					<div
						className="sm:block"
						onClick={
							(repeatWeekly && selectedEndDate) || !repeatWeekly
								? () => {
									return preferenceSave(
										currentDay,
										"PREFER_NOT",
										memberPreference?.id,
										repeatWeekly,
										selectedEndDate,
										preferenceNote
									);
								}
								: () =>
									setMessage(
										showFailure({
											title: "Please set an end date.",
											subTitle: "Or uncheck the repeat checkbox."
										})
									)
						}
					>
						<div className="flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
							<ThumbDownIcon className="w-5 h-5 text-pink-600 mr-2" /> Prefer Not
						</div>
					</div>
				)}
				{memberPreference?.status !== "PREFER" && (
					<div
						className="sm:block"
						onClick={
							(repeatWeekly && selectedEndDate) || !repeatWeekly
								? () => preferenceSave(currentDay, "PREFER", memberPreference?.id, repeatWeekly, selectedEndDate, preferenceNote)
								: () =>
									setMessage(
										showFailure({
											title: "Please set an end date.",
											subTitle: "Or uncheck the repeat checkbox."
										})
									)
						}
					>
						<div className=" flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
							<ThumbUpIcon className="w-5 h-5 text-green-600 mr-2" /> Prefer
						</div>
					</div>
				)}
				{memberPreference?.status !== "DELETE" && memberPreference?.status !== undefined && (
					<div
						className="sm:block"
						onClick={
							(repeatWeekly && selectedEndDate) || !repeatWeekly
								? () => preferenceSave(currentDay, "DELETE", memberPreference?.id, repeatWeekly, selectedEndDate)
								: () =>
									setMessage(
										showFailure({
											title: "Please set an end date.",
											subTitle: "Or uncheck the repeat checkbox."
										})
									)
						}
					>
						<Tooltip trigger={["hover"]} overlay={"This will delete preference for just this day"} placement="bottom">
							<div className="flex block px-2 py-2 text-sm text-gray-700 cursor-pointer items-center">
								<ThumbUpIcon className="w-5 mr-2 h-5" /> Delete
							</div>
						</Tooltip>
					</div>
				)}
			</div>
		</>
	);
};
