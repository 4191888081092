import React from "react";
import HeaderMenu, { PrimaryLink } from "../components/headers/light";
import logoImage from "ww-images/workwall-logo.svg";

const post = [
  {
    id: 2,
    title: "The Hidden Cost of Staff Turnover and 3 Sustainable Retention Strategies in the Hospitality & Retail Sectors",
    href: "#",
    description: `Today, I think we are all very aware of how the hospitality & retail industries grapple with an escalating challenge of employee turnover. We see it with some of our favourite venues only able to open 4 out of 7 days a week due to staff shortages or new intersting businesses having to postpone opening for weeks to months as they can't find enough staff to open. Where we though the aftermath of Covid we would see things improve we have actually seen this issue has only intensified, becoming increasingly costly and complex. We can see a true insight into exactly how much this can impact a business's resources in one of the only extensive studies done in the area, from Cornell University's Hospitality Research Center over 20 years ago. In this, they uncover a startling average cost of US$5,864 per replaced front of house employee. In the current uncertain and competitive climate, a financial toll of this magnitude is clearly unsustainable, necessitating a critical re-evaluation and strengthening of employee retention strategies. This is especially true when you factor in an average turnover rate of 30% in both hospitality and retail (which more than doubles when you look at the States). Something I've seen to be very apparent the more owners and managers I talk to.`,
    imageUrl:
      "https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHdvcmslMjBwZW9wbGUlMjBjYWZlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
      date: "May 17, 2023",
      datetime: "2023-05-17",
      category: { title: "Well-being", href: "#" },
      author: {
        name: "Richard Goggin",
        role: "CEO",
        href: "#",
        imageUrl: "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
      }
    }]
    
    export default function Blog2({ scrollTo }) {
      return (
        <>
          <HeaderMenu roundedHeaderButton={PrimaryLink} scrollTo={scrollTo} />
    
          <article key={post[0].id} className="flex flex-col mx-4 items-center justify-between">
            <div className="relative w-full">
              <img
                src={post[0].imageUrl}
                alt="img-bar-restaurant-hospitality-tech"
                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
              />
              <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="max-w-2xl">
              <div className="mt-8 flex items-center text-md">
                <time dateTime={post[0].datetime} className="text-gray-500">
                  {post[0].date}
                </time>
                <span className="mx-8 text-gray-500 text-md">4 mins</span>
                <a
                  href={post[0].category.href}
                  className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
                >
                  {post[0].category.title}
                </a>
              </div>
              <div className="group relative">
              </div>
              <div className="container mx-auto px-6 py-8">
<h1 className="text-4xl font-bold mb-4">{post[0].title}</h1>
<p className="text-lg mb-6">{post[0].description}</p>

<h2 className="text-2xl font-bold mb-2">The Hidden Costs of Employee Turnover</h2>
<p className="text-lg mb-4">When analysing the cost of employee turnover, it is not just the obvious, immediate expenses that weigh on the balance sheet. The actual cost encompasses various elements, not just the obvious ones here:</p>

<ul className="list-disc pl-5 mb-6">
  <li className="mb-2"><strong>Recruitment Expenses:</strong> The costs associated with advertising, interviewing, and selection processes which can be substantial, both financially and in terms of time and resources.</li>
  <li className="mb-2"><strong>Administrative Processing:</strong> This includes the paperwork and other administrative tasks associated with hiring a new employee or the process of an existing one leaving.</li>
  <li className="mb-2"><strong>Orientation and Training:</strong> Onboarding a new employee costs money. It includes the process of training them and helping them reach a level where they can perform their tasks effectively. This can take have direct cost with qualifications, like manual handling, but also costs in the lost time & productivity of managers & staff helping the new employee learn the ropes.</li>
  
</ul>

<h2 className="text-2xl font-bold mb-2">The Indirect Costs</h2>
<p className="text-lg mb-4">Beyond the direct costs, high employee turnover also results in indirect costs that can have an even more damaging impact on the business. That study showed that these costs actually make up over half the cost:</p>

<ul className="list-disc pl-5 mb-6">
<li><strong>Lost Productivity:</strong> From the moment an employee hands in their notice until a new employee can function at full capacity, there is often a noticeable dip in productivity. Not just from the employee on the way out slowing down, but a new employee probably won't be up to scratch for months.</li>
  <li ><strong>Decreased Customer Satisfaction:</strong> In the hospitality industry, high turnover rates can lead to inconsistent service quality, which can erode customer satisfaction and loyalty over time. It might be patience thinning as my hairline follows suit, but I think many might still agree when I say wait times at the bar have been getting steadily worse for years.</li>
  <li><strong>Poor Morale:</strong> It's not just the exiting and the new employee that are affected but all the staff feel the hit. There's going to be an additional, self-perpetuating strain put on all employees while you get your replacement gets up to standard, but also, if you've built your team well, they're obviously going to miss any employee that leaves and they will be more likely to question their own decisions and where they are. This can also be felt by customers.</li>
  <li className="mb-2"><strong>Reputation Damage:</strong> A high employee turnover rate can have a detrimental effect on the company's reputation, making it less appealing to potential employees and possibly deterring customers.</li>
</ul>

<h2 className="text-2xl font-bold mb-2">Effective Strategies for Staff Retention</h2>
<p className="text-lg mb-4">Given the profound financial and operational implications of staff turnover, it is paramount to establish and implement effective retention strategies. The focus areas include:</p>



<h3 className="text-xl font-bold mb-2">1. Employee Engagement</h3>
<p className="text-lg mb-4">Developing and nurturing a positive work culture that promotes:</p>

<ul className="list-disc pl-5 mb-6">
  <li className="mb-2"><strong>Regular Communication:</strong> Keeping staff informed about the company's direction, day-to-day goings on and involving them in decision-making processes, where applicable, can increase their sense of belonging and commitment.</li>
  <li className="mb-2"><strong>Recognition:</strong>  Regularly acknowledging and rewarding employees for their hard work, dedication, and achievements can significantly boost morale and job satisfaction. Rewards, vouchers, employee of the month, or even just a mention on a noticeboard, can be acknowledgment enough for many of the hard work and effort they are putting in.</li>
  <li><strong>Career Advancement Opportunities:</strong>  Offering opportunities for further skill development and career progression can improve employee retention rates significantly.</li>
</ul>
<h3 className="text-xl font-bold mb-2">2. Leverage Technology</h3>
<p className="text-lg mb-4">Embracing and leveraging innovative tech solutions can:</p>
<ul className="list-disc pl-5 mb-6">
  <li className="mb-2"><strong>Streamline Operations:</strong> By reducing administrative burden, both managers and employees can focus more on core duties and customer service.</li>
  <li className="mb-2"><strong>Improve Flexibility:</strong> One of the top reasons employees gave to stay in these industries, and probably what they allow for better than any other, is more flexibility. This can greatly improve work/life balance and team morale, and therefore, the health, energy and productivity of your entire team.</li>
  <li><strong>Facilitate Learning and Development:</strong> Technology can serve as a platform for continuous skill development and training. Don't allow your workplace to stagnate, encourage your staff to learn and grow with you. Not only will you be rewarded with better employees but they will feel appreciated and like they are constantly bettering themselves thanks to you. A little investment now could save you much bigger loses down the line.</li>
</ul>
<h3 className="text-xl font-bold mb-2">4. Comprehensive Onboarding</h3>
<p className="text-lg mb-4">The Cornell study underscores the role of comprehensive onboarding in reducing turnover. It is important to ensure that new employees have a deep understanding of their roles, receive adequate and effective training, and see a clear and attainable pathway for career progression within the organisation. You're going to want to make sure you check in with your staff, especially when they are new, so they can feel comfortable in their role as soon as possible. From there they will start to enjoy it much more.</p>



<p className="text-lg">High staff turnover shouldn't have to be an unavoidable evil of these industries, as is the prevailing thought from those that I have talked to. Let the Cornell University Hospitality Research Centre's study serve as a stark reminder of the steep costs associated with high employee turnover. Perhaps these costs can't be completely eradicated but they can certainly be improved. In doing so, we create a self-sustaining, symbiotic relationship, with happy staff creating happy customers, happy customers mean happy owners, happy owners lead to happy mangers and so on. It is a wakeup call to the hospitality & retail that cannot be ignored. It is clear that prioritising staff retention is not merely beneficial - it's an essential survival strategy in our current climate.</p>

<div className="relative mt-8 flex items-center gap-x-4">
              <img src={logoImage} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
              <div className="text-sm leading-6">
                <p className="font-semibold text-gray-900">
                  <a href={post[0].author.href}>
                    <span className="absolute inset-0" />
                    {post[0].author.name}
                  </a>
                </p>
                <p className="text-gray-600">{post[0].author.role}</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
