import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputField, Yup, showFailure, showSuccess, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils, personUtils } from "ww-stores";
const memberSchema = Yup.object().shape({
	password: Yup.string().min(3),
	email: Yup.string().required().email()
});

const ClockInUser = () => {
	const { organisation } = useContext(OrganisationContext);
	const { members } = organisation;
	const { setMessage } = useSimpleMessage();
	const [staffMembers, setStaffMembers] = useState([]);
	const [savingMember, setSavingMember] = useState(false);
	const [clockInMember, setClockInMember] = useState({});
	useEffect(() => {
		if (members) {
			setStaffMembers(members);
		}
		if (organisation.id) loadClockInUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members]);
	const loadClockInUser = async () => {
		const data = await orgUtils.getClockMember(organisation.id);
		setClockInMember(data.items[0] ?? {});
	};

	return (
		<>
			<div className="divide-y divide-gray-200 lg:col-span-9 h-fit">
				<div className="p-6 px-4 sm:p-6 lg:pb-8">
					<div className="text-center">
						<svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 48 48" aria-hidden="true">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
							/>
						</svg>
						<h2 className="mt-2 text-lg font-medium text-gray-900">Add Clock-In Login</h2>
						<div className="mt-2 text-md font-light text-grey-900">Here you can create a login for your clock-in system.</div>
						<div className="mt-2 text-md font-light text-grey-900">
							This will be used to open the clock-in system page, keeping admin secure in another login.
						</div>
						<div className="mt-2 text-md font-light text-grey-900">
							The email will have to be unique to this feature and not used elsewhere in the software.
						</div>
						<div className="mt-2 text-md font-light text-grey-900">(for example: clockin@yourcompany.com)</div>
					</div>
					{organisation?.maxAllowedMembers >= members?.length && (
						<Form
							validationSchema={memberSchema}
							enableReinitialize={true}
							disabled={savingMember}
							initialValues={{ email: clockInMember?.email ?? "", password: clockInMember?.password ?? "Password@123" }}
							onSubmit={async (data, { resetForm }) => {
								setSavingMember(true);
								if (!staffMembers.find((member) => member.email === data.email)) {
									try {
										const { email, password } = data;
										// check email in the person ?
										if (Object.keys(clockInMember).length) {
											await orgUtils.removeMember(clockInMember.id);
										}
										await personUtils.signUp(email, password).then(async (response) => {
											await personUtils
												.saveProfile({ sub: response.sub, salary: 0, username: "Clock-In" })
												.then(async (resp) => {
													await orgUtils.addMember(resp, organisation.id, email, "Clock In User", null, true, false);
												});
										});
										await loadClockInUser();
										resetForm();
										setMessage(
											showSuccess({
												title: "Clock-In Member added"
											})
										);
									} catch (error) {
										setSavingMember(false);
										setMessage(
											showFailure({
												title: "Fix Below error!",
												subTitle: error.message
											})
										);
									}
								} else {
									setMessage(
										showFailure({
											title: "This email address is already registered to an existing user."
										})
									);
								}
								setSavingMember(false);
							}}
						>
							<div className="grid grid-cols-9 gap-6 mb-8 lg:h-24">
								<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-4">
									<div className="w-full">
										<InputField name="email" type="email" label="Email" />
									</div>
								</div>
								{/* {!Object.keys(clockInMember).length && ( */}
								<div className="h-full flex items-end col-span-9 sm:col-span-9 lg:col-span-3">
									<div>
										<InputField name="password" type="password" label="Password" />
									</div>
								</div>
								{/* )} */}
								<div className="h-full flex items-end pb-1 col-span-9 sm:col-span-9 lg:col-span-2">
									<div className="w-full">
										<Button type="submit" label={Object.keys(clockInMember).length ? "Edit" : "Add"} disabled={savingMember} />
									</div>
								</div>
							</div>
							<div className="text-center">
								<div className="mt-4 text-md font-light text-grey-900">
									Once the email has been set, login with that email on the device or devices you would like to use for clocking-in.
								</div>
								<div className="mt-2 text-sm font-light text-grey-900">
									(you will still need to verify the email so go to "sign up" on the login screen, click "verify" at the bottom, and
									enter the code sent to that email)
								</div>
							</div>
						</Form>
					)}
				</div>
			</div>
		</>
	);
};

export default ClockInUser;
