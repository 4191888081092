import React, { useContext, useEffect, useState } from "react";
import { Button, Form, HexColorPicker, InputField, Yup, showFailure, showSuccess, useNavigate, useSimpleMessage } from "ww-framework";
import { OrganisationContext, orgUtils } from "ww-stores";

const roleSchema = Yup.object().shape({
	name: Yup.string().required().min(2),
	labelColour: Yup.string().matches(/^#[0-9A-F]{6}$/i, "Not a valid HEX CSS value.")
});

const EditRole = ({ compProps }) => {
	const { organisation, setOrganisation } = useContext(OrganisationContext);
	const [roleSaving, setRoleSaving] = useState(false);
	const [role, setRole] = useState({ id: "", name: "", labelColour: "#c62cf6" });
	const { record } = compProps;
	const { setMessage } = useSimpleMessage();
	let navigate = useNavigate();

	useEffect(() => {
		if (record) setRole(record);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [record.id]);

	return (
		<Form
			validationSchema={roleSchema}
			enableReinitialize={true}
			disabled={roleSaving}
			initialValues={role}
			onSubmit={async (data, { resetForm }) => {
				try {
					setRoleSaving(true);

					const roleDetails = {
						...data,
						...{ organisationID: organisation.id }
					};

					const updateOrg = await orgUtils.saveRole(roleDetails);

					setOrganisation({
						...updateOrg,
						...(await orgUtils.mapOrgMembers(updateOrg)),
						...{ report: await orgUtils.mapReport(updateOrg?.report?.items || [], updateOrg.id) },
						...{ departments: updateOrg.departments?.items || [] },
						...{ maxAllowedMembers: window.sessionStorage.getItem("mam") ?? updateOrg?.subscription?.items?.[0]?.quantity }
					});
					setRoleSaving(false);
					resetForm();
					navigate(-1);
					setMessage(
						showSuccess({
							title: "Role saved successfully."
						})
					);
				} catch (error) {
					setRoleSaving(false);
					setMessage(
						showFailure({
							title: "Unable to save Role.",
							subTitle: error.message
						})
					);
				}
			}}
		>
			<div className="w-72 sm:w-full flex flex-col p-5">
				<div className="mt-5">
					<InputField label="Name" name="name" />
				</div>
				<div className="mt-4 flex justify-center">
					<HexColorPicker color={role.labelColour} onChange={(value) => setRole({ ...role, ...{ labelColour: value } })} />
				</div>
				<div className="mt-2">
					<InputField name="labelColour" value={role.labelColour} onChange={(value) => setRole({ ...role, ...{ labelColour: value } })} />
				</div>
				<div className="w-full">
					<Button type="submit" label="Save" disabled={roleSaving} />
				</div>
			</div>
		</Form>
	);
};

export default EditRole;
