import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings";
import { SectionDescription } from "../misc/Typography";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import GroupIconImage from "../../images/group.svg";
import SaveTimeIconImage from "../../images/save_time.svg";
import ChartIconImage from "../../images/chart.svg";
import SaveMoneyIconImage from "../../images/save-money.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import ChatIconImage from "../../images/speech-bubble.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
	${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
	${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
	${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
	.imageContainer {
		${tw`border text-center rounded-full p-5 flex-shrink-0`}
		img {
			${tw`w-12 h-12 stroke-2`}
		}
	}

	.textContainer {
		${tw`sm:ml-4 mt-4 sm:mt-2`}
	}

	.title {
		${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
	}

	.description {
		${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
	}
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
	${tw`pointer-events-none absolute -right-36 -bottom-60 w-64 opacity-50 transform translate-x-32 translate-y-48 `}
`;

const Features = ({
	cards = null,
	heading = "Amazing Features",
	subheading = "Features",
	description = "Our features have been designed with the customer first. Constant feedback has led us to develop WorkWall to be as easy as possible to use while delivering the most amount of value.",
	id = "features"
}) => {
	/*
	 * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
	 *  1) imageSrc - the image shown at the top of the card
	 *  2) title - the title of the card
	 *  3) description - the description of the card
	 *  If a key for a particular card is not provided, a default value is used
	 */

	const defaultCards = [
		{
			imageSrc: SaveTimeIconImage,
			title: "Save Time",
			description:
				"Save hours every week usually spent on rostering & related tasks (find cover, checking shifts, etc.) & focus on other aspects of the business."
		},
		{
			imageSrc: ChatIconImage,
			title: "Improved Communication",
			description:
				"Contact the right people at the right time through our roster integrated messaging systems for a more knowledgeable & better informed team."
		},

		{
			imageSrc: SaveMoneyIconImage,
			title: "Save Money",
			description: "Better staff & customer retention (as well as having a more productive & efficient team) means more profits for you."
		},
		{
			imageSrc: ChartIconImage,
			title: "Track Progress",
			description: "Monitor how your business is performing and adjust costs accordingly with easy to follow analytics."
		},
		{
			imageSrc: ReliableIconImage,
			title: "Easy Changes",
			description: "Empower staff to swap or give away shifts, only hassling your managers for final approval."
		},
		{
			imageSrc: GroupIconImage,
			title: "Collaboration & Engagement",
			description: "Improve workplace engagement & bring your team closer together."
		}
	];

	if (!cards) cards = defaultCards;
	return (
		<Container id={id}>
			<ThreeColumnContainer>
				{subheading && <Subheading>{subheading}</Subheading>}
				<Heading>{heading}</Heading>
				{description && <Description>{description}</Description>}
				<VerticalSpacer />
				{cards.map((card, i) => (
					<Column key={i}>
						<Card>
							<span className="imageContainer">
								<img src={card.imageSrc || defaultCardImage} alt="" />
							</span>
							<span className="textContainer">
								<span className="title">{card.title || "Fully Secure"}</span>
								<p className="description">
									{card.description || " ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
								</p>
							</span>
						</Card>
					</Column>
				))}
			</ThreeColumnContainer>
			<DecoratorBlob />
		</Container>
	);
};
export default Features;
