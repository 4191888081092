import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "./CardForm";
const stripePromise = loadStripe("pk_live_51LblLdBoz1fiofaSP4pT5X1I36k9ZQuIV6BNYPFhW42rVXKGTGidMTNB947QBhobc6SeXAfWOB46pC4XtBdxIhOg004ZWXoVHY");

export default function Example() {
	return (
		<Elements stripe={stripePromise}>
			<CardForm />
		</Elements>
	);
}
