import { CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import React, { memo, useContext, useEffect, useState } from "react";
import { Avatar, epochToTimeStr, fillWeek, Tooltip, useWindowDimensions } from "ww-framework";
import { OrganisationContext, orgUtils, PersonContext, personUtils } from "ww-stores";
import ClockInOutView from "./ClockInOutView";

const MemberAvatar = memo(({ member, style = "" }) => <Avatar user={member} size="h-8 w-8" twClasses={`${style} z-20`} />);

const getLastAction = (punch) => {
	if (!punch) return { action: "No Punch", time: "N/A" };
	if (punch.out) return { action: "Clocked Out", time: epochToTimeStr(punch.out) };
	if (punch.breaks && punch.breaks.length > 0) {
		const lastBreak = JSON.parse(punch.breaks[punch.breaks.length - 1]);
		if (!lastBreak.end) return { action: "On Break", time: epochToTimeStr(lastBreak.start) };
		return { action: "Returned from Break", time: epochToTimeStr(lastBreak.end) };
	}
	return { action: "Clocked In", time: epochToTimeStr(punch.in) };
};

const AttendanceView = () => {
	const { organisation } = useContext(OrganisationContext);
	const { person } = useContext(PersonContext);
	const { width } = useWindowDimensions();
	const [currentFilter, setCurrentFilter] = useState("");
	const [filteredMembers, setFilteredMembers] = useState([]);
	const [listType, setListType] = useState("members");
	const [currentDate, setCurrentDate] = useState(new Date());
	const [shifts, setShifts] = useState([]);
	const [punches, setPunches] = useState([]);
	const [showRosteredToday, setShowRosteredToday] = useState(false);
	const [showClockedIn, setShowClockedIn] = useState(false);
	const [breakCount, setBreakCount] = useState(0);
	const [sorted, setSorted] = useState(true);
	const [isViewingPreviousDay, setIsViewingPreviousDay] = useState(false);
	const [viewingDate, setViewingDate] = useState(currentDate);
	const [checkedMembers, setCheckedMembers] = useState([]);
	const [showClockIn, setShowClockIn] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [sortByStatus, setSortByStatus] = useState(true);
	

	useEffect(() => {
		async function setCurrentDateData() {
			if (organisation.id && currentDate) {

				const membersWithoutCurrentUser = organisation?.members.filter((member) => member.email !== person.email);
				setFilteredMembers(membersWithoutCurrentUser);

				await fetchShifts();
				await fetchPunches();

				// Ensure sorting is done after setting members
				handleSort(true);
			}
		}

		setCurrentDateData();
	}, [organisation?.id, currentDate]);

	useEffect(() => {
		async function setInitialData() {
			if (organisation?.id && currentDate) {
				// Set filtered members without the current user
				const membersWithoutCurrentUser = organisation?.members.filter((member) => member.email !== person.email);
				setFilteredMembers(membersWithoutCurrentUser);

				// Fetch shifts and punches
				await fetchShifts();
				await fetchPunches();

				// Mark data as loaded
				setDataLoaded(true);
			}
		}
		setInitialData();
	}, [organisation?.id, currentDate]);
	useEffect(() => {
		if (dataLoaded) {
			handleSort(true); // Sort by status on first load
			setDataLoaded(false); // Reset the flag to prevent re-sorting
		}
	}, [dataLoaded]); // This triggers only when data is fully loaded

	useEffect(() => {
		async function setDateData() {
			if (organisation?.id && viewingDate) {
				const membersWithoutCurrentUser = organisation?.members.filter((member) => member.email !== person.email);
				setFilteredMembers(membersWithoutCurrentUser);
				await fetchShifts();
				await fetchPunches();

				// Ensure sorting is done after setting members
				handleSort(true);
			}
		}

		setDateData();
	}, [organisation?.id, viewingDate]);

	useEffect(() => {
		let totalBreaks = 0;
		filteredMembers.forEach((member) => {
			const memberShifts = shifts.filter((s) => s.memberID === member.orgUserId);
			const memberPunches = punches.filter((p) => memberShifts.some((s) => s.id === p.shiftID));
			const isOnBreak = memberPunches.some((punch) => {
				if (punch && punch.breaks && punch.breaks.length > 0) {
					const lastBreak = JSON.parse(punch.breaks[punch.breaks.length - 1]);
					return !lastBreak.end; // If the break hasn't ended, the member is still on break
				}
				return false;
			});
			if (isOnBreak) {
				totalBreaks += 1;
			}
		});
		setBreakCount(totalBreaks); // Update the break count only when necessary
	}, [filteredMembers, shifts, punches]);

	const toggleDayView = () => {
		if (isViewingPreviousDay) {
			setViewingDate(new Date()); // Reset to today
		} else {
			// Switch to previous day
			const previousDate = new Date(currentDate);
			previousDate.setDate(currentDate.getDate() - 1);
			setViewingDate(previousDate); // Set to previous day
			handleSort(true);
		}
		setIsViewingPreviousDay(!isViewingPreviousDay);
		handleSort();
	};
	const resetToDefault = () => {
		setSorted(true); // Sort by status when resetting
		setFilteredMembers(organisation.members?.filter((member) => member.email !== person.email));
		handleSort(); // Apply status sort by default
	};
	const FilterTab = ({ tabListType, label }) => {
		return (
			<h2
				className={`my-2 ml-2 text-lg text-lightww-600 cursor-pointer hover:border-darkww-400 border-b-2 ${
					listType === tabListType && "border-pink-500"
				}`}
				onClick={() => {
					setListType(tabListType);
					if (tabListType === "members") {
						resetToDefault(); // Reset filters and sort by status
					}
				}}
			>
				{label}
			</h2>
		);
	};

	const fetchShifts = async () => {
		try {
			const newDays = fillWeek(viewingDate, organisation?.startDay); // Use viewingDate now
			const {
				first: { baseEpoch: startDate },
				last: { baseEpoch: endDate }
			} = newDays;
			let weekShifts = await orgUtils.getShifts(organisation.id, startDate, endDate, false);
			let todaysEpoch = viewingDate?.setUTCHours(0, 0, 0, 0) / 1000; // Use viewingDate
			const filtered = weekShifts?.filter((s) => s.baseDay === todaysEpoch);
			setShifts(filtered);
			return filtered;
		} catch (error) {
			console.log(error);
		}
	};
	const fetchPunches = async () => {
		let todaysEpoch = viewingDate?.setUTCHours(0, 0, 0, 0) / 1000;
		const { items } = await orgUtils.getPunchOfDay(organisation.id, todaysEpoch);
		setPunches(items);
		return items;
	};

	const isRosteredForCurrentTime = (shiftStart, shiftEnd) => {
		// Get the local time zone offset in hours (convert from minutes to hours)
		const timeZoneOffset = new Date().getTimezoneOffset() / 60;
		const currentTime = new Date().getTime() / 1000;
		const adjustedShiftStart = shiftStart + timeZoneOffset * 3600;
		const adjustedShiftEnd = shiftEnd + timeZoneOffset * 3600;
		return adjustedShiftStart <= currentTime && currentTime <= adjustedShiftEnd;
	};

	const membersRosteredToday = filteredMembers?.filter((member) =>
		shifts.some(
			(s) => s.memberID === member?.orgUserId && isRosteredForCurrentTime(s.shiftStart, s.shiftEnd) // Check if they are rostered for current time
		)
	);

	const setFilters = async (e) => {
		if (e.target.value)
			setFilteredMembers(filteredMembers?.filter((m) => personUtils.displayName(m)?.toLowerCase().startsWith(e.target.value.toLowerCase())));
		else setFilteredMembers(organisation?.members);
	};

	const handelMembers = (e = "", r, type) => {
		if (type === "role") setFilteredMembers(organisation.members?.filter((m) => m.roleIDs && m.roleIDs.includes(r.id)));
		if (type === "dept") setFilteredMembers(organisation.members?.filter((m) => m.departmentIDs && m.departmentIDs.includes(r.id)));
		setListType("members");
		if (type === "role" || type === "dept") {
			setCurrentFilter({ r, type });
		}
	};

	const handleCheckboxChange = (memberId) => {
		setCheckedMembers((prevCheckedMembers) => {
			if (prevCheckedMembers?.includes(memberId)) {
				return prevCheckedMembers?.filter((id) => id !== memberId);
			} else {
				return [...prevCheckedMembers, memberId];
			}
		});
	};

	// Reset checkboxes
	const resetCheckedMembers = () => {
		setCheckedMembers([]);
	};

	// Sorting function based on last action
	const getSortOrder = (member, memberPunches, lastAction) => {
		if (!memberPunches || memberPunches?.length === 0) {
			if (shifts.some((s) => s.memberID === member?.orgUserId)) return 4; // Rostered but not clocked in
			return 5; // Everyone else
		}
		if (lastAction === "Clocked In" || lastAction === "Returned from Break") return 1;
		if (lastAction === "On Break") return 2;
		if (lastAction === "Clocked Out") return 3;
		return 5; // Default to the last category
	};
	const punchesCurrentlyClockedIn = punches.filter((punch) => punch.out === null);

	const punchesWithShifts = punchesCurrentlyClockedIn?.filter((punch) => shifts.find((shift) => shift.id === punch.shiftID));

	const punchesWithMembers = punchesWithShifts?.filter((punch) => {
		const matchingShift = shifts?.find((shift) => shift.id === punch?.shiftID);
		return filteredMembers.some((member) => member?.orgUserId === matchingShift?.memberID);
	});

	const handleSort = (sortByStatus = false) => {
		if (!filteredMembers?.length) {
			console.log("No members to sort yet.");
			return;
		}

		let sortedMembers;

		if (sortByStatus) {
			// Sort by status (Clocked In, On Break, Clocked Out, etc.)
			sortedMembers = [...filteredMembers].sort((a, b) => {
				const aLastPunch = punches?.filter((p) => shifts?.some((s) => s.memberID === a.orgUserId && s.id === p.shiftID)).slice(-1)[0];
				const aLastAction = aLastPunch ? getLastAction(aLastPunch)?.action : "No Punch";

				const bLastPunch = punches?.filter((p) => shifts?.some((s) => s.memberID === b.orgUserId && s.id === p.shiftID)).slice(-1)[0];
				const bLastAction = bLastPunch ? getLastAction(bLastPunch)?.action : "No Punch";

				return getSortOrder(a, aLastPunch, aLastAction) - getSortOrder(b, bLastPunch, bLastAction);
			});
			setSorted(false); // For the next sort button click to be alphabetical
		} else {
			// Sort alphabetically
			sortedMembers = [...filteredMembers].sort((a, b) => {
				const nameA = personUtils?.displayName(a) || a.orgUsername || "No Name";
				const nameB = personUtils?.displayName(b) || b.orgUsername || "No Name";
				return nameA.localeCompare(nameB);
			});
			setSorted(true); // For the next sort button click to be by status
		}

		setFilteredMembers(sortedMembers); // Update filteredMembers state
	};
	if (showClockIn) {
		return <ClockInOutView />;
	}
	const toggleSort = () => {
		handleSort(sortByStatus); // Use current `sortByStatus` value
		setSortByStatus(!sortByStatus); // Toggle between sorting by status and alphabetical
	};
	// Render Attendance View
	return (
		<>
			<div className="bg-white rounded-xl shadow-lg mx-2 min-h-fit lg:mx-6 mb-8">
				<div className="min-w-full border rounded-lg">
					<div className={`overflow-auto h- ${width < 500 && "hidden"}`}>
						<div className="flex items-center justify-between mx-6 mt-4">
							<button
								className="bg-darkww-400 text-white font-semibold px-4 py-2 min-w-[140px] text-sm rounded"
								onClick={toggleSort} // Toggle sort order on button click
							>
								{!sortByStatus ? "Sort A-Z" : "Sort by Status"}
							</button>
							<div className="flex flex-col items-center">
								<button className="bg-lightww-100 text-darkww-800 font-semibold px-2 py-1 text-sm rounded" onClick={toggleDayView}>
									{isViewingPreviousDay ? "View Current Day" : "View Previous Day"}
								</button>
								<span className="text-darkww-700 font-bold text-2xl py-2">
									{viewingDate.toLocaleDateString()} {/* Display the date */}
								</span>
							</div>
							<button
								onClick={() => setShowClockIn(true)} // Toggle to show ClockInOutView
								className="bg-darkww-400 text-white font-semibold px-4 py-2 text-sm min-w-[140px] rounded"
							>
								Clock-in View
							</button>
						</div>

						<div className="mx-auto mt-3 mb-1 px-4 sticky top-4 z-50 w-full md:w-2/5">
							<div className="relative text-gray-600">
								<span className="absolute inset-y-0 left-0 flex items-center pl-2">
									<svg
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										viewBox="0 0 24 24"
										className="w-6 h-6 text-gray-300"
									>
										<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
									</svg>
								</span>
								<input
									type="search"
									className="block w-full py-1 pl-10 bg-gray-100 rounded-xl outline-none"
									name="search"
									placeholder="Search"
									required
									onChange={(e) => setFilters(e)}
								/>
							</div>
						</div>
						<div className="mx-auto w-full md:w-7/8 lg:w-3/5">
							<div className="flex justify-around">
								<FilterTab tabListType="members" label="Members" />
								<FilterTab tabListType="roles" label="Roles" />
								{organisation?.departments && organisation?.departments?.length > 0 && <FilterTab tabListType="depts" label="Departments" />}
							</div>
							<div className="flex flex-col text-darkww-700 font-bold mx-5 my-2">
								<span className="mb-2 underline text-lg">Summary :</span>

								<div className="relative grid grid-cols-2 gap-4">
									<div className=" flex flex-row items-center justify-self-end">
										<button className="absolute bg-darkww-400 text-white px-1 py-1 left-0 text-xs rounded" onClick={resetCheckedMembers}>
											Reset Checks
										</button>
										<span className="text-sm font-medium text-gray-400">Checked Employees:</span>
										<div className="ml-2 rounded-lg px-2 bg-blue-100">
											{checkedMembers.filter((memberId) => filteredMembers.some((member) => member.orgUserId === memberId)).length}
										</div>
									</div>

									<div className="flex flex-row items-center justify-self-end">
										{/* <input type="checkbox" checked={showClockedIn} onChange={() => setShowClockedIn(!showClockedIn)} className="mr-2 rounded-sm" /> */}
										<span className="text-sm font-medium text-gray-400">Employees clocked in, not finished</span>
										<div className="ml-2 rounded-lg px-2 bg-blue-100">{punchesWithMembers.length}</div>
									</div>
									<div className="flex flex-row items-center justify-self-end">
										{/* <input
												type="checkbox"
												checked={showRosteredToday}
												onChange={() => setShowRosteredToday(!showRosteredToday)}
												className="mr-2 rounded-sm"
											/> */}
										<span className="text-sm font-medium text-gray-400">Employees rostered for current time:</span>
										<div className="ml-2 rounded-lg px-2 bg-blue-100">{membersRosteredToday.length}</div>
									</div>
									<div className="flex flex-row items-center justify-self-end">
										{/* <input type="checkbox" checked={showClockedIn} onChange={() => setShowClockedIn(!showClockedIn)} className="mr-2 rounded-sm" /> */}
										<span className="text-sm font-medium text-gray-400">Employees currently on break:</span>
										<div className="ml-2 rounded-lg px-2 bg-blue-100">{breakCount}</div>
									</div>
								</div>

								<hr className="my-1" />
							</div>

							<div className={`mx-3 my-1 overflow-auto h-96 ${width < 500 && "hidden"}`}>
								<div className="relative text-gray-600">
									<span className="inset-y-0 left-0 flex items-center pl-2">
										{listType === "members" ? (
											<div className="flex flex-col w-full">
												{filteredMembers
													?.filter((member) => !member.isPayRoll)
													.map((member, index) => (
														<MemberListTile
															key={index}
															index={index}
															member={member}
															shifts={shifts}
															punches={punches}
															setBreakCount={setBreakCount}
															checked={checkedMembers.includes(member.orgUserId)}
															handleCheckboxChange={handleCheckboxChange}
														/>
													))}
											</div>
										) : listType === "roles" ? (
											<div className="flex flex-col w-full">
												{organisation?.roles?.map((r) => (
													<span
														className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
														key={r.id}
														onClick={(e) => handelMembers(e, r, "role")}
													>
														<div style={{ backgroundColor: r?.labelColour }} className="h-6 md:h-12 w-6 rounded-l-md z-10"></div>
														<div className="w-full">
															<div className="flex justify-between">
																<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">{r.name}</span>
																<span className="relative">
																	{r?.unReadCount > 0 && (
																		<div className="rounded-full bg-pink-600 w-5 h-5 text-white center absolute flex items-center justify-center top-1 -left-2">
																			{r.unReadCount}
																		</div>
																	)}
																</span>
															</div>
														</div>
													</span>
												))}
											</div>
										) : (
											listType === "depts" && (
												<div className="flex flex-col w-full">
													{organisation.departments?.map((d) => (
														<span
															className={`cursor-pointer flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-darkww-100 cursor-pointer focus:outline-none`}
															key={d.id}
															onClick={(e) => handelMembers(e, d, "dept")}
														>
															<div className="w-full">
																<div className="flex justify-between">
																	<span className="block ml-10 sm:ml-4 font-semibold text-xl text-darkww-600">{d.name}</span>
																</div>
															</div>
														</span>
													))}
												</div>
											)
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const MemberListTile = ({ index, member, shifts, punches, setBreakCount, checked, handleCheckboxChange }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const memberShifts = shifts.filter((s) => s.memberID === member.orgUserId);
	const memberPunches = punches.filter((p) => memberShifts.some((s) => s.id === p.shiftID));

	const lastPunch = memberPunches[memberPunches.length - 1];
	const { action: lastAction, time: lastActionTime } = getLastAction(lastPunch);

	const toggleExpansion = () => setIsExpanded((prev) => !prev);

	return (
		<div className="mt-1" key={`${index}-${member.orgUserId}`}>
			<div className="h-12 flex items-center w-full">
				<div className={`flex flex-row flex-1 items-center px-2 bg-white py-1 ${isExpanded ? "shadow-sm" : "shadow-md"} rounded-md w-full`}>
					<div className="flex h-10 flex-1 items-center">
						<MemberAvatar member={member} />
						<div className="pl-2 text-left">
							<div className="text-sm font-medium truncate w-full">
								{member?.orgUsername ? member.orgUsername : personUtils.displayName(member) || "No Name"}
							</div>
						</div>
					</div>
					{memberShifts.length > 0 && (
						<div className="flex items-center pr-1">
							<Tooltip trigger={["hover"]} overlay={"Rostered Today"} placement="top">
								<CalendarIcon className="w-5 h-5 text-blue-500" />
							</Tooltip>
						</div>
					)}
					{memberPunches.length > 0 && (
						<div
							className={`flex items-center pr-2 rounded-lg px-1 h-8 ${
								lastAction === "Clocked Out" ? "bg-green-200" : lastAction === "On Break" ? "bg-green-100" : "bg-red-100"
							}`}
						>
							<ClockIcon className={`mr-1 w-5 h-5 ${lastAction === "Clocked Out" ? "text-green-500" : "text-pink-500"}`} />
							<span className="text-xs font-semibold text-gray-500">
								{lastAction} at {lastActionTime}
							</span>
						</div>
					)}
					<div className="flex items-center pr-2">
						<button onClick={toggleExpansion}>
							{isExpanded ? (
								<ChevronUpIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
							) : (
								<ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
							)}
						</button>
					</div>
					<input type="checkbox" checked={checked} onChange={() => handleCheckboxChange(member.orgUserId)} className="mr-2" />
				</div>
			</div>

			{/* Expanded view showing all punches */}
			{isExpanded && memberShifts.length > 0 && (
				<div className="bg-gray-100 p-2 shadow-md rounded-b-md">
					{memberShifts.map((shift, index) => {
						const { role, shiftStart, shiftEnd, setAsClose } = shift;
						const punch = memberPunches.find((p) => p.shiftID === shift.id);
						return (
							<div key={index} className="mt-1">
								<div className="flex items-center">
									<div className="flex flex-row flex-1 py-0 shadow-md rounded-md">
										<div style={{ backgroundColor: role?.labelColour }} className="w-8 rounded-l-md"></div>
										<div className="flex-1 py-1 bg-white rounded-r-md flex items-center justify-between">
											<div className="flex flex-row">
												<div className="pl-2 flex flex-col">
													<div className=" flex flex-row items-center">
														<Tooltip trigger={["hover"]} overlay={"Rostered Today"} placement="top">
															<CalendarIcon className="w-5 h-5 -ml-2 pr-1 text-blue-500" />
														</Tooltip>
														<span className="inline-block text-sm font-medium text-gray-400">Start</span>
													</div>
													<div className={`bg-darkww-500 border-2 border-white shadow-sm rounded-md px-2`}>
														<div className="text-white text-xs">{epochToTimeStr(shiftStart)}</div>
													</div>
												</div>
												<div className="ml-2 flex flex-col">
													<span className="text-sm font-medium text-gray-400">End</span>
													<div className="bg-darkww-700 border-2 border-white shadow-sm rounded-md px-2 w-fit">
														<div className="text-white text-xs">{setAsClose ? "Close" : epochToTimeStr(shiftEnd)}</div>
													</div>
												</div>
											</div>

											{/* Display punch in/out and break info */}
											{punch && (
												<div className="flex flex-row">
													<div className="pl-2 flex flex-col">
														<div className=" flex flex-row items-center">
															<Tooltip trigger={["hover"]} overlay={"Clock-in Times"} placement="top">
																<ClockIcon className="w-5 h-5 -ml-2 pr-1 text-red-500" />
															</Tooltip>
															<span className="text-sm font-medium text-gray-400">In</span>
														</div>
														<div className={`bg-red-600 border-2 border-white shadow-sm rounded-md px-2`}>
															<div className="text-white text-xs">{epochToTimeStr(punch.in)}</div>
														</div>
													</div>
													{punch.breaks && punch.breaks.length > 0 && (
														<div className="ml-2 flex flex-col">
															<span className="text-sm font-medium text-gray-400">Breaks</span>
															{punch.breaks.map((breakData, index) => {
																const parsedBreak = JSON.parse(breakData);
																return (
																	<div key={index} className="bg-pink-200 border-2 border-white shadow-sm rounded-md px-2">
																		<div className="text-xs text-gray-800">
																			From {epochToTimeStr(parsedBreak.start)}
																			{parsedBreak.end && <span> to {epochToTimeStr(parsedBreak.end)}</span>}
																		</div>
																	</div>
																);
															})}
														</div>
													)}
													<div className="ml-2 flex flex-col">
														<span className="text-sm font-medium text-gray-400">Out</span>
														<div className={`${punch.out ? "bg-green-600" : "bg-gray-100"} border-2 border-white shadow-sm rounded-md px-2 w-fit`}>
															<div className="text-white text-xs">{epochToTimeStr(punch.out)}</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default AttendanceView;
