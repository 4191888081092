import { API, graphqlOperation } from "aws-amplify";

import { dateMeta } from "ww-framework";
import { deletePreference as AWSDeletePreferences, createPreference, updatePreference } from "../../graphql/mutations.js";
import { getOrgPreferences, getPreferenceOrgMember } from "../../graphql/queries.js";

export const getPreferences = async (organisationID, startRange, endRange) => {
	return new Promise(async (resolve, reject) => {
		// endRange will be the very start of the day, need to set it to 23:59 to include shifts on that day.
		const inclLastDay = endRange + 23 * 60 * 60 + 59 * 60;
		const queryParams = {
			organisationID,
			limit: 1000,
			filter: { preferenceDate: { between: [startRange, inclLastDay] } }
		};

		try {
			// The legacy shifts refers to the shifts that were stored prior to the weekStartDay index being added to the shifts. This call can hopefully be removed in the future when ALL shifts have this index populated.
			const { data } = await API.graphql(graphqlOperation(getOrgPreferences, queryParams));

			const { getOrgPreferences: legacyPreferences } = data;
			resolve(legacyPreferences);
		} catch (error) {
			reject(error);
		}
	});
};

export const getUserPreferences = async (memberID, startRange, endRange) => {
	return new Promise(async (resolve, reject) => {
		// endRange will be the very start of the day, need to set it to 23:59 to include shifts on that day.
		const inclLastDay = endRange + 23 * 60 * 60 + 59 * 60;
		const queryParams = {
			memberID,
			limit: 1000,
			filter: { preferenceDate: { between: [startRange, inclLastDay] } }
		};

		try {
			// The legacy shifts refers to the shifts that were stored prior to the weekStartDay index being added to the shifts. This call can hopefully be removed in the future when ALL shifts have this index populated.
			const { data } = await API.graphql(graphqlOperation(getPreferenceOrgMember, queryParams));

			const { getPreferenceOrgMember: legacyPreferences } = data;
			resolve(legacyPreferences);
		} catch (error) {
			reject(error);
		}
	});
};

export const savePreference = async (preferenceDetails) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { organisationID, preferenceType, preferenceDate, memberID, repeat, repeatEndTimestamp, preferenceNote } = preferenceDetails;

			if (repeat) {
				const startDate = new Date(preferenceDate * 1000);
				const repeatEndDate = new Date(repeatEndTimestamp * 1000);
				const daysUntilNextSameDay = (7 - startDate.getDay() + startDate.getDay()) % 7;
				startDate.setDate(startDate.getDate() + daysUntilNextSameDay);
				let preferencesToSave = [];

				const existingPreferences = await getExistingPreferencesTillDate(memberID, startDate, repeatEndDate);

				for (let date = startDate; date <= repeatEndDate; date.setDate(date.getDate() + 7)) {
					//if its the first entry, preferenceId will be null and a new entry will be created, otherwise it will be the id of the existing preference
					const preferenceId = existingPreferences.find((pref) => pref.preferenceDate === date.getTime() / 1000)?.id || null;

					if (preferenceType === "DELETE") {
						if (preferenceId) {
							await deletePreference(preferenceId);
						}
						continue;
					}

					preferencesToSave.push({
						id: preferenceId,
						organisationID,
						status: preferenceType,
						preferenceDate: date.getTime() / 1000,
						memberID,
						repeat: true,
						repeatEndDate: repeatEndTimestamp,
						note: preferenceNote
					});
				}
				const savedPreferences = await Promise.all(
					preferencesToSave.map((preference) => {
						const mutation = preference.id ? updatePreference : createPreference;
						return API.graphql({
							query: mutation,
							variables: { input: preference }
						});
					})
				);
				const updatedPreferences = savedPreferences.map(({ data }) => {
					return data[preferenceDetails.id ? "updatePreference" : "createPreference"];
				});

				resolve(updatedPreferences);
			} else {
				let preferenceMapped = {
					id: preferenceDetails.id || null,
					organisationID: preferenceDetails.organisationID,
					status: preferenceDetails.preferenceType,
					preferenceDate: preferenceDetails.preferenceDate,
					memberID: preferenceDetails.memberID,
					repeat: preferenceDetails.repeat,
					note: preferenceNote
				};
				const mutation = preferenceDetails.id ? updatePreference : createPreference;
				const { data } = await API.graphql({
					query: mutation,
					variables: { input: preferenceMapped }
				});
				const savedPreference = data[preferenceDetails.id ? "updatePreference" : "createPreference"];

				const updatedPreference = { ...preferenceDetails, ...savedPreference };
				resolve(updatedPreference);
			}
		} catch (error) {
			reject(error);
		}
	});
};

const getExistingPreferencesTillDate = async (memberID, startDate, endDate) => {
	let startDateEpoch = dateMeta(startDate).baseEpoch;
	let endDateEpoch = dateMeta(endDate).baseEpoch;
	try {
		const preferences = await getUserPreferences(memberID, startDateEpoch, endDateEpoch);
		return preferences.items;
	} catch (error) {
		console.log("Error fetching existing preference:", error);
		return null;
	}
};

export const deletePreference = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			await API.graphql({
				query: AWSDeletePreferences,
				variables: { input: { id: id } }
			});
			resolve();
		} catch (error) {
			reject(error);
		}
	});
};
