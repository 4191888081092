import { API, graphqlOperation } from "aws-amplify";
import {
	createAllMemberGiveAway,
	createAllMemberSwapShift,
	createGiveAway,
	createRequest,
	deleteAllMemberGiveAway,
	deleteAllMemberSwapShift,
	deleteGiveAway,
	updateGiveAway,
	updateRequest
} from "../../graphql/mutations.js";
import {
	getOrgGiveAway,
	getOrgMemberGiveAway,
	getOrgMemFromRequest,
	getOrgRequest,
	listAllMemberGiveAways,
	listAllMemberSwapShifts
} from "../../graphql/queries.js";

export const saveRequest = async (request) => {
	return new Promise(async (resolve, reject) => {
		try {
			let requestMapped = {
				id: request.id || null,
				organisationID: request.organisationID,
				fromMemberID: request.fromMemberID,
				fromShiftId: request.fromShiftId,
				toShiftId: request.toShiftId,
				memberID: request.memberID,
				status: request?.status || "PENDING",
				expirationTime: request?.expirationTime,
				note: request?.note || null
			};
			const mutation = request.id ? updateRequest : createRequest;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: requestMapped }
			});
			const savedRequest = data[request.id ? "updateRequest" : "createRequest"];
			const updatedRequest = { ...request, ...savedRequest };
			resolve(updatedRequest);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveAllRequest = async (request) => {
	return new Promise(async (resolve, reject) => {
		try {
			let requestMapped = {
				id: request.id || null,
				organisationID: request.organisationID,
				fromMemberID: request.fromMemberID,
				fromShiftId: request.fromShiftId,
				roleID: request.roleID,
				expirationTime: request?.expirationTime,
				note: request?.note || null
			};
			const { data } = await API.graphql({
				query: createAllMemberSwapShift,
				variables: { input: requestMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const getAllRequest = async (roleIDs = [], orgID) => {
	return new Promise(async (resolve, reject) => {
		try {
			let filter = { organisationID: { eq: orgID } };
			if (roleIDs && roleIDs.length > 0) {
				let filterMembers = roleIDs.map((item) => JSON.parse(`{"roleID":{"eq":"${item}"}}`));
				filter = { and: [{ or: filterMembers }, { organisationID: { eq: orgID } }] };
			}
			const { data } = await API.graphql(
				graphqlOperation(listAllMemberSwapShifts, {
					limit: 20,
					filter: filter
				})
			);
			const { listAllMemberSwapShifts: legacyRequest } = data;
			resolve(legacyRequest);
		} catch (error) {
			reject(error);
		}
	});
};
export const getMemberRequests = async (orgID, memberID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgRequest, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "DESC",
					filter: { memberID: { eq: memberID } }
				})
			);
			const { getOrgRequest: legacyRequest } = data;
			resolve(legacyRequest);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrgRequests = async (orgID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgRequest, {
					organisationID: orgID,
					limit: 1000,
					sortDirection: "DESC"
				})
			);
			const { getOrgRequest: legacyRequest } = data;
			resolve(legacyRequest);
		} catch (error) {
			reject(error);
		}
	});
};
export const getFromMemberRequests = async (memberID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgMemFromRequest, {
					fromMemberID: memberID,
					limit: 1000,
					sortDirection: "DESC"
				})
			);
			const { getOrgMemFromRequest: legacyRequest } = data;
			resolve(legacyRequest);
		} catch (error) {
			reject(error);
		}
	});
};
// GiveAway
export const saveGiveAway = async (giveAway) => {
	return new Promise(async (resolve, reject) => {
		try {
			let giveAwayMapped = {
				id: giveAway.id || null,
				organisationID: giveAway.organisationID,
				fromMemberID: giveAway.fromMemberID,
				memberID: giveAway.memberID,
				shiftId: giveAway.shiftId,
				status: giveAway?.status || "PENDING",
				expirationTime: giveAway?.expirationTime,
				note: giveAway?.note || null
			};
			const mutation = giveAway.id ? updateGiveAway : createGiveAway;
			const { data } = await API.graphql({
				query: mutation,
				variables: { input: giveAwayMapped }
			});
			const savedGiveAway = data[giveAway.id ? "updateGiveAway" : "createGiveAway"];
			const updatedGiveAway = { ...giveAway, ...savedGiveAway };
			resolve(updatedGiveAway);
		} catch (error) {
			reject(error);
		}
	});
};
export const getMemberGiveAway = async (orgID, memberID = null) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = memberID
				? await API.graphql(
						graphqlOperation(getOrgGiveAway, {
							organisationID: orgID,
							limit: 1000,
							filter: { memberID: { eq: memberID } },
							sortDirection: "DESC"
						})
				  )
				: await API.graphql(
						graphqlOperation(getOrgGiveAway, {
							organisationID: orgID,
							limit: 1000,
							sortDirection: "DESC"
						})
				  );
			const { getOrgGiveAway: legacyRequest } = data;
			resolve(legacyRequest);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrganisationMemberGiveAway = async (orgID, memberID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(getOrgMemberGiveAway, {
					organisationID: orgID,
					fromMemberID: { eq: memberID },
					limit: 1000,
					sortDirection: "DESC"
				})
			);
			const { getOrgMemberGiveAway: memberGiveAways } = data;
			resolve(memberGiveAways);
		} catch (error) {
			reject(error);
		}
	});
};
export const getOrganisationMembersGiveAways = async (orgID, memberIDs) => {
	return new Promise(async (resolve, reject) => {
		try {
			let filterMembers = memberIDs.map((item) => JSON.parse(`{"fromMemberID":{"eq":"${item}"}}`));
			filterMembers = filterMembers.concat(memberIDs.map((item) => JSON.parse(`{"memberID":{"eq":"${item}"}}`)));

			const { data } = await API.graphql(
				graphqlOperation(getOrgGiveAway, {
					organisationID: orgID,
					limit: 1000,
					filter: { or: filterMembers },
					sortDirection: "DESC"
				})
			);
			const { getOrgGiveAway: memberGiveAways } = data;
			resolve(memberGiveAways);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyGiveAway = async (giveAwayID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteGiveAway,
				variables: { input: { id: giveAwayID } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const saveAllGiveAway = async (request) => {
	return new Promise(async (resolve, reject) => {
		try {
			let requestMapped = {
				id: request?.id || null,
				organisationID: request.organisationID,
				fromMemberID: request.fromMemberID,
				shiftId: request.shiftId,
				roleID: request.roleID,
				expirationTime: request?.expirationTime,
				note: request?.note || null
			};
			const { data } = await API.graphql({
				query: createAllMemberGiveAway,
				variables: { input: requestMapped }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const getAllGiveAway = async (roleIDs = [], orgID, memberID) => {
	return new Promise(async (resolve, reject) => {
		try {
			let filter = { and: [{ organisationID: { eq: orgID } }, { fromMemberID: { ne: memberID } }] };
			if (roleIDs && roleIDs.length > 0) {
				let filterMembers = roleIDs.map((item) => JSON.parse(`{"roleID":{"eq":"${item}"}}`));
				filter = { and: [{ or: filterMembers }, { organisationID: { eq: orgID } }, { fromMemberID: { ne: memberID } }] };
			}
			const { data } = await API.graphql(
				graphqlOperation(listAllMemberGiveAways, {
					limit: 20,
					filter: filter
				})
			);
			const { listAllMemberGiveAways: legacyRequest } = data;
			resolve(legacyRequest);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyAllGiveAway = async (giveAwayID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql({
				query: deleteAllMemberGiveAway,
				variables: { input: { id: giveAwayID } }
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
export const destroyAllMemberSwapShiftByShiftId = async (ShiftId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(
				graphqlOperation(listAllMemberSwapShifts, {
					filter: {
						fromShiftId: { eq: ShiftId }
					}
				})
			);
			const results = await Promise.all(
				data.listAllMemberSwapShifts.items.map(async (request) => {
					await API.graphql({
						query: deleteAllMemberSwapShift,
						variables: { input: { id: request.id } }
					});
				})
			);
			resolve(results);
		} catch (error) {
			console.log(error);
		}
	});
};

export const destroyAllMemberSwapShift = async (id) => {
	return new Promise(async (resolve, reject) => {
		try {
			const results = await API.graphql({
				query: deleteAllMemberSwapShift,
				variables: { input: { id: id } }
			});

			resolve(results);
		} catch (error) {
			reject(error);
		}
	});
};
